import { faCircleCheck, faCircleQuestion, faCircleXmark, faFaceSmile, faFaceSmileBeam } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ieoStatus = (status: string) => {
    switch (status) {
        case 'I':
            return <FontAwesomeIcon icon={faCircleCheck} color="#34A853"  className="fs-5"/>

            break;
        case 'E':
            return <FontAwesomeIcon icon={faCircleXmark} color="#E94560"    className="fs-5 text-denger"/>
            break;
        case 'O':
            return <FontAwesomeIcon icon={faCircleQuestion} color="#63e6be"    className="fs-5 text-denger"/>
            break;
        default:
            return ""

    }
}

export default ieoStatus;


export function convertTo12HourFormat(time: string): string {
    // Split the time into hours, minutes, and seconds
    let [hours, minutes, seconds] = time.split(':').map(Number);

    // Determine AM or PM
    const period = hours < 12 ? 'AM' : 'PM';

    // Convert hours from 24-hour to 12-hour format
    if (hours === 0) {
        hours = 12; // Midnight case
    } else if (hours > 12) {
        hours -= 12;
    } else if (hours === 12) {
        // Do nothing, it's already 12 PM
    }

    // Format the time in 12-hour format with leading zeros for hours and minutes
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;

    return formattedTime;
}


export function getTripStatus(status: number): string {
    switch (status) {
        case -1:
            return "Deleted";
        case 0: 
            return "Draft";
        case 1:
            return "Approval Pending";
        case 2: 
            return "Live";
        case 3: 
            return "Rejected";
        case 4:
            return "Terminated";
        default:
            return "Unknown"; // or handle other cases as needed
    }
}


