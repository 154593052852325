// src/types/interfaces.ts

import { first } from "lodash";
import { ToastOptions } from "react-toastify";

//Trip Location
export interface LocationItem {
  id: number;
  name: string;
  sub_heading: string | null;
  slug: string;
  long_name: string;
  country_code: string;
  short_code: string;
  latitude: string;
  longitude: string;
  viewport_east: string;
  viewport_west: string;
  viewport_north: string;
  viewport_south: string;
  description: string | null;
  master_currency_code: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  parent_id: number;
  status: number;
  locationType: {
    code: string;
    name: string;
  };
  coverImage: Image;
}

export interface LocationState {
  locations: LocationItem[] | null;
  loading: boolean;
  error: string | null;
}

export interface ImageDetails {
  id: number;
  name: string;
  caption: string;
  alt: string;
  url: string;
}

export type Month = {
  month_no: number;
  full_name: string;
  short_name: string;
};

export type MonthMetaData = {
  meta_months_no: number;
  month: Month;
};
//Trip Category

export interface TripCategoryItem {
  id: number;
  code: string;
  name: string;
  sub_heading: string;
  slug: string;
  description: string;
  image: string | null;
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  is_in_topmost: number;
  status: number;
  iconImage: Image | null;
  bannerImage: Image | null;
  listingImage: Image | null;
}

export const intialTripCategoryItem: TripCategoryItem = {
  id: 0,
  code: "",
  name: "",
  sub_heading: "",
  slug: "",
  description: "",
  image: "",
  meta_title: "",
  meta_description: "",
  meta_keywords: "",
  is_in_topmost: 0,
  status: 0,
  iconImage: null,
  bannerImage: null,
  listingImage: null,
};

//Exclusive-inclusive

export interface IconImage {
  name: string;
  caption: string;
  alt: string;
  url: string;
}

export interface InclusiveExclusiveItem {
  code: string;
  name: string;
  iconImage: IconImage;
}

//Ground Transport
export interface GroundTransportItem {
  code: string;
  name: string;
  iconImage: IconImage;
}

//Ground Transport
export interface ActivityCategoryTypeItem {
  code: string;
  name: string;
  iconImage: IconImage;
}

//Ground Transport
export interface ActivityCategoryTypeItem {
  code: string;
  name: string;
  iconImage: IconImage;
}

//activity category
export interface ActivityCategoryType {
  code: string;
  name: string;
}

export interface ActivityCategoryItem {
  code: string;
  name: string;
  iconImage: IconImage;
  activityCategoryType: ActivityCategoryType;
}

/*Metas */
export interface CurrencyData {
  code: string;
  name: string;
  symbol: string | null;
}

export interface LanguageData {
  code: string;
  parent_code: string | null;
  name: string;
  updated: string;
}

export interface CountryData {
  code: string;
  name: string | null;
  iso_a3: string;
  iso_num: string;
}

export interface MonthData {
  month_no: number;
  full_name: string;
  short_name: string;
}

export type OptionType = { value: string; label: string };
export interface Image {
  id: number;
  name: string;
  caption: string;
  alt: string;
  width: number;
  height: number;
  url: string;
}

export interface Location {
  id: number;
  name: string;
  sub_heading: string | null;
  slug: string;
  long_name: string;
  country_code: string;
  short_code: string;
  latitude: string;
  longitude: string;
  viewport_east: string;
  viewport_west: string;
  viewport_north: string;
  viewport_south: string;
  description: string | null;
  master_currency_code: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  parent_id: number;
  status: number;
  locationType: {
    code: string;
    name: string;
  };
  coverImage: any; // Assuming coverImage could be another complex type or null
}

export interface MealsItem {
  id: number;
  code: string;
  name: string;
  iconImage: Image;
}

export interface BaseActivity {
  id: number;
  trip_category_code: string;
  tag_id: any[];
  title: string;
  note: string;
  start_location: string;
  is_end_location_available: number;
  end_location: string;
  is_optional: string;
  airline: string;
  ground_transport_code: string;
  departure_location: string;
  departure_time: string;
  arrival_location: string;
  arrival_time: string;
  start_at: string;
  duration: string;
  duration_type: string;
  sequence: number;
  status: number;
  groundTransport: string;
  listingImages: number[];
  listingImagesPreview: Image[];
  listingImagesPreserve: number[];
  types: string;
  startLocation: Location;
  endLocation: Location;

  check_in_time: string;
  check_out_time: string;
  selectedStartLoc: { value: string; label: string };
  selectedEndLoc: { value: string; label: string };
  selectedGtCategory: { value: string; label: string };
  selectedArrivalLoc: { value: string; label: string };
  selectedDeptLoc: { value: string; label: string };
  airlineOp: { value: string; label: string };
}

export interface ActivitiesType {
  ACTI: BaseActivity;
  ACCO: BaseActivity;
  FLIG: BaseActivity;
  GROU: BaseActivity;
  INFO: BaseActivity;
}
export interface DayItinerary {
  id: number;
  title: string;
  info: string;
  start_location: string;
  is_end_location: string;
  end_location: any; // Assuming endLocation could be another complex type or null
  hotel_name: string;
  hotel_latitude: string;
  hotel_longitude: string;
  day: number;
  status: number;
  listingImages: number[];
  listingImagesPreview: Image[];
  listingImagesPreserve: number[];
  startLocation: Location;
  endLocation: Location; // Assuming endLocation could be another complex type or null
  meals: MealsItem[];
  mealsArray: number[];
  selectedStartLoc: { value: string; label: string };
  selectedEndLoc: { value: string; label: string };
  is_same_as_above: number;

  dayplanOrder: number[];
  activity: ActivitiesType[];
}

export interface Trip {
  id: number;
  version: string;
  trip_uuid: string;
  title: string;
  slug: string;
  meta_currency_code: string;
  price: number;
  no_of_days: number;
  start_location: number;
  end_location: number;
  banner: number;
  description: string | null;
  details: string | null;
  meta_title: string | null;
  is_meta_title_same_as_title: number;
  meta_keywords: string | null;
  is_meta_title_same_as_trip_tags: number;
  meta_description: string | null;
  is_meta_title_same_as_description: number;
  privacy_policy: string | null;
  term_and_conditions: string | null;
  change_policy: string | null;
  must_carry: string | null;
  accomodation_status: string;
  accomodation_note: string | null;
  flight_status: string;
  flight_note: string | null;
  ground_transport_status: string;
  ground_transport_note: string | null;
  meals_status: string;
  meals_note: string | null;
  included: string | null;
  excluded: string | null;
  optional: string | null;
  agent_id: number;
  owner_id: number;
  status: number;
  iswishlisted: number;
  bannerImage: Image;
  listingImages: Image[];
  tripCategory: any[]; // Adjust this according to the actual structure of tripCategory
  tripAvailability: any[]; // Adjust this according to the actual structure of tripAvailability
  dayItinerary: DayItinerary[];
}

//for user Profile
type Role = {
  code: string;
  name: string;
};

export type Agent = {
  id: number;
  name: string;
  slug: string;
  meta_type_code: string;
  parent_id: number | null;
  email_address: string;
  phone_number: string | null;
  website_url: string | null;
  address_id: number;
  profile_image_id: number | null;
  date_of_birth: string | null;
  gender: string | null;
  timezone_id: number;
  meta_agent_status_code: string;
  about: string;
  signup_notes: string;
  sub_title: string;
  facebook: string;
  instagram: string;
  pinterest: string;
  tiktok: string;
  twitter: string;
  youtube: string;
  cover_image_id: number | null;
  default_currency_code: string;
  notes: string | null;
  score: number;
  score_weighting: string;
  address_line_1: string | null;
  address_line_2: string | null;
  address_line_3: string | null;
  address_line_city: string | null;
  address_line_state: string | null;
  address_master_country_code: string | null;
  address_postal_code: string | null;
  status: number;
  coverImage: Image;
  location: Location;
  profileImage: Image;
};

export type UserAgent = {
  role: Role;
  agent: Agent;
};

type User = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  image_id: number | null;
  phone_number: string;
  email: string;
  dob: string;
  passport_first_name: string;
  passport_last_name: string;
  passport_nationality_iso_a3: string;
  passport_number: string;
  user_type: number;
  is_system_user: number;
  is_superadmin: number;
  status: number;
  image: Image;
  userTypeString: string;
  userAgent: UserAgent;
  travelStyleTag: Tags[];
  preferredTripTypeTag: Tags[];
  travelPrefernceTag: Tags[];
  lookingForTag: Tags[];
  airports: AirportTags[];
  topDestination: TopDestinationTags[];
};

export type UserProfile = {
  user: User;
};
const defaultImage: Image = {
  id: 0,
  name: "",
  caption: "",
  alt: "",
  width: 0,
  height: 0,
  url: "",
};

const tags: Tags = {
  id: 0,
  name: "",
  slug: "",
  description: "",
  notes: null,
  coverImage: defaultImage,
};

export const initialLocationItem: LocationItem = {
  id: 0,
  name: "",
  sub_heading: null,
  slug: "",
  long_name: "",
  country_code: "",
  short_code: "",
  latitude: "",
  longitude: "",
  viewport_east: "",
  viewport_west: "",
  viewport_north: "",
  viewport_south: "",
  description: null,
  master_currency_code: null,
  meta_title: null,
  meta_keywords: null,
  meta_description: null,
  parent_id: 0,
  status: 0,
  locationType: {
    code: "",
    name: "",
  },
  coverImage: defaultImage,
};

const initialAgent: Agent = {
  id: 0,
  name: "",
  slug: "",
  meta_type_code: "",
  parent_id: null,
  email_address: "",
  phone_number: null,
  website_url: null,
  address_id: 0,
  profile_image_id: null,
  date_of_birth: null,
  gender: null,
  timezone_id: 0,
  meta_agent_status_code: "",
  about: "",
  signup_notes: "",
  sub_title: "",
  facebook: "",
  instagram: "",
  pinterest: "",
  tiktok: "",
  twitter: "",
  youtube: "",
  cover_image_id: null,
  default_currency_code: "USD",
  notes: null,
  score: 0,
  score_weighting: "1.00000",
  address_line_1: null,
  address_line_2: null,
  address_line_3: null,
  address_line_city: null,
  address_line_state: null,
  address_master_country_code: null,
  address_postal_code: null,
  status: 1,
  coverImage: defaultImage,
  location: initialLocationItem,
  profileImage: defaultImage,
};
export const initialUserProfile: UserProfile = {
  user: {
    id: 0,
    username: "",
    first_name: "",
    last_name: "",
    image_id: 0,
    phone_number: "",
    email: "",
    dob: "",
    passport_first_name: "",
    passport_last_name: "",
    passport_nationality_iso_a3: "",
    passport_number: "",
    user_type: 0,
    is_system_user: 0,
    is_superadmin: 0,
    status: 0,
    userTypeString: "",
    image: defaultImage,
    userAgent: {
      role: {
        code: "",
        name: "",
      },
      agent: initialAgent,
    },
    travelStyleTag: [],
    preferredTripTypeTag: [],
    travelPrefernceTag: [],
    lookingForTag: [],
    airports: [],
    topDestination: [],
  },
};
interface Status {
  id: number;
  name: string;
}

type TripStatus = {
  trip_status_datetime: string; // "2024-07-17 11:06:47"
  status: Status;
};
interface Version {
  version: string;
  tripStatus: TripStatus;
  tripStatusDatetime: string;
}

export interface Tags {
  id: number;
  name: string;
  slug: string;
  description: string;
  notes: string | null;
  coverImage: Image;
}
export interface BlogsCategoryFront {
  id: number | null;
  name: string;
  slug: string;
  blogCount: number;
}

export interface BlogFiltersFront {
  pageIndex: number;
  search: string;
  pageSize: number;
  category: BlogsCategoryFront;
}

export interface AirportTags {
  code: string;
  name: string;
  country_code: string;
  location_name: string;
  latitude: string | null;
  longitude: string | null;
}
export interface TopDestinationTags {
  id: number;
  name: string;
  sub_heading: string;
  slug: string | null;
  long_name: string | null;
  latitude: string | null;
  longitude: string | null;
  is_top_location: number;
}

//trip details props

export type TripDetailsFormProps = {
  id?: string;
  version: string;
  versions: Version[];
  tags: Tags[];
  agent: Agent;
  pendingVersion: Version;
  liveVersion: Version;
  trip_uuid: string;
  status: number;
  tripStatus: TripStatus;
  title?: string;
  meta_currency_code?: string;
  priceExchange: {
    currency: string;
    price: number | null;
  };
  price?: string;
  no_of_days?: number;
  start_location?: string;
  start_location_option?: OptionType;
  endLocation: Location;
  startLocation: Location;
  end_location?: string;
  end_location_option?: OptionType;
  banner_image?: any;
  listing_images?: any;
  listingImages?: Image[];
  description?: string;
  banner_image_preview: any;
  listing_image_preview: any;
  listing_images_preserve: number[];
  iswishlisted: number;
  details?: string;

  slectedCategoriesCode: number[];
  selectedCategories?: TripCategoryItem[];
  trip_availabilities_code: number[];
  trip_availabilities?: Month[];

  meta_title: string;
  is_meta_title_same_as_title: number;
  meta_keywords: string;
  is_meta_title_same_as_trip_tags: number;
  meta_description: string;
  is_meta_title_same_as_description: number;

  // accomodation: {
  //     accomodation_status: string;
  //     accomodation_note: string;
  // },
  // flight: {
  //     flight_status: string;
  //     flight_note: string;
  // },
  // ground_transport: {
  //     ground_transport_status: string;
  //     ground_transport_note: string;
  // },
  // meals: {
  //     meals_status: string;
  //     meals_note: string;
  // },

  accomodation_status: string;
  accomodation_note: string;
  flight_status: string;
  flight_note: string;
  ground_transport_status: string;
  ground_transport_note: string;
  meals_status: string;
  meals_note: string;
  included: string;
  excluded: string;
  optional: string;

  privacy_policy: string;
  term_and_conditions: string;
  change_policy: string;
  must_carry: string;

  dayItinerary: DayItinerary[];
  dayItineraryOrder: number[];
};
export const initialLocation: Location = {
  id: 0,
  name: "",
  sub_heading: null,
  slug: "",
  long_name: "",
  country_code: "",
  short_code: "",
  latitude: "",
  longitude: "",
  viewport_east: "",
  viewport_west: "",
  viewport_north: "",
  viewport_south: "",
  description: null,
  master_currency_code: null,
  meta_title: null,
  meta_keywords: null,
  meta_description: null,
  parent_id: 0,
  status: 0,
  locationType: {
    code: "",
    name: "",
  },
  coverImage: null,
};

const versionData: Version = {
  version: "",
  tripStatus: {
    trip_status_datetime: "",
    status: {
      id: 1,
      name: "",
    },
  },
  tripStatusDatetime: "",
};

const tripStatus: TripStatus = {
  trip_status_datetime: "",
  status: {
    id: 0,
    name: "",
  },
};

// Initialize formData with correct types
export const initialFormData: TripDetailsFormProps = {
  id: "",
  version: "",
  versions: [],
  tags: [],
  agent: initialAgent,
  pendingVersion: versionData,
  liveVersion: versionData,
  trip_uuid: "",
  status: 0,
  tripStatus: tripStatus,
  title: "",
  meta_currency_code: "",
  priceExchange: {
    currency: "",
    price: null,
  },
  price: "",
  no_of_days: 0,
  start_location: "",
  endLocation: initialLocation,
  startLocation: initialLocation,
  start_location_option: { value: "", label: "" },
  end_location: "",
  end_location_option: { value: "", label: "" },
  banner_image: "",
  listingImages: [],
  listing_images: [],
  description: "",
  banner_image_preview: "",
  listing_image_preview: [],
  listing_images_preserve: [],
  iswishlisted: 0,
  details: "",
  slectedCategoriesCode: [],
  selectedCategories: [],
  trip_availabilities_code: [],
  trip_availabilities: [],
  meta_title: "",
  is_meta_title_same_as_title: 0,
  meta_keywords: "",
  is_meta_title_same_as_trip_tags: 0,
  meta_description: "",
  is_meta_title_same_as_description: 0,

  accomodation_status: "",
  accomodation_note: "",
  flight_status: "",
  flight_note: "",
  ground_transport_status: "",
  ground_transport_note: "",
  meals_status: "",
  meals_note: "",
  included: "",
  excluded: "",
  optional: "",

  privacy_policy: "",
  term_and_conditions: "",
  change_policy: "",
  must_carry: "",

  dayItinerary: [
    {
      id: 0,
      title: "",
      info: "",
      start_location: "",
      is_end_location: "",
      end_location: "",
      hotel_name: "",
      hotel_latitude: "",
      hotel_longitude: "",
      day: 0,
      status: 0,
      listingImages: [],
      listingImagesPreview: [],
      listingImagesPreserve: [],
      startLocation: initialLocation,
      endLocation: initialLocation,
      selectedStartLoc: { value: "", label: "" },
      selectedEndLoc: { value: "", label: "" },
      meals: [],
      mealsArray: [],
      dayplanOrder: [],
      activity: [],
      is_same_as_above: 0,
    },
  ],
  dayItineraryOrder: [],
};

export const initialActivitiesData: ActivitiesType = {
  ACTI: {
    id: 0,
    trip_category_code: "ACTI",
    tag_id: [],
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: 0,
    end_location: "",
    is_optional: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
    start_at: "",
    check_in_time: "",
    check_out_time: "",
    duration: "",
    duration_type: "",
    sequence: 0,
    status: 1,
    groundTransport: "",
    listingImages: [],
    listingImagesPreview: [],
    listingImagesPreserve: [],

    types: "",
    startLocation: initialLocation,
    endLocation: initialLocation,
    selectedStartLoc: { value: "", label: "" },
    selectedEndLoc: { value: "", label: "" },
    selectedGtCategory: { value: "", label: "" },
    selectedArrivalLoc: { value: "", label: "" },
    selectedDeptLoc: { value: "", label: "" },
    airlineOp: { value: "", label: "" },
  },

  ACCO: {
    id: 0,
    trip_category_code: "ACCO",
    tag_id: [],
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: 0,
    end_location: "",
    is_optional: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
    start_at: "",
    check_in_time: "",
    check_out_time: "",
    duration: "",
    duration_type: "",
    sequence: 0,
    status: 1,
    groundTransport: "",
    listingImages: [],
    listingImagesPreview: [],
    listingImagesPreserve: [],
    types: "",
    startLocation: initialLocation,
    endLocation: initialLocation,
    selectedStartLoc: { value: "", label: "" },
    selectedEndLoc: { value: "", label: "" },
    selectedGtCategory: { value: "", label: "" },
    selectedArrivalLoc: { value: "", label: "" },
    selectedDeptLoc: { value: "", label: "" },
    airlineOp: { value: "", label: "" },
  },

  FLIG: {
    id: 0,
    trip_category_code: "FLIG",
    tag_id: [],
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: 0,
    end_location: "",
    is_optional: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
    start_at: "",
    check_in_time: "",
    check_out_time: "",
    duration: "",
    duration_type: "",
    sequence: 0,
    status: 1,
    groundTransport: "",
    listingImages: [],
    listingImagesPreview: [],
    listingImagesPreserve: [],

    types: "",
    startLocation: initialLocation,
    endLocation: initialLocation,
    selectedStartLoc: { value: "", label: "" },
    selectedEndLoc: { value: "", label: "" },
    selectedGtCategory: { value: "", label: "" },
    selectedArrivalLoc: { value: "", label: "" },
    selectedDeptLoc: { value: "", label: "" },
    airlineOp: { value: "", label: "" },
  },

  GROU: {
    id: 0,
    trip_category_code: "GROU",
    tag_id: [],
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: 0,
    end_location: "",
    is_optional: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
    start_at: "",
    check_in_time: "",
    check_out_time: "",
    duration: "",
    duration_type: "",
    sequence: 0,
    status: 1,
    groundTransport: "",
    listingImages: [],
    listingImagesPreview: [],
    listingImagesPreserve: [],

    types: "",
    startLocation: initialLocation,
    endLocation: initialLocation,
    selectedStartLoc: { value: "", label: "" },
    selectedEndLoc: { value: "", label: "" },
    selectedGtCategory: { value: "", label: "" },
    selectedArrivalLoc: { value: "", label: "" },
    selectedDeptLoc: { value: "", label: "" },
    airlineOp: { value: "", label: "" },
  },

  INFO: {
    id: 0,
    trip_category_code: "INFO",
    tag_id: [],
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: 0,
    end_location: "",
    is_optional: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
    check_in_time: "",
    check_out_time: "",
    start_at: "",
    duration: "",
    duration_type: "",
    sequence: 0,
    status: 1,
    groundTransport: "",
    listingImages: [],
    listingImagesPreview: [],
    listingImagesPreserve: [],
    types: "",
    startLocation: initialLocation,
    endLocation: initialLocation,
    selectedStartLoc: { value: "", label: "" },
    selectedEndLoc: { value: "", label: "" },
    selectedGtCategory: { value: "", label: "" },
    selectedArrivalLoc: { value: "", label: "" },
    selectedDeptLoc: { value: "", label: "" },
    airlineOp: { value: "", label: "" },
  },
};

//for trip details Edit Mode

export type TripDetailsEditMode = {
  breifDescriptionEdit: boolean;
  tripCategoryEdit: boolean;
  monthsEdit: boolean;
  tagsEdit: boolean;
  seoEdit: boolean;
  ieEdit: boolean;
  addInfoEdit: boolean;
  privacyPolicy: boolean;
  termCondition: boolean;
  changePolicy: boolean;
  mustCarry: boolean;
  daysInfo: boolean[];
};

export const initialTripDetailsEditMode: TripDetailsEditMode = {
  breifDescriptionEdit: false,
  tripCategoryEdit: false,
  monthsEdit: false,
  tagsEdit: false,
  seoEdit: false,
  ieEdit: false,
  addInfoEdit: false,
  privacyPolicy: false,
  termCondition: false,
  changePolicy: false,
  mustCarry: false,
  daysInfo: [],
};

export interface TripLocation {
  startLocation: string;
  endLocation: string;
}

export interface ToastContextType {
  showToast: (message: string, options?: ToastOptions) => void;
  showSuccessToast: (message: string, options?: ToastOptions) => void;
  showInfoToast: (message: string, options?: ToastOptions) => void;
  showWarningToast: (message: string, options?: ToastOptions) => void;
  showErrorToast: (message: string, options?: ToastOptions) => void;
}

export const initialActivityError = {
  ACTI: {
    dayId: "",
    activityIndex: "",
    activityCode: "",
    tag_id: "",
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: "",
    end_location: "",
    is_optional: "",
    listingImages: "",
    trip_category_code: "",
    listing_images: "",
    start_at: "",
    duration: "",
    duration_type: "",
    check_in_time: "",
    check_out_time: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
  },
  ACCO: {
    dayId: "",
    activityIndex: "",
    activityCode: "",
    tag_id: "",
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: "",
    end_location: "",
    is_optional: "",
    listingImages: "",
    trip_category_code: "",
    listing_images: "",
    start_at: "",
    duration: "",
    duration_type: "",
    check_in_time: "",
    check_out_time: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
  },
  FLIG: {
    dayId: "",
    activityIndex: "",
    activityCode: "",
    tag_id: "",
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: "",
    end_location: "",
    is_optional: "",
    listingImages: "",
    trip_category_code: "",
    listing_images: "",
    start_at: "",
    duration: "",
    duration_type: "",
    check_in_time: "",
    check_out_time: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
  },
  GROU: {
    dayId: "",
    activityIndex: "",
    activityCode: "",
    tag_id: "",
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: "",
    end_location: "",
    is_optional: "",
    listingImages: "",
    trip_category_code: "",
    listing_images: "",
    start_at: "",
    duration: "",
    duration_type: "",
    check_in_time: "",
    check_out_time: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
  },
  INFO: {
    dayId: "",
    activityIndex: "",
    activityCode: "",
    tag_id: "",
    title: "",
    note: "",
    start_location: "",
    is_end_location_available: "",
    end_location: "",
    is_optional: "",
    listingImages: "",
    trip_category_code: "",
    listing_images: "",
    start_at: "",
    duration: "",
    duration_type: "",
    check_in_time: "",
    check_out_time: "",
    airline: "",
    ground_transport_code: "",
    departure_location: "",
    departure_time: "",
    arrival_location: "",
    arrival_time: "",
  },
};

export interface FilterStatus {
  status: number;
  title: string;
  month: number[];
  location: number;
  no_of_days: string;
}

export interface FilterLeads {
  name: string;
  tripName: string;
  month: number[];
  status: number;
}

export interface FilterStatusMain {
  status: number;
  title: string;
  start_date: Date | null;
  end_date: Date | null;
  // month: number[];
  location: number;
  location_name: string;
  destination: string;
  no_of_days: string;
  tour: string;
  categories: string[];
  min_price: number;
  max_price: number;
  agent_name: string;
  sort: string;
  availability: string;
  pageIndex: number;
}

export type EnquireNowFormProps = {
  trip_uuid: string;
  trip_version: string | null;
  no_of_travellers: number;
  trip_duration: number;
  is_date_firm: number | null;
  is_trip_lenth_firm: number | null;
  trip_date: Date;
  trip_type: string;
  trip_type_option: { value: string; label: string };
  travel_style: string;
  travel_style_option: { value: string; label: string };
  notes: string;
  depature_from: string;
  budget_min: number;
  budget_max: number;
  name: string;
  email: string;
  phone: string;
  recaptcha: string | null;
  meta_currency_code: string | null;
};

export const initialEnquireNow: EnquireNowFormProps = {
  trip_uuid: "",
  trip_version: "",
  no_of_travellers: 0,
  trip_duration: 0,
  is_date_firm: null,
  is_trip_lenth_firm: null,
  trip_date: new Date(),
  trip_type: "",
  trip_type_option: { value: "", label: "" },
  travel_style: "",
  travel_style_option: { value: "", label: "" },
  notes: "",
  depature_from: "",
  budget_min: 50,
  budget_max: 1000,
  name: "",
  email: "",
  phone: "",
  recaptcha: null,
  meta_currency_code: "",
};
export const initialEnqNowError = {
  trip_uuid: "",
  no_of_travellers: "",
  is_date_firm: "",
  trip_duration: "",
  trip_date: "",
  trip_type: "",
  is_trip_lenth_firm: "",
  travel_style: "",
  budget_min: "",
  budget_max: "",
  depature_from: "",
  name: "",
  email: "",
  phone: "",
  recaptcha: "",
  meta_currency_code: "",
  notes: "",
};

export type BecomeACreatorProps = {
  first_name: string | "";
  last_name: string | "";
  contact_no: string | "";
  email: string | "";
  location_id: number;
  instagram_url: string | "";
  tik_tok_url: string | "";
  facebook_url: string | "";
  other_url: string | "";
  travel_type_focus_on: string | "";
  how_currently_monetize: string | "";
  plan_to_grow_your_audience: string | "";
  recently_stayed_property: string | "";
  recently_stayed_property_latitude: string | "";
  recently_stayed_property_longitude: string | "";
  have_you_group_tour: string | "";
  why_you_wanna_join_us: string | "";
  add_me_to_creator_list: number;
  recaptcha: string | null;
};

export const initialBecomeACreator: BecomeACreatorProps = {
  first_name: "",
  last_name: "",
  contact_no: "",
  email: "",
  location_id: 0,
  instagram_url: "",
  tik_tok_url: "",
  facebook_url: "",
  other_url: "",
  travel_type_focus_on: "",
  how_currently_monetize: "",
  plan_to_grow_your_audience: "",
  recently_stayed_property: "",
  recently_stayed_property_latitude: "",
  recently_stayed_property_longitude: "",
  have_you_group_tour: "",
  why_you_wanna_join_us: "",
  add_me_to_creator_list: 0,
  recaptcha: null,
};
export const initialBecomeACreatorError = {
  first_name: "",
  last_name: "",
  contact_no: "",
  email: "",
  location_id: "",
  instagram_url: "",
  tik_tok_url: "",
  facebook_url: "",
  other_url: "",
  travel_type_focus_on: "",
  how_currently_monetize: "",
  plan_to_grow_your_audience: "",
  recently_stayed_property: "",
  recently_stayed_property_latitude: "",
  recently_stayed_property_longitude: "",
  have_you_group_tour: "",
  why_you_wanna_join_us: "",
  add_me_to_creator_list: "",
  recaptcha: "",
};

export type CreatorProfileProps = {
  name: string | "";
  location_id: number;
  location_option: { value: string; label: string };
  default_currency_code: string;
  currency_option: { value: string; label: string };
  email_address: string;
  phone_number: string;
  website_url: string;
  languages: string[];
  languages_option: { value: string; label: string }[];
  sub_title: string;
  about: string;
  facebook: string;
  instagram: string;
  pinterest: string;
  tiktok: string;
  youtube: string;
  twitter: string;
  profile_image_id: number | null;
  profile_image_2_id: number | null;
  listing_image_id: number | null;
  cover_image_id: number | null;
  profile_image_url: string | "";
  profile_image_2_url: string | "";
  listing_image_url: string | "";
  cover_image_url: string | "";
};

export const initialCreatorProfile: CreatorProfileProps = {
  name: "",
  location_id: 0,
  location_option: { value: "", label: "" },
  default_currency_code: "",
  currency_option: { value: "", label: "" },
  email_address: "",
  phone_number: "",
  website_url: "",
  languages: [],
  languages_option: [],
  sub_title: "",
  about: "",
  facebook: "",
  instagram: "",
  pinterest: "",
  tiktok: "",
  youtube: "",
  twitter: "",
  profile_image_id: null,
  profile_image_2_id: null,
  listing_image_id: null,
  cover_image_id: null,
  profile_image_url: "",
  profile_image_2_url: "",
  listing_image_url: "",
  cover_image_url: "",
};

export const initialCreatorProfileError = {
  name: "",
  location_id: "",
  default_currency_code: "",
  email_address: "",
  phone_number: "",
  website_url: "",
  languages: "",
  sub_title: "",
  about: "",
  facebook: "",
  instagram: "",
  pinterest: "",
  tiktok: "",
  youtube: "",
  twitter: "",
  profile_image_id: "",
  profile_image_2_id: "",
  listing_image_id: "",
  cover_image_id: "",
  profile_image_url: "",
  profile_image_2_url: "",
  listing_image_url: "",
  cover_image_url: "",
};

export type CreatorTravelerProps = {
  first_name: string | "";
  last_name: string | "";
  phone_number: string;
  email: string;
  notes: string;
};

export const initialTravlerProfile: CreatorTravelerProps = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  notes: "",
};
export const initialTravlerProfileError = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  notes: "",
};

export interface TravelerProfile {
  first_name: string;
  last_name: string;
  passport_first_name: string;
  passport_last_name: string;
  passport_nationality_iso_a3: string;
  passport_number: string;
  phone_number: string;
  dob: Date;
  travel_style_tags: number[];
  preferred_trip_type_tags: number[];
  travel_prefernce_tags: number[];
  looking_for_tags: number[];
  top_destionations: number[];
  preferred_airports: string[];
  image_id: string;
  profile_image_url: string;
}

export const initialTravelerProfile: TravelerProfile = {
  first_name: "",
  last_name: "",
  passport_first_name: "",
  passport_last_name: "",
  passport_nationality_iso_a3: "",
  passport_number: "",
  phone_number: "",
  dob: new Date(new Date().setFullYear(new Date().getFullYear() - 12)),
  travel_style_tags: [],
  preferred_trip_type_tags: [],
  travel_prefernce_tags: [],
  looking_for_tags: [],
  top_destionations: [],
  preferred_airports: [] as string[],
  image_id: "",
  profile_image_url: "",
};

export interface TravelerProfileStart {
  first_name: string;
  last_name: string;
  passport_first_name: string;
  passport_last_name: string;
  passport_nationality_iso_a3: string;
  passport_number: string;
  phone_number: string;
  dob: string;
  travel_style_tags: number[];
  preferred_trip_type_tags: number[];
  travel_prefernce_tags: number[];
  looking_for_tags: number[];
  top_destionations: number[];
  preferred_airports: string[];
  image_id: string;
  profile_image_url: string;
}

export const initialTravelerProfileStart: TravelerProfileStart = {
  first_name: "",
  last_name: "",
  passport_first_name: "",
  passport_last_name: "",
  passport_nationality_iso_a3: "",
  passport_number: "",
  phone_number: "",
  dob: "",
  travel_style_tags: [],
  preferred_trip_type_tags: [],
  travel_prefernce_tags: [],
  looking_for_tags: [],
  top_destionations: [],
  preferred_airports: [] as string[],
  image_id: "",
  profile_image_url: "",
};
export interface TravelerProfileError {
  first_name: string;
  last_name: string;
  passport_first_name: string;
  passport_last_name: string;
  passport_nationality_iso_a3: string;
  passport_number: string;
  phone_number: string;
  travel_style_tags: string;
  preferred_trip_type_tags: string;
  travel_prefernce_tags: string;
  looking_for_tags: string;
  top_destionations: string;
  preferred_airports: string;
  image_id: string;
  profile_image_url: string;
}

export const initialTravelerProfileError: TravelerProfileError = {
  first_name: "",
  last_name: "",
  passport_first_name: "",
  passport_last_name: "",
  passport_nationality_iso_a3: "",
  passport_number: "",
  phone_number: "",
  travel_style_tags: "",
  preferred_trip_type_tags: "",
  travel_prefernce_tags: "",
  looking_for_tags: "",
  top_destionations: "",
  preferred_airports: "",
  image_id: "",
  profile_image_url: "",
};

export type ModalShowState = {
  travelStyle: boolean;
  travelName: boolean;
  travelPassport: boolean;
  travelContact: boolean;
  travelStyles: boolean;
  travelTripType: boolean;
  travelPreferencesType: boolean;
  lookingFor: boolean;
  topDestination: boolean;
  airports: boolean;
};
export const initialModalShowState: ModalShowState = {
  travelStyle: false,
  travelName: false,
  travelPassport: false,
  travelContact: false,
  travelStyles: false,
  travelTripType: false,
  travelPreferencesType: false,
  lookingFor: false,
  topDestination: false,
  airports: false,
};

export interface GetInTouch {
  name: string;
  email: string;
  message: string;
  recaptcha: string | null;
}
export const initialGetInTouch = {
  name: "",
  email: "",
  message: "",
  recaptcha: null,
};

export interface GetInTouchErrors {
  name: string;
  email: string;
  message: string;
  recaptcha: string;
}
export const initialGetInTouchErrors = {
  name: "",
  email: "",
  message: "",
  recaptcha: "",
};

export interface Leads {
  id: number;
  trip_uuid: string;
  trip_version: string;
  no_of_travellers: number;
  trip_date: string;
  is_date_firm: number;
  trip_duration: number;
  is_trip_length_firm: number;
  trip_type: string;
  travel_style: string;
  budget_min: number;
  budget_max: number;
  departure_from: string;
  notes: string;
  name: string;
  email: string;
  phone: string;
  created_at: string;
  status: number;
  tripName: string;
  currency: string;
  leadStatus: string;
}

// Example usage
const initialLeads: Leads = {
  id: 0,
  trip_uuid: "",
  trip_version: "",
  no_of_travellers: 0,
  trip_date: "",
  is_date_firm: 0,
  trip_duration: 0,
  is_trip_length_firm: 0, // Corrected the key
  trip_type: "",
  travel_style: "",
  budget_min: 0,
  budget_max: 0,
  departure_from: "", // Corrected the key
  notes: "",
  name: "",
  email: "",
  phone: "",
  created_at: "",
  status: 1,
  tripName: "",
  currency: "",
  leadStatus: "",
};

export interface AsSeenIn {
  id: number | null;
  name: string;
  reference_url: string;
  image: number | null;
  caption: string;
  sequence: number | null;
  listingImage: Image;
}

const initialAsSeenIn: AsSeenIn = {
  id: null,
  name: "",
  reference_url: "",
  image: null,
  caption: "",
  sequence: null,
  listingImage: defaultImage,
};

export interface StaticPagesProps {
  meta_title: string;
  meta_keyword: string;
  meta_description: string;
  description: string;
  heading: string;
  slug: string;
  status: number;
  bannerImage: Image;
  listingImage: Image[];
}

export const initialStaticPages: StaticPagesProps = {
  meta_title: "",
  meta_keyword: "",
  meta_description: "",
  description: "",
  heading: "",
  slug: "",
  status: 0,
  bannerImage: defaultImage,
  listingImage: [],
};

export interface BlogProps {
  title: string;
  sub_title: string;
  slug: string;
  description: string;
  meta_title: string;
  meta_description: string;
  schedule: number | null;
  published_date_time: Date | null;
  is_commentable: number | null;
  is_comment_moderation: number | null;
  image: string;
  is_comment: number | null;
  is_approved: number | null;
  views: number | null;
  author: string;
  listingImage: Image;
  galleryImages: Image[];
  category: BlogsCategoryFront;
}
export const initialBlog: BlogProps = {
  title: "",
  sub_title: "",
  slug: "",
  description: "",
  meta_title: "",
  meta_description: "",
  schedule: null,
  published_date_time: null,
  is_commentable: null,
  is_comment_moderation: null,
  image: "",
  is_comment: null,
  is_approved: null,
  views: null,
  author: "",
  listingImage: defaultImage,
  galleryImages: [],
  category: {
    id: null,
    name: "",
    slug: "",
    blogCount: 0,
  },
};

export interface SummaryProps {
  total: number;
  page: number;
  pageSize: number;
  total_page: number;
}

export const initialSummary: SummaryProps = {
  total: 0,
  page: 1,
  pageSize: 12,
  total_page: 1,
};

interface ContentItem {
  id: number | null;
  item_key: string;
  item_value: string;
  parent_id: number | null;
  content_type: string;
}

export const initialContentItem: ContentItem = {
  id: null,
  item_key: "",
  item_value: "",
  parent_id: null,
  content_type: "",
};

export interface FooterParent {
  top_destination: ContentItem;
  top_categories: ContentItem;
  travel_creators: ContentItem;
  footer_copyright: ContentItem;
}

export const initialFooterParent: FooterParent = {
  top_destination: initialContentItem,
  top_categories: initialContentItem,
  travel_creators: initialContentItem,
  footer_copyright: initialContentItem,
};

export interface ChildItemsHome {
  home_hero_heading_1: ContentItem;
  home_hero_heading_2: ContentItem;
  banner_sub_heading_1: ContentItem;
  home_hero_headingg_3: ContentItem;
  home_hero_headingg_4: ContentItem;
  banner_sub_heading_2: ContentItem;
  banner_sub_heading_3: ContentItem;
  second_section_1: ContentItem;
  second_section_2: ContentItem;
  second_section_3: ContentItem;
  third_section_1: ContentItem;
  third_section_2: ContentItem;
  third_section_3: ContentItem;
  card_section_heading_1: ContentItem;
  card_section_heading_2: ContentItem;
  card_sub_head_1: ContentItem;
  card_sub_head_2: ContentItem;
  testimonials_heading: ContentItem;
  reviwe_1: ContentItem;
  reviwe_2: ContentItem;
  banner_image: ContentItem;
  youtube_link: ContentItem;
}

export const initialChildItemsHome: ChildItemsHome = {
  home_hero_heading_1: initialContentItem,
  home_hero_heading_2: initialContentItem,
  banner_sub_heading_1: initialContentItem,
  home_hero_headingg_3: initialContentItem,
  home_hero_headingg_4: initialContentItem,
  banner_sub_heading_2: initialContentItem,
  banner_sub_heading_3: initialContentItem,
  second_section_1: initialContentItem,
  second_section_2: initialContentItem,
  second_section_3: initialContentItem,
  third_section_1: initialContentItem,
  third_section_2: initialContentItem,
  third_section_3: initialContentItem,
  card_section_heading_1: initialContentItem,
  card_section_heading_2: initialContentItem,
  card_sub_head_1: initialContentItem,
  card_sub_head_2: initialContentItem,
  testimonials_heading: initialContentItem,
  reviwe_1: initialContentItem,
  reviwe_2: initialContentItem,
  banner_image: initialContentItem,
  youtube_link: initialContentItem
};

export interface ChildItemsBecome {
  become_section_first_1: ContentItem;
  become_section_first_2: ContentItem;
  become_section_first_3: ContentItem;
  become_section_first_4: ContentItem;
  become_sub_first_1: ContentItem;
  become_btn: ContentItem;
  become_section_second_1: ContentItem;
  become_section_second_2: ContentItem;
  become_section_second_3: ContentItem;
  become_sub_second_1: ContentItem;
  become_section_third_1: ContentItem;
  become_section_third_2: ContentItem;
  become_section_third_3: ContentItem;
  become_sub_third_1: ContentItem;
  banner_text_3: ContentItem;
  banner_text_2: ContentItem;
  become_section_fourth_1: ContentItem;
  become_section_fourth_2: ContentItem;
  become_section_fourth_3: ContentItem;
  become_sub_fourth_1: ContentItem;
  become_section_fifth_1: ContentItem;
  become_section_fifth_2: ContentItem;
  become_section_fifth_3: ContentItem;
  become_sub_fifth_1: ContentItem;
}

export const initialChildItemsBecome: ChildItemsBecome = {
  become_section_first_1: initialContentItem,
  become_section_first_2: initialContentItem,
  become_section_first_3: initialContentItem,
  become_section_first_4: initialContentItem,
  become_sub_first_1: initialContentItem,
  become_btn: initialContentItem,
  become_section_second_1: initialContentItem,
  become_section_second_2: initialContentItem,
  become_section_second_3: initialContentItem,
  become_sub_second_1: initialContentItem,
  become_section_third_1: initialContentItem,
  become_section_third_2: initialContentItem,
  become_section_third_3: initialContentItem,
  become_sub_third_1: initialContentItem,
  banner_text_3: initialContentItem,
  banner_text_2: initialContentItem,
  become_section_fourth_1: initialContentItem,
  become_section_fourth_2: initialContentItem,
  become_section_fourth_3: initialContentItem,
  become_sub_fourth_1: initialContentItem,
  become_section_fifth_1: initialContentItem,
  become_section_fifth_2: initialContentItem,
  become_section_fifth_3: initialContentItem,
  become_sub_fifth_1: initialContentItem,
};

export interface ChildItemCreator {
  creator_heading_1: ContentItem;
  creator_heading_2: ContentItem;
  creator_heading_3: ContentItem;
  creator_sub_head_1: ContentItem;
  creator_sub_head_3: ContentItem;
  creator_first_1: ContentItem;
  creator_sub_head_12: ContentItem;
  creator_ptext_1: ContentItem;
  bold_ptext_1: ContentItem;
  bold_ptext_2: ContentItem;
  bold_ptext_3: ContentItem;
  bold_ptext_4: ContentItem;
  bold_ptext_5: ContentItem;
  creator_ptext_2: ContentItem;
  creator_ptext_3: ContentItem;
  creator_ptext_4: ContentItem;
  creator_ptext_5: ContentItem;
  creator_ptext_6: ContentItem;
  creator_ptext_7: ContentItem;
  heading_first: ContentItem;
  heading_second: ContentItem;
  heading_fourth_1: ContentItem;
  heading_fourth_2: ContentItem;
  sub_heading_1: ContentItem;
  sub_heading_2: ContentItem;

}

export const initialChildItemCreator: ChildItemCreator = {
  creator_heading_1: initialContentItem,
  creator_heading_2: initialContentItem,
  creator_heading_3: initialContentItem,
  creator_sub_head_1: initialContentItem,
  creator_sub_head_3: initialContentItem,
  creator_first_1: initialContentItem,
  creator_sub_head_12: initialContentItem,
  creator_ptext_1: initialContentItem,
  bold_ptext_1: initialContentItem,
  bold_ptext_2: initialContentItem,
  bold_ptext_3: initialContentItem,
  bold_ptext_4: initialContentItem,
  bold_ptext_5: initialContentItem,
  creator_ptext_2: initialContentItem,
  creator_ptext_3: initialContentItem,
  creator_ptext_4: initialContentItem,
  creator_ptext_5: initialContentItem,
  creator_ptext_6: initialContentItem,
  creator_ptext_7: initialContentItem,
  heading_first: initialContentItem,
  heading_second: initialContentItem,
  heading_fourth_1: initialContentItem,
  heading_fourth_2: initialContentItem,
  sub_heading_1: initialContentItem,
  sub_heading_2: initialContentItem,
};

export interface ChildItemFaq {
  banner_heading_1: ContentItem;
  banner_heading_2: ContentItem;
  banner_head_sub: ContentItem;
}

export const initialChildItemFaq: ChildItemFaq = {
  banner_heading_1: initialContentItem,
  banner_heading_2: initialContentItem,
  banner_head_sub: initialContentItem,
};

export interface ChildItemBlog {
  blog_banner_text: ContentItem;
  blog_category: ContentItem;
  blog_other: ContentItem;
}

export const initialChildItemBlog: ChildItemBlog = {
  blog_banner_text: initialContentItem,
  blog_category: initialContentItem,
  blog_other: initialContentItem,
};

export interface ChildItemBlogDetails {
  blog_banner: ContentItem;
  blog_post: ContentItem;
}

export const initialChildItemBlogDetails: ChildItemBlogDetails = {
  blog_banner: initialContentItem,
  blog_post: initialContentItem,
};

export interface ChildItemTravelProfile {
  travel_banner_1: ContentItem;
  travel_banner_2: ContentItem;
  travel_banner_3: ContentItem;
  travel_heading_1: ContentItem;
  travel_heading_2: ContentItem;
  form_heading_1: ContentItem;
  form_heading_2: ContentItem;
  form_heading_3: ContentItem;
  form_heading_4: ContentItem;
  form_heading_5: ContentItem;
  form_heading_6: ContentItem;
  form_heading_7: ContentItem;
  form_heading_8: ContentItem;
  form_heading_9: ContentItem;
  form_heading_10: ContentItem;
  form_heading_11: ContentItem;
  form_heading_sub: ContentItem;
  form_update_btn: ContentItem;
  form_add_btn: ContentItem;
  travel_text: ContentItem;
  travel_btn: ContentItem;
}

export const initialChildItemTravelProfile: ChildItemTravelProfile = {
  travel_banner_1: initialContentItem,
  travel_banner_2: initialContentItem,
  travel_banner_3: initialContentItem,
  travel_heading_1: initialContentItem,
  travel_heading_2: initialContentItem,
  form_heading_1: initialContentItem,
  form_heading_2: initialContentItem,
  form_heading_3: initialContentItem,
  form_heading_4: initialContentItem,
  form_heading_5: initialContentItem,
  form_heading_6: initialContentItem,
  form_heading_7: initialContentItem,
  form_heading_8: initialContentItem,
  form_heading_9: initialContentItem,
  form_heading_10: initialContentItem,
  form_heading_11: initialContentItem,
  form_heading_sub: initialContentItem,
  form_update_btn: initialContentItem,
  form_add_btn: initialContentItem,
  travel_text: initialContentItem,
  travel_btn: initialContentItem,
};

export interface ChildItemAllBtnText {
  view_all_trip: ContentItem;
  trip_card_btn: ContentItem;
  join_wait_list: ContentItem;
  become_a_creators: ContentItem;
}
export const initialChildItemAllBtnText: ChildItemAllBtnText = {
  view_all_trip: initialContentItem,
  trip_card_btn: initialContentItem,
  join_wait_list: initialContentItem,
  become_a_creators: initialContentItem
};

export interface ChildItemTopDestinations {
  description: ContentItem;
}
export const initialChildTopDestinations: ChildItemTopDestinations = {
  description: initialContentItem,
};

export interface ChildItemTopCreator {
  heading1: ContentItem;
  heading2: ContentItem;
  main_heading1: ContentItem;
  main_heading2: ContentItem;
  descriptions: ContentItem;

}
export const initialChildItemTopCreator: ChildItemTopCreator = {
  heading1: initialContentItem,
  heading2: initialContentItem,
  main_heading1: initialContentItem,
  main_heading2: initialContentItem,
  descriptions: initialContentItem,
};


export interface Team {
  name: string;
  organization: string;
  caption: string;
  designation: string;
  linkedin: string;
  youtube: string;
  tiktok: string;
  facebook: string;
  profileImage: Image | null;
}
export const initialTeam: Team = {
  name: "",
  organization: "",
  caption: "",
  designation: "",
  linkedin: "",
  youtube: "",
  tiktok: "",
  facebook: "",
  profileImage: defaultImage,
};

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const colourOptions: readonly ColourOption[] = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];
