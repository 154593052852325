import classNames from 'classnames';
import IntercomChat from 'components/common/chat-widget/IntercomChat';
import Footer from 'components/footers/Footer';
import FooterCookieBanner from 'components/footers/FooterCookieBanner';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopHorizontalCreator from 'components/navbars/navbar-horizontal/NavbarTopHorizontalCreator';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import SignInForm from 'pages/authmodals/SignInForm';
import SignUp from 'pages/authmodals/SignUp';
import { useAppContext } from 'providers/AppProvider';
import { useAuth } from 'providers/AuthContext';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { useSignIn } from 'providers/SignInProvider';
import { useToast } from 'providers/ToastProvider';

import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import apiCall from 'services/api';

const MainLayout = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const { isAuthenticated, userType, creatorDashboard } = useAuth()
  const { setShowIn, setToggleForms, setResetPassToken } = useSignIn();
  const { showToast, showSuccessToast, showInfoToast, showErrorToast } = useToast();
  const { contentClass, footerClass } = useMainLayoutContext();
  const navigate = useNavigate();
  const location = useLocation();



  const renderNavbar = () => {
    if (navbarPosition === 'horizontal' || navbarPosition === 'combo') {
      return (Boolean(creatorDashboard) === false) ? <NavbarTopHorizontal /> : <NavbarTopHorizontalCreator />;
    }
    return null; // or any other fallback component
  };

  const verifyToken = async (token: string): Promise<boolean> => {
    try {
      const response = await apiCall({
        url: `verify-token/${token}`,
        method: 'GET',
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });

      // Check if the status is 200
      if (response?.status === 200) {
        const errors = response?.data?.errors;
        if (errors) {
          const errorMessage = errors?.token?.[0];
          if (errorMessage === "Invalid Token") {
            showErrorToast("Invalid Token");
          } else if (errorMessage === "Invalid Token, Token is expired") {
            showErrorToast("Invalid Token, Token is expired");
          } else if (errorMessage === "Token cannot be blank.") {
            showErrorToast("Token cannot be blank.");
          }
          return false; // return false in case of error
        } else if (response?.data?.message === "Token Verified") {
          showSuccessToast("Token Verified");
          return true; // return true when the token is verified
        }
      } else {
        showErrorToast("An error occurred while verifying the token.");
      }
    } catch (error: any) {
      showErrorToast("An unexpected error occurred while verifying the token.");
      console.error("Token verification error:", error);
    }
    return false; // return false in case of failure
  };

  useEffect(() => {
    let isMounted = true;

    const queryParams = new URLSearchParams(location.search);
    const resetToken = queryParams.get('resettoken');
    const handleTokenVerification = async () => {
      if (resetToken && isMounted) {
        const isTokenValid = await verifyToken(resetToken);
        if (isTokenValid) {
          // If the token is valid, update the state and navigate
          setToggleForms((prev) => ({
            signIn: false,
            forgotPassword: false,
            resetPassword: true, // Set resetPassword to true when resetToken is present
          }));
          setResetPassToken(resetToken);
          setShowIn(true); // Show the modal or form
          navigate('/');
          return;
        }
      }
    }
    handleTokenVerification()

    // const currentUrl = window.location.pathname;
    // const currentUrl = window.location.search;
    const currentUrl = location.pathname + location.search;
    if (isMounted) {
      if (Boolean(creatorDashboard) === true && isAuthenticated === true && (userType === 2 || userType === 4)) {

        if (currentUrl.includes('/creator')) {
          navigate(currentUrl);
        } else if (!currentUrl.includes('/creator') && Boolean(creatorDashboard) === true) {
          navigate('/creator/dashboard');
        } else if (currentUrl === '/') {
          navigate('/creator/dashboard');
        } else {
          navigate(currentUrl);
        }
      } else if (isAuthenticated !== true) {
        if (currentUrl.includes('/message')
          || currentUrl.includes('/travel-profile')
          || currentUrl.includes('/book-a-trip')
          || currentUrl.includes('/my-account')
          || currentUrl.includes('/my-trips')
        ) {
          navigate('/');
        }
      }
      else {
        if (currentUrl.includes('/creator')) {
          // URL contains "creator"
          navigate('/');
        } else {
          // URL does not contain "creator"
          navigate(currentUrl);
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [creatorDashboard, isAuthenticated, navigate]);

  return (
    <Container fluid className="px-0">
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      {navbarPosition === 'vertical' && <NavbarTopDefault />}
      {renderNavbar()}
      {navbarPosition === 'dual' && <NavbarDual />}

      <div className={classNames(contentClass, 'content')}>
        <Outlet />
        {(Boolean(creatorDashboard) === false) ? <><Footer className={classNames(footerClass, 'position-relative')} /> <FooterCookieBanner /></> : ""}

        <SignUp showErrorToast={showErrorToast} showSuccessToast={showSuccessToast} />
        <SignInForm showErrorToast={showErrorToast} showSuccessToast={showSuccessToast} />
        <IntercomChat />
      </div>
    </Container>
  );
};

export default MainLayout;
