import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSignUp } from "providers/SignUpProvider";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import { useAuth } from "providers/AuthContext";
import { EyeIcon2 } from "./EyeIcon";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

type SignUpProps = {
  showErrorToast: (message: string) => void;
  showSuccessToast: (message: string) => void;
}

const SignUp: React.FC<SignUpProps> = ({ showErrorToast, showSuccessToast }) => {
  const { signUp, signInGoogle } = useAuth()
  const { show, setShow } = useSignUp();
  const [googleAuthError, setGoogleAuthError] = useState<any>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [commonError, setCommonError] = useState<any>("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: ""

  })
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: ""
  })


  const handleClose = () => {
    setGoogleAuthError("");
    setError({
      first_name: "",
      last_name: "",
      email: "",
      password: ""
    })
    setShow(false)
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setError({
      ...error,
      [name]: '',
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let errorMessage = "";

    // Validation for first_name and last_name
    if (name === "first_name" || name === "last_name") {
      if (!value.trim()) {
        errorMessage = `${name.replace('_', ' ')} is required.`;
      } else if (!/^[A-Za-z]+$/.test(value)) {
        errorMessage = `${name.replace('_', ' ')} must contain only letters.`;
      } else if (value.length < 2) {
        errorMessage = `${name.replace('_', ' ')} must be at least 2 characters long.`;
      }
    }

    // Validation for email
    if (name === "email") {
      if (!value.trim()) {
        errorMessage = "Email is required.";
      } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        errorMessage = "Please enter a valid email address.";
      }
    }

    // Validation for password
    if (name === "password") {
      if (!value.trim()) {
        errorMessage = "Password is required.";
      } else if (value.length < 6) {
        errorMessage = "Password must be at least 6 characters long.";
      }
    }

    setError({
      ...error,
      [name]: errorMessage,
    });

  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setValidated(true);

    const newErrors = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    };

    // First name validation
    if (!formData.first_name.trim()) {
      newErrors.first_name = "First name is required.";
    } else if (!/^[A-Za-z]+$/.test(formData.first_name)) {
      newErrors.first_name = "First name must contain only letters.";
    } else if (formData.first_name.length < 2) {
      newErrors.first_name = "First name must be at least 2 characters long.";
    }

    // Last name validation
    if (!formData.last_name.trim()) {
      newErrors.last_name = "Last name is required.";
    } else if (!/^[A-Za-z]+$/.test(formData.last_name)) {
      newErrors.last_name = "Last name must contain only letters.";
    } else if (formData.last_name.length < 2) {
      newErrors.last_name = "Last name must be at least 2 characters long.";
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Password validation
    if (!formData.password.trim()) {
      newErrors.password = "Password is required.";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }

    setError(newErrors);

    if (form.checkValidity() === false || Object.values(newErrors).some((error) => error !== "")) {
      return;
    }

    setLoading(true);

    try {
      const value = await signUp(formData.first_name, formData.last_name, formData.email, formData.password, setCommonError, handleError, showErrorToast);
      if (value === true) {
        setShow(false); // Close the modal after successful sign-in
      }

    } catch (error) {
      // Handle error from signIn function or any other errors
      console.error("Sign-in error:", error);
      setCommonError("An error occurred. Please try again.");
    }
    finally {
      setLoading(false);
    }
  };

  const handleError = (error: any) => {
    const errors = error?.errors;
    // Dynamically set errors based on the response
    Object.keys(errors).forEach((key) => {
      const errorMessages = errors[key];
      const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message

      // Set the error state for the corresponding field
      setError((prevData) => ({
        ...prevData,
        [key]: firstErrorMessage,
      }));
    });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const token = tokenResponse.access_token;
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
      const result = userInfo.data;
      signInGoogle(
        "google",
        result.sub,
        result.email,
        setGoogleAuthError,
        handleClose
      );
    }

  });
  useEffect(() => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 1500);
  }, [commonError, googleAuthError]);
  
  return (
    <Modal show={show} onHide={handleClose} className="sign-up-modal" centered={true} backdrop="static">
      <Modal.Header >
        <p className="modal-title-custom mb-0">Sign Up</p>
        <div className="modal-close" onClick={handleClose}>
          <FontAwesomeIcon icon={faClose} className="fs-9" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-bdy">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="First name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                isInvalid={error.first_name !== ''}
              />
              <Form.Control.Feedback type="invalid">
                {error.first_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Last name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                isInvalid={error.last_name !== ''}
              />
              <Form.Control.Feedback type="invalid">
                {error.last_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="text"
                name="email"
                placeholder="name@example.com"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                isInvalid={error.email !== ""}
              />
              <Form.Control.Feedback type="invalid">
                {error?.email && error?.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Password</Form.Label>
              <div className="form-icon-container">
                <Form.Control
                  required
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="password"
                  value={formData?.password}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  minLength={6}
                  isInvalid={error.password !== ""}
                />
                <EyeIcon2 togglePasswordVisibility={togglePasswordVisibility} showPassword={showPassword} />
                <Form.Control.Feedback type="invalid">
                  {error?.password && error?.password}
                </Form.Control.Feedback>
              </div>

            </Form.Group>
            <div className="custom-btn-size d-flex justify-content-center py-4">
              <Button variant="primary" className="flex-grow-1 flex-lg-grow-0 w-100" type="submit" disabled={loading}> Sign Up </Button>
            </div>

            <div className="custom-btn-size d-flex justify-content-center pb-3">
              <Button onClick={() => { loginGoogle() }} variant="outline-primary" className="flex-grow-1 flex-lg-grow-0 w-100 custom-btn"><i className="fab fa-google fa-1x"></i>   Sign up with Google </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>

    </Modal>
  )
}

export default SignUp