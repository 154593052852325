import parse from 'html-react-parser';
import '../../src/assets/css/sidharth.css'
import { Form, Spinner } from 'react-bootstrap';
import { useAuth } from 'providers/AuthContext';
import { ChangeEvent, useRef, useState } from 'react';
import useWindowWidth from 'hooks/useWindowWidth';
import { useToast } from 'providers/ToastProvider';
import ReCAPTCHA from "react-google-recaptcha";
import { GetInTouch, GetInTouchErrors, initialGetInTouch, initialGetInTouchErrors } from 'types/interfaces';
import apiCall from 'services/api';
import { handleApiError } from 'services/handleApiError';
import { getHeaders } from 'services/getHeaders';
import { useMeta } from 'providers/MetaContext';

const GetInTouchComp = () => {
  let text = `Get in <span className="high-light-red">Touch</span>`;
  const recaptchaRef = useRef(null)
  const windowWidth = useWindowWidth();
  const { showToast, showSuccessToast, showInfoToast, showErrorToast } = useToast();
  const { userTkn, signOut } = useAuth();
  const { currency } = useMeta();
  const [loading, setLoading] = useState<boolean>(false)
  const [formData, setFormData] = useState<GetInTouch>(initialGetInTouch)
  const [errors, setErrors] = useState<GetInTouchErrors>(initialGetInTouchErrors)

  const handleRecaptchaChange = (token: string | null) => {
    setFormData(prevState => ({
      ...prevState,
      recaptcha: token
    }));
    setErrors(prevState => ({
      ...prevState,
      recaptcha: ''
    }));
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
  
    // Enforce maximum length for message field
    if (name === 'message' && value.length > 1000) {
      return; // Prevent further changes if the length exceeds the limit
    }
  
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  
    setErrors(prevState => ({
      ...prevState,
      [name]: ''
    }));
  };
  


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { name, email, message, recaptcha } = formData;

    let errors = { ...initialGetInTouchErrors };


    let isValid = true;

    if (!name.trim()) {
      errors.name = "Full Name is required";
      isValid = false;
    } else if (/^\d/.test(name)) {
      errors.name = "Full Name cannot start with a number";
      isValid = false;
    } else if (name.length > 50) {
      errors.name = "Full Name cannot exceed 50 characters";
      isValid = false;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    const maxMessageLength = 1000;
    if (!message.trim()) {
      errors.message = "Message is required";
      isValid = false;
    } else if (message.length > maxMessageLength) {
      errors.message = `Message cannot exceed ${maxMessageLength} characters`;
      isValid = false;
    }
    // Validate reCAPTCHA
    if (!recaptcha) {
      errors.recaptcha = "Please complete the CAPTCHA";
      isValid = false;
    }

    if (!isValid) {
      setErrors(errors);
      return;
    }

    if (isValid) {
      try {
        setLoading(true)
        const formDataSubmit = new FormData();

        formDataSubmit.append('name', formData?.name);
        formDataSubmit.append('email', formData?.email);
        formDataSubmit.append('message', formData?.message);

        const response = await apiCall({
          url: `contact-us`,
          method: 'POST',
          data: formDataSubmit, // Change this line
          headers: getHeaders(userTkn, currency),
        });

        if (response?.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Dynamically set errors based on the response
            Object.keys(errors).forEach((key) => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
              // Set the error state for the corresponding field
              setErrors((prevData: any) => ({
                ...prevData,
                [key]: firstErrorMessage,
              }));
            });
          } else {
            setFormData(initialGetInTouch)
            showSuccessToast("Your intrest submitted sucessfully!!");

          }

        }


      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);

      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 2000);


      }
    }
  }
  return (
    <>
      {loading && <div className="loading-overlay">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="listing-spinner"
        />
      </div>
      }
      <div>
        <section id="hero-faq" className="d-flex flex-column justify-content-center heading">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <p className="hero-heading fw-bold high-light-white text-center">{parse(text)}</p>
                <p className='fw-bold high-light-white fs-5 text-center'>Chat with us online through our chat function</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className={`${windowWidth > 1024 ? 'container' : 'container-fluid'} getInTouch enquire-now-modal`}>
            <Form onSubmit={handleSubmit} className="enquire-now-form">
              <div className="row justify-content-center mb-3">
              </div>
              <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label className="mb-1">First Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label className="mb-1">Email address *</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email address"
                    value={formData.email}
                    onChange={handleInputChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label className="mb-1">Message*</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    maxLength={1000}
                    className='text-area-custom'
                    placeholder='write here...'
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    isInvalid={!!errors.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
                <div className=" mb-3">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LcBwTEqAAAAAKuwQiSRUYl95xpyZIet55neOE8g" // Replace with your actual site key
                    onChange={handleRecaptchaChange}
                  />
                  {errors.recaptcha && (
                    <div className="error-feedback">{errors.recaptcha}</div>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center cancelSubmitButtons">
                <button className="buttonSubmit mx-7" type="submit" disabled={loading}>Submit</button>
              </div>
            </Form>
          </div>
        </section>
      </div>
    </>
  )
}

export default GetInTouchComp