import React, { useState } from 'react';
import { Dropdown, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import './styles.css'; // Import your custom CSS file
import group30915 from '../../../assets/img/travel/svg/destination/group30915.svg';
import { useMaster } from 'providers/MasterContext';
import { FilterStatusMain } from 'types/interfaces';

interface MultiSelectDestinationProps {
  setFilters:React.Dispatch<React.SetStateAction<FilterStatusMain>>;
  filters: FilterStatusMain;
}


const MultiSelectDestination: React.FC<MultiSelectDestinationProps> = ({filters, setFilters}) => {
  const {destinationOptions} = useMaster();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleSelect = (destination: string) => {
    // setSelectedDestinations(prev => {
    //   if (prev.includes(destination)) {
    //     return prev.filter(item => item !== destination);
    //   } else {
    //     return [...prev, destination];
    //   }
    // });
   
    setFilters(prev =>({
      ...prev,
      destination: destination
    }))
    setIsDropdownOpen(false)
  };

  const handleApply = () => {
    setIsDropdownOpen(false)
    // Handle apply logic, e.g., updating state or making an API call
  };

  const handleReset = () => {
    setFilters(prev =>({
      ...prev,
      destination: ""
    }))
  };

  return (
    <Dropdown autoClose="outside" className="h-100 custom-destination-dropdown" 
    show={isDropdownOpen}
    onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
    >
      <Dropdown.Toggle
        className="custom-destination-btn d-flex align-items-center"
        variant=""
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {/* <FontAwesomeIcon icon={faMountain} className="fs-12" /> */}
        <img src={group30915} alt="Destination Icon" className="" />
       {(destinationOptions?.find(item => item.value === filters.destination))?.label ??  "Destination"}
        <FontAwesomeIcon icon={faAngleDown} className='destination-btn-icon' />
      </Dropdown.Toggle>

      <Dropdown.Menu
        align="end"
        className="navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-destination shadow"
      >
        <Card className="position-relative border-0">
          <Card.Body className="p-0 destination-card">
            {destinationOptions?.map((destination, index) => (
              <div
                key={index}
                className={`destination ${ destination?.value === filters?.destination ? 'selected' : ''}`}
                onClick={() => handleSelect(destination?.value)}
              >
                <span>{destination?.label}</span>
                <FontAwesomeIcon icon={faGreaterThan} className="destination-icon" />
              </div>
            ))}
            {/* <div className="d-flex justify-content-between destination-btn-container">
              <Button variant="outline-primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleApply}>Apply</Button>
              <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleReset}>Reset</Button>
            </div> */}
          </Card.Body>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectDestination;
