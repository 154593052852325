import React, { useState } from 'react';
import { Dropdown, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import vector from '../../../assets/img/travel/svg/destination/vector.svg';
import './styles.css'; // Import your custom CSS file
import { FilterStatusMain } from 'types/interfaces';

interface Category {
  title: string;
  image?: string; // Assuming image is optional, adjust as needed
}


type MultiSelectCategoriesProps = {
  tripCategories:any;
  setFilters:React.Dispatch<React.SetStateAction<FilterStatusMain>>;
  filters: FilterStatusMain;
  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
  setFilterStatus: React.Dispatch<React.SetStateAction<boolean>>;
}
const MultiSelectCategories: React.FC<MultiSelectCategoriesProps> = ({tripCategories, filters, setFilters, selectedCategories, setSelectedCategories, setFilterStatus}) => {
 
 
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  // const handleSelect = (categories: string) => {
  //   setSelectedcategoriess(prev => {
  //     if (prev.includes(categories)) {
  //       return prev.filter(item => item !== categories);
  //     } else {
  //       return [...prev, categories];
  //     }
  //   });
  // };
  const handleSelect = (category: string) => {
    setSelectedCategories(prev => {
      const updatedCategories = prev.includes(category)
        ? prev.filter(item => item !== category)
        : [...prev, category];
  
      // Update the filters with the new categories
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        categories: updatedCategories,
      }));
  
      return updatedCategories;
    });
  };

  const handleApply = () => {
   
    setIsDropdownOpen(false)
    // setFilterStatus(true)
    // Handle apply logic, e.g., updating state or making an API call
  };

  const handleReset = () => {
    setSelectedCategories([]);
    setFilters((prevFilters) => ({
      ...prevFilters, // Ensure a new reference
      categories: []
    }));
  };

  return (
    <Dropdown autoClose="outside" className="h-100 custom-categories-dropdown"
      show={isDropdownOpen}
      onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
    >
      <Dropdown.Toggle
        className="custom-categories-btn d-flex align-items-center"
        variant=""
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {/* <FontAwesomeIcon icon={faMountain} className="fs-12" /> */}
        <img src={vector} alt="Vector Icon" className="" />
        categories
        <FontAwesomeIcon icon={faAngleDown} className='categories-btn-icon' />
      </Dropdown.Toggle>

      <Dropdown.Menu
        align="end"
        className="navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-categories shadow"
      >
        <Card className="position-relative border-0">
          <Card.Body className="p-0 categories-card categories-grid">
            {tripCategories?.map((categories:any) => (
              <div
                key={categories.code}
                className={`categories ${selectedCategories.includes(categories.code) ? 'selected' : ''}`}
                onClick={() => handleSelect(categories.code)}
              >
                <div className='categories-image'>
                  <img src={categories?.iconImage?.url} alt={categories?.iconImage?.alt} className="categories-icon" />
                </div>
                <span>{categories.name}</span>

              </div>
            ))}
           
          </Card.Body>
          <div className="d-flex justify-content-center categories-btn-container gap-4">
              <Button variant="outline-primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleApply}>Apply</Button>
              <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleReset}>Reset</Button>
            </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectCategories;
