import IntercomChat from "components/common/chat-widget/IntercomChat"

const Messages = () => {
  let text = "Coming soon!!!!"
  return (

    <div className="vh-75 d-flex align-items-center justify-content-center">
{text}
{/* <IntercomChat /> */}
    </div>
  )
}

export default Messages
