import { useCookies } from "providers/CookiesProvider";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

function FooterCookieBanner() {
  //   const [isVisible, setIsVisible] = useState(false);
  const { isVisible, setIsVisible } = useCookies();

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem("cookiesAccepted");

    if (!hasAcceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", true);
    setIsVisible(false);
  };

  if (!isVisible) return null; // Only render if banner should be visible

  return (
    <div style={popupStyles}>
      <Row className="align-items-center h-100 border-0">
        <Col sm={12} md={8} lg={10}>
          <div className="d-flex justify-content-start align-items-center">
            Like every other website, we use cookies. By using our site, you
            acknowledge that you agree to let us.{" "}
            <a href="/terms-conditions">Learn more</a>
          </div>
        </Col>
        <Col sm={12} md={4} lg={2}>
          <div className="custom-btn-size d-flex justify-content-end align-items-center">
            <button
              onClick={handleAccept}
              className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2"
            >
              <span className="ml-2">Accept</span>
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const popupStyles = {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "#333",
  color: "#fff",
  padding: "15px",
  zIndex: 1000,
  boxShadow: "0px -2px 5px rgba(0,0,0,0.2)",
};

export default FooterCookieBanner;
