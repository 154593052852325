import "swiper/css";
import 'swiper/css';
import 'swiper/css/pagination';
import Unicon from "components/base/Unicon";
import { UilArrowDown } from "@iconscout/react-unicons";
import Avatar from "components/base/Avatar";
import MultiSlidesCrousal from "components/crousals/multislides/MultiSlidesCrousal";
import VerticalSlider from "components/crousals/verticalslider/VerticalSlider";
import MultiSlidesCrousalCreator from "components/crousals/multislides/MultiSlidesCrousalCreator";
import OneSlideCrousal from "components/crousals/multislides/OneSlideCrousal";
import Testimonial from "components/asseenin/Testimonial";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "providers/ToastProvider";
import { Agent, ChildItemAllBtnText, ChildItemsHome, initialChildItemAllBtnText, initialChildItemsHome } from "types/interfaces";
import apiCall from "services/api";
import { handleApiError } from "services/handleApiError";
import { useAuth } from "providers/AuthContext";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import TripCard from "components/cards/TripsCard";
import NumberToDivs from "components/travel-common/NumberToDivsProps";
import { Link, useNavigate } from "react-router-dom";
import { markWishList } from "services/markWishList";
import { useSignIn } from "providers/SignInProvider";
import { TrustIndexScript } from "components/travel-common/TrustIndexScript";
import { TrustIndexScriptReview } from "components/travel-common/TrustIndexScriptReview";
import useWindowWidth from "hooks/useWindowWidth";
import ViewAllTrip from "components/travel-common/ViewAllTrip";
import ViewAllTripCenter from "components/travel-common/ViewAllTripCenter";






const Home = () => {
    const { showErrorToast } = useToast();
    const { userTkn, signOut } = useAuth();
    const { currency } = useMeta();
    const { setShowIn } = useSignIn();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [homeContentItems, setHomeContentItems] = useState<ChildItemsHome>(initialChildItemsHome);
    const [btnText, setBtnText] = useState<ChildItemAllBtnText>(initialChildItemAllBtnText);
    const [agents, setAgents] = useState<Agent[]>([]);
    const [agentsTotal, setAgentsTotal] = useState("");
    const [trips, setTrips] = useState<any[]>([]);
    const [totalTrips, setTotalTrips] = useState(0);
    const [homeData, setHomeData] = useState(0);
    const windowWidth = useWindowWidth()


    const handleMarkWishlist = async (tripId: string) => {
        await markWishList(tripId, userTkn, currency, setTrips, setShowIn, apiCall, showErrorToast);
    };

    useEffect(() => {
        const fetchTripData = async () => {
            try {

                const [contentResponse, btnTextResponse, homePageResponse] = await Promise.all([
                    apiCall({
                        url: `cms/content-items/home`,
                        method: 'GET',
                        headers: getHeaders(userTkn, currency),
                    }),
                    apiCall({
                        url: `cms/content-items/all_btn_text`,
                        method: 'GET',
                        headers: getHeaders(userTkn, currency),
                    }),
                    apiCall({
                        url: `cms/home-page`,
                        method: 'GET',
                        headers: getHeaders(userTkn, currency),
                    })
                ])

                if (contentResponse?.status === 200) {
                    let data = contentResponse?.data?.ContentItem?.data?.content?.childs;
                    setHomeContentItems(data)
                }

                if (btnTextResponse?.status === 200) {
                    let data = btnTextResponse?.data?.ContentItem?.data?.content?.childs;
                    setBtnText(data)
                }

                if (homePageResponse?.status === 200) {
                    let data = homePageResponse?.data?.ContentItem?.data?.content?.childs;
                    setHomeData(data)
                }


            } catch (error: any) {

                handleApiError(error, showErrorToast, signOut);

            } finally {

            }
        };

        fetchTripData();
    }, [currency]);
    
    useEffect(() => {
        const fetchTripData = async () => {
            try {
                setLoading(true);
                const [agentsResponse, tripsResponse] = await Promise.all([
                    apiCall({
                        url: `agents?sort=-is_top_agent`,
                        method: 'GET',
                        headers: getHeaders(userTkn, currency),
                    }),
                    apiCall({
                        url: `tours?page=&pageSize=12`,
                        method: 'GET',
                        headers: getHeaders(userTkn, currency),
                    })
                ])
                if (agentsResponse?.status === 200) {
                    let data = agentsResponse?.data?.Agents?.data;
                    setAgents(data);
                    setAgentsTotal(agentsResponse?.data?.Agents?.summary?.total)
                }
                if (tripsResponse?.status === 200) {
                    const data = tripsResponse?.data?.TripVersion?.data;
                    const total = tripsResponse?.data?.TripVersion?.summary.total;
                    setTotalTrips(total);
                    setTrips(data);
                }


            } catch (error: any) {

                handleApiError(error, showErrorToast, signOut);

            } finally {
                setLoading(false);
            }
        };

        fetchTripData();
    }, [currency]);

    const memoizedHomeContentItems = useMemo(() => homeContentItems, [homeContentItems]);
    const memoizedBtnText= useMemo(() => btnText, [btnText]);

    const memoizedTrips = useMemo(() => {
        return trips.slice(0, 4).map((item, index) => (
            <TripCard
                key={index}
                item={item}
                markWishList={handleMarkWishlist}
                title ={memoizedBtnText?.trip_card_btn?.item_value}
            />
        ));
    }, [trips, handleMarkWishlist]);

    const memoizedMultiSlidesCrousalCreator = useMemo(() => {
        return (Array.isArray(agents) && agents.length > 0) ? (
            <MultiSlidesCrousalCreator agents={agents} />
        ) : null;
    }, [agents])

    return (

        <div  >
            {/*first section Find Prefect Trip */}
            <section id="hero" 
             style={{ 
                backgroundImage: `url(${memoizedHomeContentItems?.banner_image?.item_value})`, 
                backgroundPosition: 'top center' 
            }} 
            className="d-flex flex-column justify-content-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5">
                            {memoizedHomeContentItems?.home_hero_heading_1?.item_value && <p className="main-heading fw-bold high-light-white"><span className="high-light-red">{memoizedHomeContentItems?.home_hero_heading_1?.item_value ?? "Tailor-made"}</span> {memoizedHomeContentItems?.home_hero_heading_2?.item_value ?? "trips by your favourite travel creators"} </p>}
                            {memoizedHomeContentItems?.banner_sub_heading_1?.item_value && <p className="sub-heading high-light-white fw-semibold">{memoizedHomeContentItems?.banner_sub_heading_1?.item_value ?? "Discover the world thanks to our tailor-made trips from our community of travel creators"} </p>}
                            {memoizedBtnText?.view_all_trip?.item_value &&
                                <ViewAllTrip title={memoizedBtnText?.view_all_trip?.item_value} />
                            }

                        </div>
                        <div className="col-xl-2  d-flex align-items-center mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0">
                            <a href={memoizedHomeContentItems?.youtube_link?.item_value} target="_blank" rel="noopener noreferrer" className="glightbox play-btn mb-4"></a>
                        </div>
                        <div className="col-xl-5"></div>
                    </div>
                </div>
            </section>
            {/* <InstagramFeed /> */}
            {/*second section*/}
            <div className="d-flex flex-column justify-content-center py-4">
                <div className="container">
                    <div className="d-flex flex-column justify-content-center ">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 text-center pb-5 pt-3">
                                    {memoizedHomeContentItems?.second_section_1?.item_value && <p className="main-heading fw-bold high-light-black"> {memoizedHomeContentItems?.second_section_1?.item_value ?? "Find the"} <span className="high-light-red">{memoizedHomeContentItems?.second_section_2?.item_value ?? "perfect"}</span> {memoizedHomeContentItems?.second_section_3?.item_value ?? "trip"}</p>}
                                </div>

                                <MultiSlidesCrousal />

                                {memoizedBtnText?.view_all_trip?.item_value &&
                                    <div className="col-xl-12 my-5">
                                        <ViewAllTripCenter title={memoizedBtnText?.view_all_trip?.item_value} />
                                    </div>

                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*third section*/}
            <div className="d-flex flex-column justify-content-center py-5  unique-container">
                <div className="container unique-container py-4">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 text-center pt-5">
                            {memoizedHomeContentItems?.third_section_1?.item_value && <p className="main-heading fw-bold high-light-black"> <span className="high-light-red">{memoizedHomeContentItems?.third_section_1?.item_value ?? "Unique"} </span>{memoizedHomeContentItems?.third_section_2?.item_value ?? "experiences."} </p>}
                            {memoizedHomeContentItems?.third_section_3?.item_value && <p className="main-heading fw-bold high-light-black">{memoizedHomeContentItems?.third_section_3?.item_value ?? "How Travel Creators works."}</p>}
                        </div>

                        <div className="custom-btn-size d-flex justify-content-center py-3">
                            {/* <Button variant='phoenix-primary' className="p-1 bg-transparent"><Unicon icon={UilCodeBranch} size={16} color="#525b75" /></Button> */}
                            <Avatar size="xl" variant="name" className="custom-btn-circle"><Unicon icon={UilArrowDown} size={30} color="#ffffff" /></Avatar>
                        </div>

                    </div>
                </div>
            </div>

            {/*fourth section*/}
            <div className=" ">
                <VerticalSlider title={btnText?.view_all_trip.item_value}/>
            </div>
            {/*fifth section*/}
            <div className="d-flex flex-column justify-content-center tips-count-padding">
                <div className="container-fluid">
                    <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3 justify-content-center">
                        <div className="col-xl-9 text-start">
                            {memoizedHomeContentItems?.card_section_heading_1?.item_value && <p className="main-heading fw-bold high-light-black m-0 pb-2">{memoizedHomeContentItems?.card_section_heading_1?.item_value ?? "Trips from our"}<span className="high-light-red"> {memoizedHomeContentItems?.card_section_heading_2?.item_value ?? "creators"}</span></p>}
                            {memoizedHomeContentItems?.card_sub_head_1?.item_value && <p className="sub-heading light-black fw-semibold m-0"> {memoizedHomeContentItems?.card_sub_head_1?.item_value ?? "Lorem um dolor sit amet, consectetur adipiscing elit, sed do"}<br /> {memoizedHomeContentItems?.card_sub_head_2?.item_value ?? "eiusmod tempor incididunt ut labore. Nemo enim ipsam."}</p>}
                        </div>
                        <div className="col-xl-3 ">
                            <div className="trips d-flex flex-column ">
                                <div className="trips-count d-flex flex-row gap-2 justify-content-end">
                                    {totalTrips > 0 &&
                                        <NumberToDivs number={totalTrips} />
                                    }
                                </div>
                                <div className="view-all-trips  d-flex justify-content-end pt-3">
                                    {memoizedBtnText?.view_all_trip?.item_value && <Link to={"/trips-and-destination"} style={{ textDecoration: 'none' }} className="custom-link">{memoizedBtnText?.view_all_trip?.item_value ?? "View All Trips"}</Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*sixth section*/}
            <section className="pb-0">
                <div className="container-fluid">
                    <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3 justify-content-start">
                        {(Array.isArray(trips) && trips.length > 0) ?
                            <>
                                {trips?.slice(0, 4).map((item, index) => (
                                    <TripCard
                                        key={index}
                                        item={item}
                                        markWishList={handleMarkWishlist}
                                        title ={memoizedBtnText?.trip_card_btn?.item_value}
                                    />
                                ))}
                            </>
                            :
                            <div className="col-12  d-flex justify-content-center">
                                <h3>Sorry No Trip Found!!</h3>
                            </div>
                        }
                        {memoizedBtnText?.view_all_trip?.item_value &&
                            <ViewAllTripCenter title={memoizedBtnText?.view_all_trip?.item_value} />
                        }
                    </div>

                </div>

            </section>
            <div className="container-fluid unique-container my-5">
                <div className="row justify-content-center">
                    <div className="col-xl-12 text-center mt-5">
                        <p className="main-heading fw-bold high-light-black"> Hear From <span className="high-light">Our Fans</span></p>
                    </div>
                </div>
            </div>
            <div id="trustindex-container-review"></div>

            <TrustIndexScriptReview />
            {/*seven section*/}
            <div className="d-flex flex-column justify-content-center ">
                <div className="container">
                    <div className="d-flex flex-column justify-content-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 text-center pb-4 mb-4 mt-5">
                                    {memoizedHomeContentItems?.testimonials_heading?.item_value && <p className="main-heading fw-bold high-light-black">   <span className="high-light-red">{agentsTotal} </span>{memoizedHomeContentItems?.testimonials_heading?.item_value ?? "creators waiting for you"}</p>}
                                </div>
                                {(Array.isArray(agents) && agents.length > 0) &&
                                    <MultiSlidesCrousalCreator agents={agents} />
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <section className="pb-0">
                <div className="container-fluid unique-container mb-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 text-center py-5">
                            <div className="main-heading fw-bold high-light-black m-0"> {"Hear From"} <span className="high-light-red">{"Our Fans"}</span></div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="pb-0 pt-0">
                <div id="trustindex-container-review"></div>

                <TrustIndexScriptReview />

            </section>



            {/*third section*/}
            <div className="unique-container">

                <OneSlideCrousal title={memoizedBtnText?.view_all_trip?.item_value} />
            </div>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-xl-12 text-center mt-5">
                        {memoizedHomeContentItems?.second_section_1?.item_value && <p className="main-heading fw-bold high-light-black m-0 pt-5"> {"Get Inspired By"} <span className="high-light-red">{"Our Creators"}</span></p>}
                    </div>
                </div>
            </div>
            <div id="trustindex-container"></div>

            <TrustIndexScript />

            <Testimonial />
        </div>
    )
}

export default Home
