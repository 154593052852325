import { Dropdown } from "react-bootstrap";
import CoverImage from 'assets/img/travel/banner/cover-photo.png';
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import { useToast } from "providers/ToastProvider";
import { AxiosError } from "axios";
import { ChildItemAllBtnText, ColourOption, initialChildItemAllBtnText, Tags } from "types/interfaces";
import DummyTripAgent from "components/common/dummyview/DummyTripAgent";
import { default as ReactSelect, components, StylesConfig } from "react-select";
import Testimonial from "components/asseenin/Testimonial";
import { handleApiError } from "services/handleApiError";
import { getHeaders } from "services/getHeaders";
import { useMeta } from "providers/MetaContext";
import AgentCard from "components/cards/AgentCard";
import TripCard from "components/cards/TripsCard";
import { useSignIn } from "providers/SignInProvider";
import { markWishList } from "services/markWishList";
import NotFound from "components/404/NotFound";
import DummyCard from "components/common/dummycard/DummyCard";




const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = "#E94560";
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? "#E94560"
                    : isFocused
                        ? "#e945601f"
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? "#E94560"
                        ? '#ffffff'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : "#e945601f"
                    : undefined,
            },
        };
    },

};

const TagsView = () => {
    const location = useLocation();
    const { tagId } = location.state || {};
    const { signOut, userTkn } = useAuth()
    const { currency } = useMeta();
    const { setShowIn } = useSignIn();
    const { showErrorToast } = useToast();
    const { tagslug } = useParams<{ tagslug: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTrips, setLoadingTrips] = useState<boolean>(false);
    const [tagsData, setTagsData] = useState<Tags | undefined>(undefined);
    const [agentData, setAgentData] = useState<any | []>([]);
    const [trips, setTrips] = useState<any[]>([]);

    const [agentOptions, setAgentOptions] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState({ optionSelected: [] });
    const [days, setDays] = useState<any[]>([]);
    const [selectedDays, setSelectedDays] = useState({ daysSelected: [] });
    const [destination, setDestination] = useState<any[]>([]);
    const [selectedDestination, setSelectedDestination] = useState({ destinationSelected: [] });
    const [sortBy, setSortBy] = useState("price");
    const [btnText, setBtnText] = useState<ChildItemAllBtnText>(
        initialChildItemAllBtnText
      );

    const handleMarkWishlist = async (tripId: string) => {
        await markWishList(tripId, userTkn, currency, setTrips, setShowIn, apiCall, showErrorToast);
    };

    useEffect(() => {

        let isMounted = true;  // Flag to track component mount status
        const fetchTripData = async () => {
            try {
                setLoading(true);
              const btnTextRequest =  apiCall({
                    url: `cms/content-items/all_btn_text`,
                    method: "GET",
                    headers: getHeaders(userTkn, currency),
                  });
                const tagRequest = await apiCall({
                    url: `tags/${tagslug}`,
                    method: 'GET',
                    headers: getHeaders(userTkn, currency)

                });

                const agentRequest = await apiCall({
                    url: `agents?pageSize=4`,
                    method: 'GET',
                    headers: getHeaders(userTkn, currency)

                });


                const agentFilterRequest = await apiCall({
                    url: `tags/${tagslug}/agents?pagination=0`,
                    method: 'GET',
                    headers: getHeaders(userTkn, currency)

                });

                const daysRequest = apiCall({
                    url: `tags/${tagslug}/trip-lengths`,
                    method: 'GET',
                    headers: getHeaders(userTkn, currency)
                });

                const destinationRequest = apiCall({
                    url: `tags/${tagslug}/destinations`,
                    method: 'GET',
                    headers: getHeaders(userTkn, currency)
                });

                const [btnTextResponse, tagResponse, agentResponse, agentFilterResponse, daysResponse, destinationResponse] = await Promise.all([btnTextRequest, tagRequest, agentRequest, agentFilterRequest, daysRequest, destinationRequest]);

                
                if (btnTextResponse?.status === 200) {
                    let data = btnTextResponse?.data?.ContentItem?.data?.content?.childs;
                    setBtnText(data);
                  }
                if (isMounted && tagResponse.status === 200) {
                    if (tagResponse?.data?.errors) {
                        const errors = tagResponse?.data.errors;
                        showErrorToast(errors)

                    } else {
                        const data = tagResponse?.data?.Tag?.data;
                        setTagsData(data)
                    }
                }
                if (agentResponse.status === 200) {
                    if (agentResponse?.data?.errors) {
                        const errors = agentResponse?.data.errors;
                        showErrorToast(errors);
                    } else {
                        const data = agentResponse?.data?.Agents?.data;
                        setAgentData(data);
                    }
                }


                if (agentFilterResponse.status === 200) {
                    if (agentFilterResponse?.data?.errors) {
                        const errors = agentFilterResponse?.data.errors;
                        showErrorToast(errors);
                    } else {
                        const agents = agentFilterResponse?.data?.TagAgents;
                        const options = agents?.map((item: any) => ({ value: item.id, label: item?.name }))
                        setAgentOptions(options);
                    }
                }

                if (daysResponse.status === 200) {
                    if (daysResponse?.data?.errors) {
                        const errors = daysResponse?.data.errors;
                        showErrorToast(errors);
                    } else {
                        const days = daysResponse?.data?.TripLengths
                        const options = days?.map((item: any) => ({ value: item.no_of_days, label: item?.name }))
                        setDays(options);
                    }
                }
                if (destinationResponse.status === 200) {
                    if (destinationResponse?.data?.errors) {
                        const errors = destinationResponse?.data.errors;
                        showErrorToast(errors);
                    } else {
                        const destinations = destinationResponse?.data?.TagDestinations
                        const options = destinations?.map((item: any) => ({ value: item.id, label: item?.name }))
                        setDestination(options);
                    }
                }
            } catch (error: any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {
                if (isMounted) setLoading(false);
            }
        };


        if (tagslug) {
            fetchTripData();
        }
        // Cleanup function
        return () => {
            isMounted = false;  // Mark component as unmounted
        };

    }, [tagslug, tagId, signOut, showErrorToast]);

    useEffect(() => {

        let isMounted = true;  // Flag to track component mount status
        const fetchTripData = async () => {
            try {
                setLoadingTrips(true);
                let agentsIds = selectedOption?.optionSelected?.map((item: any, index: number) => (item.value))
                let daysIds = selectedDays?.daysSelected?.map((item: any, index: number) => (item.value))
                let destination = selectedDestination?.destinationSelected?.map((item: any, index: number) => (item.value))
                const tripsRequest = await apiCall({
                    url: `tours?tag[0]=${[tagId]}&agent_id[0]=${agentsIds}&no_of_days[0]=${daysIds}&location=${destination}&sort=${sortBy}&pageSize=20`,
                    method: 'GET',
                    headers: getHeaders(userTkn, currency)

                });


                const [tripsResponse] = await Promise.all([tripsRequest]);



                if (tripsResponse.status === 200) {
                    if (tripsResponse?.data?.errors) {
                        const errors = tripsResponse?.data.errors;
                        showErrorToast(errors);
                    } else {
                        const trips = tripsResponse?.data?.TripVersion?.data;
                        setTrips(trips);
                    }
                }

            } catch (error: any) {

                if ((error as AxiosError).response?.status === 401) {
                    showErrorToast(error?.message)
                    // setTimeout(() => {
                    //     signOut();
                    // }, 3000);
                } else if ((error as AxiosError)) {
                    showErrorToast(error?.message)
                }

            } finally {
                if (isMounted) setLoadingTrips(false);
            }
        };


        if (tagslug) {
            fetchTripData();
        }
        // Cleanup function
        return () => {
            isMounted = false;  // Mark component as unmounted
        };

    }, [tagslug, tagId, selectedOption, selectedDays, selectedDestination, sortBy, signOut, showErrorToast, currency]);

    const Option = (props: any) => {
        return (
            <div className="trip-details-form">
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const handleMultiOptions = (selected: any) => {
        setSelectedOption({
            optionSelected: selected
        });
    }
    const handleMultiOptionsDays = (selected: any) => {
        setSelectedDays({
            daysSelected: selected
        });
    }

    const handleMultiOptionsDestination = (selected: any) => {
        setSelectedDestination({
            destinationSelected: selected
        });
    }

    const handleShortBy = (value: number) => {
        if (value === 1) {
            setSortBy("price")
        } else {
            setSortBy("-price")
        }
    }


    return (
        <>
            {loading &&
                <>
                    <DummyTripAgent />
                    <section className="pb-0">
                        <div className="container-fluid">
                            <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3">
                                <div className="col-xl-12 ">
                                    <p className="dummy-text-placeholder">Loading ...</p>
                                </div>
                                <DummyCard />
                                <DummyCard />
                                <DummyCard />
                                <DummyCard />
                                <DummyCard />
                                <DummyCard />
                                <DummyCard />
                                <DummyCard />
                            </div>
                        </div>
                    </section>
                </>
            }
            {!loading &&
                <>
                    {tagsData ?
                        <>
                            <div className="mb-5 card banner-card">
                                <div className="d-flex justify-content-center align-items-end position-relative mb-7 mb-xxl-0 card-header" style={{ minHeight: "320px" }}>
                                    <div className="bg-holder rounded-top hover-actions-trigger position-absolute" style={{ backgroundImage: `url(${tagsData?.coverImage?.url ? tagsData?.coverImage?.url : CoverImage})` }}>

                                    </div>

                                </div>
                                <div className="card-body ">
                                    <div className="justify-content-xl-center row">
                                        <div className="col-auto ">
                                            <div className="d-flex flex-wrap mb-3 align-items-center justify-content-center">
                                                <h2 className="me-2">{tagsData?.name}</h2>
                                            </div>
                                            <p className="fs-6 text-body-secondary">{tagsData?.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section className="pb-0 pt-0 ">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <p className="main-heading  fw-bold high-light-black pb-0 mb-0"> Travel<span className="high-light-red"> Creators</span> </p>
                                        <div className="ml-3 mr-3">
                                            <hr className="hr-line " />
                                        </div>
                                        {agentData?.slice(0, 4).map((agent: any, index: number) => {
                                            return (
                                                <AgentCard key={index} agent={agent} />
                                            )
                                        })}

                                    </div>
                                </div>
                            </section>

                            <section className="pb-0 pt-0">
                                <div className="container p-0">
                                    <div className="row">

                                        <p className="main-heading  fw-bold high-light-black pb-0 mb-0"> My<span className="high-light-red"> trips</span> </p>
                                        <div className="ml-3 mr-3">
                                            <hr className="hr-line " />
                                            <div className="filtes-container ">
                                                <div className="row">
                                                    <div className="col-lg-3 pb-4">
                                                        {(Array.isArray(agentOptions) && agentOptions.length > 0) && <ReactSelect
                                                            options={agentOptions}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            className={`tags-filter trips-select-filter-container`}
                                                            styles={colourStyles}
                                                            value={selectedOption.optionSelected}
                                                            components={{
                                                                Option
                                                            }}

                                                            onChange={handleMultiOptions}


                                                            classNamePrefix="trips-tags-filter"
                                                        />
                                                        }
                                                    </div>
                                                    <div className="col-lg-3">
                                                        {(Array.isArray(days) && days.length > 0) && <ReactSelect
                                                            options={days}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            className={`tags-filter trips-select-filter-container`}
                                                            placeholder="Select Days"
                                                            styles={colourStyles}
                                                            value={selectedDays.daysSelected}
                                                            components={{
                                                                Option
                                                            }}

                                                            onChange={handleMultiOptionsDays}


                                                            classNamePrefix="trips-tags-filter"
                                                        />
                                                        }
                                                    </div>
                                                    <div className="col-lg-3">
                                                        {(Array.isArray(destination) && destination.length > 0) && <ReactSelect
                                                            options={destination}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            className={`tags-filter trips-select-filter-container`}
                                                            placeholder="Select Destinations"
                                                            styles={colourStyles}
                                                            value={selectedDestination.destinationSelected}
                                                            components={{
                                                                Option
                                                            }}

                                                            onChange={handleMultiOptionsDestination}


                                                            classNamePrefix="trips-tags-filter"
                                                        />
                                                        }
                                                    </div>
                                                    <div className="col-lg-3 d-flex justify-content-end">
                                                        <Dropdown className="custom-sort-dp">
                                                            <Dropdown.Toggle variant="primary" id="share-dropdown">
                                                                Sort by: {sortBy === "price" ? "Low to High" : "High to Low"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item className={`item-option-dp ${sortBy === "price" ? 'selected' : ''}`} onClick={() => { handleShortBy(1) }}>Low to High</Dropdown.Item>
                                                                <Dropdown.Item className={`item-option-dp ${sortBy === "-price" ? 'selected' : ''}`} onClick={() => { handleShortBy(2) }}>High to Low</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {loadingTrips && ( // Full page loading overlay
                                            <section className="pb-0">
                                                <div className="container-fluid">
                                                    <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3">
                                                        <div className="col-xl-12 ">
                                                            <p className="dummy-text-placeholder">Loading ...</p>
                                                        </div>
                                                        <DummyCard />
                                                        <DummyCard />
                                                        <DummyCard />
                                                        <DummyCard />
                                                        <DummyCard />
                                                        <DummyCard />
                                                        <DummyCard />
                                                        <DummyCard />
                                                    </div>
                                                </div>
                                            </section>

                                        )}
                                        {!loadingTrips &&
                                            <>
                                                {(Array.isArray(trips) && trips.length > 0) ?
                                                    <>
                                                        {trips?.map((item, index) => (
                                                            <TripCard
                                                                key={index}
                                                                item={item}
                                                                markWishList={handleMarkWishlist}
                                                                title={btnText?.trip_card_btn?.item_value}
                                                            />
                                                        ))}
                                                    </>
                                                    :
                                                    <div className="col-12  d-flex justify-content-center">
                                                        <h3>Sorry No Trip Found!!</h3>
                                                    </div>
                                                }
                                            </>

                                        }



                                    </div>
                                </div>
                            </section>

                            <Testimonial />
                        </>
                        :
                        <NotFound />
                    }
                </>
            }

        </>
    )
}

export default TagsView