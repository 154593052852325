import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

type CookiesContextType = {
    isVisible:boolean;
    setIsVisible:  Dispatch<SetStateAction<boolean>>;
}

const CookiesContext = createContext<CookiesContextType | undefined>(undefined);

// Define a helper function to use the context
export function useCookies() {
    const context = useContext(CookiesContext);
    if (!context) {
      throw new Error("useCookies must be used within an CookiesProvider");
    }
    return context;
  }

  // Define the CookiesProvider component
type CookiesProviderProps = {
    children: ReactNode;
  };

  export function CookiesProvider({ children }: CookiesProviderProps) {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <CookiesContext.Provider
          value={{
            isVisible, 
            setIsVisible
           
          }}
        >
          {children}
        </CookiesContext.Provider>
      );
  }