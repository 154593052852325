export const enguireNowSteps = {
  "steps": [
    {
      "step": 1,
      "title": "Basic Details",

    },
    {
      "step": 2,
      "title": "Budget Details",
    
    },
    {
      "step": 3,
      "title": "Traveller Details",
   
    },
    {
      "step": 4,
      "title": " Congratulations.",
    
    }
  ]
}