import { faUsps } from "@fortawesome/free-brands-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "components/base/Avatar";
import { useAuth } from "providers/AuthContext";
import { useMeta } from "providers/MetaContext";
import { useToast } from "providers/ToastProvider";
import { Suspense, useEffect, useState } from "react";
import { Card, Form, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import apiCall from "services/api";
import { handleApiError } from "services/handleApiError";
import avtar1 from 'assets/img/travel/profile/profile.jpg';
import { EnquireNowFormProps, initialEnqNowError, initialEnquireNow, initialSummary, SummaryProps } from "types/interfaces";
import TripRequestModal from "./modal/TripRequestModal";

const Traveller = () => {
  const { userTkn, signOut, userProfile } = useAuth();
  const { currency } = useMeta();
  const { showErrorToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [travelers, setTravelers] = useState<any[]>([])
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    sort: 'firstname',
    search: '',
    pageIndex: 1
  });
  const [summary, setSummary] = useState<SummaryProps>(initialSummary);
  const [filterStatus, setFilterStatus] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [travelerData, setTravelerData] = useState<any>("")
  const [enquireData, setEnquireData] = useState<EnquireNowFormProps>(initialEnquireNow)
  const [errors, setErrors] = useState(initialEnqNowError)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `travellers`
        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
          },
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.Travellers?.data;
            setTravelers(prev => {
              if (filters?.pageIndex === 1) {
                // Reset data if fetching the first page
                return [...data];
              } else {
                // Append new data for subsequent pages
                return [...prev, ...data];
              }
            });

          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userTkn])

  useEffect(() => {

    const fetchData = async () => {
      if (!filterStatus) return; // Exit early if filterStatus is false
      if (pageIndex === 1) {
        setTravelers([]);
      }

      try {
        setLoading(true);
        // Convert availability array to query string parameters

        const baseUrl = 'travellers';
        const commonParamsArray: string[] = [`page=${pageIndex}`, `pageSize=12`];
        if (filters?.search) {
          commonParamsArray.push(`search=${filters.search}`);
        }

        if (filters?.sort) {
          commonParamsArray.push(`sort=${filters.sort}`);
        }

        const commonParams = commonParamsArray.join('&');
        const url = `${baseUrl}?${commonParams}`;

        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
          },
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.Travellers?.data;
            const summary = response?.data?.Travellers?.summary;

            setSummary({
              total: summary.total,
              page: summary.page,
              pageSize: summary.pageSize,
              total_page: summary.total_page,
            });

            setTravelers(prev => {
              if (filters?.pageIndex === 1) {
                // Reset data if fetching the first page
                return [...data];
              } else {
                // Append new data for subsequent pages
                return [...prev, ...data];
              }
            });


            setLoading(false);
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
        setLoading(false);
      } finally {
        setLoading(false);
        setLoadingMore(false);
        setFilterStatus(false)
      }
    };

    fetchData();
  }, [userTkn, pageIndex, filterStatus]);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFilters((prev) => ({
      ...prev,
      [name]: String(value) // Assuming status is a number
    }));

  };
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
  
    setFilters((prev) => ({
      ...prev,
      [name]: value // Assuming status is a number
    }));

  };
  // submit filters
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFilterStatus(true)

  };

  const resetFilters = () => {
    setFilters({
      sort: 'firstname',
      search: '',
      pageIndex: 1
    });

    setFilterStatus(true)
  }

  const setTripRequest = (item: any) => {
    setModalShow(!modalShow)
    setTravelerData(item)
  }

  const handleSubmitTripRequest = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { trip_date, is_date_firm, trip_duration, is_trip_lenth_firm, no_of_travellers, meta_currency_code, budget_min, budget_max, notes, travel_style, depature_from, trip_uuid, trip_version } = enquireData;
    let validationErrors = { ...initialEnqNowError };
    let isValid = true;

    if (!trip_uuid) {
      validationErrors.trip_uuid = "Trip is required";
      isValid = false;
    }
    if (!meta_currency_code) {
      validationErrors.meta_currency_code = "Currency is required";
      isValid = false;
    }
    if (!no_of_travellers) {
      validationErrors.no_of_travellers = "Number of travellers is required";
      isValid = false;
    }
    // Validate is_date_firm (must be either 0 or 1)
    if (is_date_firm !== 0 && is_date_firm !== 1) {
      validationErrors.is_date_firm = "Required";
      isValid = false;
    }
    if (!trip_duration) {
      validationErrors.trip_duration = "Number of days is required";
      isValid = false;
    }
    // if (!trip_type) {
    //     validationErrors.trip_type = "Trip type is required";
    //     isValid = false;
    // }
    if (!trip_date) {
      validationErrors.trip_date = "Trip date is required";
      isValid = false;
    }
    if (is_trip_lenth_firm === null) {
      validationErrors.is_trip_lenth_firm = "Required";
      isValid = false;
    }
    if (!travel_style) {
      validationErrors.travel_style = "Travel style is required";
      isValid = false;
    }
    if (!budget_min) {
      validationErrors.no_of_travellers = "Minmum budget is required";
      isValid = false;
    }
    if (!budget_max) {
      validationErrors.no_of_travellers = "Maximum budget is required";
      isValid = false;
    }
    if (!depature_from) {
      validationErrors.depature_from = "Depart from is required";
      isValid = false;
    }
    if (!isValid) {
      setErrors(validationErrors);
      return;
    }

    if (isValid) {
      try {
          const formData = new FormData();

          formData.append('no_of_travellers', String(enquireData?.no_of_travellers));
          formData.append('trip_duration', String(enquireData?.trip_duration));
          formData.append('is_date_firm', String(enquireData?.is_date_firm));
          formData.append('is_trip_lenth_firm', String(enquireData.is_trip_lenth_firm));
          const formattedDate = enquireData?.trip_date ? new Date(enquireData?.trip_date).toISOString().split('T')[0] : '';
          formData.append('trip_date', formattedDate);
          // formData.append('trip_type', enquireData?.trip_type);
          formData.append('travel_style', enquireData?.travel_style);
          formData.append('notes', enquireData?.notes);
          formData.append('depature_from', enquireData?.depature_from);
          formData.append('budget_min', String(enquireData?.budget_min));
          formData.append('budget_max', String(enquireData?.budget_max));
          formData.append('currency', meta_currency_code ?? '');
          formData.append('trip_uuid', enquireData?.trip_uuid);
          formData.append('trip_version', trip_version ?? "");
          formData.append('notes', notes ?? "");

          formData.append('name', travelerData?.name);
          formData.append('email', travelerData?.email);
          formData.append('phone', travelerData?.phone);
   
     
          formData.append('phone', travelerData?.phone);

          const headers = userTkn
              ? { 'x-access-token': userTkn }
              : {};

          const response = await apiCall({
              url: `travellers/${userProfile?.user?.id}/lead`,
              method: 'POST',
              data: formData,
              headers: {
                'x-access-token': userTkn,
              },
          });

          if (response?.status === 200) {
              if (response?.data?.errors) {
                  const errors = response?.data.errors;
                  showErrorToast(errors);
              } else {
                setTravelerData("");
                setEnquireData(initialEnquireNow);
                setErrors(initialEnqNowError);
                setModalShow(!modalShow);
              }

          }

      } catch (error:any) {
          handleApiError(error, showErrorToast, signOut);
      } finally {

      }
      // nextStepSave();
  } else {
      setErrors(validationErrors);
      showErrorToast("Please fill in all required fields.");
  }
  };

  return (

    <div  >
      {loading && ( // Full page loading overlay
        <div className="loading-overlay">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="listing-spinner"
          />
        </div>
      )}
      <div className="pt-3 pb-3 trips-listing-heading" >
        <div className="container-fluid cardListing">
          <div className="row mb-5">
            <div className="col-12  col-sm-12  col-md-6  col-lg-9 col-xl-9 col-xxl-9 ">
              <p className="main-heading fw-bold high-light-black m-0 text-center text-sm-center text-md-start ">Travelers</p>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3  d-flex justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end  pt-3  order-md-2 order-lg-2 order-xl-2 order-xxl-2">
              <div className="custom-btn-size">
                <Button variant="primary" className="flex-grow-1 flex-lg-grow-0 create-new-btn " onClick={() => { navigate("/creator/travelers/create") }}> Create New</Button>
              </div>
            </div>

          </div>
        </div>
        <div className="container-fluid cardListing">
          <Form className="trip-search-modal" onSubmit={handleSubmit}>
            <div className="filter-container ">
              <Row className="d-flex justify-content-center align-items-center">
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4  mb-3 mb-md-0">
                  <Form.Group controlId={"months-select"}>
                    <div className="form-icon-container">
                      <Form.Control
                        type="text"
                        name="search"
                        placeholder="Trip Title"
                        value={filters?.search}
                        onChange={handleChange}
                        className="flex-grow-1 title-filter"
                        id="title-filter"
                      />
                      <div className="eye-icon2">
                        <FontAwesomeIcon
                          className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
                          style={{ left: '10px', cursor: 'pointer' }}
                          icon={faMagnifyingGlass}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <Form.Group controlId={"months-select"}>
                    <div className="form-icon-container">
                      <Form.Select
                        aria-label="Default select example"
                        value={filters?.sort}
                        onChange={handleSelectChange}
                        name="sort"
                        className="flex-grow-1"
                        id="select-status"
                      >
                        <option className="select-option-style" value="firstname">A to Z</option>
                        <option className="select-option-style" value="-firstname">Z to A</option>
                      </Form.Select>
                      <div className="eye-icon2">
                        <FontAwesomeIcon
                          className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
                          style={{ left: '10px', cursor: 'pointer' }}
                          icon={faUsps}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 custom-btn-small d-flex justify-content-center justify-content-md-start gap-2 mt-xs-3  mt-3 mt-md-0  mt-lg-0  mt-xl-0 mt-xxl-0  ">
                  <Button type="submit" aria-label="Find" className="flex-grow-0 ">
                    Find
                  </Button>
                  <Button variant="outline-primary" onClick={resetFilters} className=""> Reset </Button>
                </div>
              </Row>
            </div>
          </Form>
          {Array.isArray(travelers) && travelers.length > 0 && (
            <div className="row">
              {travelers.map((item, index) => (
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3" key={index}>
                  <Card className='southAfricaCards mb-4'>

                    <Card.Body className='cardBody leads-card'>
                      <div className="traveller-card-cont">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="d-flex flex-row align-items-center gap-3 mb-0">
                            <Avatar
                              src={item?.image ? item?.image : avtar1}
                              size="2xl"
                              className="card-profile-image"
                            />
                            <p className="creator-title m-0">{item?.first_name} {item?.last_name}</p>

                          </div>
                          <div className="custom-btn-small">
                            <Button onClick={() => { setTripRequest(item) }} type="button" aria-label="Find" className="flex-grow-0 ">
                              Request
                            </Button>
                          </div>
                        </div>
                        <div className='cardText mt-3'>
                          <div className="row mb-2">
                            <div className="col lisitingLeftSide">Phone</div>
                            <div className="col lisitingRightSide cardTitle">{item?.phone_number ?? ""}</div>
                          </div>
                          <div className="row mb-2">
                            <div className="col lisitingLeftSide">Email</div>
                            <div className="col lisitingRightSide">{item?.email ?? ""}</div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow && <TripRequestModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          enquireData={enquireData}
          setEnquireData={setEnquireData}
          errors={errors}
          setErrors={setErrors}
          handleSubmit={handleSubmitTripRequest}
          loading={loading}
          travelerData={travelerData}
        />
        }
      </Suspense>
    </div>
  )
}

export default Traveller