// MetaContext.tsx
import axios from 'axios';
import Constants from 'common/Constants';
import { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import apiCall from 'services/api';
import { AsSeenIn, CountryData, CurrencyData, LanguageData, MonthData } from 'types/interfaces';

type OptionType = { value: string; label: string };

type MetaContextType = {
    currencies: CurrencyData[] | null;
    months: MonthData[] | null;
    currenciesOptions: OptionType[] | undefined;
    languageOptions: any[] | undefined;
    countryOptions: any[] | undefined;
    asSeenIn: AsSeenIn[] | [];
    currency: string;
    setCurrency: (newCurrency: string) => void;

}
// Create the context
const MetaContext = createContext<MetaContextType | undefined>(undefined);

// Define a hook to use the context
export function useMeta() {
    const context = useContext(MetaContext);
    if (!context) {
        throw new Error('useMeta must be used within a MetaProvider');
    }
    return context;
}

// Define the MetaProvider component
type MetaProviderProps = {
    children: ReactNode;
};

export function MetaProvider({ children }: MetaProviderProps) {
    const [currencies, setCurrencies] = useState<CurrencyData[] | null>(null);
    const [currenciesOptions, setCurrenciesOptions] = useState<OptionType[]>([]);
    const [months, setMonths] = useState<MonthData[] | null>(null);
    const [language, setLanguage] = useState<LanguageData[] | []>([])
    const [languageOptions, setLanguageOptions] = useState<any[]>([]);
    const [countryOptions, setCountryOptions] = useState<any[]>([]);
    const [asSeenIn, setAsSeenIn] = useState<AsSeenIn[]>([])
    const [currency, setCurrencyState] = useState<string | "">(() => {
        return localStorage.getItem("currency") || "ZAR";
    });

    const [cmsContentItems, setCmsContentItems] = useState<any>("")
    const [ipData, setIpData] = useState(null);
    const token = 'b82cc57d0cec91';

    useEffect(() => {
        const fetchData = async () => {
            try {

                // Or example of parallel requests using Promise.all
                const [
                    ipResponse,
                    currencyResponse,
                    monthsResponse,
                    languageResponse,
                    countryResponse,
                    asSeenInResponse,
                    contentItemsResponse
                ] = await Promise.all([

                    axios.get(
                        `https://ipinfo.io/json?token=${token}`
                    )
                    ,
                    apiCall({
                        url: `meta/currency`,
                        method: 'GET',
                        headers: {
                            "Access-Control-Allow-Origin": "*"
                        }
                    }),

                    apiCall({
                        url: `meta/months`,
                        method: 'GET',
                        headers: {
                            "Access-Control-Allow-Origin": "*"
                        }
                    }),

                    apiCall({
                        url: `meta/dictionary`,
                        method: 'GET',
                        headers: {
                            "Access-Control-Allow-Origin": "*"
                        }
                    }),

                    apiCall({
                        url: `meta/country`,
                        method: 'GET',
                        headers: {
                            "Access-Control-Allow-Origin": "*"
                        }
                    }),

                    apiCall({
                        url: `cms/as-seen-in`,
                        method: 'GET',
                        headers: {
                            "Access-Control-Allow-Origin": "*"
                        }
                    }),
                    apiCall({
                        url: `cms/content-items`,
                        method: 'GET',
                        headers: {
                            "Access-Control-Allow-Origin": "*"
                        }
                    }),

                ])
                // Handle each response individually
                if (currencyResponse?.status === 200 && ipResponse?.status === 200) {
                    let currencyData = currencyResponse?.data?.MetaCurrency?.data;
                    let ipData = ipResponse?.data;
                    let country = ipData?.country;

                    setCurrencies(currencyData);
                    const transformedOptions = currencyData?.map((item: any) => ({
                        value: item.code,
                        label: item.name
                    }));
                    setCurrenciesOptions(transformedOptions);
                    // Check if the country matches any currency code
                    const matchedCurrency = currencyData.find(
                        (item:any) => item.code === country
                    );

                    if (country && matchedCurrency) {
                        setCurrencyState(matchedCurrency.code);
                        localStorage.setItem("currency", matchedCurrency.code);
                    }
                     else {
                        setCurrencyState("ZAR");
                        localStorage.setItem("currency", "ZAR");
                    }

                } else {
                    throw new Error("Failed to fetch locations");
                }

                if (monthsResponse?.status === 200) {
                    setMonths(monthsResponse?.data?.MetaMonth?.data);
                } else {
                    throw new Error("Failed to fetch trip categories");
                }

                if (languageResponse?.status === 200) {
                    let data = languageResponse?.data?.MetaDictionary?.data;
                    setLanguage(data);

                    const transformedOptions = data?.map((item: LanguageData) => ({
                        value: item.code,
                        label: item.name
                    }));

                    // Set the options state with the transformed data
                    setLanguageOptions(transformedOptions)
                }

                if (countryResponse?.status === 200) {
                    let data = countryResponse?.data?.country?.data;
                    const transformedOptions = data?.map((item: CountryData) => ({
                        value: item.iso_a3,
                        label: item.name
                    }));

                    setCountryOptions(transformedOptions)
                }
                if (asSeenInResponse?.status === 200) {
                    let data = asSeenInResponse?.data?.AsSeenIn?.data;
                    setAsSeenIn(data);
                }
                if (contentItemsResponse?.status === 200) {
                    let data = contentItemsResponse?.data?.ContentItem?.data;
                    setCmsContentItems(data)
                }


            } catch (error: any) {
                console.error("error", error)
            }
        };

        fetchData();

        return () => {
            // Cleanup function if needed
        };
    }, []);

    // useEffect(() => {
    //     if (currencies) {
    //         const transformedOptions = currencies?.map(item => ({
    //             value: item.code,
    //             label: item.name
    //         }));

    //         // Set the options state with the transformed data
    //         setCurrenciesOptions(transformedOptions)
    //     }
    // }, [currencies]);

    const setCurrency = (newCurrency: string) => {
        setCurrencyState(newCurrency);
        localStorage.setItem("currency", newCurrency);
    };

    return (
        <MetaContext.Provider value={
            {
                currencies,
                currenciesOptions,
                months,
                languageOptions,
                countryOptions,
                asSeenIn,
                currency,
                setCurrency
            }}>
            {children}
        </MetaContext.Provider>
    );
}