import Testimonial from "components/asseenin/Testimonial";
import { useAuth } from "providers/AuthContext";
import { useToast } from "providers/ToastProvider";
import { useEffect, useState } from "react";
import { Agent, ChildItemTopCreator, initialChildItemTopCreator } from "types/interfaces";
import { useMeta } from "providers/MetaContext";
import { handleApiError } from "services/handleApiError";
import apiCall from "services/api";
import { getHeaders } from "services/getHeaders";
import AgentCard from "components/cards/AgentCard";
import DummyAgentCard from "components/common/dummycard/DummyAgentCard";

const TopCreators = () => {

  const { signOut, userTkn } = useAuth();
  const { currency } = useMeta();
  const [loading, setLoading] = useState<boolean>(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const { showErrorToast } = useToast();
  const [topCreatorContentItems, setTopCreatorContentItems] =
  useState<ChildItemTopCreator>(initialChildItemTopCreator);

 

  useEffect(() => {
    let isMounted = true;  // Flag to track component mount status

    const fetchTripData = async () => {
      try {
        setLoading(true);
        const [contentResponse, agentsResponse  ] = await Promise.all([
          apiCall({
            url: `cms/content-items/top_creators`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `agents?page=1&pageSize=12&sort=-is_top_agent`,
            method: 'GET',
            headers: getHeaders(userTkn, currency)
          })
        ]);

        if (contentResponse?.status === 200) {
          let data = contentResponse?.data?.ContentItem?.data?.content?.childs;
          setTopCreatorContentItems(data);
        }

        if (agentsResponse?.status === 200) {
          if (agentsResponse?.data?.errors) {
            const errors = agentsResponse?.data.errors;
            // Handle errors if needed
          } else {
            const data = agentsResponse?.data?.Agents?.data;
            setAgents(data)

          }
        }





      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        if (isMounted) setLoading(false);
      }
    }
    fetchTripData()
  }, [showErrorToast]);

  return (
    <>
      <section id="hero-faq" className="d-flex flex-column justify-content-center heading">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="hero-heading fw-bold high-light-white text-center"><span className="high-light-red">{topCreatorContentItems?.main_heading1?.item_value ?? ""} </span>{topCreatorContentItems?.main_heading2?.item_value ?? ""}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-0 pt-5">
        <div className="container-fluid cont">
          <div className="row justify-content-center">
            <div className="col-xl-12 ">
              <p className="main-heading fw-bold high-light-black"> {topCreatorContentItems?.heading1?.item_value ?? ""}<span className="high-light-red">  {topCreatorContentItems?.heading2?.item_value ?? ""}</span> </p>
            </div>

            <div className="col-xl-9">
              <div className="creator-text">
                <p>{topCreatorContentItems?.descriptions?.item_value ?? ""}</p>
              </div>
            </div>
            <div className="col-xl-3">

            </div>
            {loading && ( // Full page loading overlay
              <>

                <DummyAgentCard />
                <DummyAgentCard />
                <DummyAgentCard />
                <DummyAgentCard />
                <DummyAgentCard />
                <DummyAgentCard />
                <DummyAgentCard />
                <DummyAgentCard />
              </>


            )}
            {!loading &&
              <>

                {(Array.isArray(agents) && agents.length > 0) &&
                  agents?.map((agent, index) => (
                    <AgentCard key={index} agent={agent} />
                  ))
                }
              </>
            }
          </div>

        </div>
      </section>
      <Testimonial />


    </>
  )
}

export default TopCreators