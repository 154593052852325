// PrivateRoute.tsx

import { useAuth } from 'providers/AuthContext';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type PrivateRouteProps = {
  path?: string;
  element?: React.ReactNode;
};

export function PrivateRouteAuth({ path, element }: PrivateRouteProps) {

  const { isAuthenticated} = useAuth();


    // If authenticated and workspace is set, render the protected route content
    return (isAuthenticated )? <>{element}</> : <Navigate to="/" replace />;
 


}
