import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import { useState } from 'react';
import { addDays } from 'date-fns';
import { Card, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import date_range_light from '../../../assets/img/travel/svg/destination/date_range_light.svg';
import './styles.css';

// interface  CustomDateRangeProps {
//   setFilters: React.Dispatch<React.SetStateAction<FilterStatusMain>>;
//   filters: FilterStatusMain;
//   setFilterStatus: React.Dispatch<React.SetStateAction<boolean>>;
// }

const CustomDateRangePicker = ({ setFilters, dateRange, setDateRange}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [dateRange, setDateRange] = useState([
  //   {
  //     startDate: new Date(), // Start from today
  //     endDate: addDays(new Date(), 0), // End date 7 days from today
  //     key: 'selection'
  //   }
  // ]);

  const handleOnChange = (item) => {
    setDateRange([item])
    setFilters(prevFilters => ({
      ...prevFilters,
      start_date: item?.startDate,
      end_date: item?.endDate,
    }));
  }
  const handleApply = () => {
    setIsDropdownOpen(false);
    setFilters(prevFilters => ({
      ...prevFilters,
      start_date: dateRange[0]?.startDate,
      end_date: dateRange[0]?.endDate,
    }));
  }
  const handleReset = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'selection'
      }
    ]);
    setFilters(prevFilters => ({
      ...prevFilters,
      start_date: null,
      end_date: null,
    }));
  };

  return (
    <>
      <Dropdown autoClose="outside" className="h-100 custom-daterange-dropdown"
        show={isDropdownOpen}
        onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <Dropdown.Toggle
          className="custom-daterange-btn d-flex align-items-center"
          variant=""
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {/* <FontAwesomeIcon icon={faMountain} className="fs-12" /> */}
          <img src={date_range_light} alt="Vector Icon" className="" />
          Travel Between
          <FontAwesomeIcon icon={faAngleDown} className='daterange-btn-icon' />
        </Dropdown.Toggle>

        <Dropdown.Menu
          align="end"
          className="navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-daterange shadow"
        >
          <Card className="position-relative border-0">
            <Card.Body className="p-0  daterange-grid">
              <DateRangePicker
                // onChange={item => setDateRange([item.selection])}
                onChange={item => { handleOnChange(item.selection) }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                showMonthAndYearPickers={false}
                color="#E94560"
                rangeColors={"#FEF4F4"}
                ranges={dateRange}
                direction="horizontal"
              />

            </Card.Body>
            <div className="d-flex justify-content-center daterange-btn-container gap-4">
              <Button variant="outline-primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleApply}>Apply</Button>
              <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleReset}>Reset</Button>
            </div>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    </>

  )
}

export default CustomDateRangePicker