// PrivateRoute.tsx

import { useAuth } from 'providers/AuthContext';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type PrivateRouteProps = {
  path?: string;
  element?: React.ReactNode;
};

export function PrivateRouteAuthCreator({ path, element }: PrivateRouteProps) {

  const { isAuthenticated,  userType} = useAuth();
  


    // If authenticated and workspace is set, render the protected route content
    return (isAuthenticated &&  (userType === 2 || userType === 3 || userType === 4)) ? <>{element}</> : <Navigate to="/" replace />;
 


}
