import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select, { components, MultiValueProps, MultiValue, ActionMeta } from 'react-select';

// Define the type for the options
interface OptionType {
    value: string;
    label: string;
}

interface MultiSelectMonthsProps {
    setFilters: React.Dispatch<React.SetStateAction<{
        name: string;
        tripName: string;
        month: number[];
        status: number
    }>>;
    months: number[];
}

// Custom MultiValue component
const CustomMultiValue: React.FC<MultiValueProps<OptionType, true>> = (props) => {
    const { data, selectProps } = props;
    const { value } = data;

    // Retrieve the currently selected options
    const selectedOptions = selectProps.value as OptionType[];

    // Show only the first selected item and "..." if there are more
    if (selectedOptions.length > 1) {
        if (data === selectedOptions[0]) {
            return (
                <components.MultiValue {...props}>
                    <span>{selectedOptions[0].label}</span>
                    {selectedOptions.length > 1 && <span> +{selectedOptions.length - 1}</span>}
                </components.MultiValue>
            );
        }
        return null;
    }

    // Default behavior for single or no selected item
    return <components.MultiValue {...props} />;
};

const monthsOptions: OptionType[] = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
];

const MySelectComponentLeads: React.FC<MultiSelectMonthsProps> = ({ setFilters, months }) => {

    const [formData, setFormData] = useState<OptionType[]>([]);
    // Handle change events with the correct type
    const handleMonthChange = (newValue: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        // Convert selected options to integers and remove duplicates
        const selectedMonths = Array.from(new Set(newValue.map(option => parseInt(option.value))));
        setFormData(Array.from(newValue));
        setFilters(prevFilters => ({
            ...prevFilters,
            month: selectedMonths,
        }));

    };

    useEffect(()=>{
        if(months?.length === 0){
            setFormData([])
        }
    },[months])

    return (
        <Form.Group controlId={"months-select"}>
            <div className="form-icon-container">
                <Select
                    isMulti
                    value={formData}
                    options={monthsOptions}
                    onChange={handleMonthChange}
                    className={`trips-select-filter-container`}
                    components={{ MultiValue: CustomMultiValue }}
                    classNamePrefix="trips-months-filter"
                />
                <div className="eye-icon2">
                    <FontAwesomeIcon
                        className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
                        style={{ left: '10px', cursor: 'pointer' }}
                        icon={faCalendar}
                    />

                </div>
            </div>

        </Form.Group>

    );
};

export default MySelectComponentLeads;
