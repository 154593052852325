import { Button } from "react-bootstrap";
import BannerImage from "../../assets/img/travel/banner/search.jpg";
import group30469 from '../../assets/img/travel/svg/destination/group30469.svg';
import user_blk_pink from '../../assets/img/travel/svg/destination/user_blk_pink2.svg';
import mount_trip from '../../assets/img/travel/svg/profile/mount_trip.svg';
import travel_pref from '../../assets/img/travel/svg/profile/travel_pref.svg';
import perm_media from '../../assets/img/travel/svg/profile/perm_media.svg';
import group_loc from '../../assets/img/travel/svg/profile/group_loc.svg';
import travel_flight from '../../assets/img/travel/svg/profile/travel_flight.svg';
import group_flight from '../../assets/img/travel/svg/profile/group_flight.svg';
import phone_call from '../../assets/img/travel/svg/profile/phone_call.svg';
import date_range_light from '../../assets/img/travel/svg/profile/date_range_light.svg';
import passport from '../../assets/img/travel/svg/profile/passport.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faArrowRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { lazy, Suspense, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ChildItemTravelProfile, initialChildItemTravelProfile, initialModalShowState, initialTravelerProfile, initialTravelerProfileError, initialTravelerProfileStart, ModalShowState, TravelerProfile, TravelerProfileError, TravelerProfileStart } from "types/interfaces";
import { useAuth } from "providers/AuthContext";
import apiCall from "services/api";
import { useToast } from "providers/ToastProvider";
import { getYear, getMonth } from 'date-fns';
import { Modal } from "react-bootstrap";
import ImageManager from "common/ImageManager";
import Avatar from "components/base/Avatar";
import avatar57 from 'assets/img/travel/profile/profile.jpg';
import { useNavigate } from "react-router-dom";
import { handleApiError } from "services/handleApiError";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";


const TravellerName = lazy(() => import('./profilemodals/TravellerName'));
const TravelStyles = lazy(() => import('./profilemodals/TravelStyles'));
const PassportDetails = lazy(() => import('./profilemodals/PassportDetails'));
const TravellerContact = lazy(() => import('./profilemodals/TravellerContact'));
const TravelPreferredTripType = lazy(() => import('./profilemodals/TravelPreferredTripType'));
const TravelPreferencesType = lazy(() => import('./profilemodals/TravelPreferencesType'));
const TravelLookingFor = lazy(() => import('./profilemodals/TravelLookingFor'));
const TravelTopDestinations = lazy(() => import('./profilemodals/TravelTopDestinations'));
const TravelPreferredAirports = lazy(() => import('./profilemodals/TravelPreferredAirports'));

const range = (start: number, end: number, step: number): number[] => {
  const result = [];
  for (let i = start; i <= end; i += step) {
    result.push(i);
  }
  return result;
};

const TravelProfile = () => {
  const navigate = useNavigate();
  const { userTkn, signOut, userProfile, setUserProfile } = useAuth();
  const { currency } = useMeta();
  const { showErrorToast, showSuccessToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false)

  const [modalShow, setModalShow] = useState<ModalShowState>(initialModalShowState)

  const [selectedStylesTags, setSelectedStylesTags] = useState<string[]>([]); // for display "travel style Tags"
  const [selectedTripTypesTags, setSelectedTripTypesTags] = useState<string[]>([]); // for display "travel Trip Type Tags"
  const [selectedPreferencesTypesTags, setSelectedPreferencesTypesTags] = useState<string[]>([]); // for display "travel Preferences Type Tags"
  const [selectedLookingForTags, setSelectedLookingForTags] = useState<string[]>([]); // for display "travel Looking For Type Tags"
  const [selectedTopDestinationsTags, setSelectedTopDestinationsTags] = useState<string[]>([]); // for display "travel Looking For Type Tags"
  const [selectedPreferredAirportsTags, setSelectedPreferredAirportsTags] = useState<string[]>([]); // for display "travel Looking For Type Tags"
  const years = range(1923, getYear(new Date()) + 1, 1);
  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];

  const [isOpen, setIsOpen] = useState(false);

  const [startFormData, setStartFormData] = useState<TravelerProfileStart>(initialTravelerProfileStart);
  const [formData, setFormData] = useState<TravelerProfile>(initialTravelerProfile);
  const [errors, setErrors] = useState<TravelerProfileError>(initialTravelerProfileError);
  const [dobError, setDobError] = useState("");
  const [modalShowImg, setModalShowImg] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number[]>([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState<any[]>([]);
  const [customImageSize, setCustomImageSize] = useState({
    minWidth: 100,
    minHeight: 100,
    maxWidth: 100,
    maxHeight: 100
  })
  const [homeContentItems, setHomeContentItems] = useState<ChildItemTravelProfile>(initialChildItemTravelProfile);



  const openModal = (modalName: keyof ModalShowState) => {
    setModalShow({
      travelStyle: modalName === 'travelStyle',
      travelName: modalName === 'travelName',
      travelPassport: modalName === 'travelPassport',
      travelContact: modalName === 'travelContact',
      travelStyles: modalName === 'travelStyles',
      travelTripType: modalName === 'travelTripType',
      travelPreferencesType: modalName === 'travelPreferencesType',
      lookingFor: modalName === 'lookingFor',
      topDestination: modalName === 'topDestination',
      airports: modalName === 'airports',
    });
  };
  const formatDate = (dateString: Date): string => {
    //here data= "2012-09-11" // value like this
    const date = new Date(dateString); // Convert the string to a Date object

    if (isNaN(date.getTime())) {
      // Check for invalid date
      return "Invalid Date";
    }
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;;
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };


  useEffect(() => {

    const fetchProfile = async () => {
      try {
        setLoading(true);

        const [response, contentItemResponse] = await Promise.all([
          apiCall({
            url: `profile`,
            method: 'POST',
            headers: getHeaders(userTkn, currency)
          }),
          apiCall({
            url: `cms/content-items/travel_profile`,
            method: 'GET',
            headers: getHeaders(userTkn, currency),
          }),
        ])



        if (response?.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;



          } else {
            let data = response?.data?.user;

            setSelectedStylesTags(data?.travelStyleTag?.map((item: any) => item?.name) ?? []);
            setSelectedTripTypesTags(data?.preferredTripTypeTag?.map((item: any) => item?.name) ?? []);
            setSelectedPreferencesTypesTags(data?.travelPrefernceTag?.map((item: any) => item?.name) ?? []);
            setSelectedLookingForTags(data?.lookingForTag?.map((item: any) => item?.name) ?? []);
            setSelectedTopDestinationsTags(data?.topDestination?.map((item: any) => item?.name) ?? []);
            setSelectedPreferredAirportsTags(data?.airports?.map((item: any) => item?.name) ?? [])
            setSelectedImage([data?.image?.id])
            setStartFormData(prev => ({
              ...prev,
              first_name: data?.first_name ?? "",
              last_name: data?.last_name ?? "",
              passport_first_name: data?.passport_first_name ?? "",
              passport_last_name: data?.passport_last_name ?? "",
              passport_nationality_iso_a3: data?.passport_nationality_iso_a3 ?? "",
              passport_number: data?.passport_number ?? "",
              phone_number: data?.phone_number ?? "",
              dob: data?.dob ? formatDate(data.dob) : "",
              travel_style_tags: data?.travelStyleTag?.map((item: any) => item?.id) ?? [],
              preferred_trip_type_tags: data?.preferredTripTypeTag?.map((item: any) => item?.id) ?? [],
              travel_prefernce_tags: data?.travelPrefernceTag?.map((item: any) => item?.id) ?? [],
              looking_for_tags: data?.lookingForTag?.map((item: any) => item?.id) ?? [],
              top_destionations: data?.topDestination?.map((item: any) => item?.id) ?? [],
              preferred_airports: data?.airports?.map((item: any) => item?.code) ?? [],
              image_id: data?.image?.id,
              profile_image_url: data?.image?.url
            }))



            setFormData(prev => ({
              ...prev,
              first_name: data?.first_name ?? "",
              last_name: data?.last_name ?? "",
              passport_first_name: data?.passport_first_name ?? "",
              passport_last_name: data?.passport_last_name ?? "",
              passport_nationality_iso_a3: data?.passport_nationality_iso_a3 ?? "",
              passport_number: data?.passport_number ?? "",
              phone_number: data?.phone_number ?? "",
              dob: data?.dob ? new Date(data.dob) : new Date(new Date().setFullYear(new Date().getFullYear() - 12)),
              travel_style_tags: data?.travelStyleTag?.map((item: any) => item?.id) ?? [],
              preferred_trip_type_tags: data?.preferredTripTypeTag?.map((item: any) => item?.id) ?? [],
              travel_prefernce_tags: data?.travelPrefernceTag?.map((item: any) => item?.id) ?? [],
              looking_for_tags: data?.lookingForTag?.map((item: any) => item?.id) ?? [],
              top_destionations: data?.topDestination?.map((item: any) => item?.id) ?? [],
              preferred_airports: data?.airports?.map((item: any) => item?.code) ?? [],
              image_id: data?.image?.id,
              profile_image_url: data?.image?.url
            }));
          }
        }

        if (contentItemResponse?.status === 200) {
          let data = contentItemResponse?.data?.ContentItem?.data?.content?.childs;
          setHomeContentItems(data)
        }

      } catch (error: any) {

        handleApiError(error, showErrorToast, signOut);

      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 2000);


      }
    }

    fetchProfile()
  }, [])

  const handleSubmitName = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { first_name, last_name } = formData;
    let validationErrors = { ...initialTravelerProfileError };
    let isValid = true;

    // Validate name
    if (!first_name) {
      validationErrors.first_name = "Name is required";
      isValid = false;
    } else if (/^\d/.test(first_name)) {
      validationErrors.first_name = "Name cannot start with a number";
      isValid = false;
    } else if (first_name.length > 30) {
      validationErrors.first_name = "Name cannot exceed 30 characters";
      isValid = false;
    }

    // Validate last_name (if entered)
    if (last_name.trim()) {
      if (/^\d/.test(last_name)) {
        validationErrors.last_name = "Last name cannot start with a number";
        isValid = false;
      } else if (last_name.length > 30) {
        validationErrors.last_name = "Last name cannot exceed 30 characters";
        isValid = false;
      }
    }
    if (!isValid) {
      setErrors(validationErrors);
      return;
    }

    if (isValid) {
      try {
        setLoading(true)
        const formDataSubmit = new FormData();
        formDataSubmit.append('first_name', formData?.first_name);
        formDataSubmit.append('last_name', String(formData?.last_name));

        const response = await apiCall({
          url: `traveler-profile-update?section=name`,
          method: 'POST',
          data: formDataSubmit, // Change this line
          headers: getHeaders(userTkn, currency)
        });
        if (response?.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;

            // Dynamically set errors based on the response
            Object.keys(errors).forEach((key) => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
              // Set the error state for the corresponding field
              setErrors((prevData: any) => ({
                ...prevData,
                [key]: firstErrorMessage,
              }));
            });
          } else {
            let data = response?.data?.name?.data;
            setStartFormData(prev => ({
              ...prev,
              first_name: data?.first_name,
              last_name: data?.last_name
            }))

            setFormData(prev => ({
              ...prev,
              first_name: data?.first_name,
              last_name: data?.last_name
            }));
            showSuccessToast(response?.data?.message)
            setModalShow(prev => ({
              ...prev,
              travelName: false
            }))
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);

      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 2000);


      }
    }
  };

  const handleDateChange = (value: any) => {
    setFormData(prev => ({
      ...prev,
      dob: value
    }))
    setDobError("")
  }

  const handleSubmitDob = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    try {
      setLoading(true);

      const today = new Date();
      const selectedDate = new Date(formData?.dob);
      // Remove time from both today and selectedDate for comparison
      today.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);

      if (selectedDate >= today) {
        setDobError("Date of birth must be before today Date!");
        return; // Stop form submission if validation fails
      }

      // const formattedDate = formData?.dob ? new Date(formData?.dob).toISOString().split('T')[0] : '';
      const date = new Date(formData?.dob);

      // Manually extract the year, month, and day in local time
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, '0');

      // Format the date as YYYY-MM-DD
      const formattedDate = `${year}-${month}-${day}`;
      const formDataSubmit = new FormData();
      formDataSubmit.append('dob', formattedDate);



      const response = await apiCall({
        url: `traveler-profile-update?section=dob`,
        method: 'POST',
        data: formDataSubmit,
        headers: getHeaders(userTkn, currency)
      });
      if (response?.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;

          // Dynamically set errors based on the response
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0];
            setErrors((prevData: any) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          let data = response?.data?.dob?.data;
          setStartFormData(prev => ({
            ...prev,
            dob: data?.dob ? formatDate(data.dob) : "",
          }))
          setFormData(prev => ({
            ...prev,
            dob: new Date(data?.dob)
          }))
          handleClick(event)

        }
      }
    } catch (error: any) {
      handleApiError(error, showErrorToast, signOut);

    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };




  const closeGallery = () => {

    const syntheticEvent = new Event('submit', { bubbles: true, cancelable: true });
    handleSubmitProfileImage(syntheticEvent as unknown as React.FormEvent<HTMLFormElement>);

  }
  const handleSubmitProfileImage = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { image_id } = formData;
    let errors = { ...initialTravelerProfileError };
    let isValid = true;
    // Check if at least one image is selected
    if (selectedImage.length === 0) {
      errors.image_id = "Please select at least one image.";
      isValid = false;
    }

    if (!isValid) {
      // Display error toast with all collected errors
      showErrorToast(errors.image_id);
      return;
    }


    if (isValid) {
      try {
        setLoading(true);
        const formDataSubmit = new FormData();
        let imageId = selectedImage[0]
        // Dynamically append only non-null values to formDataSubmit

        if (selectedImage && selectedImage.length > 0) {
          formDataSubmit.append('image_id', String(selectedImage[0])); // Add the image ID if needed
        }




        const response = await apiCall({
          url: `traveler-profile-update?section=image_upload`,
          method: 'POST',
          data: formDataSubmit,
          headers: getHeaders(userTkn, currency)
        });
        if (response?.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;

            // Dynamically set errors based on the response
            Object.keys(errors).forEach((key) => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
              // Set the error state for the corresponding field
              setErrors((prevData: any) => ({
                ...prevData,
                [key]: firstErrorMessage,
              }));
            });
          } else {
            let data = response?.data?.image_upload?.data?.image;

            setFormData(prevData => ({
              ...prevData,
              image_id: data.id,
              profile_image_url: data.url
            }));
            setStartFormData(prevData => ({
              ...prevData,
              image_id: data.id,
              profile_image_url: data.url
            }));
            setUserProfile((prevState) => ({
              ...prevState,
              user: {
                ...prevState.user,
                image: data
              }
            }));

            setModalShowImg(false);
            showSuccessToast("Update successfully!!");
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    }
  }

  return (

    <div  >
      <section id="hero-faq" className="d-flex flex-column justify-content-center"
        style={{ background: `url(${BannerImage})` }}>

        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              {homeContentItems?.travel_banner_1?.item_value && <p className="hero-heading fw-bold high-light-red text-center m-0"> <span className="high-light-red">{homeContentItems?.travel_banner_1?.item_value ?? ""} {userProfile?.user?.first_name ?? ""}</span> </p>}
              {homeContentItems?.travel_banner_2?.item_value && <p className="hero-heading fw-bold high-light-white text-center m-0">{homeContentItems?.travel_banner_2?.item_value ?? ""} </p>}
              {homeContentItems?.travel_banner_3?.item_value && <p className="hero-heading fw-bold high-light-white text-center m-0">{homeContentItems?.travel_banner_3?.item_value ?? ""} </p>}
              {/* <div className="custom-btn-size d-flex justify-content-center">
                <Button variant="primary" className="flex-grow-1 flex-lg-grow-0"> Complete Profile </Button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="profile pb-0 pt-3">
        <div className="container  ">
          {/*first row*/}
          <div className="profile-container">
            {homeContentItems?.travel_heading_1?.item_value && <p className="main-heading fw-bold high-light-black text-center py-4"> <span className="high-light-red">{homeContentItems?.travel_heading_1?.item_value ?? ""}</span> {homeContentItems?.travel_heading_2?.item_value ?? ""} </p>}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-3">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  {/* <div className="d-flex align-items-center">
                    <img src={group30469} alt="Destination Icon" className="form-icon " />
                    <span>Preferred Name</span>
                  </div> */}
                  <div className="d-flex justify-content-between profile-style" onClick={() => { openModal('travelName') }}>
                    <div className="d-flex align-items-center"><img src={group30469} alt="Destination Icon" className="form-icon" /><span>{homeContentItems?.form_heading_1?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_update_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  <input type="text" disabled className="firstName openText" placeholder="Name" value={`${startFormData?.first_name ?? ""} ${startFormData?.last_name ?? ""}`} />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-3 mb-sm-3  mb-md-3  mb-lg-3 d-flex d-sm-flex d-lg-flex justify-content-start justify-content-sm-end">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  <div className="d-flex justify-content-between profile-style" onClick={() => { openModal('travelStyles') }}>
                    <div className="d-flex align-items-center"><img src={user_blk_pink} alt="Destination Icon" className="user-group" /><span>{homeContentItems?.form_heading_2?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_update_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="selected-travel-style d-flex  mt-4">
                    <div className=" d-flex gap-1 gap-sm-1 gap-md-1 gap-lg-4">{selectedStylesTags?.slice(0, 2).map((item, index) => (<div className="travel-style" key={index}>{item}</div>))}</div>
                    {startFormData?.travel_style_tags?.length > 2 && <div className="style-extra-count" onClick={() => { openModal('travelStyles') }}>{`+${startFormData?.travel_style_tags?.length - 2}`}</div>}
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/*second row*/}
          <div className="profile-container lg-mt-5 md-mt-5 sm-mt-0">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6  mb-3">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  <div className="d-flex justify-content-between profile-style" onClick={() => { openModal('travelTripType') }}>
                    <div className="d-flex align-items-center"><img src={mount_trip} alt="Destination Icon" className="user-group" /><span>{homeContentItems?.form_heading_3?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_update_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="selected-travel-style d-flex  mt-4">
                    <div className=" d-flex gap-1 gap-sm-1 gap-md-1 gap-lg-4">{selectedTripTypesTags.slice(0, 2).map((item, index) => (<div className="travel-style" key={index}>{item}</div>))}</div>
                    {selectedTripTypesTags.length > 2 && <div className="style-extra-count" onClick={() => { openModal('travelTripType') }}>{`+${selectedTripTypesTags.length - 2}`}</div>}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-3 d-flex d-sm-flex d-lg-flex justify-content-start justify-content-sm-end">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  <div className="d-flex justify-content-between profile-style" onClick={() => { openModal('travelPreferencesType') }}>
                    <div className="d-flex align-items-center"><img src={travel_pref} alt="Destination Icon" className="user-group" /><span>{homeContentItems?.form_heading_4?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_update_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="selected-travel-style d-flex  mt-4">
                    <div className=" d-flex gap-1 gap-sm-1 gap-md-1 gap-lg-4">{selectedPreferencesTypesTags.slice(0, 2).map((item, index) => (<div className="travel-style" key={index}>{item}</div>))}</div>
                    {selectedPreferencesTypesTags.length > 2 && <div className="style-extra-count" onClick={() => { openModal('travelPreferencesType') }}>{`+${selectedPreferencesTypesTags.length - 2}`}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Third row*/}
          <div className="profile-container lg-mt-5 md-mt-5 sm-mt-0">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-3">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  <div className="d-flex justify-content-between profile-style" onClick={() => { openModal('lookingFor') }}>
                    <div className="d-flex align-items-center">
                      <img src={group_loc} alt="Destination Icon" className="user-group" />
                      <span>{homeContentItems?.form_heading_5?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_update_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="selected-travel-style d-flex  mt-4">
                    <div className=" d-flex gap-1 gap-sm-1 gap-md-1 gap-lg-4">{selectedLookingForTags?.slice(0, 2).map((item, index) => (<div className="travel-style" key={index}>{item}</div>))}</div>
                    {selectedLookingForTags?.length > 2 && <div className="style-extra-count" onClick={() => { openModal('lookingFor') }}>{`+${selectedLookingForTags?.length - 2}`}</div>}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-3 d-flex d-sm-flex d-lg-flex justify-content-start justify-content-sm-end">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  <div className="d-flex justify-content-between profile-style" onClick={() => { openModal('topDestination') }}>
                    <div className="d-flex align-items-center"><img src={travel_flight} alt="Destination Icon" className="user-group" /><span>{homeContentItems?.form_heading_6?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_update_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="selected-travel-style d-flex  mt-4">
                    <div className=" d-flex gap-1 gap-sm-1 gap-md-1 gap-lg-4">{selectedTopDestinationsTags.slice(0, 2).map((item, index) => (<div className="travel-style" key={index}>{item}</div>))}</div>
                    {selectedTopDestinationsTags.length > 2 && <div className="style-extra-count" onClick={() => { openModal('topDestination') }}>{`+${selectedTopDestinationsTags.length - 2}`}</div>}
                  </div>
                </div>
              </div>


            </div>
          </div>
          {/*Fourth row*/}
          <div className="profile-container lg-mt-5 md-mt-5 sm-mt-0">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-3">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  <div className="d-flex justify-content-between profile-style" onClick={() => { openModal('airports') }}>
                    <div className="d-flex align-items-center"><img src={group_flight} alt="Destination Icon" className="user-group" /><span>{homeContentItems?.form_heading_7?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_update_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="selected-travel-style d-flex  mt-4">
                    <div className=" d-flex gap-1 gap-sm-1 gap-md-1 gap-lg-4">{selectedPreferredAirportsTags.slice(0, 2).map((item, index) => (<div className="travel-style" key={index}>{item}</div>))}</div>
                    {selectedPreferredAirportsTags.length > 2 && <div className="style-extra-count" onClick={() => { openModal('airports') }}>{`+${selectedPreferredAirportsTags.length - 2}`}</div>}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-3 d-flex d-sm-flex d-lg-flex justify-content-start justify-content-sm-end">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  <div className="d-flex justify-content-between profile-style" onClick={() => { setModalShowImg(true) }}>
                    <div className="d-flex align-items-center"><img src={perm_media} alt="Destination Icon" className="user-group" /><span>{homeContentItems?.form_heading_8?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_update_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="selected-travel-style d-flex  mt-4">
                    <div className=" ">
                      <Avatar src={startFormData?.profile_image_url ?? avatar57} size="xl" className='' />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="profile pt-0">
        <hr className="dashed-seprator" />
        <div className="container ">
          {/*first row*/}
          <div className="profile-container">
            <p className="main-heading fw-bold high-light-black text-center py-4 m-0"> <span className="high-light-red">{homeContentItems?.travel_heading_1?.item_value ?? ""}</span> {homeContentItems?.travel_heading_2?.item_value ?? ""} </p>
            <p className="brand-icon-heading text-center"> {homeContentItems.travel_text.item_value ?? ""} </p>
            <div className="row mt-5">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3 ">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  <div className="d-flex justify-content-between profile-style" onClick={() => { openModal('travelPassport') }}>
                    <div className="d-flex align-items-center"><img src={passport} alt="Destination Icon" className="user-group" /><span>{homeContentItems?.form_heading_9?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_add_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  <input type="text" disabled value={`${startFormData?.passport_first_name} ${startFormData?.passport_last_name}`} className="firstName openText passport" placeholder="Add details" onClick={() => { openModal("travelPassport") }} />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3 d-flex d-sm-flex d-lg-flex justify-content-start justify-content-sm-end">
                <div className="profile-box p-4 p-sm-2 p-xs-custom">
                  <div className="d-flex justify-content-between profile-style" onClick={(e) => { handleClick(e) }}>
                    <div className="d-flex align-items-center"><img src={date_range_light} alt="Destination Icon" className="user-group" /><span>{homeContentItems?.form_heading_10?.item_value ?? ""}</span> </div>
                    <div className="update-style d-flex align-items-center justify-content-center gap-2">
                      <p>{homeContentItems?.form_add_btn?.item_value ?? ""}</p>
                      <div className="forward-icon">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#E94560"
                          className=" fs-14"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <input type="date" disabled value={startFormData?.dob} className="firstName openText dob" placeholder="Add details" onClick={(e) => { handleClick(e) }} /> */}
                  <input
                    type="text"
                    disabled
                    value={startFormData?.dob ? startFormData.dob : ""}
                    className="firstName openText dob"
                    placeholder="Add details"
                    onClick={(e) => { handleClick(e) }}
                  />
                  {isOpen && (
                    <div className="date-picker-container">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center "><img src={date_range_light} alt="Destination Icon" className="user-group" />
                          <p className="m-0 title-dob">{homeContentItems?.form_heading_10?.item_value ?? ""}</p>
                        </div>
                        <div className="modal-close-profile" onClick={(e) => { handleClick(e) }}>
                          <FontAwesomeIcon icon={faClose} className="fs-9" />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center my-3">
                        {/* <DatePicker className="dob-picker" selected={startDate} onChange={(date) => setStartDate(date)} inline /> */}
                        <DatePicker
                          inline
                          className="dob-picker"

                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }: any) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                                gap: "10px"
                              }}
                            >
                              <button className="month-nav-left" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                              </button>
                              <select
                                value={getYear(date)}
                                onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => changeYear(Number(value))}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <button className="month-nav-right" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <FontAwesomeIcon icon={faAngleRight} />
                              </button>
                            </div>
                          )}
                          selected={formData?.dob}
                          onChange={(date: Date | null) => {
                            if (date) {
                              handleDateChange(date)
                            }
                          }}
                          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 12))}
                          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                        />
                      </div>
                      {dobError && <div className={dobError ? 'error-feedback' : ""}>
                        {dobError}
                      </div>
                      }
                      <div className="custom-btn-size d-flex justify-content-center">
                        <Button variant="primary" className="flex-grow-1 flex-lg-grow-0  w-100" onClick={(e) => {
                          handleSubmitDob(e);


                        }}> Save </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>


            </div>
          </div>
          {/*Second row*/}
          <div className="profile-container lg-mt-5 md-mt-5 sm-mt-0">
            <div className=" col-md-12 col-lg-12 d-flex justify-content-center">
              <div className="profile-box p-4 p-sm-2 p-xs-custom  w-xs-100 w-sm-100  w-md-50">
                <div className="d-flex justify-content-between profile-style" onClick={() => { openModal('travelContact') }}>
                  <div className="d-flex align-items-center"><img src={phone_call} alt="Destination Icon" className="user-group" /><span>{homeContentItems?.form_heading_11?.item_value ?? ""} </span> <span className="info">{homeContentItems?.form_heading_sub?.item_value ?? ""}</span></div>
                  <div className="update-style d-flex align-items-center justify-content-center gap-2">
                    <p>{homeContentItems?.form_update_btn?.item_value ?? ""}</p>
                    <div className="forward-icon">
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        color="#E94560"
                        className=" fs-14"
                      />
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  disabled
                  className="firstName openText phone"
                  value={startFormData?.phone_number}
                  placeholder="Phone Number"
                />

              </div>

            </div>
            {homeContentItems?.travel_btn?.item_value &&
              <div className="custom-btn-size d-flex justify-content-center mt-5">
                <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={() => { navigate("/trips-and-destination") }}> {homeContentItems?.travel_btn?.item_value ?? ""} </Button>
              </div>
            }
          </div>
        </div>
      </section>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow?.travelName && <TravellerName
          modalShow={modalShow}
          setModalShow={setModalShow}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleSubmit={handleSubmitName}
          loading={loading}
        />
        }
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow?.travelStyles && <TravelStyles
          modalShow={modalShow}
          setModalShow={setModalShow}
          formData={formData}
          setFormData={setFormData}
          setStartFormData={setStartFormData}
          setSelectedStylesTags={setSelectedStylesTags}
          errors={errors}
          setErrors={setErrors}
        />
        }
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow?.travelTripType && <TravelPreferredTripType
          modalShow={modalShow}
          setModalShow={setModalShow}
          formData={formData}
          setFormData={setFormData}
          setStartFormData={setStartFormData}
          setSelectedTripTypesTags={setSelectedTripTypesTags}
          errors={errors}
          setErrors={setErrors}
        />
        }
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow?.travelPreferencesType && <TravelPreferencesType
          modalShow={modalShow}
          setModalShow={setModalShow}
          formData={formData}
          setFormData={setFormData}
          setStartFormData={setStartFormData}
          setSelectedPreferencesTypesTags={setSelectedPreferencesTypesTags}
          errors={errors}
          setErrors={setErrors}
        />
        }
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow?.lookingFor && <TravelLookingFor
          modalShow={modalShow}
          setModalShow={setModalShow}
          formData={formData}
          setFormData={setFormData}
          setStartFormData={setStartFormData}
          setSelectedLookingForTags={setSelectedLookingForTags}
          errors={errors}
          setErrors={setErrors}
        />
        }
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow?.topDestination && <TravelTopDestinations
          modalShow={modalShow}
          setModalShow={setModalShow}
          formData={formData}
          setFormData={setFormData}
          setStartFormData={setStartFormData}
          setSelectedTopDestinationsTags={setSelectedTopDestinationsTags}
          errors={errors}
          setErrors={setErrors}
        />
        }
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow?.airports && <TravelPreferredAirports
          modalShow={modalShow}
          setModalShow={setModalShow}
          formData={formData}
          setFormData={setFormData}
          setStartFormData={setStartFormData}
          setSelectedPreferredAirportsTags={setSelectedPreferredAirportsTags}
          errors={errors}
          setErrors={setErrors}
        />
        }
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow?.travelPassport && <PassportDetails
          modalShow={modalShow}
          setModalShow={setModalShow}
          formData={formData}
          setFormData={setFormData}
          setStartFormData={setStartFormData}
          errors={errors}
          setErrors={setErrors}
        />
        }
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {modalShow?.travelContact && <TravellerContact
          modalShow={modalShow}
          setModalShow={setModalShow}
          formData={formData}
          setFormData={setFormData}
          setStartFormData={setStartFormData}
          errors={errors}
          setErrors={setErrors}
        />
        }
      </Suspense>

      <Modal
        show={modalShowImg}
        onHide={() => setModalShowImg(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Image Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageManager selectedImage={selectedImage} setSelectedImage={setSelectedImage}
            selectedImageUrl={selectedImageUrl} setSelectedImageUrl={setSelectedImageUrl}
            customSize={customImageSize}

            type={"bannerImage"}
            closeModal={() => closeGallery()}
          />

        </Modal.Body>
      </Modal>
    </div>
  )
}

export default TravelProfile
