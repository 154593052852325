import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEnquireNow } from "providers/EnquireNowProvider";
import React, { Suspense, useEffect, lazy } from "react";
import { Modal, Row } from "react-bootstrap"
import { enguireNowSteps } from "./enguireNowSteps";
import { initialEnqNowError, initialEnquireNow } from "types/interfaces";
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import { handleApiError } from "services/handleApiError";
import { getHeaders } from "services/getHeaders";
import { useMeta } from "providers/MetaContext";

const BasicDetails = lazy(() => import('./steps/BasicDetails'));
const PricingDetails = lazy(() => import('./steps/PricingDetails'));
const UserInfo = lazy(() => import('./steps/UserInfo'));
const RequestStatus = lazy(() => import('./steps/RequestStatus'));

type TripSignInProps = {
    showErrorToast: (message: string) => void;
    showSuccessToast: (message: string) => void;
    slug: string;
}

export type Step = {
    step: number;
    title: string;
};

const EnquireNow: React.FC<TripSignInProps> = ({ showErrorToast, showSuccessToast, slug }) => {
    const { showEnquireNow, setShowEnquireNow,
        formSteps,
        setFormSteps,
        currentStep,
        setCurrentStep,
        enquireData,
        setEnquireData,
        errors,
        setErrors
    } = useEnquireNow();
    const { userTkn, signOut } = useAuth();
    const { currency } = useMeta();

    const handleClose = () => {
        setCurrentStep(0);
        setEnquireData(initialEnquireNow)
        setErrors(initialEnqNowError)
        setFormSteps(enguireNowSteps?.steps);
        setShowEnquireNow(false)
    };

    useEffect(() => {
        setFormSteps(enguireNowSteps?.steps);
    }, []);

    const handleSubmitBasic = () => {
        const { no_of_travellers, is_date_firm, trip_duration, trip_date, trip_type, is_trip_lenth_firm, travel_style } = enquireData;
        let validationErrors = { ...initialEnqNowError };
        let isValid = true;
        if (!no_of_travellers) {
            validationErrors.no_of_travellers = "Number of travellers is required";
            isValid = false;
        }
        // Validate is_date_firm (must be either 0 or 1)
        if (is_date_firm !== 0 && is_date_firm !== 1) {
            validationErrors.is_date_firm = "Please select if the trip date is firm";
            isValid = false;
        }
        if (!trip_duration) {
            validationErrors.trip_duration = "Number of days is required";
            isValid = false;
        }
        if (!trip_type) {
            validationErrors.trip_type = "Trip type is required";
            isValid = false;
        }
        if (!trip_date) {
            validationErrors.trip_date = "Trip date is required";
            isValid = false;
        }
        if (is_trip_lenth_firm === null) {
            validationErrors.is_trip_lenth_firm = "Please select if the trip length is firm";
            isValid = false;
        }
        if (!travel_style) {
            validationErrors.travel_style = "Travel style is required";
            isValid = false;
        }

        if (isValid) {
            nextStepSave();
        } else {
            setErrors(validationErrors);
            showErrorToast("Please fill in all required fields.");
        }
    };

    const handleSubmitPricing = () => {
        const { budget_min, budget_max, depature_from } = enquireData;
        let validationErrors = { ...initialEnqNowError };
        let isValid = true;
        if (!budget_min) {
            validationErrors.no_of_travellers = "Minmum budget is required";
            isValid = false;
        }
        if (!budget_max) {
            validationErrors.no_of_travellers = "Maximum budget is required";
            isValid = false;
        }
        if (!depature_from) {
            validationErrors.depature_from = "Depart from is required";
            isValid = false;
        }

        if (isValid) {
            nextStepSave();
        } else {
            setErrors(validationErrors);
            showErrorToast("Please fill in all required fields.");
        }
    }

    const handleSubmitUserInfo = async () => {
        const { name, email, phone, recaptcha } = enquireData;
        let validationErrors = { ...initialEnqNowError };
        let isValid = true;
        // Validate name
        if (!name.trim()) {
            validationErrors.name = "Name is required";
            isValid = false;
        } else if (/^\d/.test(name)) {
            validationErrors.name = "Name cannot start with a number";
            isValid = false;
        } else if (name.length > 50) {
            validationErrors.name = "Name cannot exceed 50 characters";
            isValid = false;
        }

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim()) {
            validationErrors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(email)) {
            validationErrors.email = "Invalid email format";
            isValid = false;
        }

        // Validate phone
        const phoneRegex = /^[+]?[0-9]{1,15}$/;
        if (!phone) {
            validationErrors.phone = "Phone number is required";
            isValid = false;
        } else if (!phoneRegex.test(phone)) {
            validationErrors.phone = "Invalid phone number. Only [0-9] and + are allowed, and it should be a maximum of 15 digits.";
            isValid = false;
        }

        // Validate reCAPTCHA
        if (!recaptcha) {
            validationErrors.recaptcha = "Please complete the CAPTCHA";
            isValid = false;
        }

        if (isValid) {
            try {
                const formData = new FormData();

                formData.append('no_of_travellers', String(enquireData?.no_of_travellers));
                formData.append('trip_duration', String(enquireData?.trip_duration));
                formData.append('is_date_firm', String(enquireData?.is_date_firm));
                formData.append('is_trip_lenth_firm', String(enquireData.is_trip_lenth_firm));
                const formattedDate = enquireData?.trip_date ? new Date(enquireData?.trip_date).toISOString().split('T')[0] : '';
                formData.append('trip_date', formattedDate);
                formData.append('trip_type', enquireData?.trip_type);
                formData.append('travel_style', enquireData?.travel_style);
                formData.append('notes', enquireData?.notes);
                formData.append('depature_from', enquireData?.depature_from);
                formData.append('budget_min', String(enquireData?.budget_min));
                formData.append('budget_max', String(enquireData?.budget_max));
                formData.append('name', enquireData?.name);
                formData.append('email', enquireData?.email);
                formData.append('phone', enquireData?.phone);

                const headers = userTkn
                    ? { 'x-access-token': userTkn }
                    : {};

                const response = await apiCall({
                    url: `tour/${slug}/lead`,
                    method: 'POST',
                    data: formData,
                    headers: getHeaders(userTkn, currency)
                });

                if (response?.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;
                        showErrorToast(errors);
                    } else {

                        nextStepSave();

                    }

                }

            } catch (error:any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {

            }
            // nextStepSave();
        } else {
            setErrors(validationErrors);
            showErrorToast("Please fill in all required fields.");
        }

    }

    const nextStepSave = () => {
        if (currentStep < formSteps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const goToStep = (index: number) => {
        if (index >= 0 && index < formSteps.length) {
            setCurrentStep(index);
        }
    };

    const renderStepContent = (step: Step) => {
        switch (step.step) {
            case 1:
                return <BasicDetails
                    enquireData={enquireData}
                    setEnquireData={setEnquireData}
                    errors={errors}
                    setErrors={setErrors}
                    handleSubmit={handleSubmitBasic}

                />;
            case 2:
                return <PricingDetails

                    enquireData={enquireData}
                    setEnquireData={setEnquireData}
                    errors={errors}
                    setErrors={setErrors}
                    handleSubmit={handleSubmitPricing}

                />;
            case 3:
                return <UserInfo
                    enquireData={enquireData}
                    setEnquireData={setEnquireData}
                    errors={errors}
                    setErrors={setErrors}
                    handleSubmit={handleSubmitUserInfo}
                />;
            case 4:
                return <RequestStatus
                    enquireData={enquireData}
                />;
            default:
                return null;
        }
    };

    const nextStepClick = (currentStep: number) => {
        return () => {
            switch (currentStep) {
                case 0:
                    handleSubmitBasic();
                    break;
                case 1:
                    handleSubmitPricing();
                    break;
                case 2:
                    handleSubmitUserInfo();
                    break;
                default:
                    nextStepSave();
            }
        };
    };

    const handleDone = () => {

        // showSuccessToast(response?.data?.message)
        handleClose();

    }

    return (
        <Modal size="xl" show={showEnquireNow} onHide={handleClose} className="enquire-now-modal" style={{maxHeight:"700px", marginTop:"10px"}} centered={false} backdrop="static" aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header >
                <div></div>
                <div className="d-flex justify-content-center text-center mb-0">
                    <div>
                        <h3 className="mb-2">Request</h3>
                        <p className="fs-6">{formSteps[currentStep]?.title}</p>
                    </div>
                </div>
                <div className="modal-close mt-1 " onClick={handleClose}>
                    <FontAwesomeIcon icon={faClose} className="fs-9" />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="body-enquire-now">
                    <Row>
                        <div className='col-md-12 mb-2'>
                            <div className="stepper">
                                {formSteps?.map((step, index) => (
                                    <React.Fragment key={index}>
                                        <div className="step-container" key={index}>
                                            {/* <div className={`step-title-${index}`}>{step.title}</div> */}
                                            <div
                                                className={`step ${index === currentStep ? 'active' : ''}  ${currentStep > index ? 'completed' : ''}`}
                                                onClick={() => goToStep(index)}
                                            >
                                                <div className="step-number"></div>
                                            </div>
                                        </div>
                                        {index < formSteps.length - 1 && <div className="step-connector" key={index + 1}>
                                            <span className={`steppr-line  ${currentStep > index ? 'completed-line' : ''}`}></span>
                                        </div>}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </Row>
                    {formSteps.length > 0 && (
                        <Suspense fallback={<div className='step-content-0-main'>Loading...</div>}>
                            <div className={currentStep !== 0 ? `step-content-${currentStep}` : ""}>
                                {renderStepContent(formSteps[currentStep])}
                            </div>
                        </Suspense>
                    )}

                    <>
                        <div className={`step-navigation-enquire mt-3 navigatio-step-${currentStep}`}>

                            {(currentStep < 3 && currentStep !== 0) && <div className="custom-btn-size d-flex justify-content-center py-1 ">
                                <button onClick={prevStep} disabled={currentStep === 0} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                    {"Previous"}
                                </button>
                            </div>
                            }

                            {currentStep < 3 && <div className={`navigatio-step-${currentStep}`}>
                                <div className="custom-btn-size d-flex justify-content-center py-1">
                                    <button
                                        // onClick={nextStepSave}
                                        onClick={nextStepClick(currentStep)}
                                        disabled={currentStep === formSteps.length - 1}
                                        type="button"
                                        className="flex-grow-1 flex-lg-grow-0 btn btn-primary"
                                    >
                                        {"Next"}
                                    </button>
                                </div>
                            </div>
                            }

                            {currentStep === 3 && <div className="custom-btn-size d-flex justify-content-center py-1">
                                <button onClick={handleDone} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                    {"Done"}
                                </button>
                            </div>
                            }


                        </div>

                    </>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default EnquireNow