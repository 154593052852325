import DropzoneCustom from 'components/base/DropzoneCustom';
import { FileAttachment } from 'components/common/AttachmentPreview';
import { useAuth } from 'providers/AuthContext';
import { useImageGallery } from 'providers/ImageGalleryContext';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Form, Nav, Spinner, Tab } from 'react-bootstrap';
import apiCall from 'services/api';
import Cropper from 'react-easy-crop';
import { AxiosError } from 'axios';
import { useToast } from 'providers/ToastProvider';
import SearchBox from 'components/common/SearchBox';
import { handleApiError } from 'services/handleApiError';



interface Image {
  id: string;
  url: string;
  name: string;
  caption: string;
  alt: string;
}

interface Size {
  minWidth: number;
  minHeight: number;
  maxWidth: number;
  maxHeight: number;
}

type ImageProps = {
  selectedImage: number[],
  setSelectedImage: React.Dispatch<React.SetStateAction<number[]>>,
  selectedImageUrl: any[],
  setSelectedImageUrl: React.Dispatch<React.SetStateAction<any[]>>,
  customSize?: Size,
  type: string,
  closeModal: () => void,
}

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  cropSize: { width: number; height: number },
  originalFileType: string
): Promise<string> {
  const image = new Image();
  image.src = imageSrc;



  return new Promise((resolve, reject) => {
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject('Canvas context is not available');
        return;
      }

      canvas.width = cropSize.width;
      canvas.height = cropSize.height;

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        cropSize.width,
        cropSize.height
      );

      // Determine the correct MIME type based on the originalFileType
      let mimeType = 'image/png'; // Default MIME type

      if (originalFileType.includes('jpeg')) {
        mimeType = 'image/jpeg';
      } else if (originalFileType.includes('png')) {
        mimeType = 'image/png';
      }

      canvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          resolve(url);
        } else {
          reject('Failed to create blob');
        }
      }, mimeType);
    };

    image.onerror = () => {
      reject('Failed to load image');
    };
  });
}


const ImageManager: React.FC<ImageProps> = ({ selectedImage, setSelectedImage, customSize, type, closeModal, selectedImageUrl, setSelectedImageUrl }) => {
  const { showToast, showSuccessToast, showInfoToast, showErrorToast } = useToast();
  const [activeTab, setActiveTab] = useState<'GALLERY' | 'UPLOAD'>('GALLERY');
  const [images, setImages] = useState<Image[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { userTkn, signOut } = useAuth();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [summary, setSummary] = useState<any | null>({
    "total": 0,
    "page": 1,
    "pageSize": 12,
    "total_page": 1,
  });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const defaultSize: Size = {
    minWidth: 450,
    minHeight: 325,
    maxWidth: 450,
    maxHeight: 325,
  };



  const [imageSize, setImageSize] = useState<Size>(customSize || defaultSize);
  const [errorMessage, setErrorMessage] = useState('');
  const [imageDetails, setImageDetails] = useState<any[]>([]);
  const [cropImage, setCropImage] = useState(false);
  // const [crop, setCrop] = useState<Crop>();
  const containerSize = { width: 960, height: 225 };
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [filter, setFilter] = useState<string>('');
  const [cropSize, setCropSize] = useState({ width: imageSize?.maxWidth, height: imageSize?.minHeight });
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [sucessUpload, setSucessUpload] = useState<boolean>(false)

  const fetchImages = useCallback(async (searchQuery?: string) => {
    try {
      if (pageIndex >= 2) {
        setLoadingMore(true);
      } else {
        setLoading(true);
      }

      const response = await apiCall({
        url: `photos?page=${pageIndex}&pageSize=20&height=${imageSize?.maxHeight}&width=${imageSize?.maxWidth}&search=${searchQuery}`,
        method: 'GET',
        headers: {
          'x-access-token': userTkn,
        },
      });

      if (response?.status === 200) {
        let data = response?.data?.images?.data;
        const summary = response?.data?.images?.summary;

        setImages((prevImages: Image[]) => {
          const updatedImages = data.map((newImage: Image) => {
            const existingImage = prevImages.find(image => image.id === newImage.id);
            return existingImage && JSON.stringify(existingImage) !== JSON.stringify(newImage)
              ? newImage
              : existingImage || newImage;
          });
          return [...prevImages, ...updatedImages.filter((newImage: Image) => !prevImages.some(image => image.id === newImage.id))];
        });
        setSummary({
          "total": summary?.total,
          "page": summary?.page,
          "pageSize": summary?.pageSize,
          "total_page": summary?.total_page,
        });
      }
    } catch (error: any) {
      handleApiError(error, showErrorToast, signOut);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  }, [userTkn, pageIndex]);

  useEffect(() => {
    if (userTkn) {
      fetchImages(searchQuery);
    }
  }, [userTkn, pageIndex]);



  const handleLoadMore = () => {
    setLoadingMore(true);
    if (Number(pageIndex) <= Number(summary?.total_page)) {

      // Set loading state for "Load More" button
      setPageIndex(prevPage => prevPage + 1);
    } else {
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);

    }
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    if (errorMessage) return;
    if (selectedFile.size > 1048576) {
      showErrorToast("File size exceeds 1 MB. Please select a smaller file.");
      return; // Exit the function to prevent further execution
    }
    const formData = new FormData();
    const fileName = selectedFile.name;
    formData.append('image_file', selectedFile);
    formData.append('name', fileName); // Set name
    formData.append('caption', fileName); // Set caption
    formData.append('alt', fileName); // Set alt

    try {
      const response = await apiCall({
        url: 'photos/create',
        method: 'POST',
        data: formData,
        headers: {
          'x-access-token': userTkn,
        },
      });

      if (response?.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;
          showErrorToast(errors);
        } else {
          // Set selected file to null
          fetchImages(searchQuery);
          setSelectedFile(null);
          setCroppedImageUrl('');

          // Fetch images


          // Optionally, switch to the "GALLERY" tab
          setActiveTab('GALLERY');
        }

      }



    } catch (error: any) {
      // Check if the error is an AxiosError
      if (error.isAxiosError) {
        const axiosError = error as AxiosError;

        // Check the status code
        if (axiosError.response?.status === 413) {
          showErrorToast("Payload Too Large: Please reduce the size of your request.");
        } else {
          const errorMessage = (axiosError.response?.data as { message?: string })?.message || 'An error occurred during upload .';
          showErrorToast(errorMessage);
        }
      } else {
        // Handle non-Axios errors
        showErrorToast(error?.message || 'An unknown error occurred.');
      }

    }
    setSelectedFile(null); // Clear file input after upload
  };




  const handleImageSelect = (id: number, type: string, image: any) => async (event: React.ChangeEvent<HTMLInputElement>) => {

    // Clone the current state arrays
    const updatedListingImages = [...selectedImage];
    const updatedImageDetails = [...selectedImageUrl];

    // Check if id is already in the array
    const index = updatedListingImages.indexOf(id);

    // Extract relevant properties from the image object
    const imageDetails = {
      id: image.id,
      url: image.url,
      name: image.name
    };

    if (type === "bannerImage") {
      if (index === -1) {
        // For "bannerImage", only one image can be selected
        setSelectedImage([id]);
        setSelectedImageUrl([imageDetails]);
      } else {
        // If already selected, unselect it
        setSelectedImage([]);
        setSelectedImageUrl([]);
      }
    } else if (type === "listingImage") {
      if (index === -1) {
        // For "listingImage", multiple images can be selected
        updatedListingImages.push(id);
        updatedImageDetails.push(imageDetails);
        setSelectedImage(updatedListingImages);
        setSelectedImageUrl(updatedImageDetails);
      } else {
        // If already selected, remove it
        updatedListingImages.splice(index, 1);
        updatedImageDetails.splice(index, 1);
        setSelectedImage(updatedListingImages);
        setSelectedImageUrl(updatedImageDetails);
      }
    }
  };



  const handleDropImage = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setSelectedFile(file)

  };

  const getFileMimeType = (fileName: string): string => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      default:
        return 'image/png'; // Default MIME type if unknown
    }
  };

  //crop image 
  const onCropComplete = useCallback(async (croppedArea: any, croppedAreaPixels: any) => {
    try {
      const imageSrc = imageDetails[0]?.preview;

      if (!imageSrc) {
        throw new Error('Image source is not available');
      }

      const originalFileType = imageDetails[0]?.name ? getFileMimeType(imageDetails[0]?.name) : 'image/png';
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, cropSize, originalFileType);


      if (!croppedImage) {
        console.error('Failed to get cropped image');
        return;
      }

      setCroppedImageUrl(croppedImage);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  }, [imageDetails]);

  const handleConfirmCrop = async () => {
    if (!croppedImageUrl) {
      console.error('No cropped image available');
      return;
    }

    try {
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const croppedFile = new File([blob], selectedFile?.name || 'croppedImage.png', { type: blob.type });
      const url = URL.createObjectURL(blob);

      setSelectedFile(croppedFile);  // Set the cropped file as the selected file

      setCroppedImageUrl(url);
      setCropImage(false);
      setErrorMessage('')

      // Create a link element to download the image
      // const link = document.createElement('a');
      // link.href = url;
      // link.download = 'cropped-image.png';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);


    } catch (error) {
      console.error('Error saving cropped image:', error);
    }
  };

  const modalAction = (type: string) => {
    closeModal()
  }

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value); // Update the search query
  };

  const handleSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setPageIndex(1);
      setImages([])
      fetchImages(searchQuery); // Trigger search
    }
  };

  const handleSearchClick = () => {
    setPageIndex(1);
    setImages([])
    fetchImages(searchQuery); // Trigger search
  };
  return (
    <Tab.Container id={`tabs-1`} activeKey={activeTab} onSelect={(k) => setActiveTab(k as 'GALLERY' | 'UPLOAD')}>
      <Nav variant="underline" className="">
        <Nav.Item>
          <Nav.Link eventKey="GALLERY">GALLERY</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="UPLOAD">Upload</Nav.Link>
        </Nav.Item>
      </Nav>

      <Tab.Content>
        <Tab.Pane eventKey="GALLERY">
          <div className="image-manager">
            <div className="trip-image-grid-view">
              <div className="container mt-3 mb-3">
                <div className="row">
                  <div className="col d-flex custom-search-box">
                    <SearchBox
                      type="text"
                      className="w-100 custom-search"
                      placeholder="Search images..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                      onKeyDown={handleSearchKeyPress}
                    />
                    <div className='custom-btn-small'>
                      <Button variant="primary" className='custom-search-btn' onClick={handleSearchClick}>Search</Button>
                    </div>
                  </div>
                </div>
              </div>
              {loading ?
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                :
                <div className=''>
                  <div className="trip-image-grid">
                    {images.map((image: any) => (
                      <div className='galley-image-container' key={image.id}>
                        <img
                          src={image.url}
                          alt={image.title}
                          style={{ cursor: 'pointer' }}
                        />
                        <Form.Check
                          type='checkbox'
                          className='gallery-image-check'
                          name="is_meta_title_same_as_title"
                          label={""}
                          disabled={image?.width !== imageSize.maxWidth}
                          checked={selectedImage?.includes(image?.id)}
                          onChange={handleImageSelect(image?.id, type, image)}
                        />

                      </div>
                    ))}

                    {images?.length > 0 &&
                      <>
                        {((Number(pageIndex) === 1 && !loading) || Number(pageIndex) > 1) &&
                          <div className="custom-btn-small d-flex justify-content-center mt-3">
                            {Number(summary?.page) === Number(summary?.total_page) ?
                              <div className="d-flex justify-content-center"></div>
                              :
                              <>
                                {summary.total_page >= 2 &&
                                  <button onClick={handleLoadMore} className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2">
                                    {loadingMore ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                      : ''
                                    }
                                    <span className="ml-2">{"Load More"}</span>
                                  </button>
                                }
                              </>

                            }
                          </div>
                        }
                      </>
                    }
                  </div>
                </div>
              }
            </div>
            <div className='custom-btn-small confirm-gallery-container d-flex justify-content-end mt-3 mr-2'>
              <Button className='confirm-gallery-btn' onClick={() => { modalAction(type) }}>Confirm</Button>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="UPLOAD" >
          <div style={{ position: "relative" }}>
            {!cropImage ?
              <div>
                <div className="image-manager">
                  <DropzoneCustom
                    imageSize={imageSize}

                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    setCropImage={setCropImage}
                    croppedImageUrl={croppedImageUrl}
                    multiple={false}
                    setImageDetails={setImageDetails}
                    onDrop={(acceptedFiles: File[]) => handleDropImage(acceptedFiles)}
                  />


                </div>

                {(!errorMessage && selectedFile) && <Button className='mt-2' onClick={handleUpload}>Upload</Button>}
              </div>

              :
              <div>

                <div style={{  width: '100%', height: '450px' }}>
                  <Cropper
                    image={imageDetails[0]?.preview}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={imageSize.maxWidth / imageSize.maxHeight}
                    onCropComplete={onCropComplete}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                    cropSize={cropSize}
                    showGrid={true}
                    minZoom={0.2}
                    maxZoom={3}
                    zoomSpeed={0.5}
                    zoomWithScroll={true}
                    restrictPosition	={false}
                    objectFit = "contain"
                    style={{
                      containerStyle: {
                        width: '100%',
                        height: '100%',
                        position: 'relative'
                      },
                      cropAreaStyle: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                      }
                    }}
                  />

                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <label>Zoom:</label>
                    <input
                      type="range"
                      min="1"
                      max="3"
                      step="0.1"
                      value={zoom}
                      onChange={(e) => setZoom(parseFloat(e.target.value))}
                    />
                  </div>
                  <div>
                    <label>Rotate:</label>
                    <input
                      type="number"
                      min="0"
                      max="360"
                      value={rotation}
                      onChange={(e) => setRotation(parseFloat(e.target.value))}
                    />
                  </div>
                  <div>
                    {/* <label>Filter:</label>
                    <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                      <option value="">None</option>
                      <option value="grayscale(100%)">Grayscale</option>
                      <option value="sepia(100%)">Sepia</option>
                      <option value="blur(5px)">Blur</option>
                      <option value="contrast(200%)">Contrast</option>
                    </select> */}
                  </div>
                </div>
                <div className='d-flex gap-2'>
                  <Button onClick={() => { setCropImage(false); setErrorMessage('') }}>Cancel</Button>
                  <Button onClick={handleConfirmCrop}>Confirm Crop</Button>
                </div>
              </div>

            }


          </div>

        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>

  );
};

export default ImageManager;
