import BannerImage from "assets/img/travel/banner/search.jpg";
import { Card, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import apiCall from "services/api";
import parse from 'html-react-parser';
import Testimonial from "components/asseenin/Testimonial";
import { useAuth } from "providers/AuthContext";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import DummyTeamCard from "components/common/dummycard/DummyTeamCard";
import TeamCard from "components/cards/TeamCard";


type AboutDataItems = {
    meta_title: string;
    meta_keyword: string;
    meta_description: string;
    description: string;
    heading: string;
    slug: string;
    status: number;
    bannerImage: null;
    listingImage: null;
}

const AboutUs = () => {
    const { userTkn, signOut } = useAuth();
    const { currency } = useMeta();
    const [aboutData, setAboutData] = useState<AboutDataItems | null>(null);
    const [team, setTeam] = useState<AboutDataItems | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {

                const [response, teamResponse] = await Promise.all([
                    apiCall({
                        url: `cms/about`,
                        method: 'GET',
                        headers: getHeaders(userTkn, currency),
                    }),
                    apiCall({
                        url: `cms/team`,
                        method: 'GET',
                        headers: getHeaders(userTkn, currency),
                    })
                ])


                if (response.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;
                        // Handle errors if needed
                    } else {
                        let aboutData = response?.data?.StaticPage?.data
                        setAboutData(aboutData);
                    }
                }
                if (teamResponse.status === 200) {
                    if (teamResponse?.data?.errors) {
                        const errors = teamResponse?.data.errors;
                        // Handle errors if needed
                    } else {
                        let data = teamResponse?.data?.Team?.data
                        setTeam(data);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <>  {loading && ( // Full page loading overlay
            <div className="loading-overlay">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="listing-spinner"
                />
            </div>
        )}
            <section id="hero-faq" className="d-flex flex-column justify-content-center"
                style={{ background: `url(${BannerImage})` }} >

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <p className="hero-heading fw-bold high-light-white text-center"> {aboutData?.meta_title} </p>
                            <p className="faq-sub-title">{aboutData?.meta_keyword}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-0">
                <div className="container-fluid card-content">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 mb-5">
                            <p className="heading-big" dangerouslySetInnerHTML={{ __html: aboutData?.heading || '' }} />
                            <p className="small-heading mb-5"></p>
                        </div>

                        <div className="col-lg-4 mb-3">
                            <Card className="card-trip">
                                <div className="top-text">
                                    <h6>1</h6>
                                </div>
                                <div className="center-text">
                                    <p>Enabling Creators</p>
                                </div>
                                <div className="bottom-text">
                                    <p>Curate, Inspire and Monetize your Audience</p>
                                </div>
                            </Card>
                        </div>

                        <div className="col-lg-4 mb-3">
                            <Card className="card-trip">
                                <div className="top-text">
                                    <h6>2</h6>
                                </div>
                                <div className="center-text">
                                    <p>Inspiring Travelers</p>
                                </div>
                                <div className="bottom-text">
                                    <p>Trusted, Vetted Creators to curate multi-day trips</p>
                                </div>
                            </Card>
                        </div>

                        <div className="col-lg-4 mb-3">
                            <Card className="card-trip">
                                <div className="top-text">
                                    <h6>3</h6>
                                </div>
                                <div className="center-text">
                                    <p>Sustainable Travel</p>
                                </div>
                                <div className="bottom-text">
                                    <p>Partnering with the best travel providers</p>
                                </div>
                            </Card>
                        </div>

                        <div className="col-lg-12">
                            <p className="about-para">{aboutData?.description ? parse(aboutData?.description || '') : ''}</p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="pb-0">
                <div className="container-fluid card-content">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 ">
                            <p className="main-heading fw-bold high-light-black">Meet the Travel Creators<span className="high-light-red">  Team</span> </p>
                        </div>

                        {loading && ( // Full page loading overlay
                            <>
                                <DummyTeamCard />
                                <DummyTeamCard />
                                <DummyTeamCard />
                                <DummyTeamCard />
                                <DummyTeamCard />
                                <DummyTeamCard />
                            </>


                        )}

                        {!loading &&
                            <>

                                {(Array.isArray(team) && team.length > 0) &&
                                    team?.map((team, index) => (
                                        <TeamCard key={index} team={team} />
                                    ))
                                }
                            </>
                        }

                    </div>
                </div>
            </section>


            <Testimonial />
        </>
    );
}

export default AboutUs;
