import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './DummyTrip.css'; // Ensure you have the relevant CSS

const DummyTripAgent = () => {
    return (
        <>
            <section id="hero-faq" className="dummy-hero d-flex flex-column justify-content-center">
                <div className="dummy-banner-placeholder-view"></div>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 text-center">
                            <div className="center-img d-flex align-items-center justify-content-center">
                                <div className="rounded-circle-border dummy-profile-img"></div>
                            </div>
                            <p className="dummy-price">Loading...</p>
                            <div className="icons">
                                {/* Placeholder icons */}
                                <i className="bi bi-facebook dummy-icon"></i>
                                <i className="bi bi-instagram dummy-icon"></i>
                                <i className="bi bi-twitter dummy-icon"></i>
                                <i className="bi bi-whatsapp dummy-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Container className="cont dummy-container mb-4">
                <Row className="pb-5 pt-4">
                    <Col xl={12} className="px-5 dummy-section">
                        <Row>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-3 d-flex align-items-end">
                                <div className="dummy-paragraph"></div>
                            </div>

                        </Row>
                    </Col>
                </Row>

                

                <Row className="mb-3">
                    <Col lg={12}>
                        <div className="dummy-paragraph"></div>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col lg={12}>
                        <div className="dummy-paragraph"></div>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col lg={2}>
                        <div className="categories-container">
                            <div className="dummy-category"></div>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="categories-container">
                            <div className="dummy-category"></div>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="categories-container">
                            <div className="dummy-category"></div>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="categories-container">
                            <div className="dummy-category"></div>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="categories-container">
                            <div className="dummy-category"></div>
                        </div>
                    </Col>
                </Row>



           





            </Container>
        </>
    );
};

export default DummyTripAgent;
