import BannerImage from "../../assets/img/travel/banner/tripprofile.png";
import CardImage from "../../assets/img/travel/banner/profileTrip.png";
import Button from "components/base/Button";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Card, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import { handleApiError } from "services/handleApiError";
import { useToast } from "providers/ToastProvider";
import { Tab } from "react-bootstrap";
import { Col } from "react-bootstrap";
import TripCard from "components/cards/TripsCard";
import { markWishList } from "services/markWishList";
import { useMeta } from "providers/MetaContext";
import { useSignIn } from "providers/SignInProvider";
import {
  ChildItemAllBtnText,
  initialChildItemAllBtnText,
  initialSummary,
  SummaryProps,
} from "types/interfaces";
import { getHeaders } from "services/getHeaders";
import Testimonial from "components/asseenin/Testimonial";
import DummyNoTripBooked from "components/common/dummycard/DummyNoTripBooked";

const MyTrips = () => {
  const navigate = useNavigate();
  const { userTkn, signOut } = useAuth();
  const { showErrorToast } = useToast();
  const { currency } = useMeta();
  const { setShowIn } = useSignIn();
  const [loading, setLoading] = useState(false);
  const [liveTrips, setLiveTrips] = useState<any[]>([]);
  const [pastTrips, setPastTrips] = useState<any[]>([]);
  const [savedTrips, setSavedTrips] = useState<any[]>([]);
  const [summaryLive, setSummaryLive] = useState<SummaryProps>(initialSummary);
  const [summaryPast, setSummaryPast] = useState<SummaryProps>(initialSummary);
  const [summarySaved, setSummarySaved] =
    useState<SummaryProps>(initialSummary);
  const [trips, setTrips] = useState<any[]>([]);
  const [totalTrips, setTotalTrips] = useState(0);
  const [btnText, setBtnText] = useState<ChildItemAllBtnText>(
    initialChildItemAllBtnText
  );

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        setLoading(true);
        const [
          btnTextResponse,
          liveTripsResponse,
          pastTripsResponse,
          savedTripsResponse,
          tripsResponse,
        ] = await Promise.all([
          apiCall({
            url: `cms/content-items/all_btn_text`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `my-live-trips`,
            method: "GET",
            headers: {
              "x-access-token": userTkn,
            },
          }),
          apiCall({
            url: `my-past-trips`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `my-wishlisted-trips`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `tours?page=&pageSize=12`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
        ]);
        if (btnTextResponse?.status === 200) {
          let data = btnTextResponse?.data?.ContentItem?.data?.content?.childs;
          setBtnText(data);
        }
        if (liveTripsResponse?.status === 200) {
          let data = liveTripsResponse?.data?.LiveTripVersion?.data;
          const summary = liveTripsResponse?.data?.LiveTripVersion?.summary;

          setSummaryLive({
            total: summary.total,
            page: summary.page,
            pageSize: summary.pageSize,
            total_page: summary.total_page,
          });
          setLiveTrips(data);
        }
        if (pastTripsResponse?.status === 200) {
          const data = pastTripsResponse?.data?.PastTripVersion?.data;
          const summary = pastTripsResponse?.data?.PastTripVersion?.summary;

          setSummaryPast({
            total: summary.total,
            page: summary.page,
            pageSize: summary.pageSize,
            total_page: summary.total_page,
          });
          setPastTrips(data);
        }
        if (savedTripsResponse?.status === 200) {
          const data = savedTripsResponse?.data?.WishlitedTripVersion?.data;
          const summary =
            savedTripsResponse?.data?.WishlitedTripVersion?.summary;

          setSummarySaved({
            total: summary.total,
            page: summary.page,
            pageSize: summary.pageSize,
            total_page: summary.total_page,
          });
          setSavedTrips(data);
        }
        if (tripsResponse?.status === 200) {
          const data = tripsResponse?.data?.TripVersion?.data;
          const total = tripsResponse?.data?.TripVersion?.summary.total;
          setTotalTrips(total);
          setTrips(data);
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setLoading(false);
      }
    };

    fetchTripData();
  }, [currency]);

  const handleMarkWishlist = async (tripId: string) => {
    await markWishList(
      tripId,
      userTkn,
      currency,
      setTrips,
      setShowIn,
      apiCall,
      showErrorToast
    );
  };
  const handleMarkWishlistLiveTrips = async (tripId: string) => {
    await markWishList(
      tripId,
      userTkn,
      currency,
      setLiveTrips,
      setShowIn,
      apiCall,
      showErrorToast
    );
  };
  const handleMarkWishlistPastTrip = async (tripId: string) => {
    await markWishList(
      tripId,
      userTkn,
      currency,
      setPastTrips,
      setShowIn,
      apiCall,
      showErrorToast
    );
  };
  const handleMarkWishlistSavedTrips = async (tripId: string) => {
    await markWishList(
      tripId,
      userTkn,
      currency,
      setPastTrips,
      setShowIn,
      apiCall,
      showErrorToast
    );
  };
  return (
    <>
      <section
        id="hero-faq"
        className="d-flex flex-column justify-content-center"
        style={{ background: `url(${BannerImage})` }}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="hero-heading fw-bold high-light-white text-center">
                Hi <span className="high-light-red">Janny</span>{" "}
              </p>
              <p className="hero-heading fw-bold high-light-white trip-pf  text-center">
                You have{" "}
                <span className="live-button">
                  {summaryLive?.total ?? 0} Live
                </span>{" "}
                trip request
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-0">
        <div className="container-fluid cont custom-tab-nav-items">
          <Tab.Container defaultActiveKey="live">
            {/* Tabs in one row */}
            <Row className="mb-3  justify-content-start">
              <Nav variant="underline" className="d-flex gap-5 ">
                <Nav.Item>
                  <div className="custom-tab-item-trip-1 ms-3">
                    <Nav.Link
                      eventKey="live"
                      className="custom-tab-nav-items-trip"
                    >
                      Live Trip
                    </Nav.Link>
                  </div>
                </Nav.Item>
                <Nav.Item>
                  <div className="custom-tab-item-trip ms-3">
                    <Nav.Link
                      eventKey="past"
                      className="custom-tab-nav-items-trip"
                    >
                      Past Trip
                    </Nav.Link>
                  </div>
                </Nav.Item>
                <Nav.Item>
                  <div className="custom-tab-item-trip ms-3">
                    <Nav.Link
                      eventKey="saved"
                      className="custom-tab-nav-items-trip"
                    >
                      Saved Trip
                    </Nav.Link>
                  </div>
                </Nav.Item>
              </Nav>
            </Row>

            {/* Active Tab Content */}
            <Row>
              <Col xl={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="live">
                    <Row>
                      {summaryLive?.total > 0 && (
                        <div className="col-12 mx-2">
                          {" "}
                          <p className="main-heading fw-bold high-light-black m-0">
                            <span className="high-light-red">
                              {summaryLive?.total}
                            </span>{" "}
                            Trips
                          </p>
                        </div>
                      )}
                      {Array.isArray(liveTrips) && liveTrips.length > 0 ? (
                        <>
                          {liveTrips?.map((item, index) => (
                            <TripCard
                              key={index}
                              item={item}
                              markWishList={handleMarkWishlistLiveTrips}
                              title={btnText?.trip_card_btn?.item_value}
                            />
                          ))}
                        </>
                      ) : (
                        <DummyNoTripBooked />
                      )}
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="past">
                    {summaryPast?.total > 0 && (
                      <div className="col-12 mx-2">
                        {" "}
                        <p className="main-heading fw-bold high-light-black m-0">
                          <span className="high-light-red">
                            {summaryPast?.total}
                          </span>{" "}
                          Trips
                        </p>
                      </div>
                    )}
                    <Row>
                      {Array.isArray(pastTrips) && pastTrips?.length > 0 ? (
                        <>
                          {pastTrips?.map((item, index) => (
                            <TripCard
                              key={index}
                              item={item}
                              markWishList={handleMarkWishlistPastTrip}
                              title={btnText?.trip_card_btn?.item_value}
                            />
                          ))}
                        </>
                      ) : (
                        <DummyNoTripBooked />
                      )}
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="saved">
                    {summarySaved?.total > 0 && (
                      <div className="col-12 mx-2">
                        {" "}
                        <p className="main-heading fw-bold high-light-black m-0">
                          <span className="high-light-red">
                            {summarySaved?.total}
                          </span>{" "}
                          Trips
                        </p>
                      </div>
                    )}
                    <Row>
                      {Array.isArray(savedTrips) && savedTrips?.length > 0 ? (
                        <>
                          {savedTrips?.map((item, index) => (
                            <TripCard
                              key={index}
                              item={item}
                              markWishList={handleMarkWishlistSavedTrips}
                              title={btnText?.trip_card_btn?.item_value}
                            />
                          ))}
                        </>
                      ) : (
                        <DummyNoTripBooked />
                      )}
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </section>

      <section className="pb-0">
        <div className="container-fluid cont">
          <div className="row justify-content-center">
            <div className="col-xl-12 text-center">
              <p className="main-heading fw-bold high-light-black">
                Get inspired by our top creators
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-0">
        <div className="container-fluid cont">
          <div className="row justify-content-center">
            <div className="col-xl-9 text-start mb-5">
              <p className="main-heading fw-bold high-light-black m-0">
                Best <span className="high-light-red">ecotourism </span>breaks
              </p>
            </div>
            <div className="col-xl-3 mb-5">
              <div className="trips d-flex flex-column ">
                <div className="view-all-trips  d-flex justify-content-end pt-2">
                  <Link
                    to={"/trips-and-destination"}
                    style={{ textDecoration: "none" }}
                    className="custom-link"
                  >
                    + Show more
                  </Link>
                </div>
              </div>
            </div>

            <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3 justify-content-start">
              {Array.isArray(trips) && trips.length > 0 ? (
                <>
                  {trips
                    ?.slice(0, 4)
                    .map((item, index) => (
                      <TripCard
                        key={index}
                        item={item}
                        markWishList={handleMarkWishlist}
                        title={btnText?.trip_card_btn?.item_value}
                      />
                    ))}
                </>
              ) : (
                <div className="col-12  d-flex justify-content-center">
                  <h3>Sorry No Trip Found!!</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="pb-0">
        <div className="container-fluid cont">
          <div className="row justify-content-center">
            <div className="col-xl-9 text-start">
              <p className="main-heading fw-bold high-light-black m-0">Discover <span className="high-light-red">South Easy Asia </span></p>
            </div>
            <div className="col-xl-3 ">
              <div className="trips d-flex flex-column ">
                <div className="view-all-trips  d-flex justify-content-end pt-2">
                  <Link to={"/trips-and-destination"} style={{ textDecoration: 'none' }} className="custom-link">+ Show more</Link>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <Card className="card-trip">
                  <div className='image-container'>
                    <Card.Img variant="top" className='cardImg customCardImg' src={CardImage4} />
                    <div className="trip-location">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="trip-like-icon fs-12" />
                      <div className="trip-like">
                        <FontAwesomeIcon icon={faHeart} className="trip-like-icon fs-12" />
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="footer-nav-category">Discover Morocco’s Desert</p>
                    </Card.Title>
                    <div className="d-flex flex-row align-items-center mb-0">
                      <Avatar src={avtar5} size="2xl" className="card-profile-image" />
                      <div className="d-flex flex-column gap-1 ps-3 created-by-agent">
                        <p className="created-by m-0">Created by</p>
                        <p className="creator-title m-0">Anurag Yadav</p>
                      </div>
                    </div>
                    <div className="trip-discription">
                      <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum, laboriosam debitis necessitatibus atque eaque officia temporibus consequatur. Omnis fuga expedita natus corporis autem similique nostrum perspiciatis, necessitatibus sint quia dolorem.</div>
                    </div>
                    <div className="trip-price">
                      <p className="m-0">From £899 / person</p>
                    </div>
                    <div className="custom-btn-size d-flex justify-content-center align-items-center">
                      <Link to={{}} className="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-2">
                        Enquire Now
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <Card className="card-trip">
                  <div className='image-container'>
                    <Card.Img variant="top" className='cardImg customCardImg' src={CardImage5} />
                    <div className="trip-location">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="trip-like-icon fs-12" />
                      <div className="trip-like">
                        <FontAwesomeIcon icon={faHeart} className="trip-like-icon fs-12" />
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="footer-nav-category">Discover Morocco’s Desert</p>
                    </Card.Title>
                    <div className="d-flex flex-row align-items-center mb-0">
                      <Avatar src={avtar6} size="2xl" className="card-profile-image" />
                      <div className="d-flex flex-column gap-1 ps-3 created-by-agent">
                        <p className="created-by m-0">Created by</p>
                        <p className="creator-title m-0">Mahi Singh</p>
                      </div>
                    </div>
                    <div className="trip-discription">
                      <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum, laboriosam debitis necessitatibus atque eaque officia temporibus consequatur. Omnis fuga expedita natus corporis autem similique nostrum perspiciatis, necessitatibus sint quia dolorem.</div>
                    </div>
                    <div className="trip-price">
                      <p className="m-0">From £899 / person</p>
                    </div>
                    <div className="custom-btn-size d-flex justify-content-center align-items-center">
                      <Link to={{}} className="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-2">
                        Enquire Now
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <Card className="card-trip">
                  <div className='image-container'>
                    <Card.Img variant="top" className='cardImg customCardImg' src={CardImage6} />
                    <div className="trip-location">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="trip-like-icon fs-12" />
                      <div className="trip-like">
                        <FontAwesomeIcon icon={faHeart} className="trip-like-icon fs-12" />
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="footer-nav-category">Discover Morocco’s Desert</p>
                    </Card.Title>
                    <div className="d-flex flex-row align-items-center mb-0">
                      <Avatar src={avtar7} size="2xl" className="card-profile-image" />
                      <div className="d-flex flex-column gap-1 ps-3 created-by-agent">
                        <p className="created-by m-0">Created by</p>
                        <p className="creator-title m-0">Sandeep Singh</p>
                      </div>
                    </div>
                    <div className="trip-discription">
                      <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum, laboriosam debitis necessitatibus atque eaque officia temporibus consequatur. Omnis fuga expedita natus corporis autem similique nostrum perspiciatis, necessitatibus sint quia dolorem.</div>
                    </div>
                    <div className="trip-price">
                      <p className="m-0">From £899 / person</p>
                    </div>
                    <div className="custom-btn-size d-flex justify-content-center align-items-center">
                      <Link to={{}} className="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-2">
                        Enquire Now
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <Card className="card-trip">
                  <div className='image-container'>
                    <Card.Img variant="top" className='cardImg customCardImg' src={CardImage7} />
                    <div className="trip-location">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="trip-like-icon fs-12" />
                      <div className="trip-like">
                        <FontAwesomeIcon icon={faHeart} className="trip-like-icon fs-12" />
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="footer-nav-category">Discover Morocco’s Desert</p>
                    </Card.Title>
                    <div className="d-flex flex-row align-items-center mb-0">
                      <Avatar src={avtar8} size="2xl" className="card-profile-image" />
                      <div className="d-flex flex-column gap-1 ps-3 created-by-agent">
                        <p className="created-by m-0">Created by</p>
                        <p className="creator-title m-0">Akshat khurana</p>
                      </div>
                    </div>
                    <div className="trip-discription">
                      <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum, laboriosam debitis necessitatibus atque eaque officia temporibus consequatur. Omnis fuga expedita natus corporis autem similique nostrum perspiciatis, necessitatibus sint quia dolorem.</div>
                    </div>
                    <div className="trip-price">
                      <p className="m-0">From £899 / person</p>
                    </div>
                    <div className="custom-btn-size d-flex justify-content-center align-items-center">
                      <Link to={{}} className="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-2">
                        Enquire Now
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="pb-0">
        <div className="container-fluid cont">
          <div className="row justify-content-center">
            <div className="col-xl-9 text-start">
              <p className="main-heading fw-bold high-light-black m-0">Discover <span className="high-light-red">South Easy Asia </span></p>
            </div>
            <div className="col-xl-3 ">
              <div className="trips d-flex flex-column ">
                <div className="view-all-trips  d-flex justify-content-end pt-2">
                  <Link to={"/trips-and-destination"} style={{ textDecoration: 'none' }} className="custom-link">+ Show more</Link>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <Card className="card-trip">
                  <div className='image-container'>
                    <Card.Img variant="top" className='cardImg customCardImg' src={CardImage8} />
                    <div className="trip-location">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="trip-like-icon fs-12" />
                      <div className="trip-like">
                        <FontAwesomeIcon icon={faHeart} className="trip-like-icon fs-12" />
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="footer-nav-category">Discover Morocco’s Desert</p>
                    </Card.Title>
                    <div className="d-flex flex-row align-items-center mb-0">
                      <Avatar src={avtar1} size="2xl" className="card-profile-image" />
                      <div className="d-flex flex-column gap-1 ps-3 created-by-agent">
                        <p className="created-by m-0">Created by</p>
                        <p className="creator-title m-0">Anurag Yadav</p>
                      </div>
                    </div>
                    <div className="trip-discription">
                      <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum, laboriosam debitis necessitatibus atque eaque officia temporibus consequatur. Omnis fuga expedita natus corporis autem similique nostrum perspiciatis, necessitatibus sint quia dolorem.</div>
                    </div>
                    <div className="trip-price">
                      <p className="m-0">From £899 / person</p>
                    </div>
                    <div className="custom-btn-size d-flex justify-content-center align-items-center">
                      <Link to={{}} className="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-2">
                        Enquire Now
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <Card className="card-trip">
                  <div className='image-container'>
                    <Card.Img variant="top" className='cardImg customCardImg' src={CardImage} />
                    <div className="trip-location">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="trip-like-icon fs-12" />
                      <div className="trip-like">
                        <FontAwesomeIcon icon={faHeart} className="trip-like-icon fs-12" />
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="footer-nav-category">Discover Morocco’s Desert</p>
                    </Card.Title>
                    <div className="d-flex flex-row align-items-center mb-0">
                      <Avatar src={avtar2} size="2xl" className="card-profile-image" />
                      <div className="d-flex flex-column gap-1 ps-3 created-by-agent">
                        <p className="created-by m-0">Created by</p>
                        <p className="creator-title m-0">Mahi Singh</p>
                      </div>
                    </div>
                    <div className="trip-discription">
                      <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum, laboriosam debitis necessitatibus atque eaque officia temporibus consequatur. Omnis fuga expedita natus corporis autem similique nostrum perspiciatis, necessitatibus sint quia dolorem.</div>
                    </div>
                    <div className="trip-price">
                      <p className="m-0">From £899 / person</p>
                    </div>
                    <div className="custom-btn-size d-flex justify-content-center align-items-center">
                      <Link to={{}} className="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-2">
                        Enquire Now
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <Card className="card-trip">
                  <div className='image-container'>
                    <Card.Img variant="top" className='cardImg customCardImg' src={CardImage3} />
                    <div className="trip-location">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="trip-like-icon fs-12" />
                      <div className="trip-like">
                        <FontAwesomeIcon icon={faHeart} className="trip-like-icon fs-12" />
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="footer-nav-category">Discover Morocco’s Desert</p>
                    </Card.Title>
                    <div className="d-flex flex-row align-items-center mb-0">
                      <Avatar src={avtar3} size="2xl" className="card-profile-image" />
                      <div className="d-flex flex-column gap-1 ps-3 created-by-agent">
                        <p className="created-by m-0">Created by</p>
                        <p className="creator-title m-0">Sandeep Singh</p>
                      </div>
                    </div>
                    <div className="trip-discription">
                      <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum, laboriosam debitis necessitatibus atque eaque officia temporibus consequatur. Omnis fuga expedita natus corporis autem similique nostrum perspiciatis, necessitatibus sint quia dolorem.</div>
                    </div>
                    <div className="trip-price">
                      <p className="m-0">From £899 / person</p>
                    </div>
                    <div className="custom-btn-size d-flex justify-content-center align-items-center">
                      <Link to={{}} className="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-2">
                        Enquire Now
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <Card className="card-trip">
                  <div className='image-container'>
                    <Card.Img variant="top" className='cardImg customCardImg' src={CardImage5} />
                    <div className="trip-location">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="trip-like-icon fs-12" />
                      <div className="trip-like">
                        <FontAwesomeIcon icon={faHeart} className="trip-like-icon fs-12" />
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="footer-nav-category">Discover Morocco’s Desert</p>
                    </Card.Title>
                    <div className="d-flex flex-row align-items-center mb-0">
                      <Avatar src={avtar4} size="2xl" className="card-profile-image" />
                      <div className="d-flex flex-column gap-1 ps-3 created-by-agent">
                        <p className="created-by m-0">Created by</p>
                        <p className="creator-title m-0">Akshat khurana</p>
                      </div>
                    </div>
                    <div className="trip-discription">
                      <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum, laboriosam debitis necessitatibus atque eaque officia temporibus consequatur. Omnis fuga expedita natus corporis autem similique nostrum perspiciatis, necessitatibus sint quia dolorem.</div>
                    </div>
                    <div className="trip-price">
                      <p className="m-0">From £899 / person</p>
                    </div>
                    <div className="custom-btn-size d-flex justify-content-center align-items-center">
                      <Link to={{}} className="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-2">
                        Enquire Now
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>


            <div className="custom-btn-size d-flex justify-content-center trip-view-custom">
              <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" >Browse Trips</Button>
            </div>
          </div>
        </div>
      </section> */}
      <Testimonial />
    </>
  );
};

export default MyTrips;
