// MasterContext.tsx
import { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { ActivityCategoryItem, BlogsCategoryFront, GroundTransportItem, InclusiveExclusiveItem, LocationItem, MealsItem, Tags, TripCategoryItem } from 'types/interfaces';
import apiCall from 'services/api';


// Define the context type
type MasterContextType = {
    locations: LocationItem[] | null;
    locationsForDp: OptionType[] | undefined;
    destinationOptions: OptionType[] | undefined;
    airlineOptions:OptionType[] | undefined;
    tripCategories: TripCategoryItem[] | null;
    topCategories: TripCategoryItem[] | null;
    inclusivesExclusives: InclusiveExclusiveItem[] | null;
    meals: MealsItem[] | null;
    groundTransport: GroundTransportItem[] | null;
    groundGT: ActivityCategoryItem[] | null;
    groundGTOptions: OptionType[] | undefined;
    travelStyleTag: Tags[] | [];
    travelStyleTagOption: OptionType[] | undefined;
    travelTripTypeTagOption: OptionType[] | undefined;
    travelTripTypeTag: Tags[] | [];
    travelPreferencesTypeTag: Tags[] | [];
    travelLookingForTag: Tags[] | [];
    travelTopDestinationTag: Tags[] | [];
    travelPreferredAirportsTag: Tags[] | [];
    travelPreferredAirportsTagOptions: OptionType[] | undefined;
    tagsOptions: OptionType[] | null
    setTagsOptions: React.Dispatch<React.SetStateAction<OptionType[]>>
    frontBlogCategory: BlogsCategoryFront[] | []


};

// Create the context
const MasterContext = createContext<MasterContextType | undefined>(undefined);

// Define a helper function to use the context
export function useMaster() {
    const context = useContext(MasterContext);
    if (!context) {
        throw new Error('useMaster must be used within a MasterProvider');
    }
    return context;
}

// Define the MasterProvider component
type MasterProviderProps = {
    children: ReactNode;
};

type OptionType = { value: string; label: string };

export function MasterProvider({ children }: MasterProviderProps) {
    const [locations, setLocations] = useState<LocationItem[] | null>(null);
    const [locationsForDp, setLocationsForDp] = useState<OptionType[]>([]);
    const [destinationOptions, setDestinationOptions] = useState<OptionType[]>([]);
    const [airlineOptions, setAirlineOptions] = useState<OptionType[]>([]);
    const [tripCategories, setTripCategories] = useState<TripCategoryItem[] | null>(null);
    const [topCategories, setTopCategories] = useState<TripCategoryItem[] | null>(null);
    const [inclusivesExclusives, setInclusivesExclusives] = useState<InclusiveExclusiveItem[] | null>(null);
    const [meals, setMeals] = useState<MealsItem[] | null>(null);
    const [groundTransport, setGroundTransport] = useState<GroundTransportItem[] | null>(null);
    const [groundGT, setGroundGT] = useState<ActivityCategoryItem[] | null>(null);
    const [groundGTOptions, groundGTOptionsOptions] = useState<OptionType[]>([]);
    const [tagsOptions, setTagsOptions] = useState<OptionType[]>([]);
    const [travelStyleTag, setTravelStyleTag] = useState<Tags[]>([]);
    const [travelTripTypeTag, setTravelTripTypeTag] = useState<Tags[]>([]);
    const [travelStyleTagOption, setTravelStyleTagOption] = useState<OptionType[]>([]);
    const [travelTripTypeTagOption, setTravelTripTypeTagOption] = useState<OptionType[]>([]);
    const [travelPreferencesTypeTag, setTravelPreferencesTypeTag] = useState<Tags[]>([]);
    const [travelLookingForTag, setTravelLookingForTag] = useState<Tags[]>([]);
    const [travelTopDestinationTag, setTravelTopDestinationTag] = useState<Tags[]>([]);
    const [travelPreferredAirportsTag, setTravelPreferredAirportsTag] = useState<Tags[]>([]);
    const [travelPreferredAirportsTagOptions, setTravelPreferredAirportsTagOptions] = useState<OptionType[]>([]);
    const [frontBlogCategory, setFrontBlogCategory] = useState<BlogsCategoryFront[]>([]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const [
                    locationsResponse,
                    destinationResponse,
                    airlineResponse,
                    tripCategoryResponse,
                    topCategoriesResponse,
                    inExResponse,
                    mealsResponse,
                    groundTransportResponse,
                    tagsResponse,
                    travelStyleTagResponse,
                    travelTripTypeTagResponse,
                    travelPreferencesTypeTagResponse,
                    travelLookingForTagResponse,
                    travelTopDestinationsResponse,
                    travelPreferredAirportsResponse,
                    blogCategoryResponse

                ] = await Promise.all([
                    apiCall({
                        url: `master/locations?pagination=0`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/locations?is_continent=1`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/airlines`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/trip-category?pagination=0`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/top-trip-category`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/inclusive-exclusive?pagination=0`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/meals?pagination=0`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/ground-transport?pagination=0`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/tags?pagination=0`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/travel-style-tag`,
                        method: 'GET'
                    }),

                    apiCall({
                        url: `master/prefered-trip-type-tag`,
                        method: 'GET'
                    }),

                    apiCall({
                        url: `master/travel-preference-tag`,
                        method: 'GET'
                    }),

                    apiCall({
                        url: `master/looking-for-tag`,
                        method: 'GET'
                    }),

                    apiCall({
                        url: `master/top-locations`,
                        method: 'GET'
                    }),

                    apiCall({
                        url: `master/airports`,
                        method: 'GET'
                    }),
                    apiCall({
                        url: `master/frontend-blog-category`,
                        method: 'GET'
                    }),

                ])

                // Handle each response individually
                if (locationsResponse?.status === 200) {
                    setLocations(locationsResponse?.data?.MasterLocation?.data);
                } else {
                    throw new Error("Failed to fetch locations");
                }
                if (destinationResponse?.status === 200) {
                    let data = destinationResponse?.data?.MasterLocation?.data
                    if (data) {
                        const options = data?.map((item: any) => ({
                            value: String(item.id),
                            label: String(item.name)
                        }));

                        // Set the options state with the transformed data
                        setDestinationOptions(options);
                    }

                } else {
                    throw new Error("Failed to fetch locations");
                }
                if (airlineResponse?.status === 200) {
                    let data = airlineResponse?.data?.Airlines?.data
                    if (data) {
                        const options = data?.map((item: any) => ({
                            value: String(item.code),
                            label: String(item.name)
                        }));

                        // Set the options state with the transformed data
                        setAirlineOptions(options);
                    }

                } else {
                    throw new Error("Failed to fetch locations");
                }
                if (tripCategoryResponse?.status === 200) {
                    setTripCategories(tripCategoryResponse?.data?.MasterTripCategory?.data);
                } else {
                    throw new Error("Failed to fetch trip categories");
                }
                if (topCategoriesResponse?.status === 200) {
                    setTopCategories(topCategoriesResponse?.data?.MasterTripCategory?.data);
                } else {
                    throw new Error("Failed to fetch trip categories");
                }

                if (inExResponse?.status === 200) {
                    setInclusivesExclusives(inExResponse.data.data);
                } else {
                    throw new Error("Failed to fetch inclusive/exclusive items");
                }

                if (mealsResponse?.status === 200) {
                    setMeals(mealsResponse?.data?.MasterMeals?.data);
                } else {
                    throw new Error("Failed to fetch meals");
                }

                if (groundTransportResponse?.status === 200) {
                    let data = groundTransportResponse.data.MasterGroundTransport.data
                    setGroundTransport(data);
                    setGroundGT(data);
                    if (data) {
                        const transformedOptions = data?.map((item: any) => ({
                            value: String(item.code),
                            label: item.name
                        }));
                        // Set the options state with the transformed data
                        groundGTOptionsOptions(transformedOptions)
                    }
                } else {
                    throw new Error("Failed to fetch ground transport");
                }



                if (tagsResponse?.status === 200) {
                    let data = tagsResponse?.data?.MasterTags?.data
                    if (data) {
                        const transformedOptions = data?.map((item: any) => ({
                            value: String(item.name),
                            label: String(item.name)
                        }));

                        // Set the options state with the transformed data
                        setTagsOptions(transformedOptions)
                    }

                } else {
                    throw new Error("Failed to fetch activity categories");
                }

                if (travelStyleTagResponse?.status === 200) {
                    let data = travelStyleTagResponse?.data?.TravelStyleTag?.data;
                    setTravelStyleTag(data)
                    let styleOptions = data?.map((item: any) => ({ value: item.name, label: item?.name }));
                    setTravelStyleTagOption(styleOptions);
                }
                if (travelTripTypeTagResponse?.status === 200) {
                    let data = travelTripTypeTagResponse?.data?.MasterTripTypeTag?.data;
                    setTravelTripTypeTag(data);
                    let options = data?.map((item: any) => ({ value: item.name, label: item?.name }));
                    setTravelTripTypeTagOption(options);
                }
                if (travelPreferencesTypeTagResponse?.status === 200) {
                    let data = travelPreferencesTypeTagResponse?.data?.TravelPreferenceTag?.data;
                    setTravelPreferencesTypeTag(data)
                }
                if (travelLookingForTagResponse?.status === 200) {
                    let data = travelLookingForTagResponse?.data?.LookingForTag?.data;
                    setTravelLookingForTag(data)
                }
                if (travelTopDestinationsResponse?.status === 200) {
                    let data = travelTopDestinationsResponse?.data?.MasterLocation?.data;
                    setTravelTopDestinationTag(data)
                }
                if (travelPreferredAirportsResponse?.status === 200) {
                    let data = travelPreferredAirportsResponse?.data?.Airports?.data;
                    let options = data?.map((item: any) => ({ value: item.code, label: item?.name }));
                    setTravelPreferredAirportsTag(data);
                    setTravelPreferredAirportsTagOptions(options);
                }
                if (blogCategoryResponse?.status === 200) {
                    let data = blogCategoryResponse?.data?.FrontendBlogCategory?.data;
                    setFrontBlogCategory(data)
                }
            } catch (error: any) {
                console.error("error", error)
            }
        };

        fetchData();

    }, []);

    useEffect(() => {
        if (locations) {
            const transformedOptions = locations?.map(item => ({
                value: String(item.id),
                label: item.name
            }));

            // Set the options state with the transformed data
            setLocationsForDp(transformedOptions)
        }
    }, [locations]);




    return <MasterContext.Provider
        value={{
            locations,
            locationsForDp,
            destinationOptions,
            airlineOptions,
            tripCategories,
            topCategories,
            inclusivesExclusives,
            meals,
            groundTransport,

            groundGT,
            groundGTOptions,

            tagsOptions,
            setTagsOptions,
            travelStyleTag,
            travelStyleTagOption,
            travelTripTypeTag,
            travelTripTypeTagOption,
            travelPreferencesTypeTag,
            travelLookingForTag,
            travelTopDestinationTag,
            travelPreferredAirportsTag,
            travelPreferredAirportsTagOptions,
            frontBlogCategory
        }}>
        {children}
    </MasterContext.Provider>;
}
