import { Button, Card, Spinner } from "react-bootstrap";
import BannerImage from "../assets/img/travel/hero/common-hero.png";
import { Form } from "react-bootstrap";
import MultiSelectDestination from "./trips-and-destination/destination/MultiSelectDestination";
import MultiSelectCategories from "./trips-and-destination/categories/MultiSelectCategories";
import { useEffect, useState } from "react";
import usericon1 from "../assets/img/travel/svg/destination/usericon1.svg";
import PriceRange from "./trips-and-destination/pricerange/PriceRange";
import {
  ChildItemAllBtnText,
  FilterStatusMain,
  initialChildItemAllBtnText,
  initialSummary,
  OptionType,
  SummaryProps,
} from "types/interfaces";
import { useAuth } from "providers/AuthContext";
import apiCall from "services/api";
import { AxiosError } from "axios";
import { useToast } from "providers/ToastProvider";
import DummyCard from "components/common/dummycard/DummyCard";
import CustomSelectFilter from "components/select/CustomSelectFilter";
import { useMaster } from "providers/MasterContext";
import CustomSelectFilterTour from "components/select/CustomSelectFilterTour";
import { useMeta } from "providers/MetaContext";
import Testimonial from "components/asseenin/Testimonial";
import CustomSelectFilterAgent from "components/select/CustomSelectFilterAgent";
import TripCard from "components/cards/TripsCard";
import { getHeaders } from "../services/getHeaders";
import { useSearchParams, useNavigate } from "react-router-dom";
import { markWishList } from "services/markWishList";
import { useSignIn } from "providers/SignInProvider";
import CustomDateRangePicker from "./trips-and-destination/date-range-picker/CustomDateRangePicker";
import { addDays } from "date-fns";
import { handleApiError } from "services/handleApiError";

const TripsDestination = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userTkn, signOut } = useAuth();
  const { setShowIn } = useSignIn();
  const { months, currency } = useMeta();
  const { showErrorToast } = useToast();
  const { tripCategories } = useMaster();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [trips, setTrips] = useState<any[]>([]);
  // const [pageIndex, setPageIndex] = useState<number>(1);
  const [summary, setSummary] = useState<SummaryProps>(initialSummary);
  const [filters, setFilters] = useState<FilterStatusMain>({
    status: 0,
    title: "",
    start_date: null,
    end_date: null,
    // month: [],
    location: 0,
    location_name: "",
    destination: "",
    no_of_days: "",
    tour: "",
    categories: [],
    min_price: 0,
    max_price: 0,
    agent_name: "",
    sort: "price",
    availability: "",
    pageIndex: 1,
  });
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(), // Start from today
      endDate: addDays(new Date(), 0), // End date 7 days from today
      key: "selection",
    },
  ]);

  const [location, setLocation] = useState<OptionType | null>(null);
  const [agent, setAgent] = useState<OptionType | null>(null);
  const [tour, setTour] = useState<OptionType | null>(null);
  const [filterStatus, setFilterStatus] = useState<boolean>(false);
  const [monthsOptions, setMonthsOptions] = useState<OptionType[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<OptionType | null>(null);
  const [value, setValue] = useState<[number, number]>([0, 5000]); // price filter
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]); // category
  const [locationCount, setLocationCount] = useState(false);
  const [btnText, setBtnText] = useState<ChildItemAllBtnText>(
    initialChildItemAllBtnText
  );

  const handleMarkWishlist = async (tripId: string) => {
    await markWishList(
      tripId,
      userTkn,
      currency,
      setTrips,
      setShowIn,
      apiCall,
      showErrorToast
    );
  };

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        const [btnTextResponse] = await Promise.all([
          apiCall({
            url: `cms/content-items/all_btn_text`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
        ]);

        if (btnTextResponse?.status === 200) {
          let data = btnTextResponse?.data?.ContentItem?.data?.content?.childs;
          setBtnText(data);
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
      }
    };

    fetchTripData();
  }, [currency]);

  useEffect(() => {
    const monthsOp = (months || []).map((item: any) => ({
      value: String(item?.month_no),
      label: item?.full_name,
    }));
    setMonthsOptions(monthsOp);
  }, [months]);

  useEffect(() => {
    if (filterStatus) return;

    const pageFromUrl = searchParams.get("page");
    const pageNum = pageFromUrl ? parseInt(pageFromUrl, 10) : 1;
    setFilters((prevFilters) => ({
      ...prevFilters, // Ensure a new reference
      pageIndex: pageNum,
    }));

    const fetchData = async () => {
      try {
        setLocationCount(false);
        setLoading(true);
        const url = `tours?page=${pageNum}&pageSize=12&sort=${filters?.sort}`;
        const response = await apiCall({
          url: url,
          method: "GET",
          headers: getHeaders(userTkn, currency),
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.TripVersion?.data;
            const summary = response?.data?.TripVersion?.summary;

            setSummary({
              total: summary.total,
              page: summary.page,
              pageSize: summary.pageSize,
              total_page: summary.total_page,
            });

            setTrips((prev) => {
              if (filters?.pageIndex === 1) {
                // Reset data if fetching the first page
                return [...data];
              } else {
                // Append new data for subsequent pages
                return [...prev, ...data];
              }
            });

            setLoading(false);
          }
        }
      } catch (error: any) {
        if ((error as AxiosError).response?.status === 401) {
          showErrorToast(error?.message);
          setTimeout(() => {
            signOut();
          }, 3000);
        }
        setLoading(false);
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    };

    fetchData();
  }, [userTkn, filters?.pageIndex, currency]);

  const buildUrl = (filters: FilterStatusMain, pageIndex: number) => {
    const params = new URLSearchParams({
      page: pageIndex.toString(),
      pageSize: "12",
    });

    // Add filter parameters only if they have a meaningful value
    if (filters.location)
      params.append("location", filters.location.toString());
    if (filters.tour) params.append("title", filters.tour);
    if (filters.destination) params.append("destination", filters.destination);
    // if (filters.categories.length > 0) params.append("category[0]", filters.categories.join(','));
    if (filters.categories.length > 0) {
      filters.categories?.forEach((item: any, index: any) => {
        params.append(`category[${index}]`, item);
      });
    }
    if (filters.min_price)
      params.append("min_price", filters.min_price.toString());
    if (filters.max_price)
      params.append("max_price", filters.max_price.toString());
    if (filters.agent_name) params.append("agent_id", filters.agent_name); // This line is fine
    if (filters.sort) params.append("sort", filters.sort);
    if (filters.start_date) {
      const date = new Date(filters.start_date);

      // Manually extract the year, month, and day in local time
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, "0");

      // Format the date as YYYY-MM-DD
      const formattedDate = `${year}-${month}-${day}`;
      params.append("start_date", formattedDate);
    }
    if (filters.end_date) {
      const date = new Date(filters.end_date);

      // Manually extract the year, month, and day in local time
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, "0");

      // Format the date as YYYY-MM-DD
      const formattedDate = `${year}-${month}-${day}`;
      params.append("end_date", formattedDate);
    }
    // if (filters.availability) params.append("availability[0]", filters.availability);

    // Check if no filters were applied
    if (Array.from(params).length === 2) {
      // Only 'page' and 'pageSize' are in params
      return `tours?page=${pageIndex}&pageSize=12`;
    }

    return `tours?${params.toString()}`;
  };

  const buildUrlFilter = (filters: FilterStatusMain, pageIndex: number) => {
    const params = new URLSearchParams({
      page: pageIndex.toString(),
      pageSize: "12",
    });

    // Add filter parameters only if they have a meaningful value
    if (filters.location)
      params.append("location", filters.location.toString());
    if (filters.tour) params.append("title", filters.tour);
    if (filters.destination) params.append("destination", filters.destination);
    // if (filters.categories.length > 0) params.append("category[0]", filters.categories.join(','));
    if (filters.categories.length > 0) {
      filters.categories?.forEach((item: any, index: any) => {
        params.append(`category[${index}]`, item);
      });
    }
    if (filters.min_price)
      params.append("min_price", filters.min_price.toString());
    if (filters.max_price)
      params.append("max_price", filters.max_price.toString());
    if (filters.agent_name) params.append("agent_id", filters.agent_name); // This line is fine
    if (filters.sort) params.append("sort", filters.sort);
    // if (filters.availability) params.append("availability[0]", filters.availability);
    if (filters.start_date) {
      const date = new Date(filters.start_date);

      // Manually extract the year, month, and day in local time
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, "0");

      // Format the date as YYYY-MM-DD
      const formattedDate = `${year}-${month}-${day}`;

      params.append("start_date", formattedDate);
    }
    if (filters.end_date) {
      const date = new Date(filters.end_date);

      // Manually extract the year, month, and day in local time
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, "0");

      // Format the date as YYYY-MM-DD
      const formattedDate = `${year}-${month}-${day}`;

      params.append("end_date", formattedDate);
    }

    // Check if no filters were applied
    if (Array.from(params).length === 2) {
      // Only 'page' and 'pageSize' are in params
      return `page=${pageIndex}&pageSize=12`;
    }

    return `${params.toString()}`;
  };
  useEffect(() => {
    if (!filterStatus) return;
    const fetchData = async () => {
      if (filters?.pageIndex === 1) {
        setTrips([]);
      }
      try {
        setLoading(true);

        const url = buildUrl(filters, filters?.pageIndex);

        const response = await apiCall({
          url: url,
          method: "GET",
          headers: getHeaders(userTkn, currency),
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.TripVersion?.data;
            const summary = response?.data?.TripVersion?.summary;

            setSummary({
              total: summary.total,
              page: summary.page,
              pageSize: summary.pageSize,
              total_page: summary.total_page,
            });

            // setTrips((prevTrips) => {
            //   const tripIndexMap = new Map(prevTrips.map((trip) => [trip.trip_uuid, trip]));

            //   const updatedTrips = prevTrips.map((trip) => {
            //     const newDataTrip = data.find((newTrip: any) => newTrip.trip_uuid === trip.trip_uuid);

            //     if (newDataTrip && JSON.stringify(newDataTrip) !== JSON.stringify(trip)) {
            //       return newDataTrip;
            //     }
            //     return trip;
            //   });
            //   const newTrips = data.filter((newTrip: any) => !tripIndexMap.has(newTrip.trip_uuid));

            //   return [...updatedTrips, ...newTrips];
            // });
            setTrips((prev) => {
              if (filters?.pageIndex === 1) {
                // Reset data if fetching the first page
                return [...data];
              } else {
                // Append new data for subsequent pages
                return [...prev, ...data];
              }
            });

            setLoading(false);
          }
        }
      } catch (error: any) {
        if ((error as AxiosError).response?.status === 401) {
          showErrorToast(error?.message);
          setTimeout(() => {
            signOut();
          }, 3000);
        }
        setLoading(false);
      } finally {
        setLoading(false);
        setLoadingMore(false);
        setFilterStatus(false);
      }
    };

    fetchData();
  }, [userTkn, filters?.pageIndex, filters, filterStatus, currency]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    if (filters?.pageIndex <= summary?.total_page) {
      setFilterStatus(true);
      // Set loading state for "Load More" button
      const newPage = filters?.pageIndex + 1;
      // setPageIndex(prevPage => prevPage + 1);
      setFilters((prevFilters) => ({
        ...prevFilters, // Ensure a new reference
        pageIndex: prevFilters.pageIndex + 1,
      }));
      const url = buildUrlFilter(filters, newPage);
      navigate(`/trips-and-destination?${url}`);
    } else {
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
  };

  const handleLocation = (option: OptionType | null) => {
    setLocation(option);
    setFilters((prev) => ({
      ...prev,
      location: Number(option?.value || 0), // Assuming status is a number
    }));
  };

  const handleTour = (option: OptionType | null) => {
    setTour(option);
    setFilters((prev) => ({
      ...prev,
      tour: option?.label || "", // Assuming status is a number
    }));
  };

  const handleAgent = (option: OptionType | null) => {
    setAgent(option);
    setFilters((prev) => ({
      ...prev,
      agent_name: option?.value || "", // Assuming status is a number
    }));
  };

  // submit filters
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFilters((prevFilters) => ({
      ...prevFilters, // Ensure a new reference
      pageIndex: 1,
    }));
    setFilterStatus(true);
    setLocationCount(true);
    setFilters((prev) => ({
      ...prev,
      location_name: location?.label ?? "", // Assuming status is a number
    }));

    const url = buildUrlFilter(filters, 1);
    navigate(`/trips-and-destination?${url}`);
  };

  const resetFilters = () => {
    const resetFilterValues: FilterStatusMain = {
      status: 0,
      title: "",
      start_date: null,
      end_date: null,
      location: 0,
      location_name: "",
      destination: "",
      no_of_days: "",
      tour: "",
      categories: [],
      min_price: 0,
      max_price: 0,
      agent_name: "",
      sort: "price",
      availability: "",
      pageIndex: 1,
    };
    setFilters(resetFilterValues);

    const url = buildUrl(resetFilterValues, 1);
    navigate(`/trips-and-destination?${url}`);
    setSelectedMonth(null);
    setValue([0, 5000]);
    setSelectedCategories([]);
    setFilterStatus(true);
    setLocation(null);
    setAgent(null);
    setTour(null);
    setDateRange([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: "selection",
      },
    ]);
    setLocationCount(false);
  };

  const handleSelectChangeMonths = (option: OptionType | null) => {
    setSelectedMonth(option);
    setFilters((prev) => ({
      ...prev,
      availability: option ? option.value.toString() : "",
    }));
  };

  return (
    <div>
      {/*first section Find Prefect Trip */}
      <section
        id="hero-common"
        className="d-flex flex-column justify-content-center hero-trip-destination"
        style={{
          background: `url(${BannerImage}) top center`,
          backgroundSize: "cover",
        }}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="hero-heading fw-bold high-light-white text-center">
                <span className="high-light-red">Find the</span> perfect trip{" "}
              </p>
              <Card className="search-trip-card">
                <Form
                  className="d-flex flex-wrap gap-3 trip-search-modal"
                  onSubmit={handleSubmit}
                >
                  <div className="trip-destination-filter">
                    <Form.Group className="text-start">
                      <CustomSelectFilter
                        label={""}
                        controlId={"location"}
                        value={
                          filters.location !== 0
                            ? location
                            : { value: "", label: "" }
                        }
                        onChange={handleLocation}
                        placeholder="location"
                        location={location}
                        setLocation={setLocation}
                      />
                    </Form.Group>
                  </div>
                  <div className="trip-destination-filter">
                    <Form.Group controlId="meta_currency_code" className="mb-0">
                      <CustomSelectFilterAgent
                        label={""}
                        controlId={"Creator"}
                        value={
                          filters.agent_name !== ""
                            ? agent
                            : { value: "", label: "" }
                        }
                        onChange={handleAgent}
                        placeholder="Creator"
                        agent={agent}
                        setAgent={setAgent}
                      />
                    </Form.Group>
                  </div>
                  <div className="form-icon-container trip-destination-filter">
                    <Form.Group className="text-start">
                      <CustomSelectFilterTour
                        label={""}
                        controlId={"Tour"}
                        value={filters.tour ? tour : { value: "", label: "" }}
                        onChange={handleTour}
                        placeholder="Tour"
                        tour={tour}
                        setTour={setTour}
                      />
                    </Form.Group>
                  </div>
                  <div className="trip-destination-filter-destination">
                    <MultiSelectDestination
                      setFilters={setFilters}
                      filters={filters}
                    />
                  </div>

                  <div className="trip-destination-filter-category">
                    <MultiSelectCategories
                      tripCategories={tripCategories}
                      setFilters={setFilters}
                      filters={filters}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      setFilterStatus={setFilterStatus}
                    />
                  </div>
                  <div className="trip-destination-filter-month">
                    {/* <Form.Group controlId="meta_nonths" className="mb-0">

                      <Select
                        value={selectedMonth}
                        onChange={handleSelectChangeMonths}
                        options={monthsOptions}
                        className={`filter-agent-select-container`}
                        classNamePrefix="filter-agent-select"
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Months"
                        styles={customStylesSelect}

                      />

                    </Form.Group> */}
                    <CustomDateRangePicker
                      setFilters={setFilters}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                    />
                  </div>
                  <div className="trip-destination-filter-price">
                    <PriceRange
                      setFilters={setFilters}
                      filters={filters}
                      value={value}
                      setValue={setValue}
                      setFilterStatus={setFilterStatus}
                    />
                  </div>
                  <div className="trip-destination-filter-hl">
                    <Form.Group className="text-start">
                      <div className="form-icon-container">
                        <img
                          src={usericon1}
                          alt="Destination Icon"
                          className="form-icon "
                        />
                        <Form.Select
                          name="sort"
                          className="form-icon-input custom-selcet-item"
                          onChange={(e: any) => {
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              sort: e.target.value,
                            }));
                          }}
                          value={filters?.sort}
                        >
                          <option value={""} key={"a1"}>
                            {"Sort by"}{" "}
                          </option>
                          <option value={"price"} key={"a2"}>
                            {"Low to High"}{" "}
                          </option>
                          <option value={"-price"} key={"a3"}>
                            {" High  to Low"}{" "}
                          </option>
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </div>

                  <div className=" trip-search-btn">
                    <Button
                      variant="primary"
                      className="flex-grow-1 flex-lg-grow-0"
                      type="submit"
                    >
                      {" "}
                      Search{" "}
                    </Button>
                  </div>
                  <div className=" trip-reset-btn custom-btn-small">
                    <Button
                      variant="outline-primary"
                      onClick={resetFilters}
                      className=""
                    >
                      {" "}
                      Reset{" "}
                    </Button>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </section>
      {loading && ( // Full page loading overlay
        <section className="pb-0">
          <div className="container-fluid">
            <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3">
              <div className="col-xl-12 ">
                <p className="dummy-text-placeholder">Loading ...</p>
              </div>
              <DummyCard />
              <DummyCard />
              <DummyCard />
              <DummyCard />
              <DummyCard />
              <DummyCard />
              <DummyCard />
              <DummyCard />
            </div>
          </div>
        </section>
      )}
      {!loading && (
        <section className="pb-0">
          <div className="container-fluid ">
            <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3">
              {locationCount &&
                filters?.location_name &&
                summary?.total > 0 && (
                  <div className="col-xl-12 text-start mb-3">
                    <p className="main-heading">
                      <span className="high-light-red">{summary?.total}</span>{" "}
                      trips for {`"${filters?.location_name}"`}
                    </p>
                  </div>
                )}
              {Array.isArray(trips) && trips.length > 0 ? (
                <>
                  {trips?.map((item, index) => (
                    <TripCard
                      key={index}
                      item={item}
                      markWishList={handleMarkWishlist}
                      title={btnText?.trip_card_btn?.item_value}
                    />
                  ))}
                </>
              ) : (
                <div className="col-12  d-flex justify-content-center">
                  <h3>Sorry No Trip Found!!</h3>
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      <>
        {!loading && Number(summary.total_page) > 1 && (
          <div className="custom-btn-size d-flex justify-content-center mt-3">
            {Number(summary?.page) === Number(summary?.total_page) ? (
              <div className="d-flex justify-content-center"></div>
            ) : (
              <button
                onClick={handleLoadMore}
                className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2"
              >
                {loadingMore ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  ""
                )}
                <span className="ml-2">{"Load More"}</span>
              </button>
            )}
          </div>
        )}
      </>

      {/*last section*/}
      <Testimonial />
    </div>
  );
};

export default TripsDestination;
