import React, { useState, useEffect, lazy, Suspense, useRef } from 'react';
import { tripForms } from './tripForm';
import './TripForm.css';
const AboutTrip = lazy(() => import('./steps/AboutTrip'));
const TripDetails = lazy(() => import('./steps/TripDetails'));
const Itinerary = lazy(() => import('./steps/Itinerary'));
const Media = lazy(() => import('./steps/Media'));
import { Row } from 'react-bootstrap';
import { OptionType, TripDetailsEditMode, TripDetailsFormProps, initialFormData, initialTripDetailsEditMode } from 'types/interfaces';
import TripViewUpdate from './view/TripViewUpdate';
import { useLocation, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { useAuth } from 'providers/AuthContext';
import { useToast } from 'providers/ToastProvider';
import { AxiosError } from 'axios';
import { handleApiError } from 'services/handleApiError';


export type Step = {
    step: number;
    title: string;
};


const CreateTrip: React.FC = () => {
    const location = useLocation();
    const { trips, source } = location.state || {};
    const { trip_uuid, version } = useParams<{ trip_uuid: string, version: string }>();
    const [formSteps, setFormSteps] = useState<Step[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [message, setMessage] = useState('');
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [selectedStartLocation, setSelectedStartLocation] = useState<OptionType | null>(null);
    const [selectedEndLocation, setSelectedEndLocation] = useState<OptionType | null>(null);
    const [tripView, setTripView] = useState<boolean>(false);  // for toggle Trip Form Steps and Trip View
    const [formData, setFormData] = useState<TripDetailsFormProps>(initialFormData);
    const [startFormData, setStartFormData] = useState<TripDetailsFormProps>(initialFormData);
    const [errors, setErrors] = useState({
        title: '',
        meta_currency_code: '',
        price: '',
        no_of_days: '',
        start_location: '',
        end_location: '',
        banner_image: '',
        listing_images: '',
        description: '',

        details: '',
        trip_categories: '',
        trip_availabilities: '',
        meta_title: '',
        is_meta_title_same_as_title: '',
        meta_keywords: '',
        is_meta_title_same_as_trip_tags: '',
        meta_description: '',
        is_meta_title_same_as_description: '',
        accomodation_status: '',
        accomodation_note: '',
        flight_status: '',
        flight_note: '',
        ground_transport_status: '',
        ground_transport_note: '',
        meals_status: '',
        meals_note: '',
        included: '',
        excluded: '',
        optional: '',

        privacy_policy: '',
        term_and_conditions: '',
        change_policy: '',
        must_carry: ''
    });
    const [currentVersion, setCurrentVersion] = useState<string>(version || '');
    const { showToast, showSuccessToast, showInfoToast, showErrorToast } = useToast();
    const [loading, setLoading] = useState<boolean>(false); //when send for Approval
    const { signOut, userTkn } = useAuth()
    //for tripdetails
    const [isEditMode, setIsEditMode] = useState<TripDetailsEditMode>(initialTripDetailsEditMode);
    //for tags
    const [selected, setSelected] = useState<any[]>([])

    const [mode,] = useState(1); //for About Trip
    const [editMode, setEditMode] = useState(false); //for About Trip

    const aboutTripRef = useRef<{ handleSubmit: () => void }>(null);
    useEffect(() => {
        setFormSteps(tripForms.steps);
    }, []);


    const nextStep = () => {
        if (currentStep < formSteps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const nextStepSave = () => {
        if (currentStep < formSteps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };
    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const goToStep = (index: number) => {
        if (index >= 0 && index < formSteps.length) {
            if (formData.trip_uuid) {
                setCurrentStep(index);
                setMessage(''); // Clear any previous messages
            } else {
                setMessage('Please complete About Trip first');
            }
            // setCurrentStep(index);
        }
    };

    const handleSaveClick = () => {
        if (currentStep === 0 && aboutTripRef.current) {
            aboutTripRef.current.handleSubmit();
        } else {
            nextStep();
        }
    };


    const renderStepContent = (step: Step) => {
        switch (step.step) {
            case 1:
                return <AboutTrip
                    step={step}
                    nextStepSave={nextStepSave}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    selectedStartLocation={selectedStartLocation}
                    setSelectedStartLocation={setSelectedStartLocation}
                    selectedEndLocation={selectedEndLocation}
                    setSelectedEndLocation={setSelectedEndLocation}
                    formData={formData}
                    setFormData={setFormData}
                    startFormData={startFormData}
                    setStartFormData={setStartFormData}
                    errors={errors}
                    setErrors={setErrors}
                    mode={1}  // 1  for create, 2 for update
                    editMode={editMode}
                    setEditMode={setEditMode}
                />;
            case 2:
                return <TripDetails
                    step={step}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    formData={formData}
                    setFormData={setFormData}
                    startFormData={startFormData}
                    setStartFormData={setStartFormData}
                    errors={errors}
                    setErrors={setErrors}
                    selected={selected}
                    setSelected={setSelected}


                // tripDetailsFormData={tripDetailsFormData}
                // setTripDetailsFormData={setTripDetailsFormData}
                // tripDetailsErrors = {tripDetailsErrors}
                // setTripDetailsErrors ={setTripDetailsErrors}
                />;
            case 3:
                return <Itinerary
                    step={step}
                    formData={formData}
                    setFormData={setFormData}
                    startFormData={startFormData}
                    setStartFormData={setStartFormData}
                />;
            case 4:
                return <Media step={step} startFormData={startFormData} />;
            default:
                return null;
        }
    };

    const toggleView = () => {
        setTripView(!tripView);
    }

    const updateUrlVersion = () => {
        const currentUrl = window.location.href;
        const updatedUrl = currentUrl.replace('/v1/', '/v2/');
        window.history.replaceState(null, '', updatedUrl);
    };

    const handleUpdateUrlClick = () => {
        updateUrlVersion();
    };

    const sendForApproval = async (trip_uuid: string, version: string) => {
        try {
            setLoading(true);
            let url = `trip/${trip_uuid}/${version}/send-for-approval`
            const response = await apiCall({
                url: url,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn
                },
            });
            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                } else {
                    let data = response?.data?.TripVersion?.data;
                    setStartFormData(prev => ({
                        ...prev,
                        status: data?.status
                    }))

                    setFormData(prev => ({
                        ...prev,
                        status: data?.status
                    }))

                }
            }
        } catch (error: any) {
            handleApiError(error, showErrorToast, signOut);
            setLoading(false);

        } finally {
            setLoading(false);
        }

    }


    const copyTrip = async (trip_uuid: string, version: string) => {

        try {
            setLoading(true);
            let url = `trip/${trip_uuid}/${version}/copy-trip`
            const response = await apiCall({
                url: url,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn
                },
            });
            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                } else {

                    showSuccessToast(response?.data?.message)
                }
            }
        } catch (error: any) {
            handleApiError(error, showErrorToast, signOut);
            setLoading(false);

        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {tripView ? (<TripViewUpdate loading={loading} source={source} sendForApproval={sendForApproval} copyTrip={copyTrip} currentVersion={currentVersion} handleUpdateUrlClick={handleUpdateUrlClick} tripData={startFormData} viewType={1} tripToggle={toggleView} tripView={tripView}/>)
                :
                (<section id="create-trip-container">

                    <div className='container'>
                        <div className={`inner-container-${currentStep}`} key={currentStep}>
                            <Row>
                                <div className='col-md-12'>
                                    <div className="stepper">
                                        {formSteps.map((step, index) => (
                                            <>
                                                <div className="step-container" key={index}>
                                                    <div className={`step-title-${index}`}>{step.title}</div>
                                                    <div
                                                        className={`step ${index === currentStep ? 'active' : ''}  ${currentStep > index ? 'completed' : ''}`}
                                                        onClick={() => goToStep(index)}
                                                    >
                                                        <div className="step-number"></div>
                                                    </div>
                                                </div>
                                                {index < formSteps.length - 1 && <div className="step-connector" key={index + 1}>
                                                    <span className={`steppr-line  ${currentStep > index ? 'completed-line' : ''}`}></span>
                                                </div>}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </Row>

                            {formSteps.length > 0 && (
                                <Suspense fallback={<div className='step-content-0-main'>Loading...</div>}>
                                    <div className={currentStep !== 0 ? `step-content-${currentStep}` : ""}>
                                        {renderStepContent(formSteps[currentStep])}
                                    </div>
                                </Suspense>
                            )}
                            {currentStep !== 0 && <div className={`step-navigation navigatio-step-${currentStep}`}>
                                {currentStep !== 0 && <div className="custom-btn-size d-flex justify-content-center py-4">
                                    <button onClick={toggleView} disabled={currentStep === 0} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                        {"View"}
                                    </button>
                                </div>}
                                {currentStep !== 0 && <div className="custom-btn-size d-flex justify-content-center py-4">
                                    <button onClick={prevStep} disabled={currentStep === 0} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                        {"Previous"}
                                    </button>
                                </div>}

                                {!(currentStep === formSteps.length - 1) && <div className="custom-btn-size d-flex justify-content-center py-4">
                                    <button onClick={handleSaveClick} disabled={currentStep === formSteps.length - 1} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                        {currentStep === 0 ? "Save" : "Next"}
                                    </button>
                                </div>
                                }

                            </div>
                            }
                            {(mode === 1 && currentStep === 0 && editMode === false && formData.trip_uuid) &&
                                <div className={`step-navigation navigatio-step-${currentStep}`}>
                                    <div className="custom-btn-size d-flex justify-content-center py-4">
                                        <button onClick={handleSaveClick} disabled={currentStep === formSteps.length - 1} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                            {"Next"}
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </section>
                )
            }
        </>
    );
};

export default CreateTrip;
