import { useEffect, useState } from "react";
import apiCall from "services/api";
import parse from 'html-react-parser';
import { Nav, Spinner } from 'react-bootstrap';
import Testimonial from 'components/asseenin/Testimonial';
import { initialStaticPages, StaticPagesProps } from "types/interfaces";
import { getHeaders } from "services/getHeaders";
import { useMeta } from "providers/MetaContext";
import { useAuth } from "providers/AuthContext";



const TermsConditions = () => {
  const { currency } = useMeta();
  const { signOut, userTkn } = useAuth()
  const [termsData, setTermsData] = useState<StaticPagesProps>(initialStaticPages);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiCall({
          url: `cms/term-condition`,
          method: 'GET',
          headers: getHeaders(userTkn, currency)
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            let termsData = response?.data?.StaticPage?.data
            setTermsData(termsData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (

    <>
      {loading && ( // Full page loading overlay
        <div className="loading-overlay">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="listing-spinner"
          />
        </div>
      )}

      <section id="hero-faq" className="d-flex flex-column justify-content-center heading">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="hero-heading fw-bold high-light-white text-center"> {termsData?.meta_title} </p>
            </div>
          </div>
        </div>
      </section>


      <section className="pb-0">
        <div className="container-fluid cont">
          <div className="row justify-content-center">
            <div className="col-xl-12 ">
              <p className="main-heading fw-bold high-light-black pb-0"> {termsData?.heading} </p>
              <p className="small-text"> {termsData?.description ? parse(termsData?.description || '') : ''} </p>
            </div>
          </div>
        </div>
      </section>


      <Testimonial />

    </>

  )
}

export default TermsConditions;

