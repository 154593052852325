import React from 'react';
import { Card, Col } from 'react-bootstrap';
import './DummyCard.css'; // Ensure you create and import this CSS for dummy styles

const DummyBlogCard = () => {
  return (
    <Col xs={12} md={3} className="mb-4">
      <Card className="dummy-card card-trip">
        {/* Dummy Image Placeholder */}
        <div className="dummy-blog-container">
          <div className="dummy-blog-placeholder"></div>
        </div>

        {/* Card Body */}
        <Card.Body>
          <Card.Title>
            {/* Dummy Text Placeholder */}
            <p className="dummy-blog-text-placeholder"></p>
          </Card.Title>

          {/* Dummy Blog Details */}
          <div className="d-flex blog-detail align-items-center">
            <div>
              <span className="dummy-blog-text-placeholder"></span>
            </div>
            <div className="line mx-2">|</div>
            <div>
              <span className="dummy-blog-text-placeholder"></span>
            </div>
          </div>

          {/* Dummy Description Placeholder */}
          <div className="mt-3 trip-discription">
            <p className="dummy-blog-text-placeholder"></p>
            <p className="dummy-blog-text-placeholder"></p>
            <p className="dummy-blog-text-placeholder"></p>
          </div>
        </Card.Body>

        {/* Uncomment if footer is needed */}
        {/* 
        <Card.Footer className="footer-card">
          <p className="dummy-blog-text-placeholder">Loading category...</p>
        </Card.Footer>
        */}
      </Card>
    </Col>
  );
};

export default DummyBlogCard;
