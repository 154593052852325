import { capitalize } from 'helpers/utils';
import { useEffect, useState } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { RouteItems, routes } from 'sitemapCreator';
import TopNavMegaMenu from './TopNavMegaMenu';
import TopNavItem from './TopNavItem';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { co } from '@fullcalendar/core/internal-common';
import { useAuth } from 'providers/AuthContext';

const NavbarTopNavCreator = () => {
  const { userType} = useAuth();
  
  const filteredRoutes = routes.filter(route => {

    if (userType === 2 && (route.label === 'Pending for Approval')) {
      return false;
    }
    if ((userType === 3 || userType === 4) && (route.label === 'Pending for Approval')) {
      return true;
    }
    return true;
  });


  return (
    <Nav className="navbar-nav-top pb-4 pb-lg-0 scrollbar">
      {filteredRoutes.map(route => (
        <NavbarTopNavItem route={route} key={route.label} />
      ))}
    </Nav>
  );
};

const NavbarTopNavItem = ({ route }: { route: RouteItems }) => {
  const Icon = route.icon;
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false); 
  const { pathname } = useLocation();
  const navigation = useNavigate() as NavigateFunction;
  const path = route.path;


  const { breakpoints } = useBreakpoints();

  const handleMouseEnter = () => {
    if (breakpoints.up('lg')) {
      setShow(true);
    }
  };

  const handleMouseLeave = () => {
    if (breakpoints.up('lg')) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      setShow(false);
    }
  }, [pathname]);

  const handleClick = () => {
    navigation(`${path}`);
};

useEffect(() => {
   
  setIsActive(pathname === `${path}`); // Check if current path matches this item's path
}, [pathname, `${path}`]);

  return (
    <Dropdown
      as="li"
      show={show}
      className={`nav-item small-device-menu ${isActive ? 'active' : ''}`}
      key={route.label}
      autoClose="outside"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onToggle={() => setShow(!show)}
    >
      <Dropdown.Toggle
        as="a"
        variant=""
        className="nav-link lh-1 d-flex align-items-center cursor-pointer"
        onClick={handleClick}
      >
        <Icon className="me-2" size={22} />
        <span>
          {capitalize(
            route.horizontalNavLabel ? route.horizontalNavLabel : route.label
          )}
        </span>
      </Dropdown.Toggle>
      {/* {route.megaMenu ? (
        <TopNavMegaMenu route={route} />
      ) : (
        
        <TopNavItem route={route} />
      )} */}
    </Dropdown>
  );
};

export default NavbarTopNavCreator;
