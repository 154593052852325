import AppProvider from "providers/AppProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import BreakpointsProvider from "providers/BreakpointsProvider";
import SettingsPanelProvider from "providers/SettingsPanelProvider";
import { RouterProvider } from "react-router-dom";
import { router } from "Routes";
import "./assets/css/custom.css";
import "./assets/css/sidharth.css";
import "./assets/css/anshika.css";
import ChatWidgetProvider from "providers/ChatWidgetProvider";
import { SignUpProvider } from "providers/SignUpProvider";
import { SignInProvider } from "providers/SignInProvider";
import { AuthProvider } from "providers/AuthContext";
import { MasterProvider } from "providers/MasterContext";
import { MetaProvider } from "providers/MetaContext";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { TripsProvider } from "providers/TripsProvider";
import { ToastProvider } from "providers/ToastProvider";
import { EnquireNowProvider } from "providers/EnquireNowProvider";
import { CookiesProvider } from "providers/CookiesProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const clientId = process.env.REACT_APP_GOOGLE_AUTH;   //updated
if (!clientId) {
  throw new Error("REACT_APP_GOOGLE_AUTH is not defined in the environment variables.");
}
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <CookiesProvider>
        <MasterProvider>
          <MetaProvider>
            <EnquireNowProvider>
              <AppProvider>
                <TripsProvider>
                  <AuthProvider>
                    <ToastProvider>
                      <SignUpProvider>
                        <SignInProvider>
                          <BreakpointsProvider>
                            <RouterProvider router={router} />
                          </BreakpointsProvider>
                        </SignInProvider>
                      </SignUpProvider>
                    </ToastProvider>
                  </AuthProvider>
                </TripsProvider>
              </AppProvider>
            </EnquireNowProvider>
          </MetaProvider>
        </MasterProvider>
      </CookiesProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
