import { faDollarSign, faEuroSign, faSterlingSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const currencySymbolsPricefilter = (code: string) => {
    switch (code) {
        case 'AUD':
            return <FontAwesomeIcon icon={faDollarSign} color="#E94560" className="price-btn-icon" />
            break;
        case 'CAD':
            return <FontAwesomeIcon icon={faDollarSign} color="#E94560" className="price-btn-icon" />
            break;
        case 'EUR':
            return <FontAwesomeIcon icon={faEuroSign} color="#E94560" className="price-btn-icon" />
            break;
        case 'GBP':
            return <FontAwesomeIcon icon={faSterlingSign} color="#E94560" className="price-btn-icon" />
            break;
        case 'NZD':
            return <FontAwesomeIcon icon={faDollarSign} color="#E94560" className="price-btn-icon" />
            break;
        case 'USD': 
            return <FontAwesomeIcon icon={faDollarSign} color="#E94560" className="price-btn-icon" />
            break;
        case 'ZAR':
            return <div style={{color:"#E94560"}}>R</div>
            break;
        default:
            return ""

    }
}

export default currencySymbolsPricefilter

