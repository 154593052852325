import React from "react";
import { Card } from "react-bootstrap";
import { Agent, Team } from "types/interfaces";
import bgImg1 from 'assets/img/travel/blogs/Rectangle.png';
import avatar1 from "assets/img/team/team1.png";
import { useNavigate } from "react-router-dom";
import Unicon from "components/base/Unicon";
import { UilLinkedinAlt } from "@iconscout/react-unicons";
import profile1 from 'assets/img/travel/profile/profile.jpg';

interface TeamCardProps {
    team: Team
}

const TeamCard: React.FC<TeamCardProps> = ({ team }) => {
    const navigate = useNavigate();
    return (
        <div className="col-lg-4 mb-5">
            <Card className="card-trip">
                <div className="d-flex flex-row align-items-center mb-3 mt-3">
                    <div className="teamImg">
                        <div className="base-team">
                            <img src={team?.profileImage?.url ?? profile1} alt="team_image" />
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-1 ps-3 ">
                        <p className="name-text m-0 p-0">{team?.name ?? ""}</p>
                        <p className="creator-postion m-0 p-0">{team?.designation ?? ""}</p>
                        <p className="linkdin">
                            <a
                                href={team?.linkedin ?? "#"}// replace with actual LinkedIn profile URL
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Unicon icon={UilLinkedinAlt} size={20} color="#E94560" />
                            </a>
                        </p>

                    </div>
                </div>
            </Card>
        </div>
    );
};

export default TeamCard;
