// AuthContext.tsx


import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect
} from "react";
import { browserName, browserVersion, osName, osVersion, deviceType } from 'react-device-detect';
import { initialUserProfile, UserAgent, UserProfile } from "types/interfaces";
import { useTrips } from "./TripsProvider";
import apiCall from "services/api";




// Define the user type based on the structure of the user object

// Define the context type
type AuthContextType = {
  isAuthenticated: boolean;
  userType: number;
  creatorDashboard: boolean;
  setCreatorDashboard: (value: boolean) => void;
  isSystemUser: number;
  userAgentCode: string | '';
  userProfile: UserProfile | null;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile>>;
  signIn: (email: string, password: string, setCommonError: (error: any) => void, handleError: (error: any) => void, showErrorToast: (message: string) => void) => Promise<boolean>;
  forgotPassword: (email: string,  setCommonError: (error: any) => void, handleErrorForgot: (error: any) => void, showErrorToast: (message: string) => void, showSuccessToast:(message: string) => void) => Promise<boolean>;
  resetPassword: (password: string,  confirm_password: string, resetToken: string, setCommonError: (error: any) => void, handleErrorReset: (error: any) => void, showErrorToast: (message: string) => void, showSuccessToast:(message: string) => void) => Promise<boolean>;
  signUp: (first_name: string, last_name: string, email: string, password: string, setCommonError: (error: any) => void, handleError: (error: any) => void, showErrorToast: (message: string) => void) => Promise<boolean>;
  signInGoogle: (
    source: string,
    source_id: string,
    email: string,
    setGoogleAuthError: (error: string) => void,
    handleClose: () => void,
  ) => void; // Accept email and password as parameters
  signUpGoogle: (
    source: string,
    source_id: string,
    email: string,
    setGoogleAuthError: (error: string) => void,
    handleClose: () => void,
  ) => void; // Accept email and password as parameters
  userTkn: string | null;
  setUserTkn: (token: string | null) => void;
  signOut: () => void;
  creatorPanel: () => void;

};

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define a helper function to use the context
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

// Define the AuthProvider component
type AuthProviderProps = {
  children: ReactNode;
};



export function AuthProvider({ children }: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(0);
  const [userProfile, setUserProfile] = useState<UserProfile>(initialUserProfile);
  const [creatorDashboard, setCreatorDashboard] = useState(false)
  const [userTkn, setUserTkn] = useState<string | null>(null);
  const [isSystemUser, setIsSystemUser] = useState<number>(0)
  const [userAgentCode, setUserAgentCode] = useState<string | ''>('');
  const { setTrips } = useTrips();

  const signIn = async (
    email: string,
    password: string,
    setCommonError: (error: string) => void,
    handleError: any,
    showErrorToast: (message: string) => void
  ): Promise<boolean> => {
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    formData.append("platform", osName);
    formData.append("platform_version", osVersion);
    formData.append("browser", browserName);
    formData.append("browser_version", browserVersion);
    formData.append("device", deviceType)


    try {
      // const response = await axios.post(
      //   `${Constants.BASE_URL}/login`,
      //   formData,
      //   {
      //     headers: {
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //   }
      // );
      const response = await apiCall({
        url: `login`,
        method: 'POST',
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });
      if (response.status === 200) {

        if (response?.data?.errors) {
          setCommonError(response?.data);
          handleError(response?.data)
          return false;
        } else {
          setUserTkn(response.data.access_token);
          localStorage.setItem("accessToken", JSON.stringify(response.data.access_token));


          try {
            const formData2 = new FormData();
            // const profileResponse = await axios.post(
            //   `${Constants.BASE_URL}/profile`,
            //   formData2,
            //   {
            //     headers: {
            //       "Access-Control-Allow-Origin": "*",
            //       'x-access-token': response.data.access_token
            //     },
            //   }
            // );
            const profileResponse = await apiCall({
              url: `profile`,
              method: 'POST',
              data: formData2,
              headers: {
                "Access-Control-Allow-Origin": "*",
                'x-access-token': response.data.access_token
              }
            });

            if (profileResponse.status === 200) {
              if (profileResponse?.data?.errors) {
                console.warn("error", profileResponse?.data);
                return false;
              } else {
                localStorage.setItem("userProfile", JSON.stringify(profileResponse?.data));
                setUserProfile(profileResponse?.data);
                setUserType(profileResponse?.data?.user.user_type)
                setIsSystemUser(profileResponse?.data?.user?.is_system_user)
                const userAgentData = profileResponse?.data?.user?.userAgent || null;
                setUserAgentCode(userAgentData?.role?.code || '');
                localStorage.setItem("userType", JSON.stringify(profileResponse?.data?.user.user_type));
                localStorage.setItem("isSystemUser", JSON.stringify(profileResponse?.data?.user.is_system_user));
                localStorage.setItem("userAgentCode", JSON.stringify(userAgentData?.role?.code || ''));
                setIsAuthenticated(true);
                return true;
              }

            }

          } catch (error: any) {
            console.error("Error:", error);
            showErrorToast(error?.message)
            return false;
          }
        }
      }

      return false;
    } catch (error: any) {
      setIsAuthenticated(false);
      console.error("Sign in failed:", error);
      showErrorToast(error?.message)
      const errorMessage =
        error.response?.data || "An error occurred during sign-in.";
      setCommonError(errorMessage);
      return false;
    }
  }

  const forgotPassword = async (
    email: string,
    setCommonError: (error: string) => void,
    handleErrorForgot: any,
    showErrorToast: (message: string) => void,
    showSuccessToast: (message: string) => void
  ): Promise<boolean> => {

    const formData = new FormData();
    formData.append("email", email);
    formData.append("platform", osName);
    formData.append("platform_version", osVersion);
    formData.append("browser", browserName);
    formData.append("browser_version", browserVersion);
    formData.append("device", deviceType)


    try {
      const response = await apiCall({
        url: `forget-password`,
        method: 'POST',
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });
      if (response.status === 200) {

        if (response?.data?.errors) {
          setCommonError(response?.data);
          handleErrorForgot(response?.data)
          return false;
        } else {
        
          return true

     
        }
      }

      return false;
    } catch (error: any) {
      setIsAuthenticated(false);
      console.error("Forgot password failed:", error);
      showErrorToast(error?.message)
      const errorMessage =
        error.response?.data || "An error occurred during Forgot password.";
      setCommonError(errorMessage);
      return false;
    }
  }

  const resetPassword = async (
    password: string,
    confirm_password: string,
    resetToken: string,
    setCommonError: (error: string) => void,
    handleErrorReset: any,
    showErrorToast: (message: string) => void,
    showSuccessToast: (message: string) => void
  ): Promise<boolean> => {

    const formData = new FormData();
    formData.append("password", password);
    formData.append("confirm_password", confirm_password );
    formData.append("token", resetToken);
    formData.append("platform", osName);
    formData.append("platform_version", osVersion);
    formData.append("browser", browserName);
    formData.append("browser_version", browserVersion);
    formData.append("device", deviceType)


    try {
      const response = await apiCall({
        url: `reset-password`,
        method: 'POST',
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });
      if (response.status === 200) {

        if (response?.data?.errors) {
          setCommonError(response?.data);
          handleErrorReset(response?.data)
          return false;
        } else {
          showSuccessToast(response?.data?.message)
          return true

     
        }
      }

      return false;
    } catch (error: any) {
      setIsAuthenticated(false);
      console.error("Forgot password failed:", error);
      showErrorToast(error?.message)
      const errorMessage =
        error.response?.data || "An error occurred during Forgot password.";
      setCommonError(errorMessage);
      return false;
    }
  }


  const signUp = async (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    setCommonError: (error: string) => void,
    handleError: any,
    showErrorToast: (message: string) => void
  ): Promise<boolean> => {
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("platform", osName);
    formData.append("platform_version", osVersion);
    formData.append("browser", browserName);
    formData.append("browser_version", browserVersion);
    formData.append("device", deviceType)


    try {
      // const response = await axios.post(
      //   `${Constants.BASE_URL}/registration`,
      //   formData,
      //   {
      //     headers: {
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //   }
      // );
      const response = await apiCall({
        url: `registration`,
        method: 'POST',
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });
      if (response.status === 200) {

        if (response?.data?.errors) {
          setCommonError(response?.data);
          handleError(response?.data)
          return false;
        } else {
          setUserTkn(response.data.access_token);
          localStorage.setItem("accessToken", JSON.stringify(response.data.access_token));


          try {
            const formData2 = new FormData();
            // const profileResponse = await axios.post(
            //   `${Constants.BASE_URL}/profile`,
            //   formData2,
            //   {
            //     headers: {
            //       "Access-Control-Allow-Origin": "*",
            //       'x-access-token': response.data.access_token
            //     },
            //   }
            // );

            const profileResponse = await apiCall({
              url: `profile`,
              method: 'POST',
              data: formData2,
              headers: {
                "Access-Control-Allow-Origin": "*",
                'x-access-token': response.data.access_token
              }
            });

            if (profileResponse.status === 200) {
              if (profileResponse?.data?.errors) {
                console.warn("error", profileResponse?.data);
                return false;
              } else {
                localStorage.setItem("userProfile", JSON.stringify(profileResponse?.data));
                setUserProfile(profileResponse?.data);
                setUserType(profileResponse?.data?.user.user_type)
                setIsSystemUser(profileResponse?.data?.user?.is_system_user)
                const userAgentData = profileResponse?.data?.user?.userAgent || null;
                setUserAgentCode(userAgentData?.role?.code || '');
                localStorage.setItem("userType", JSON.stringify(profileResponse?.data?.user.user_type));
                localStorage.setItem("isSystemUser", JSON.stringify(profileResponse?.data?.user.is_system_user));
                localStorage.setItem("userAgentCode", JSON.stringify(userAgentData?.role?.code || ''));
                setIsAuthenticated(true);
                return true;
              }

            }

          } catch (error: any) {
            console.error("Error:", error);
            showErrorToast(error?.message)
            return false;
          }
        }
      }

      return false;
    } catch (error: any) {
      setIsAuthenticated(false);
      console.error("SignUp failed:", error);
      showErrorToast(error?.message)
      const errorMessage =
        error.response?.data || "An error occurred during sign-in.";
      setCommonError(errorMessage);
      return false;
    }
  }

  const signInGoogle = async (
    source: string,
    source_id: string,
    email: string,
    setGoogleAuthError: (error: string) => void,
    handleClose: () => void
  ) => {
    const formData = new FormData();
    formData.append("source", source);
    formData.append("source_id", source_id);
    formData.append("email", email);
    formData.append("platform", osName);
    formData.append("platform_version", osVersion);
    formData.append("browser", browserName);
    formData.append("browser_version", browserVersion);
    formData.append("device", deviceType)

    try {
      // const response = await axios.post(
      //   `${Constants.BASE_URL2}/social-login`,
      //   formData,
      //   {
      //     headers: {
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //   }
      // );
      const response = await apiCall({
        url: `social-login`,
        method: 'POST',
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });

      if (response.status === 200) {

        if (response?.data?.errors) {
          setGoogleAuthError(response?.data);
        } else {

          setUserTkn(response?.data?.access_token);
          localStorage.setItem("accessToken", JSON.stringify(response?.data?.access_token));
          setUserTkn(response.data.access_token);

          try {
            const [profileResponse] = await Promise.all([
              // axios.get(
              //   `${Constants.BASE_URL}/profile`,

              //   {
              //     headers: {
              //       "x-access-token": response?.data?.access_token,
              //       "Access-Control-Allow-Origin": "*",
              //     },
              //   }
              // )
              apiCall({
                url: `profile`,
                method: 'GET',
                headers: {
                  "x-access-token": response?.data?.access_token,
                  "Access-Control-Allow-Origin": "*"
                }
              })

            ]);

            if (profileResponse.status === 200) {

              if (profileResponse?.data?.errors) {
                console.warn("error", profileResponse?.data);
              } else {
                // Optionally, update the user state with additional information from the second API response
                localStorage.setItem(
                  "userProfile",
                  JSON.stringify(profileResponse?.data)
                );
                setUserProfile(profileResponse?.data);
                setUserType(profileResponse?.data?.user.user_type)
                localStorage.setItem("userType", JSON.stringify(profileResponse?.data?.user?.user_type));
                setIsAuthenticated(true);
                handleClose()
              }
            } else {
              console.error("Api Failed");
            }
          } catch (error) {
            console.error("Sign in failed:", error);
          }
        }

      } else {

        setIsAuthenticated(false);
        setGoogleAuthError("Sign-in failed. Please try again.");
      }
    } catch (error: any) {
      console.error("Sign in failed:", error);

      setIsAuthenticated(false);
      const errorMessage =
        error.response?.data || "An error occurred during sign-in.";
      setGoogleAuthError(errorMessage);
    }
  };

  const signUpGoogle = async (
    source: string,
    source_id: string,
    email: string,
    setGoogleAuthError: (error: string) => void,
    handleClose: () => void
  ) => {
    const formData = new FormData();
    formData.append("source", source);
    formData.append("source_id", source_id);
    formData.append("email", email);
    formData.append("platform", osName);
    formData.append("platform_version", osVersion);
    formData.append("browser", browserName);
    formData.append("browser_version", browserVersion);
    formData.append("device", deviceType)

    try {
      // const response = await axios.post(
      //   `${Constants.BASE_URL}/social-login`,
      //   formData,
      //   {
      //     headers: {
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //   }
      // );
      const response = await apiCall({
        url: `social-login`,
        method: 'POST',
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });

      if (response.status === 200) {

        if (response?.data?.errors) {
          setGoogleAuthError(response?.data);
        } else {

          setUserTkn(response.data.access_token);
          localStorage.setItem("accessToken", JSON.stringify(response.data.access_token));
          setUserTkn(response.data.access_token);

          try {
            const [profileResponse] = await Promise.all([
              // axios.post(
              //   `${Constants.BASE_URL}/profile`,
              //   null,
              //   {
              //     headers: {
              //       "x-access-token": response.data.access_token,
              //       "Access-Control-Allow-Origin": "*",
              //     },
              //   }
              // )
              apiCall({
                url: `profile`,
                method: 'POST',
                data: null,
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  'x-access-token': response.data.access_token
                }
              })

            ]);

            if (profileResponse.status === 200) {

              if (profileResponse?.data?.errors) {
                console.warn("error", profileResponse?.data);
              } else {
                // Optionally, update the user state with additional information from the second API response
                localStorage.setItem(
                  "userProfile",
                  JSON.stringify(profileResponse?.data)
                );
                setUserProfile(profileResponse?.data);
                setUserType(profileResponse?.data?.user.user_type)
                localStorage.setItem("userType", JSON.stringify(profileResponse?.data?.user.user_type));
                setIsAuthenticated(true);
                handleClose()
              }
            } else {
              console.error("Api Failed");
            }
          } catch (error) {
            console.error("Sign in failed:", error);
          }
        }

      } else {

        setIsAuthenticated(false);
        setGoogleAuthError("Sign-in failed. Please try again.");
      }
    } catch (error: any) {
      console.error("Sign in failed:", error);

      setIsAuthenticated(false);
      const errorMessage =
        error.response?.data || "An error occurred during sign-in.";
      setGoogleAuthError(errorMessage);
    }
  };

  const signOut = async () => {
    let accessToken: any = JSON.parse(
      localStorage.getItem("accessToken") || "{}"
    );


    try {
      // const response = await axios.post(`${Constants.BASE_URL2}/logout`, null, {
      //   headers: {
      //     "x-access-token": accessToken,
      //     "Access-Control-Allow-Origin": "*",
      //   },
      // });

      const response = await apiCall({
        url: `logout`,
        method: 'POST',
        data: null,
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Origin": "*",
        },
      });


      if (response.status === 200) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userType");
        localStorage.removeItem("dashboard")
        localStorage.removeItem("userProfile")
        localStorage.removeItem("isSystemUser")
        localStorage.removeItem("userAgentCode")

        setUserTkn(null);
        setIsAuthenticated(false);
        setCreatorDashboard(false);
        setIsSystemUser(0)
        setUserAgentCode('')
        setTrips([])
      } else {
        console.error("Api Failed");
      }
    } catch (error: any) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userType");
      localStorage.removeItem("userProfile")
      localStorage.removeItem("dashboard")
      localStorage.removeItem("isSystemUser")
      localStorage.removeItem("userAgentCode")

      setUserTkn(null);
      setIsAuthenticated(false);
      setTrips([])
      setCreatorDashboard(false)
      setIsSystemUser(0)
      setUserAgentCode('')
      console.error("sign out failed:", error.message);
      console.error("sign out failed:", error.response?.data.message);
      if (error.response?.data.message === "Invalid Access token") {
        localStorage.removeItem("userType");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userProfile")
        localStorage.removeItem("dashboard")
        localStorage.removeItem("isSystemUser")
        localStorage.removeItem("userAgentCode")
        setIsAuthenticated(false);
        setUserTkn(null);
        setTrips([]);
        setCreatorDashboard(false)
        setIsSystemUser(0)
        setUserAgentCode('')
      }
    }
  };

  const creatorPanel = async () => {
    if (creatorDashboard === true) {
      localStorage.setItem("dashboard", JSON.stringify(false));
      setCreatorDashboard(false)
    } else {
      localStorage.setItem("dashboard", JSON.stringify(true));
      setCreatorDashboard(true)
    }


  }

  useEffect(() => {
    const fetchUserFromLocalStorage = async () => {
      try {
        // Retrieve accessToken from localStorage
        const accessToken = localStorage.getItem("accessToken");
        let userProfile: any = localStorage.getItem("userProfile") || "{}";
        let user_type: any = localStorage.getItem("userType") || "";
        let dashboard: any = localStorage.getItem("dashboard") || "";
        let isSystemUser: any = localStorage.getItem("isSystemUser") || 0;
        let userAgentCode: any = localStorage.getItem("userAgentCode") || '';

        const isDashboardActive = dashboard === 'true';

        // Check if accessToken is present and not null or empty string
        if (accessToken) {
          // Parse accessToken from JSON string to object
          const accessTokenObj = JSON.parse(accessToken);
          const userProfileObj = JSON.parse(userProfile);
          const userAgentCodeObj = JSON.parse(userAgentCode);


          // Check if accessTokenObj is valid (you may have specific checks based on your accessToken structure)
          if (accessTokenObj && userProfileObj && Object.keys(userProfileObj).length !== 0) {

            // Now proceed with your logic
            setUserTkn(accessTokenObj)
            setIsAuthenticated(true);
            setUserType(Number(user_type));
            setUserProfile(userProfileObj)
            setCreatorDashboard(isDashboardActive)
            setIsSystemUser(isSystemUser)
            setUserAgentCode(userAgentCodeObj)

          } else {
            console.error("accessTokenObj is invalid or empty");
            // Handle case where accessToken is present but invalid
          }
        } else {
          console.error("accessToken is not present in localStorage");
          // Handle case where accessToken is not found in localStorage
        }
      } catch (error) {
        console.error("Error fetching user from localStorage:", error);
      }
    };

    fetchUserFromLocalStorage();
  }, []);


  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userType,
        userTkn,
        isSystemUser,
        userAgentCode,
        setUserTkn,
        creatorDashboard,
        setCreatorDashboard,
        userProfile,
        setUserProfile,

        signIn,
        forgotPassword,
        resetPassword,
        signInGoogle,
        signOut,
        creatorPanel,
        signUp,
        signUpGoogle
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
