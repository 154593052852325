import logo1 from 'assets/img/travel/brand/Rectangle.png';
import logo2 from 'assets/img/travel/brand/Rectangle2.png';
import logo3 from 'assets/img/travel/brand/image14.png';
import logo4 from 'assets/img/travel/brand/Rectangle1.png';
import { useMeta } from 'providers/MetaContext';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';

const Testimonial = () => {

    const { asSeenIn } = useMeta()
    return (
        <div className="justify-content-center d-flex flex-column py-4 testimonial-container" >
            <div>
                <div className="text-center brand-title"><span>As seen in...</span></div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className="testimonilas-container">
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={10}
                        pagination={false}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                        loop={true}
                        autoplay={{
                          delay: 3000,
                          pauseOnMouseEnter: true,
                          disableOnInteraction: false
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1, 
                                spaceBetween: 10
                            },
                            576: {
                                slidesPerView: 2, 
                                spaceBetween: 10
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 10
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 10
                            }
                        }}
                    >
                        {asSeenIn?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <a href={item?.reference_url ?? '#'} target="_blank" rel="noopener noreferrer">
                                    <div className="d-flex align-items-center">
                                        <img src={item?.listingImage?.url ?? logo1} alt={item?.listingImage?.name ?? "as seen in"} className="img-fluid equal-image" />
                                    </div>
                                </a>
                            </SwiperSlide>

                        ))}
                    </Swiper>
                </div>
            </div>

        </div>
    )
}

export default Testimonial