import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Card, Dropdown,  Nav } from 'react-bootstrap';
import avatar57 from 'assets/img/travel/profile/profile.jpg';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress, faBriefcase, faLock, faPlaneUp, faSignOut, faUser, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'providers/AuthContext';
import Button from 'components/base/Button';
import { useEnquireNow } from 'providers/EnquireNowProvider';
import { initialEnqNowError, initialEnquireNow } from 'types/interfaces';
import { enguireNowSteps } from 'pages/enquireNowModal/enguireNowSteps';
import useWindowWidth from 'hooks/useWindowWidth';

const ProfileDropdownMenu = ({ className, closeDropdown, navigate }: { className?: string, closeDropdown: () => void, navigate: any }) => {
  const { signOut, userProfile, creatorPanel, isAuthenticated, userType, creatorDashboard} = useAuth();
  const { setFormSteps, setCurrentStep, setEnquireData, setErrors } = useEnquireNow();
  const windowWidth = useWindowWidth()

  const handleSignOut = () => {
    setCurrentStep(0);
    setEnquireData(initialEnquireNow)
    setErrors(initialEnqNowError)
    setFormSteps(enguireNowSteps?.steps);
    signOut()
  }
  const [navItems] = useState([
    // {
    //   label: 'Messages',
    //   icon: faEnvelope,
    //   to: "/message"
    // },
    {
      label: 'Travel Profile',
      icon: faUser,
      to: "/travel-profile"
    },
    {
      label: 'My Trips',
      icon: faPlaneUp,
      to: "/my-trips"
    },
    {
      label: 'Book a Trip',
      icon: faBriefcase,
      to: "/book-a-trip"
    },
    {
      label: 'My Account',
      icon: faLock,
      to: "/my-account"
    },
    {
      label: 'Refer a friend',
      icon: faUserCheck,
      to: "/getintouch"
    }
    // {
    //   label: 'Logout',
    //   icon: faSignOut,
    //   to: "/logout"
    // }
  ]);
  const handleCreator = (value: string) => {
    if (value = 'manage') {
      navigate('/creator/dashboard');
    } else if (value = 'back') {
      navigate('/');
    }
    creatorPanel()

  }

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={userProfile?.user?.image?.url ?? avatar57} size="xl" />
            <h6 className="text-black"> {`${userProfile?.user?.first_name ?? ""} ${userProfile?.user?.last_name ?? ""}`}</h6>
          </div>

          <hr className='p-0 m-0' />
          
           <Nav className="nav flex-column  pb-1">
           {(isAuthenticated && (userType === 2 || userType === 3 || userType === 4) && Boolean(creatorDashboard) === false) &&
            <Nav.Item key={"logout"}>
              <Nav.Link
                // as={Link} to={item.to}
                as={Button}
                onClick={() => { handleCreator('manage') }}
                className="px-3"   >
                <FontAwesomeIcon
                  icon={faBarsProgress}

                  className="me-2 "
                />
                <span className="">{"Manage"}</span>
              </Nav.Link>
            </Nav.Item>
          }
            
            {navItems.map(item => (
              <Nav.Item key={item.label}>
                <Nav.Link
                  // as={Link} to={item.to}
                  onClick={() => {
                    closeDropdown();
                    navigate(item.to);
                  }}
                  className="px-3"   >
                  <FontAwesomeIcon
                    icon={item.icon}

                    className="me-2 "
                  />
                  <span className="">{item.label}</span>
                </Nav.Link>
              </Nav.Item>
            ))}

            <Nav.Item key={"logout"}>
              <Nav.Link
                // as={Link} to={item.to}
                as={Button}
                onClick={() => {
                  closeDropdown();
                  handleSignOut()
                }}
                className="px-3"   >
                <FontAwesomeIcon
                  icon={faSignOut}

                  className="me-2 "
                />
                <span className="">{"Sign Out"}</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {/* </Scrollbar>
          </div> */}
        </Card.Body>
        {/* <Card.Footer className="p-0 ">
          <div className="px-3">
            <Link
              to="#!"
              className=""
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </Link>
          </div>
        </Card.Footer> */}
      </Card>
    </Dropdown.Menu >
  );
};

export default ProfileDropdownMenu;
