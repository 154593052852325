import React from 'react';
import './DummyTrip.css'; // Make sure to import the CSS file
import { Button, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';

const DummyTripView = () => {
    return (
        <>
            <section id="hero-faq" className="dummy-hero d-flex flex-column justify-content-center">
                <div className="dummy-banner-placeholder-view"></div>
            </section>
            <Container  className="cont dummy-container mb-4">
                <Row className="pb-5 pt-4">
                    <Col xl={12} className="px-5 dummy-section">
                        <Row>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3 d-flex align-items-end">
                                <div className="dummy-main-heading-trip-view pt-3"></div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3">
                                <div className="d-flex flex-column flex-md-row gap-3 align-items-sm-start align-items-md-center justify-content-md-end">
                                    <div className="custom-btn-size big-font">
                                        <Button disabled variant="primary" className="btn btn-primary">Loading...</Button>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg={3}>
                        <p className="price-about">
                            From: <span className="dummy-price">Loading...</span>
                        </p>
                    </Col>
                    <Col lg={3}>
                        <p>Number of Days: <span className="dummy-price">Loading...</span></p>
                    </Col>
                    <Col lg={3}>
                        <p>Start Location: <span className="dummy-price">Loading...</span></p>
                    </Col>
                    <Col lg={3} className="d-flex justify-content-lg-end justify-content-sm-start">
                        <p>End Location: <span className="dummy-price">Loading...</span></p>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col lg={12}>
                        <div className="dummy-paragraph"></div>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col lg={12}>
                        <div className="dummy-paragraph"></div>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col lg={12}>
                        <div className="trip-cate">
                            <p className="dummy-categories">Trip Category</p>
                        </div>
                        <div className="categories-container">
                            <div className="dummy-category"></div>
                            <div className="dummy-category"></div>
                            <div className="dummy-category"></div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="trip-cate">
                            <p className="dummy-categories">Availability:</p>
                        </div>
                        <div className="months-container">
                            <div className="dummy-month"></div>
                            <div className="dummy-month"></div>
                            <div className="dummy-month"></div>
                        </div>
                    </Col>
                </Row>
                <hr className="hr-line" />
                <Row>
                    <div className="col-lg-12">
                        <div className="trip-cate">
                            <p className="cateogries-trip">Inclusive/Exclusive</p>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Col sm={12} md={6} lg={4} xl={4} className="d-flex flex-row gap-2 sm-mt-2">
                        <div className="d-flex flex-column justify-content-center">
                            <div className="dummy-status-placeholder"></div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="ie-heading py-1">
                                <div className="dummy-text-placeholder"></div>
                            </div>
                            <div className="ie-details">
                                <div className="dummy-text-placeholder"></div>
                                <div className="dummy-text-placeholder" style={{ width: '70%' }}></div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4} className="d-flex flex-row gap-2">
                        <div className="d-flex flex-column justify-content-center">
                            <div className="dummy-status-placeholder"></div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="ie-heading py-1">
                                <div className="dummy-text-placeholder"></div>
                            </div>
                            <div className="ie-details">
                                <div className="dummy-text-placeholder"></div>
                                <div className="dummy-text-placeholder" style={{ width: '70%' }}></div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4} className="d-flex flex-row gap-2">
                        <div className="d-flex flex-column justify-content-center">
                            <div className="dummy-status-placeholder"></div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="ie-heading py-1">
                                <div className="dummy-text-placeholder"></div>
                            </div>
                            <div className="ie-details">
                                <div className="dummy-text-placeholder"></div>
                                <div className="dummy-text-placeholder" style={{ width: '70%' }}></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DummyTripView;
