import { useAuth } from "providers/AuthContext";
import { useMeta } from "providers/MetaContext";
import { useToast } from "providers/ToastProvider";
import { ChangeEvent, useState, Suspense } from "react";
import { Form, Spinner } from "react-bootstrap"
import { CreatorTravelerProps, initialTravlerProfile, initialTravlerProfileError } from "types/interfaces";
import Editor, { EditorProvider } from 'react-simple-wysiwyg';
import { useNavigate } from "react-router-dom";
import apiCall from "services/api";
import { handleApiError } from "services/handleApiError";

const CreateTraveller = () => {
    const { userProfile, userTkn, signOut } = useAuth();
    const { currency } = useMeta();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<CreatorTravelerProps>(initialTravlerProfile);
    const [errors, setErrors] = useState(initialTravlerProfileError)


    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, type, checked, value } = event.target;
        // Allow input only if it meets the length requirement
        if (name === "contact_no" && value.length > 15) {
            return;
        }


        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(prevState => ({
            ...prevState,
            [name]: ''
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { first_name, last_name, email, phone_number, notes } = formData;
        let errors = { ...initialTravlerProfileError };


        let isValid = true;

        if (!first_name) {
            errors.first_name = "First name is required";
            isValid = false;
        } else if (/^\d/.test(first_name)) {
            errors.first_name = "First name cannot start with a number";
            isValid = false;
        } else if (first_name.length > 50) {
            errors.first_name = "First name cannot exceed 50 characters";
            isValid = false;
        }

        if (last_name) {
            if (/^\d/.test(last_name)) {
                errors.last_name = "Last name cannot start with a number";
                isValid = false;
            } else if (last_name.length > 50) {
                errors.last_name = "Last name cannot exceed 50 characters";
                isValid = false;
            }
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            errors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(email)) {
            errors.email = "Invalid email format";
            isValid = false;
        }
        const phoneRegex = /^[+]?[0-9]{1,15}$/;
        if (!first_name) {
            errors.phone_number = "Phone number is required";
            isValid = false;
        } else if (phone_number && !phoneRegex.test(phone_number)) {
            errors.phone_number = "Invalid phone number. Only [0-9] and + are allowed, and it should be a maximum of 15 digits.";
            isValid = false;
        }

        if (!isValid) {
            setErrors(errors);
            return;
        }

        if (isValid) {
            try {
                setLoading(true)
                const formDataSubmit = new FormData();
                formDataSubmit.append('first_name', formData?.first_name);
                formDataSubmit.append('last_name', String(formData?.last_name));
                formDataSubmit.append('email', formData?.email);
                formDataSubmit.append('phone_number', formData?.phone_number);
                formDataSubmit.append('notes', formData?.notes);

                const headers = userTkn
                    ? { 'x-access-token': userTkn }
                    : {};

                const response = await apiCall({
                    url: `travellers/create`,
                    method: 'POST',
                    data: formDataSubmit, // Change this line
                    headers,
                });

                if (response?.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;

                        // Dynamically set errors based on the response
                        Object.keys(errors).forEach((key) => {
                            const errorMessages = errors[key];
                            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                            // Set the error state for the corresponding field
                            setErrors((prevData: any) => ({
                                ...prevData,
                                [key]: firstErrorMessage,
                            }));
                        });
                    } else {
                        navigate('/creator/travelers')
                        showSuccessToast("Update sucessfully!!")

                    }
                }
            } catch (error: any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);


            }
        }

    }







    return (

        <>
            {loading && ( // Full page loading overlay
                <div className="loading-overlay">
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="listing-spinner"
                    />
                </div>
            )}
            <div className="pt-3 pb-3 trips-listing-heading" >
                <div className="container-fluid cardListing">
                    <div className="row mb-2">
                        <div className="col-12">
                            <p className="main-heading fw-bold high-light-black text-center m-0">Create <span className="high-light-red">Traveler</span></p>
                        </div>
                    </div>

                </div>
                <Form onSubmit={handleSubmit} className="enquire-now-modal">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-0 col-lg-2 col-xl-2 col-xxl-3">
                            </div>
                            <div className="col-md-12 col-lg-8 col-xl-8 col-xxl-6 ">
                                <div className="traveler-form-creator">
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <div><Form.Label className="">First Name</Form.Label></div>
                                        <Form.Control
                                            type="text"
                                            name="first_name"
                                            placeholder="First name"
                                            value={formData.first_name}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.first_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.first_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <div><Form.Label className="">Last Name</Form.Label></div>
                                        <Form.Control
                                            type="text"
                                            name="last_name"
                                            placeholder="Last name"
                                            value={formData.last_name}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.last_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.last_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <div><Form.Label className="">Email</Form.Label></div>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <div><Form.Label className="">Phone</Form.Label></div>
                                        <Form.Control
                                            type="text"
                                            name="phone_number"
                                            placeholder="Phone number"
                                            value={formData.phone_number}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.phone_number}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone_number}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <div><Form.Label className="">Notes</Form.Label></div>
                                        <Suspense fallback={<div className='step-content-0-main'>Loading...</div>}>
                                            <EditorProvider>
                                                <Editor
                                                    value={formData?.notes}
                                                    onChange={(e) => {
                                                        setFormData((prev: any) => ({
                                                            ...prev,
                                                            notes: e.target.value
                                                        }));
                                                        setErrors({ ...errors, notes: '' });
                                                    }}
                                                />
                                            </EditorProvider>
                                            {errors?.notes && (
                                                <div className={errors?.notes ? 'error-feedback' : ""}>
                                                    {errors?.notes}
                                                </div>
                                            )}
                                        </Suspense>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="col-md-0 col-lg-2 col-xl-2 col-xxl-3">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-0 col-lg-2"></div>
                            <div className="col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                                <div className="mt-2 traveller-btns-container">
                                    <div className="custom-btn-size d-flex  justify-content-evenly">
                                        <button onClick={() => { navigate('/creator/travelers') }} type="button" className="btn btn-outline-primary">
                                            {"Cancel"}
                                        </button>
                                        <button type='submit' className=" btn btn-primary">
                                            {"Save"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-0 col-lg-2"></div>
                        </div>


                    </div>
                </Form>
            </div >
        </>
    )
}

export default CreateTraveller
