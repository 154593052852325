import BannerImage from "assets/img/travel/banner/cookie.jpg";
import Testimonial from "components/asseenin/Testimonial";
import { useEffect, useState } from "react";
import apiCall from "services/api";
import { initialStaticPages, StaticPagesProps } from "types/interfaces";
import parse from 'html-react-parser';
import { useMeta } from "providers/MetaContext";
import { useAuth } from "providers/AuthContext";
import { getHeaders } from "services/getHeaders";

const CookiePolicy = () => {
  const { currency } = useMeta();
  const { signOut, userTkn } = useAuth()
  const [cookieData, setCookieData] = useState<StaticPagesProps>(initialStaticPages);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiCall({
          url: `cms/cookie-policy`,
          method: 'GET',
          headers: getHeaders(userTkn, currency)
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            let communityData = response?.data?.StaticPage?.data
            setCookieData(communityData);

          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <section id="hero-faq" className="d-flex flex-column justify-content-center"
        style={{ background: `url(${cookieData?.bannerImage?.url ?? BannerImage})` }} >

        <div className="container-fluid">
          <div className="row justify-content-center">

          </div>
        </div>
      </section>
      <section className="pb-0">
        <div className="container-fluid cont">
          <div className="row justify-content-center">
            <div className="col-xl-12 ">
              <p className="main-heading  fw-bold high-light-black pb-0"> {cookieData?.heading} </p>
              <p className="small-text"> {cookieData?.description ? parse(cookieData?.description || '') : ''} </p>
            </div>
          </div>
        </div>
      </section>


      <Testimonial />
    </>
  )
}

export default CookiePolicy