import classNames from "classnames";
import { Col, Form, Row } from "react-bootstrap";
import logo from "assets/img/icons/travel/logo2.png";
import { useNavigate } from "react-router-dom";
import { useMaster } from "providers/MasterContext";
import { useMeta } from "providers/MetaContext";
import { useEffect, useState } from "react";
import apiCall from "services/api";
import { AxiosError } from "axios";
import { useToast } from "providers/ToastProvider";
import {
  FooterParent,
  initialFooterParent,
  OptionType,
} from "types/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  const navigate = useNavigate();
  const { travelTopDestinationTag, topCategories } = useMaster();
  const { setCurrency, currenciesOptions, currency } = useMeta();
  const { showErrorToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [footerContentItems, setFooterContentItems] =
    useState<FooterParent>(initialFooterParent);

  const handleSelect = (selectedOption: OptionType) => {
    setCurrency(selectedOption.value);
    localStorage.setItem("currency", selectedOption.value);
    // window.location.reload();
  };

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        setLoading(true);
        const response = await apiCall({
          url: `cms/content-items/footer_main`,
          method: "GET",
        });

        if (response?.status === 200) {
          let data = response?.data?.ContentItem?.data?.content?.childs;
          setFooterContentItems(data);
        }
      } catch (error: any) {
        if ((error as AxiosError).response?.status === 401) {
          showErrorToast(error?.message);
        } else if (error as AxiosError) {
          showErrorToast(error?.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTripData();
  }, []);

  return (
    <footer className={classNames(className, "footer")}>
      <Row className="align-items-start h-100 border-0 footer-padding">
        <Col
          sm={12}
          md={6}
          lg={4}
          xl={4}
          xxl={4}
          className="d-flex flex-column justify-content-start mb-4 mb-lg-0"
        >
          <div className="d-flex align-items-center mb-3">
            <img src={logo} className="img-fluid" alt="image" />
          </div>

          <div className="d-flex align-items-center gap-2 ms-5">
            {/* <div className='footer-social'></div>
            <div className='footer-social'></div>
            <div className='footer-social'></div> */}
            <a
              href="https://www.facebook.com/wearethetravelcreators"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} className="fs-5" />
            </a>
            <a
              href="https://www.instagram.com/travel__creators/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} className="fs-5" />
            </a>
            <a href="https://www.tiktok.com/@travel__creators" target="_blank">
              <FontAwesomeIcon icon={faTiktok} className="fs-5" />
            </a>
          </div>
          <div className="copy-right-text pt-3 ms-5">
            <span>
              {" "}
              {footerContentItems?.footer_copyright?.item_value ??
                "© Travel Creators Ltd 2022."}{" "}
            </span>
          </div>
        </Col>
        <Col sm={12} md={6} lg={8} xl={8} xxl={8}>
          <Row className="gx-3 gy-4">
            <Col
              sm={12}
              md={6}
              lg={4}
              className="d-flex flex-column justify-content-start mb-4 mb-md-0"
            >
              <p className="footer-nav-category fw-bold">
                {footerContentItems?.top_destination?.item_value ??
                  "Top Destinations"}
              </p>
              <ul className="footer-nav-list">
                {travelTopDestinationTag?.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      navigate(`/top-destinations/${item?.id}/${item?.slug}`);
                    }}
                  >
                    <span className="fw-normal">{item?.name}</span>
                  </li>
                ))}
              </ul>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              className="d-flex flex-column justify-content-start mb-4 mb-md-0"
            >
              <p className="footer-nav-category fw-bold">
                {footerContentItems?.top_categories?.item_value ??
                  "Top Categories"}{" "}
              </p>
              <ul className="footer-nav-list">
                {topCategories?.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      navigate(`/top-categories/${item?.code}/${item?.slug}`);
                    }}
                  >
                    <span className="fw-normal">{item?.name}</span>
                  </li>
                ))}
              </ul>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              className="d-flex flex-column justify-content-start"
            >
              <p className="footer-nav-category fw-bold">
                {footerContentItems?.travel_creators?.item_value ??
                  "Travel Creators"}{" "}
              </p>
              <ul className="footer-nav-list">
                <li>
                  <span
                    className="fw-normal"
                    onClick={() => {
                      navigate("/aboutus");
                    }}
                  >
                    About Us
                  </span>
                </li>
                <li>
                  <span
                    className="fw-normal"
                    onClick={() => {
                      navigate("/trips-and-destination");
                    }}
                  >
                    Trips & Destinations
                  </span>
                </li>
                <li>
                  <span
                    className="fw-normal"
                    onClick={() => {
                      navigate("/become-a-creator");
                    }}
                  >
                    Become a Creator
                  </span>
                </li>
                <li>
                  <span
                    className="fw-normal"
                    onClick={() => {
                      navigate("/topcreators");
                    }}
                  >
                    TOP Creators
                  </span>
                </li>
                <li>
                  <span
                    className="fw-normal"
                    onClick={() => {
                      navigate("/getintouch");
                    }}
                  >
                    Get in Touch
                  </span>
                </li>
                <li>
                  <span
                    className="fw-normal"
                    onClick={() => {
                      navigate("/terms-conditions");
                    }}
                  >
                    Terms & Conditions
                  </span>
                </li>
                <li>
                  <span
                    className="fw-normal"
                    onClick={() => {
                      navigate("/copyright-policy");
                    }}
                  >
                    Copyright & Privacy
                  </span>
                </li>
                <li>
                  <span
                    className="fw-normal"
                    onClick={() => {
                      navigate("/community-guidline");
                    }}
                  >
                    Community Guidelines
                  </span>
                </li>
                <Form.Group controlId="meta_nonths" className="mb-0">
                  <div style={{ maxWidth: "100px", zIndex: 99 }}>
                    <Form.Select
                      name="sort"
                      className="form-icon-input custom-selcet"
                      // onChange={(e: any) => {
                      //   setCurrency(e.target.value);
                      // }}
                      onChange={(e) =>
                        handleSelect(
                          currenciesOptions?.find(
                            (opt) => opt.value === e.target.value
                          )!
                        )
                      }
                      value={currency}
                    >
                      {currenciesOptions?.map((item, value) => (
                        <option value={item?.value} key={value}>
                          {item?.value}{" "}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Form.Group>
                {/* <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {currency || 'Select Currency'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {currenciesOptions?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleSelect(item?.value)}
                      >
                        {item?.value}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown> */}
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
