import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Button, Card, Form, Row, Spinner } from 'react-bootstrap';
import { useAuth } from 'providers/AuthContext';
import apiCall from 'services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faUsps } from '@fortawesome/free-brands-svg-icons';
import { FilterLeads, initialSummary, Leads, SummaryProps } from 'types/interfaces';
import { useToast } from 'providers/ToastProvider';
import currencySymbols from 'services/currencySymbols';
import { formatDate } from 'services/dateFormat';
import MySelectComponentLeads from './CustomMultiValueLeads';
import { handleApiError } from 'services/handleApiError';

const CreatorLeads: React.FC = () => {
  const { userTkn, signOut } = useAuth();
  const { showErrorToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [leadsData, setLeadsData] = useState<Leads[]>([])
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [summary, setSummary] = useState<SummaryProps>(initialSummary);
  const [filters, setFilters] = useState<FilterLeads>({
    name: '',
    tripName: '',
    month: [],
    status: 0
  });
  const [filterStatus, setFilterStatus] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `leads?page=${pageIndex}&pageSize=12`
        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
          },
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.TripLeads?.data;
            const summary = response?.data?.TripLeads?.summary;
            setLeadsData(prev => {
              if (pageIndex === 1) {
                // Reset data if fetching the first page
                return [...data];
              } else {
                // Append new data for subsequent pages
                return [...prev, ...data];
              }
            });
            setSummary({
              total: summary.total,
              page: summary.page,
              pageSize: summary.pageSize,
              total_page: summary.total_page,
            });
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    };

    fetchData();
  }, [userTkn, pageIndex])

  //for filter
  useEffect(() => {

    if (!filterStatus) return; // Exit early if filterStatus is false
    if (pageIndex === 1) {
      setLeadsData([]);
    }
    const fetchData = async () => {
      try {
        setLoading(true);
        const baseUrl = 'leads';
        const availabilityParams = filters?.month
          ? filters?.month.map((val, index) => `availability[${index}]=${val}`).join('&')
          : '';

        // Dynamically construct the common params
        const commonParamsArray: string[] = [`page=${pageIndex}`, `pageSize=12`];
        if (filters?.name) {
          commonParamsArray.push(`name=${filters.name}`);
        }
    
        if (filters?.tripName) {
          commonParamsArray.push(`tripName=${filters.tripName}`);
        }
    
        if (filters?.status && filters.status !== 0) {
          commonParamsArray.push(`status=${filters.status}`);
        }
    
        // Join the common params array into a single string
        const commonParams = commonParamsArray.join('&');
        const url = `${baseUrl}?${commonParams}${availabilityParams}`
        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
          },
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.TripLeads?.data;
            const summary = response?.data?.TripLeads?.summary;
            setLeadsData(prev => {
              if (pageIndex === 1) {
                // Reset data if fetching the first page
                return [...data];
              } else {
                // Append new data for subsequent pages
                return [...prev, ...data];
              }
            });
            setSummary({
              total: summary.total,
              page: summary.page,
              pageSize: summary.pageSize,
              total_page: summary.total_page,
            });
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setLoading(false);
        setLoadingMore(false);
        setFilterStatus(false)
      }
    };

    fetchData();
  }, [userTkn, pageIndex, filterStatus])


  const handleLoadMore = () => {
    setLoadingMore(true);
    if (pageIndex <= summary?.total_page) {
      // Set loading state for "Load More" button
      setPageIndex(prevPage => prevPage + 1);
    } else {
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);

    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFilters((prev) => ({
      ...prev,
      [name]: String(value) // Assuming status is a number
    }));

  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFilterStatus(true)

  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;

    setFilters((prev) => ({
      ...prev,
      [name]: Number(value) // Assuming status is a number
    }));

  };

  const resetFilters = () => {
    setFilters({
      name: '',
      tripName: '',
      month: [],
      status: 0
    });
    setFilterStatus(true)
  }

  return (
    <div>
      {loading && ( // Full page loading overlay
        <div className="loading-overlay">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="listing-spinner"
          />
        </div>
      )}
      <div className="pt-3 pb-3 trips-listing-heading" >
        <div className="container-fluid cardListing">
          <div className="row mb-2">
            <div className="col-md-12 col-lg-7 col-xl-7 col-xxl-9">
              <p className="main-heading fw-bold m-0"><span className="high-light-red">Leads</span>  </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid cardListing">
        <Form className="trip-search-modal" onSubmit={handleSubmit}>
          <div className="filter-container ">
            <Row className="d-flex justify-content-lg-left justify-content-xl-center align-items-center">
              <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 trip-title-filter-container mt-3 mt-sm-3 mt-md-0  mt-lg-0  mt-xl-0 mt-xxl-0">
                <Form.Group controlId={"months-select"}>
                  <div className="form-icon-container">
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Search by travller"
                      value={filters?.name}
                      onChange={handleChange}
                      className="flex-grow-1 title-filter"
                      id="title-filter"
                    />
                    <div className="eye-icon2">
                      <FontAwesomeIcon
                        className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
                        style={{ left: '10px', cursor: 'pointer' }}
                        icon={faMagnifyingGlass}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 trip-title-filter-container mt-3 mt-sm-3 mt-md-0  mt-lg-0  mt-xl-0 mt-xxl-0">
                <Form.Group controlId={"months-select"}>
                  <div className="form-icon-container">
                    <Form.Control
                      type="text"
                      name="tripName"
                      placeholder="Search by trip"
                      value={filters?.tripName}
                      onChange={handleChange}
                      className="flex-grow-1 title-filter"
                      id="title-filter"
                    />
                    <div className="eye-icon2">
                      <FontAwesomeIcon
                        className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
                        style={{ left: '10px', cursor: 'pointer' }}
                        icon={faMagnifyingGlass}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>

              <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 mt-3  mt-sm-3  mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                <MySelectComponentLeads setFilters={setFilters} months={filters?.month} />
              </div>
              <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 mt-3  mt-sm-3  mt-md-3 mt-lg-0 mt-xl-0 mt-xxl-0">
                <Form.Group controlId={"months-select"}>
                  <div className="form-icon-container">
                    <Form.Select
                      aria-label="Default select example"
                      value={filters?.status}
                      onChange={handleSelectChange}
                      name="status"
                      className="flex-grow-1"
                      id="select-status"
                      disabled
                    >
                      <option className="select-option-style" value="0">Draft</option>
                      <option className="select-option-style" value="1">Send for Approval</option>
                      <option className="select-option-style" value="2">Approved</option>
                      <option className="select-option-style" value="3">Rejected</option>
                      <option className="select-option-style" value="4">Terminated</option>
                      <option className="select-option-style" value="5">Cancel</option>
                    </Form.Select>
                    <div className="eye-icon2">
                      <FontAwesomeIcon
                        className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
                        style={{ left: '10px', cursor: 'pointer' }}
                        icon={faUsps}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 custom-btn-small d-flex gap-2 mt-xs-3  mt-3 mt-md-3  mt-lg-3  mt-xl-0 mt-xxl-0  ">
                <Button type="submit" aria-label="Find" className="flex-grow-0" >
                  Find
                </Button>
                <Button variant="outline-primary" onClick={resetFilters} className=""> Reset </Button>
              </div>
            </Row>
          </div>
        </Form>
      </div>

      <section className="container-fluid cardListing pt-0">
        <div className='row'>
          {leadsData?.map((item, index) => {
            const tripDate = formatDate(item?.trip_date);
            const createdAt = formatDate(item?.created_at);
            return (
              <div key={index} className='col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-2'>
                <Card className='southAfricaCards mb-4'>

                  <Card.Body className='cardBody leads-card'>
                    <div className='cardTitleWrapper'>
                      <p className='cardTitle'>{item?.name}</p>
                    </div>
                    <div className='cardText'>
                      <div className="row mb-2">
                        <div className="col lisitingLeftSide">Trip</div>
                        <div className="col lisitingRightSide cardTitle">{item?.tripName}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col lisitingLeftSide">Start Date</div>
                        <div className="col lisitingRightSide">{tripDate ?? ''}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col lisitingLeftSide">Create Date</div>
                        <div className="col lisitingRightSide">{createdAt ?? ""}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col lisitingLeftSide">Length</div>
                        <div className="col lisitingRightSide">{item?.trip_duration ?? ""} Days {item?.is_date_firm === 1 ? "(flex)" : ""}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col lisitingLeftSide">PAX</div>
                        <div className="col lisitingRightSide">{item?.no_of_travellers ?? ""}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col lisitingLeftSide">Value</div>
                        <div className="col lisitingRightSide">{currencySymbols(item?.currency || '')} {item?.budget_max ?? ""}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col lisitingLeftSide">Phone</div>
                        <div className="col lisitingRightSide">{item?.phone ?? ""}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col lisitingLeftSide">Status</div>
                        <div className="col lisitingRightSide">{item?.leadStatus ?? ""}</div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )
          })}
        </div>
        {leadsData?.length > 0 ?
          <>
            {((Number(pageIndex) === 1 && !loading) || Number(pageIndex) > 1) && <div className="custom-btn-size d-flex justify-content-center mt-3">
              {Number(summary?.page) === Number(summary?.total_page) ?
                <div className="d-flex justify-content-center"></div> :
                <button onClick={handleLoadMore} className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2">
                  {loadingMore ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                    : ''
                  }
                  <span className="ml-2">{"Load More"}</span>
                </button>
              }

            </div>
            }
          </> :
          <>
            {!loading && <div className="d-flex justify-content-center"><h3>Oops No Record Found !!</h3></div>}
          </>
        }
      </section>
    </div >
  );
};

export default CreatorLeads;
