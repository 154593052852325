import SettingsPanel from "components/settings-panel/SettingsPanel";
import SettingsToggle from "components/settings-panel/SettingsToggle";
import useToggleStyle from "hooks/useToggleStyle";
import { useAppContext } from "providers/AppProvider";
import { useSettingsPanelContext } from "providers/SettingsPanelProvider";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";

const App = () => {
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();
  const {
    config: { theme, navbarPosition },
    setConfig,
  } = useAppContext();

  useEffect(() => {
    ReactGA.initialize("G-LTBG18DVPW"); // Replace with your Measurement ID
    ReactGA.send("pageview"); // Send initial pageview
  }, []);
  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = "default";

    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === "dark" ? "#000" : "#fff",
          }}
        />
      ) : (
        <>
          <ToastContainer position="top-center" />
          <Outlet />
        </>
      )}
    </>
  );
};

export default App;
