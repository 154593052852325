import { Button, Spinner } from "react-bootstrap";
import BannerImage from "../assets/img/travel/hero/hero-creator.png";
import BannerMobile from "../assets/img/travel/banner/mobile/lake_como.png";
import { useEffect, useState } from "react";
import { useToast } from "providers/ToastProvider";
import { useAuth } from "providers/AuthContext";
import {
  Agent,
  ChildItemAllBtnText,
  ChildItemCreator,
  initialChildItemAllBtnText,
  initialChildItemCreator,
  initialSummary,
  SummaryProps,
} from "types/interfaces";
import AgentImg from "assets/img/travel/creator/agentcard.png";
import apiCall from "services/api";
import { handleApiError } from "services/handleApiError";
import { useNavigate } from "react-router-dom";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import useWindowWidth from "hooks/useWindowWidth";
import Testimonial from "components/asseenin/Testimonial";

const CreatorsViewAll = () => {
  const { showErrorToast } = useToast();
  const windowWidth = useWindowWidth();
  const { userTkn, signOut } = useAuth();
  const { currency } = useMeta();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [creatorContentItems, setCreatorContentItems] =
    useState<ChildItemCreator>(initialChildItemCreator);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [agentsTotal, setAgentsTotal] = useState(0);

  const [pageIndex, setPageIndex] = useState<number>(1);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [summary, setSummary] = useState<SummaryProps>(initialSummary);
  const [btnText, setBtnText] = useState<ChildItemAllBtnText>(
    initialChildItemAllBtnText
  );

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        setLoading(true);
        const [btnTextResponse, contentResponse] = await Promise.all([
          apiCall({
            url: `cms/content-items/all_btn_text`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `cms/content-items/creators`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
        ]);
        if (contentResponse?.status === 200) {
          let data = contentResponse?.data?.ContentItem?.data?.content?.childs;
          setCreatorContentItems(data);
        }

        if (contentResponse?.status === 200) {
          let data = contentResponse?.data?.ContentItem?.data?.content?.childs;
          setCreatorContentItems(data);
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setLoading(true);
      }
    };

    fetchTripData();
  }, [currency]);

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        setLoading(true);
        const [agentsResponse] = await Promise.all([
          apiCall({
            url: `agents?page=${pageIndex}&pageSize=24&sort=-is_top_agent`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
        ]);

        if (agentsResponse?.status === 200) {
          let data = agentsResponse?.data?.Agents?.data;
          const summary = agentsResponse?.data?.Agents?.summary;

          setAgents((prev) => {
            if (pageIndex === 1) {
              // Reset data if fetching the first page
              return [...data];
            } else {
              // Append new data for subsequent pages
              return [...prev, ...data];
            }
          });
          setAgentsTotal(agentsResponse?.data?.Agents?.summary?.total);
          setSummary({
            total: summary.total,
            page: summary.page,
            pageSize: summary.pageSize,
            total_page: summary.total_page,
          });
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    };

    fetchTripData();
  }, [currency, pageIndex]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    if (pageIndex <= summary?.total_page) {
      // Set loading state for "Load More" button
      setPageIndex((prevPage) => prevPage + 1);
    } else {
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
  };

  return (
    <div className="creators">
      {/*first section home banner*/}
      <section
        id="hero-creators"
        className="d-flex flex-column justify-content-center"
        style={{ background: `url(${BannerImage})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 flex-column d-flex justify-content-center">
              <div className="d-flex flex-column justify-content-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                <p className="hero-heading fw-bold high-light-white  mb-1 mb-sm-1 mb-md-2 mb-lg-3 mb-xl-4 mb-xxl-4 text-center text-md-start text-lg-start text-xl-start text-xxl-start">
                  {creatorContentItems?.creator_heading_1?.item_value ??
                    "Curate."}
                </p>
                <p className="hero-heading fw-bold high-light-white mb-1 mb-sm-1 mb-md-2 mb-lg-3 mb-xl-4 mb-xxl-4 text-center text-md-start text-xl-start text-xxl-start">
                  {creatorContentItems?.creator_heading_2?.item_value ??
                    "Share."}
                </p>
                <p className="hero-heading fw-bold high-light-red mb-1 mb-sm-1  mb-md-1 mb-lg-1 mb-xl-1 mb-xxl-1 text-center text-md-start text-xl-start text-xxl-start">
                  <span className="high-light-red">
                    {creatorContentItems?.creator_heading_3?.item_value ??
                      "Earn."}
                  </span>
                </p>
              </div>
              <div className="creator-sub-heading">
                <p className="sub-heading high-light-white fw-semibold">
                  {creatorContentItems?.creator_sub_head_1?.item_value ??
                    "Join thousands of travel creators who"}
                  {windowWidth > 768 && <br />}
                  {creatorContentItems?.creator_sub_head_12?.item_value ??
                    "have turned their passion into an"}
                  {windowWidth > 768 && <br />}
                  {creatorContentItems?.creator_sub_head_3?.item_value ??
                    "online revenue stream."}
                </p>
              </div>
              {btnText?.become_a_creators?.item_value && (
                <div className="custom-btn-size d-flex justify-content-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                  <div>
                    <Button
                      variant="primary"
                      className="flex-grow-1 flex-lg-grow-0"
                      onClick={() => {
                        navigate("/intrest-form");
                      }}
                    >
                      {btnText?.become_a_creators?.item_value ??
                        "Become a Creator"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center ">
              <img
                src={BannerMobile}
                alt="chat"
                className="creator-hero-banner-sub mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </section>
      {/*Second section creator*/}
      <section className="pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col"></div>
            <div className="col-lg-8">
              <p className="creator-title-details text-center">
                {creatorContentItems?.creator_first_1?.item_value ??
                  "Join thousands of creators who have turned their passion for travel into an income"}
              </p>
            </div>
            <div className="col"></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <p className="creator-discription">
                {creatorContentItems?.creator_ptext_1?.item_value ??
                  "Travel Creators’"}
                <span>
                  {" "}
                  {creatorContentItems?.bold_ptext_1?.item_value ?? "mission"}
                </span>
                {creatorContentItems?.creator_ptext_2?.item_value ??
                  "is to help thousands of creators from around the Globe turn their passion into a sustainable income. How? Thanks to our unique"}
                <span>
                  {" "}
                  {creatorContentItems?.bold_ptext_2?.item_value ??
                    "technology platform"}
                </span>
                ,{" "}
                {creatorContentItems?.creator_ptext_3?.item_value ??
                  "creators like you can sign-up and build their first trip in minutes by selecting flights, hotels and activities from our curated list of partners. Once your trip is live on our site, simply"}
                <span>
                  {" "}
                  {creatorContentItems?.bold_ptext_3?.item_value ??
                    "promote it on your preferred channels"}
                </span>{" "}
                {creatorContentItems?.creator_ptext_4?.item_value ??
                  "to start generating revenue and use our messaging platform to communicate with travelers before and after their trips. It’s that simple."}
              </p>
              <p className="creator-discription">
                {creatorContentItems?.creator_ptext_5?.item_value ??
                  "Our success? We are already working with"}{" "}
                <span>
                  {creatorContentItems?.bold_ptext_4?.item_value ??
                    "1,628  creators"}
                </span>{" "}
                {creatorContentItems?.creator_ptext_6?.item_value ??
                  "worlwide and have generated over"}{" "}
                <span>
                  {" "}
                  {creatorContentItems?.bold_ptext_5?.item_value ??
                    "£4 million in bookings"}
                </span>{" "}
                {creatorContentItems?.creator_ptext_7?.item_value ??
                  "for them thourgh our platform. Join Travel Creators now to turn your passion into your income."}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*third section*/}
      <div className="d-flex flex-column justify-content-center ">
        <div className="container">
          <div className="d-flex flex-column justify-content-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-12 text-center pb-4 mb-4 mt-2 mt-sm-3 mt-md-4 mt-lg-5 mt-xl-5 mt-xxl-5">
                  {agentsTotal > 0 && (
                    <p className="main-heading fw-bold high-light-black">
                      {" "}
                      <span className="high-light-red">{agentsTotal}</span>{" "}
                      {creatorContentItems?.heading_first?.item_value ??
                        "creators waiting for you"}
                    </p>
                  )}
                </div>
                <div className="row gx-5 gy-5">
                  {Array.isArray(agents) && agents.length > 0 && (
                    <>
                      {agents?.map((item, index) => (
                        <div
                          onClick={() => {
                            navigate(`/agents/${item?.slug}`);
                          }}
                          className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 link-hover"
                        >
                          <div className="slide-img-container">
                            <img
                              src={item?.profileImage?.url ?? AgentImg}
                              alt="image"
                              width={365}
                              height={80}
                            />
                          </div>
                          <p className="m-0 text-center">
                            <span className="travel-category-grid fw-bold high-light-black  text-center m-0">
                              {item?.name ?? ""}{" "}
                            </span>
                          </p>
                          <p className="m-0 text-center">
                            {item?.location?.name}
                          </p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="custom-btn-size d-flex justify-content-center mt-3">
          {Number(summary?.page) === Number(summary?.total_page) ? (
            <div className="d-flex justify-content-center"></div>
          ) : (
            <button
              onClick={handleLoadMore}
              className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2"
            >
              {loadingMore ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                ""
              )}
              <span className="ml-2">{"Load More"}</span>
            </button>
          )}
        </div>
      </>
      <Testimonial />
    </div>
  );
};

export default CreatorsViewAll;
