import DummyTripView from "components/common/dummyview/DummyTripView";
import { useAuth } from "providers/AuthContext";
import { useToast } from "providers/ToastProvider";
import { useEffect, useState } from "react";
import { Accordion, Button, Nav, Tab } from "react-bootstrap";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import apiCall from "services/api";
import currencySymbols from "services/currencySymbols";
import { TripDetailsFormProps } from "types/interfaces";
import parse from 'html-react-parser';
import ieoStatus, { convertTo12HourFormat } from "services/ieoStatus";
import classNames from "classnames";
import useWindowWidth from "hooks/useWindowWidth";
import { useEnquireNow } from "providers/EnquireNowProvider";
import Avatar from "components/base/Avatar";
import avtar1 from 'assets/img/travel/profile/profile.jpg';
import EnquireNow from "pages/enquireNowModal/EnquireNow";
import Dropdown from 'react-bootstrap/Dropdown';
import DummyBanner from "assets/img/travel/banner/dummybanner.png"
import { handleApiError } from "services/handleApiError";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import NotFound from "components/404/NotFound";


type NavItemType = {
    label: string;
    icon: string;
    eventKey: string;
};


const NavItem = ({ item, isLast, windowWidth }: { item: NavItemType; isLast?: boolean, windowWidth: number }) => {
    return (
        <div className='custom-tab-item'>
            <Nav.Link
                eventKey={item.eventKey}
                className={classNames(
                    'text-center text-sm-start cursor-pointer outline-none d-sm-flex align-items-sm-center custom-tab-nav-item',
                    {
                        ' border-end border-end-sm-0 border-300': !isLast
                    }
                )}
            >
                {windowWidth < 576 && <span className="d-sm-inline">{item.label}</span>}
                <span className="d-none d-sm-inline">{item.label}</span>
            </Nav.Link>
        </div>
    );
};


const TripsViewTraveller = () => {
    const navigate = useNavigate();
    const { showToast, showSuccessToast, showInfoToast, showErrorToast } = useToast();
    const { slug } = useParams<{ slug: string }>();
    if (!slug) {
        showErrorToast("Error: No slug provided")
        navigate(-1)
    }
    const location = useLocation();
    const { showEnquireNow, setShowEnquireNow } = useEnquireNow();
    const { trips, source } = location.state || {};
    const currentUrl = window.location.origin + location.pathname;
    const { signOut, userTkn } = useAuth()
    const { currency } = useMeta();
    const windowWidth = useWindowWidth();

    const [loading, setLoading] = useState<boolean>(false);
    const [tripData, setTripData] = useState<TripDetailsFormProps | null>(null);
    const [tripView, setTripView] = useState<boolean>(false);  // for toggle Trip Form Steps and Trip View
    const [navItems, setNavItems] = useState<NavItemType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [handleDpSelect, setHandleDpSelect] = useState(0)

    const handleEmailClick = (value: number) => {
        setHandleDpSelect(value)
        const mailtoLink = `mailto:?subject=Check out this trip&body=Check out this link: ${currentUrl}`;

        // Create an anchor element and simulate a click
        const link = document.createElement('a');
        link.href = mailtoLink;
        link.target = '_blank'; // Open in a new tab (optional)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the DOM
    };

    const handleCopyLinkClick = (value: number) => {
        setHandleDpSelect(value)
        navigator.clipboard.writeText(currentUrl).then(() => {
            console.warn("Link copied to clipboard!");
        });
    };

    useEffect(() => {
        let isMounted = true;  // Flag to track component mount status

        const fetchTripData = async () => {
            try {
                setLoading(true);
                const response = await apiCall({
                    url: `tour/${slug}`,
                    method: 'GET',
                    headers: getHeaders(userTkn, currency)

                });

                if (isMounted && response.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;
                        showErrorToast(errors);
                    } else {
                        const data = response?.data?.TripVersion?.data;

                        const selectedMonthsCode = data?.tripAvailability?.map((item: any) => Number(item?.meta_months_no));
                        const selectedMonths = data?.tripAvailability?.map((item: any) => item?.month);
                        const selectedCategories = data?.tripCategory?.map((item: any) => item?.masterTripCategory);
                        const selectedCategoryCodes = data?.tripCategory?.map((item: any) => Number(item?.master_trip_category_code));

                        setTripData((prev: any) => ({
                            ...prev,
                            id: data?.id,
                            title: data?.title,
                            trip_uuid: data?.trip_uuid,
                            agent: data?.agent,
                            version: data?.version,
                            versions: data?.versions,
                            pendingVersion: data?.pendingVersion,
                            liveVersion: data?.liveVersion,
                            status: data?.status,
                            tags: data?.tags,
                            meta_currency_code: data?.meta_currency_code,
                            priceExchange: {
                                currency: data?.priceExchange?.currency,
                                price: data?.priceExchange?.price
                            },
                            price: data?.price,
                            no_of_days: data?.no_of_days,
                            start_location: data?.startLocation?.id,
                            end_location: data?.endLocation.id,
                            start_location_option: { value: data?.startLocation?.id, label: data?.startLocation?.name },
                            end_location_option: { value: data?.endLocation?.id, label: data?.endLocation?.name },
                            banner_image: data?.bannerImage?.id,
                            banner_image_preview: data?.bannerImage?.url,
                            listing_images: [],
                            listing_image_preview: data?.listingImages.map((image: any) => ({
                                url: image.url,
                                id: image.id,
                                name: image.name
                            })),
                            listing_images_preserve: data?.listingImages.map((image: any) => Number(image?.id)) || [],
                            description: data?.description,
                            details: data?.details,
                            slectedCategoriesCode: selectedCategoryCodes,
                            selectedCategories: selectedCategories,
                            trip_availabilities_code: selectedMonthsCode,
                            trip_availabilities: selectedMonths,
                            meta_title: data?.seo?.meta_title,
                            is_meta_title_same_as_title: data?.seo?.is_meta_title_same_as_title,
                            meta_keywords: data?.seo?.meta_keywords,
                            is_meta_title_same_as_trip_tags: data?.seo?.is_meta_title_same_as_trip_tags,
                            meta_description: data?.seo?.meta_description,
                            is_meta_title_same_as_description: data?.seo?.is_meta_title_same_as_description,
                            accomodation_status: data?.includedexcluded?.accomodation?.accomodation_status,
                            accomodation_note: data?.includedexcluded?.accomodation?.accomodation_note,
                            flight_status: data?.includedexcluded?.flight?.flight_status,
                            flight_note: data?.includedexcluded?.flight?.flight_note,
                            ground_transport_status: data?.includedexcluded?.ground_transport?.ground_transport_status,
                            ground_transport_note: data?.includedexcluded?.ground_transport?.ground_transport_note,
                            meals_status: data?.includedexcluded?.measls?.meals_status,
                            meals_note: data?.includedexcluded?.measls?.meals_note,
                            included: data?.includedexcluded?.included,
                            excluded: data?.includedexcluded?.excluded,
                            optional: data?.includedexcluded?.optional,
                            privacy_policy: data?.addionalInfo?.privacy_policy,
                            term_and_conditions: data?.addionalInfo?.term_and_conditions,
                            change_policy: data?.addionalInfo?.change_policy,
                            must_carry: data?.addionalInfo?.must_carry,
                            dayItinerary: data?.dayItinerary,
                            dayItineraryOrder: data?.dayItineraryOrder
                        }));

                        if (data?.dayItinerary) {
                            const filteredItinerary: any[] = [];
                            const navItems: any[] = [];
                            let currentGroup: any[] = [];
                            let startDayCount = 1;
                            let currentLabel = `Day ${startDayCount}`;
                            let eventKeyCounter = 1; // Counter for eventKey

                            // Iterate over the dayItineraryOrder to maintain order
                            for (let i = 0; i < data.dayItineraryOrder.length; i++) {
                                const dayId = data.dayItineraryOrder[i];
                                const day = data.dayItinerary.find((d: any) => d.id === dayId);
                                if (!day) continue;

                                if (day.is_same_as_above === 0) {
                                    // Push the current group with a label if it exists
                                    if (currentGroup.length > 0) {
                                        filteredItinerary.push(currentGroup[0]);
                                        navItems.push({
                                            label: currentLabel,
                                            eventKey: `day-${eventKeyCounter}`, // Use counter for eventKey
                                            icon: 'tag',
                                        });
                                        eventKeyCounter++; // Increment eventKey counter
                                    }

                                    // Reset the current group and label
                                    currentGroup = [day];
                                    startDayCount = i + 1; // Update start day
                                    currentLabel = `Day ${startDayCount}`;
                                } else {
                                    // Continue the current group
                                    currentGroup.push(day);
                                    currentLabel = `Day ${startDayCount}-${i + 1}`; // Update label with range
                                }
                            }

                            // Add the last group
                            if (currentGroup.length > 0) {
                                filteredItinerary.push(currentGroup[0]);
                                navItems.push({
                                    label: currentLabel,
                                    eventKey: `day-${eventKeyCounter}`, // Use counter for eventKey
                                    icon: 'tag',
                                });
                            }

                            // Update the state with the filtered itinerary and nav items
                            setTripData((prev: any) => ({
                                ...prev,
                                dayItinerary: filteredItinerary,
                                dayItineraryOrder: filteredItinerary.map((day: any) => day.id),
                            }));

                            setNavItems(navItems);
                        }
                    }
                }
            } catch (error: any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        if (slug) {
            fetchTripData();
        }

        // Cleanup function
        return () => {
            isMounted = false;  // Mark component as unmounted
        };
    }, [slug, signOut, showErrorToast, currency]);




    const getCategoryName = (code: string): string => {
        const categoryNames: { [key: string]: string } = {
            "ACTI": "Activities",
            "ACCO": "Accommodation",
            "FLIG": "Flight",
            "GROU": "Ground Transportation",
            "INFO": "Information"
        };

        return categoryNames[code] || "";
    };

    const handleEnquireNow = () => {
        setShowEnquireNow(true)
    }


    return (
        <div className="trip-details-form">
            {loading ?
                <DummyTripView />
                :

                <>
                    {tripData?.trip_uuid ?
                        <>
                            <section id="hero-faq" className="d-flex flex-column justify-content-center"
                                style={{ background: `url(${tripData?.banner_image_preview ? tripData?.banner_image_preview : DummyBanner})` }}>
                            </section>
                            <Container className="cont">
                                <Row className="pb-5 ">
                                    <Col xl={12} className="px-sm-3 px-md-3 px-lg-4 px-xl-5 px-xxl-5" style={{ background: "#f4f4f4" }}>
                                        <Row>
                                            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
                                                <div className="d-flex flex-row align-items-center mb-3 mt-3">
                                                    <div>
                                                        <Avatar src={tripData?.agent?.profileImage?.url ?? avtar1} size="2xl" />
                                                    </div>
                                                    <div className="d-flex flex-column gap-1 ps-3 ">
                                                        <p className="created-by m-0">Created by</p>
                                                        {/* <p className="creator-title m-0">{tripData?.agent?.name}</p> */}
                                                        <Link
                                                            state={{ tripsAll: tripData, source: "creator" }}
                                                            to={{
                                                                pathname: `/agents/${tripData?.agent?.slug}`,
                                                            }}
                                                            className=" d-flex  align-items-center"
                                                        >
                                                            {tripData?.agent?.name}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6} lg={6} xl={6} xxl={6} className="mb-3 mb-sm-3 mb-md-0 mb-lg-0 mb-xl-0 mb-xxl-0  d-flex align-items-center justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end ">
                                                <Dropdown className="custom-share-link">
                                                    <Dropdown.Toggle variant="primary" id="share-dropdown">
                                                        Share
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item className={`item-option-dp ${handleDpSelect === 1 ? 'selected' : ''}`} onClick={() => handleEmailClick(1)}>Email</Dropdown.Item>
                                                        <Dropdown.Item className={`item-option-dp ${handleDpSelect === 2 ? 'selected' : ''}`} onClick={() => handleCopyLinkClick(2)}>Copy Link</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </Col>
                                            <Col sm={12}>
                                                <hr className="hr-line px-3 m-0 " />
                                            </Col>


                                        </Row>

                                        <Row>
                                            <Col sm={12} md={12} lg={8} xl={9} xxl={9} className="pb-3 d-flex align-items-end">
                                                <div className="trip-title-big high-light-black fw-bold pt-3">{tripData?.title}</div>
                                            </Col>
                                            <Col sm={12} md={12} lg={4} xl={3} xxl={3} className="mb-3 mb-sm-3 mb-md-0 mb-lg-0 mb-xl-0 mb-xxl-0 mt-0 pb-sm-2 pb-md-2 pb-lg-0 pb-xl-0 pb-xxl-0 d-flex align-items-center justify-content-sm-start justify-content-md-start justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                                                <div className="custom-btn-size big-font">
                                                    <Button disabled={loading} variant="primary" className="btn btn-primary" onClick={handleEnquireNow}>
                                                        Enquire Now
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="col-lg-3">
                                                <p className="price-about">From:<span className="price"> {
                                                    currencySymbols(tripData?.priceExchange?.currency || '')}{' '}
                                                    {new Intl.NumberFormat().format(Number(tripData?.priceExchange?.price))}   /</span>(Per Person)
                                                </p>
                                            </div>
                                            <div className="col-lg-3">
                                                <p>Number of Days: <span className="price">{tripData?.no_of_days} Days</span></p>
                                            </div>
                                            <div className="col-lg-3">
                                                <p>Start Location: <span className="price">{tripData?.start_location_option?.label}</span></p>
                                            </div>
                                            <div className="col-lg-3 d-flex justify-content-lg-end justify-content-sm-start">
                                                <p>End Location: <span className="price">{tripData?.end_location_option?.label}</span></p>
                                            </div>
                                        </Row>
                                        {tripData?.description && <Row className="">
                                            <div className="col-lg-12">
                                                <div className="pera2 breif-discription">{tripData?.description ? parse(tripData.description || '') : ''}</div>
                                            </div>
                                        </Row>
                                        }
                                        {(tripData?.details || (tripData?.selectedCategories?.length ?? 0) > 0) && <hr className="hr-line" />}
                                        {tripData?.details && <Row className="">
                                            <div className="col-lg-12">
                                                <div className="pera2 pt-0 breif-discription">{tripData?.details ? parse(tripData.details || '') : ''}</div>
                                            </div>
                                        </Row>
                                        }
                                        <Row>
                                            {((tripData?.selectedCategories?.length ?? 0) > 0) && <div className="col-md-12 col-lg-12 col-xl-12">
                                                <div className="trip-cate">
                                                    <p className="cateogries-trip">Trip Category</p>
                                                </div>
                                                <div className="categories-container">
                                                    {
                                                        tripData?.selectedCategories?.map((categories: any, index: any) => (
                                                            <div
                                                                key={categories.id}
                                                                className={`categories-trip  selected`}
                                                                onClick={() => { navigate(`/top-categories/${categories?.code}/${categories?.slug}`) }}
                                                            >
                                                                <div className='categories-image'>
                                                                    {categories.iconImage && typeof categories.iconImage === 'object' ? (
                                                                        <>
                                                                            <img src={categories?.iconImage?.url} alt={categories.iconImage.alt || 'Category Icon'} className="categories-icon" />
                                                                        </>

                                                                    ) : (
                                                                        <img src="default-image-url" alt="Default Icon" className="categories-icon" />
                                                                    )}
                                                                </div>
                                                                <span>{categories.name}</span>

                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            }

                                            {((tripData?.trip_availabilities?.length ?? 0) > 0) && <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                                <div className="trip-cate">
                                                    <p className="cateogries-trip">Availability:</p>
                                                </div>
                                                <div className="months-container">
                                                    {tripData?.trip_availabilities?.length === 12 ?
                                                        <div
                                                            key={"all-year-around"}
                                                            className={`months-trip selected`}
                                                        >
                                                            <span>{"All Year Around"}</span>
                                                        </div>
                                                        :
                                                        <>
                                                            {tripData?.trip_availabilities?.map((months: any, index: any) => (
                                                                <div
                                                                    key={months.month_no}
                                                                    className={`months-trip selected`}
                                                                >
                                                                    <span>{months.full_name}</span>
                                                                </div>
                                                            ))}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            }
                                            {((tripData?.tags?.length ?? 0) > 0) && <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                                <div className="">
                                                    <p className="cateogries-trip">Tags:</p>
                                                </div>
                                                <div className="tags-container-view">


                                                    {tripData?.tags?.map((tag, index) => (
                                                        <Link
                                                            state={{ tagId: tag?.id, source: "creator" }}
                                                            to={{
                                                                pathname: `/tags/${tag?.slug}`,
                                                            }}
                                                            className=" d-flex  align-items-center tags-trip-link"
                                                            key={index}
                                                        >
                                                            <div
                                                                key={tag?.name}
                                                                className={`tags-trip selected`}
                                                            >
                                                                <span>{tag?.name}</span>
                                                            </div>
                                                        </Link>
                                                    ))}


                                                </div>
                                            </div>
                                            }
                                        </Row>


                                        {(tripData?.accomodation_status || tripData?.accomodation_note || tripData?.meals_status
                                            || tripData?.meals_note || tripData?.flight_status || tripData?.flight_note
                                            || tripData?.ground_transport_status || tripData?.ground_transport_note || tripData?.included
                                            || tripData?.optional || tripData?.excluded
                                        ) &&
                                            <>
                                                <hr className="hr-line" />
                                                <Row>
                                                    <div className="col-lg-12">
                                                        <div className="trip-cate">
                                                            <p className="cateogries-trip">Inclusive/Exclusive</p>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </>
                                        }

                                        {(tripData?.accomodation_status || tripData?.accomodation_note || tripData?.meals_status
                                            || tripData?.meals_note || tripData?.flight_status || tripData?.flight_note)
                                            && <Row>
                                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-row gap-2 sm-mt-2">
                                                    {tripData?.accomodation_status && <div className="d-flex flex-column justify-content-center">
                                                        {ieoStatus(tripData?.accomodation_status || "")}
                                                    </div>
                                                    }
                                                    {(tripData?.accomodation_note || tripData?.accomodation_status) && <div className="d-flex flex-column">
                                                        <div className="ie-heading py-1">{"Accommodation"}</div>
                                                        <div className="ie-details">{tripData?.accomodation_note || ''}</div>
                                                    </div>
                                                    }
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2 ">
                                                    {tripData?.meals_status && <div className="d-flex flex-column justify-content-center">
                                                        {ieoStatus(tripData?.meals_status || "")}
                                                    </div>
                                                    }
                                                    {(tripData?.meals_note || tripData?.meals_status) && <div className="d-flex flex-column">
                                                        <div className="ie-heading py-1">{"Meals"}</div>
                                                        <div className="ie-details">{tripData?.meals_note || ''}</div>
                                                    </div>
                                                    }
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">
                                                    {tripData?.flight_status && <div className="d-flex flex-column justify-content-center">
                                                        {ieoStatus(tripData?.flight_status || "")}
                                                    </div>
                                                    }
                                                    {(tripData?.flight_note || tripData?.flight_status) && <div className="d-flex flex-column">
                                                        <div className="ie-heading py-1">{"Flights"}</div>
                                                        <div className="ie-details">{tripData?.flight_note || ''}</div>
                                                    </div>
                                                    }
                                                </div>
                                            </Row>
                                        }
                                        {(tripData?.ground_transport_status || tripData?.ground_transport_note) && <Row className="py-3">
                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-row gap-2">
                                                {tripData?.ground_transport_status && <div className="d-flex flex-column justify-content-center">
                                                    {ieoStatus(tripData?.ground_transport_status || "")}
                                                </div>
                                                }
                                                {(tripData?.ground_transport_note || tripData?.ground_transport_status) && <div className="d-flex flex-column">
                                                    <div className="ie-heading py-1">{"Ground Transport"}</div>
                                                    <div className="ie-details">{tripData?.ground_transport_note || ''}</div>
                                                </div>
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">

                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">

                                            </div>
                                        </Row>
                                        }

                                        <Row className="pt-0 pb-2">
                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-row gap-2">
                                                {tripData?.included && <div className="d-flex flex-column">
                                                    <div className="ie-heading py-1">{"Included"}</div>
                                                    <div className="ie-details">{tripData?.included || ''}</div>
                                                </div>
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">
                                                {tripData?.optional && <div className="d-flex flex-column">
                                                    <div className="ie-heading py-1">{"Optional"}</div>
                                                    <div className="ie-details">{tripData?.optional || ''}</div>
                                                </div>
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">
                                                {tripData?.excluded && <div className="d-flex flex-column">
                                                    <div className="ie-heading py-1">{"Optional"}</div>
                                                    <div className="ie-details">{tripData?.excluded || ''}</div>
                                                </div>
                                                }
                                            </div>
                                        </Row>
                                        <Row className="flex-center mt-3">
                                            <Col xs={12} xl={12} className="pb-4">
                                                <div className="itin-policy">
                                                    <Tab.Container
                                                        id="controlled-tab-example"
                                                        defaultActiveKey="itinerary"
                                                    >
                                                        <Nav variant="underline" className="mb-3  horizontal-tab">
                                                            <Nav.Item>
                                                                <div className="custom-tab-item">
                                                                    <Nav.Link eventKey="itinerary" className="custom-tab-nav-item">Itinerary</Nav.Link>
                                                                </div>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <div className="custom-tab-item">
                                                                    <Nav.Link eventKey="policy" className="custom-tab-nav-item">Policy</Nav.Link>
                                                                </div>
                                                            </Nav.Item>
                                                        </Nav>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="itinerary">
                                                                <Tab.Container id="left-tabs-example" defaultActiveKey={`day-1`}>
                                                                    <Row className="g-0   border-300">
                                                                        <Col xs={12} sm={2} >
                                                                            <Nav className="day-tab-container flex-sm-column border-bottom-sm-0  border-300 fs-9 vertical-tab h-100 gap-2 tab-horizontal-sm">
                                                                                <div className="dayInfo">Day Plan</div>
                                                                                {((navItems?.length ?? 0) > 0) && navItems?.map((item, index) => {

                                                                                    return (
                                                                                        <NavItem
                                                                                            key={item.label}
                                                                                            item={item}
                                                                                            isLast={index === navItems.length - 1}
                                                                                            windowWidth={windowWidth}
                                                                                        />
                                                                                    )
                                                                                })}
                                                                            </Nav>
                                                                        </Col>

                                                                        <Col xs={12} sm={10}>
                                                                            <Tab.Content className="py-0 ps-sm-4 h-100">


                                                                                <>
                                                                                    {tripData?.dayItinerary?.map((dayData: any, index: number) => {
                                                                                        return (
                                                                                            <Tab.Pane eventKey={`day-${(index + 1)}`} key={index + dayData.day}>
                                                                                                {dayData?.title && <Row>
                                                                                                    <div className="col-lg-12"> <h5>{dayData?.title} </h5></div>
                                                                                                </Row>
                                                                                                }
                                                                                                {dayData?.info && <Row>
                                                                                                    <div className="col-lg-12"> <p>{parse(dayData?.info || '')} </p></div>
                                                                                                </Row>
                                                                                                }
                                                                                                <Row>
                                                                                                    {(dayData?.startLocation?.name || dayData?.selectedStartLoc?.label) && <div className="col-sm-12 col-md-4 col-lg-3"> <p className="mb-0">Start Location: </p>
                                                                                                        <p className="dayInfo">{dayData?.startLocation?.name}</p>
                                                                                                    </div>
                                                                                                    }
                                                                                                    {(dayData?.endLocation?.name || dayData?.selectedEndLoc?.label) && <div className="col-sm-12 col-md-4 col-lg-3"> <p className="mb-0">End Location: </p>
                                                                                                        <p className="dayInfo">{dayData?.endLocation?.name}</p>
                                                                                                    </div>
                                                                                                    }
                                                                                                    {dayData?.hotel_name && <div className="col-sm-12 col-md-4 col-lg-3"> <p className="mb-0">Hotel: </p>
                                                                                                        <span className="dayInfo">{dayData?.hotel_name}</span>
                                                                                                    </div>
                                                                                                    }
                                                                                                    {((dayData?.meals?.length ?? 0) > 0) && <div className="col-sm-12 col-md-4 col-lg-3"><p className="mb-0"> Meals: </p>
                                                                                                        <p className="dayInfo">
                                                                                                            {dayData?.meals?.map((mealItem: any, index: any) => (
                                                                                                                <span key={index}>
                                                                                                                    {mealItem?.name}
                                                                                                                    {index !== dayData?.meals?.length - 1 && ", "}
                                                                                                                </span>
                                                                                                            ))}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    }
                                                                                                </Row>
                                                                                                <Row>
                                                                                                    <div className="col-lg-12 mb-5">
                                                                                                        {((dayData?.listingImages?.length ?? 0) > 0) && <div className='listin-image-main-container border-top'>
                                                                                                            {dayData?.listingImages?.map((imageItem: any, index: number) => {
                                                                                                                return (
                                                                                                                    <div key={index} className="listing-image-item">
                                                                                                                        <div className="text-decoration-none d-flex listing-image-container">
                                                                                                                            <div className="rounded-3 flex-column me-2 position-relative text-500 border-500 banner-image-container">
                                                                                                                                <img src={imageItem?.url} alt={imageItem?.alt} className='listing-image-preview' />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })}
                                                                                                        </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </Row>
                                                                                                {dayData?.plan?.map((activity: any, activityIndex: number) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {activity?.trip_category_code && <Row className=''>
                                                                                                                <div className='col-md-12'>
                                                                                                                    <div className='border-top  '></div>
                                                                                                                </div>
                                                                                                                <div className='col-md-12   mb-3 d-flex justify-content-between align-items-center'>
                                                                                                                    <div>
                                                                                                                        {getCategoryName(activity?.trip_category_code)}
                                                                                                                    </div>
                                                                                                                    <div>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </Row>
                                                                                                            }
                                                                                                            <Row>
                                                                                                                {activity?.plan?.title && <div className='col-md-12 mb-3'>
                                                                                                                    <h5>Activity Title </h5>
                                                                                                                    <p> {activity?.plan?.title}</p>

                                                                                                                </div>
                                                                                                                }
                                                                                                                {activity?.plan?.note && <div className='col-md-12 mb-3'>
                                                                                                                    <h5>Note </h5>
                                                                                                                    <div>
                                                                                                                        {parse(activity?.plan?.note || '')}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                }
                                                                                                            </Row>
                                                                                                            <Row>
                                                                                                                {activity?.plan?.startLocation?.name && <div className='col-md-4 mb-3'>
                                                                                                                    <h5>Start Location</h5>
                                                                                                                    <div>{activity?.plan?.startLocation?.name}</div>
                                                                                                                </div>
                                                                                                                }
                                                                                                                {activity?.plan?.endLocation?.name && <div className='col-md-4 mb-3'>
                                                                                                                    <h5>End Location</h5>
                                                                                                                    <div>{activity?.plan?.endLocation?.name}</div>
                                                                                                                </div>
                                                                                                                }
                                                                                                                {(activity?.plan?.is_optional === 0 || activity?.plan?.is_optional === 1) && <div className='col-md-4 mb-3'>
                                                                                                                    <h5>Is Optional</h5>
                                                                                                                    <div>{Number(activity?.plan?.is_optional) === 1 ? "Included" : "Optional"}</div>
                                                                                                                </div>
                                                                                                                }
                                                                                                            </Row>
                                                                                                            {((activity?.plan?.listingImages?.length ?? 0) > 0) && <Row>
                                                                                                                <div className='col-md-12 mb-3'>
                                                                                                                    <h5>Images</h5>
                                                                                                                    <div className='listin-image-main-container'>
                                                                                                                        {activity?.plan?.listingImages?.map((imageItem: any, index: any) => {

                                                                                                                            return (
                                                                                                                                <div key={index} className="listing-image-item">
                                                                                                                                    <div className="text-decoration-none d-flex listing-image-container">
                                                                                                                                        <div className="rounded-3 flex-column me-2 position-relative text-500 border-500 banner-image-container">
                                                                                                                                            <img src={imageItem?.url} alt="banner image" className='listing-image-preview' />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </Row>
                                                                                                            }
                                                                                                            {activity?.trip_category_code === "ACTI" &&
                                                                                                                <>
                                                                                                                    {Array.isArray(activity?.plan?.tag_id) && <Row>
                                                                                                                        <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Type</h5>
                                                                                                                            <div>{activity?.plan?.tag_id[0]?.label}</div>
                                                                                                                        </div>
                                                                                                                        <div className='col-md-6 mb-3'>

                                                                                                                        </div>
                                                                                                                    </Row>
                                                                                                                    }
                                                                                                                    {activity?.plan?.tag?.name && <Row>
                                                                                                                        <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Type</h5>
                                                                                                                            <div>{activity?.plan?.tag?.name}</div>
                                                                                                                        </div>
                                                                                                                        <div className='col-md-6 mb-3'>

                                                                                                                        </div>
                                                                                                                    </Row>
                                                                                                                    }
                                                                                                                    <Row>
                                                                                                                        {activity?.plan?.start_at && <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Start at</h5>
                                                                                                                            <div>{activity?.plan?.start_at && convertTo12HourFormat(activity?.plan?.start_at)}</div>
                                                                                                                        </div>
                                                                                                                        }
                                                                                                                        {activity?.plan?.duration && <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Duration</h5>
                                                                                                                            <div>{activity?.plan?.duration} {activity?.plan?.duration_type}</div>
                                                                                                                        </div>
                                                                                                                        }
                                                                                                                    </Row>
                                                                                                                </>
                                                                                                            }

                                                                                                            {activity?.trip_category_code === "ACCO" &&
                                                                                                                <>
                                                                                                                    {activity?.plan?.activityCategory?.name && <Row>
                                                                                                                        <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Type</h5>
                                                                                                                            <div>{activity?.plan?.activityCategory?.name}</div>
                                                                                                                        </div>
                                                                                                                        <div className='col-md-6 mb-3'>

                                                                                                                        </div>
                                                                                                                    </Row>
                                                                                                                    }
                                                                                                                    <Row>
                                                                                                                        {activity?.plan?.check_in_time && <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Check-in Time</h5>
                                                                                                                            <div>{activity?.plan?.check_in_time && convertTo12HourFormat(activity?.plan?.check_in_time)}</div>
                                                                                                                        </div>
                                                                                                                        }
                                                                                                                        {activity?.plan?.check_out_time && <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Check-out Time</h5>
                                                                                                                            <div>{activity?.plan?.check_out_time && convertTo12HourFormat(activity?.plan?.check_out_time)}</div>
                                                                                                                        </div>
                                                                                                                        }
                                                                                                                    </Row>
                                                                                                                </>
                                                                                                            }
                                                                                                            {activity?.trip_category_code === "FLIG" &&
                                                                                                                <>
                                                                                                                    <Row>
                                                                                                                        {activity?.plan?.activityCategory?.name && <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Type</h5>
                                                                                                                            <div>{activity?.plan?.activityCategory?.name}</div>
                                                                                                                        </div>
                                                                                                                        }
                                                                                                                        {activity?.plan?.airline && <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Airline</h5>
                                                                                                                            <div>{activity?.plan?.airline}</div>
                                                                                                                        </div>
                                                                                                                        }
                                                                                                                    </Row>

                                                                                                                </>
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                })}


                                                                                            </Tab.Pane>
                                                                                        )
                                                                                    })}

                                                                                </>



                                                                            </Tab.Content>
                                                                        </Col>
                                                                    </Row>
                                                                </Tab.Container>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="policy">
                                                                <Accordion className="" defaultActiveKey="1">
                                                                    {tripData?.privacy_policy && <Accordion.Item
                                                                        className={''}
                                                                        eventKey={String(1)}
                                                                        key={1}
                                                                    >
                                                                        <Accordion.Button className="fs-7">
                                                                            <div className="seo-ie-additional">
                                                                                {"Privacy Policy"}
                                                                            </div>
                                                                        </Accordion.Button>
                                                                        <Accordion.Body className="pt-0">
                                                                            <Row>
                                                                                <div className="col-lg-12 breif-discription">
                                                                                    {parse(tripData?.privacy_policy || '')}
                                                                                </div>
                                                                            </Row>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                    }
                                                                    {tripData?.term_and_conditions && <Accordion.Item
                                                                        className={''}
                                                                        eventKey={String(2)}
                                                                        key={2}
                                                                    >
                                                                        <Accordion.Button className="fs-7">
                                                                            <div className="seo-ie-additional">
                                                                                {"Terms & Condition"}
                                                                            </div>
                                                                        </Accordion.Button>
                                                                        <Accordion.Body className="pt-0">
                                                                            <Row>
                                                                                <div className="col-lg-12 breif-discription">
                                                                                    {parse(tripData?.term_and_conditions || '')}
                                                                                </div>
                                                                            </Row>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                    }
                                                                    {tripData?.change_policy && <Accordion.Item
                                                                        className={''}
                                                                        eventKey={String(3)}
                                                                        key={3}
                                                                    >
                                                                        <Accordion.Button className="fs-7">
                                                                            <div className="seo-ie-additional">
                                                                                {"Change Policy"}
                                                                            </div>
                                                                        </Accordion.Button>
                                                                        <Accordion.Body className="pt-0">
                                                                            <Row>
                                                                                <div className="col-lg-12 breif-discription">
                                                                                    {parse(tripData?.change_policy || '')}
                                                                                </div>
                                                                            </Row>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                    }
                                                                    {tripData?.must_carry && <Accordion.Item
                                                                        className={''}
                                                                        eventKey={String(4)}
                                                                        key={4}
                                                                    >
                                                                        <Accordion.Button className="fs-7">
                                                                            <div className="seo-ie-additional">
                                                                                {"Other Information"}
                                                                            </div>
                                                                        </Accordion.Button>
                                                                        <Accordion.Body className="pt-0">
                                                                            <Row>
                                                                                <div className="col-lg-12 breif-discription">
                                                                                    {parse(tripData?.must_carry || '')}
                                                                                </div>
                                                                            </Row>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                    }
                                                                </Accordion>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Tab.Container>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>

                        </>
                        : <NotFound />
                    }
                </>

            }
            <EnquireNow showErrorToast={showErrorToast} showSuccessToast={showSuccessToast} slug={slug!} />
        </div>

    )
}

export default TripsViewTraveller