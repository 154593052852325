import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface Props {
  togglePasswordVisibility: () => void;
  showPassword: boolean;
}

export  const EyeIcon: React.FC<Props> = ({ togglePasswordVisibility, showPassword }) => {
  return (
    <div className="eye-icon">
      <FontAwesomeIcon
        className="position-absolute top-50 translate-middle-y text-600 fs-9 "
        style={{ right: '10px', cursor: 'pointer' }}
        onClick={togglePasswordVisibility}
        icon={showPassword ? faEyeSlash : faEye}
      />
    </div>
  );
};


export  const EyeIcon2: React.FC<Props> = ({ togglePasswordVisibility, showPassword }) => {
    return (
      <div className="eye-icon2">
        <FontAwesomeIcon
          className="position-absolute top-50 translate-middle-y text-600 fs-9 "
          style={{ right: '10px', cursor: 'pointer' }}
          onClick={togglePasswordVisibility}
          icon={showPassword ? faEyeSlash : faEye}
        />
      </div>
    );
  };
  