
import { useEffect, useState } from "react";
import apiCall from "services/api";
import parse from 'html-react-parser';
import { Spinner } from 'react-bootstrap';
import Testimonial from 'components/asseenin/Testimonial';
import { initialStaticPages, StaticPagesProps } from "types/interfaces";
import { useMeta } from "providers/MetaContext";
import { useAuth } from "providers/AuthContext";
import { getHeaders } from "services/getHeaders";
import BannerImage from "assets/img/travel/banner/privacy.jpg";


const CopyrightPrivacyPolicy = () => {
  const { currency } = useMeta();
  const { signOut, userTkn } = useAuth()
  const [copyRightData, setCopyRightData] = useState<StaticPagesProps>(initialStaticPages);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiCall({
          url: `cms/copyright-policy`,
          method: 'GET',
          headers: getHeaders(userTkn, currency)
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            let copyRightData = response?.data?.StaticPage?.data
            setCopyRightData(copyRightData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      {loading && ( // Full page loading overlay
        <div className="loading-overlay">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="listing-spinner"
          />
        </div>
      )}
       <section id="hero-faq" className="d-flex flex-column justify-content-center"  style={{ background: `url(${copyRightData?.bannerImage?.url ?? BannerImage})` }}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="hero-heading fw-bold high-light-white text-center">  {copyRightData?.meta_title} </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-0">
        <div className="container-fluid cont">
          <div className="row justify-content-center">
            <div className="col-xl-12 ">
              <div className="pt-2">
                <p className="main-heading  fw-bold high-light-black"> {copyRightData?.heading} </p>
                <p className="small-text">{copyRightData?.meta_description}</p>
              </div>
              <div className="pt-5">
                <p className="small-text"> {copyRightData?.description ? parse(copyRightData?.description || '') : ''} </p>
              </div>

            </div>
          </div>
        </div>
      </section>


      <Testimonial />
    </>
  )
}

export default CopyrightPrivacyPolicy;