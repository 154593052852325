import { useAuth } from "providers/AuthContext";
import { useToast } from "providers/ToastProvider";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiCall from "services/api";
import { handleApiError } from "services/handleApiError";
import { BlogProps, initialBlog } from "types/interfaces";
import BannerImage from "assets/img/travel/banner/search.jpg";
import thumb1 from "assets/img/travel/blogs/blog5.png";
import thumb2 from "assets/img/travel/blogs/blog3.png";
import thumb3 from "assets/img/travel/blogs/blog6.png";
import Unicon from "components/base/Unicon";
import { Card, Col, Row } from "react-bootstrap";
import DummyBlogDetails from "components/common/dummycard/DummyBlogDetails";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { formattedDate } from "./formattedDate";
import parse from "html-react-parser";
import DummyBlogCardSmall from "components/common/dummycard/DummyBlogCardSmall";

const CreatorBlogsView = () => {
  const navigate = useNavigate();
  const { signOut, userTkn } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [blogDetails, setBlogDetails] = useState<BlogProps>(initialBlog);
  const [blogList, setBlogList] = useState<BlogProps[]>([]);
  const { showErrorToast } = useToast();
  const { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    if (!slug) {
      showErrorToast("Error: No slug provided");
      navigate("/"); // Redirect to homepage or another page
    }
  }, [slug, navigate, showErrorToast]);

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    const fetchTripData = async () => {
      try {
        const headers = userTkn ? { "x-access-token": userTkn } : {};

        setLoading(true);
        const [blogsList] = await Promise.all([
          apiCall({
            url: `cms/creators-blog?page=1&pageSize=10`,
            method: "GET",
            headers,
          }),
        ]);

        if (blogsList?.status === 200) {
          const data = blogsList.data?.CreatorsBlogs?.data;
          setBlogList(data);
        }

        const blogsResponse = await apiCall({
          url: `cms/creators-blog/${slug}`,
          method: "GET",
          headers,
        });

        if (blogsResponse?.status === 200) {
          if (blogsResponse?.data?.errors) {
            const errors = blogsResponse?.data.errors;
            // Handle errors if needed
          } else {
            const data = blogsResponse?.data?.CreatorsBlogs?.data;
            const summary = blogsResponse?.data?.CreatorsBlogs?.summary;
            setBlogDetails(data);
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        if (isMounted) setLoading(false);
      }
    };
    fetchTripData();
  }, [slug, signOut, userTkn, showErrorToast]);
  return (
    <>
      <section
        id="hero-faq"
        className="d-flex flex-column justify-content-center"
        style={{ background: `url(${BannerImage})` }}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="hero-heading fw-bold high-light-white text-center">
                Blog Details{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5">
        <div className="container">
          <Row>
            {/* Left Column - Blog Content */}
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className="d-flex flex-column"
            >
              {loading && ( // Full page loading overlay
                <Row>
                  <DummyBlogDetails />
                </Row>
              )}
              {!loading && blogDetails && (
                <>
                  <Card.Title>
                    <p className="card-heading">{blogDetails?.title ?? ""}</p>
                  </Card.Title>
                  <div className="mb-2">{blogDetails?.sub_title ?? ""}</div>
                  <Card className="card-trip">
                    <Card.Img
                      className=""
                      variant="top"
                      src={blogDetails?.listingImage?.url ?? "thumb4"}
                      alt={blogDetails?.listingImage?.name ?? "image"}
                    />
                    <Card.Body>
                      <div className="d-flex blog-detail gap-4">
                        <div>
                          by{" "}
                          <span className="stick">
                            {blogDetails?.author ?? ""}
                          </span>
                        </div>

                        <div className="fs-9">
                          {formattedDate(blogDetails?.published_date_time)}
                        </div>
                      </div>
                      <hr />
                      <div className="mt-3 ">
                        <div>
                          {blogDetails?.description
                            ? parse(blogDetails?.description || "")
                            : ""}
                        </div>
                      </div>
                    </Card.Body>
                    {/* <Card.Footer className="footer-card">
                                                   <p className="f-name">Category name</p>
                                                 </Card.Footer> */}
                  </Card>
                </>
              )}
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              className="d-flex gap-4 flex-column"
            >
              <h3 className="post">Other Posts</h3>

              {loading && ( // Full page loading overlay
                <Row className="g-4 flex-column">
                  <DummyBlogCardSmall />
                  <DummyBlogCardSmall />
                  <DummyBlogCardSmall />
                </Row>
              )}
              {!loading && (
                <Row className="g-4 flex-column">
                  {Array.isArray(blogList) && blogList?.length > 0 ? (
                    <>
                      {blogList?.slice(0, 3)?.map((item, index) => {
                        return (
                          <Col lg={12}>
                            <Card
                              style={{ maxWidth: "32rem" }}
                              onClick={() => navigate(`/blogs/${item?.slug}`)}
                              className="blogs-card"
                            >
                              <Row className="g-0">
                                <Col md={4}>
                                  <img
                                    className="img-fluid h-100 rounded-start"
                                    src={item?.listingImage?.url}
                                    alt="..."
                                  />
                                </Col>
                                <Col md={8}>
                                  <Card.Body className="pt-3 pl-3 pr-3 pb-0">
                                    <Card.Title as="h4">
                                      {item?.category?.name}
                                    </Card.Title>
                                    <Card.Text>{item?.title ?? ""}</Card.Text>
                                    <Card.Text>
                                      <div className="d-flex flex-column align-items-left gap-2">
                                        <p className="simple m-0">
                                          by{" "}
                                          <span className="fw-bold">
                                            {item?.author}
                                          </span>
                                        </p>
                                        <p className="calendar m-0 pb-3">
                                          <Unicon
                                            icon={UilCalendarAlt}
                                            size={20}
                                            color="#5ca0e0"
                                          />
                                          <span className="ms-1">
                                            {formattedDate(
                                              item?.published_date_time
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        );
                      })}
                    </>
                  ) : (
                    <Col lg={12}>
                      <div className="">
                        {/* <h3>Sorry No Blog Found!!</h3> */}
                      </div>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default CreatorBlogsView;
