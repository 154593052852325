
import slide3 from 'assets/img/travel/crausal/vertical-crousal/pexels-ketut-subiyanto-4350202 1.png';
import slide4 from 'assets/img/travel/crausal/vertical-crousal/pexels-ketut-subiyanto-4350202-2.png';
import slide5 from 'assets/img/travel/crausal/vertical-crousal/Rectangle 27.png';

import thumb1 from 'assets/img/travel/crausal/vertical-crousal/Rectangle28.png';
import thumb2 from 'assets/img/travel/crausal/vertical-crousal/Rectangle29.png';
import thumb3 from 'assets/img/travel/crausal/vertical-crousal/Rectangle30.png';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './stylesHorizontal.css'
import Button from 'components/base/Button';
import { useNavigate } from 'react-router-dom';
import useWindowWidth from 'hooks/useWindowWidth';

interface OneSlideCrousalProps {
    title: string | "";
}



const OneSlideCrousal: React.FC<OneSlideCrousalProps> = ({title})=> {
    const windowWidth = useWindowWidth()
    const navigate = useNavigate();
    return (

        <>
            <Swiper
                // direction={'vertical'}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
                id="horizontal-swiper"
                loop={true}
                autoplay={{
                    delay: 2000000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false
                }}
            >
                <SwiperSlide>
                    <div className='row center-content'>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 ">
                            <div className="text-container custom-padding  m-0 mt-5 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                                <p className="main-heading fw-bold high-light-black m-0"> <span className="high-light">Find  </span>a trip you, love. </p>
                                <p className='slide-paragraph fw-normal light-black  mb-5'>
                                    With <span>1,024 creators</span> across the world {windowWidth > 768 && <br />}and over <span>9,000 trips</span> on our platform,{windowWidth > 768 && <br />} you’ll be sure to find a trip that meets {windowWidth > 768 && <br />}your personal needs.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 vrtical-swiper-img-container">
                            <img src={slide3} alt="image"  className='swiper-slide-img1'/>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='horizontal-second-slide'> 
                    <div className='row center-content '>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 ">
                            <div className="text-container custom-padding  m-0 mt-5 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                                <p className="main-heading fw-bold high-light-white m-0"> Message our creators  </p>
                                <p className="main-heading fw-bold high-light-white m-0">  to tailor your trip. </p>
                                <p className='slide-paragraph fw-normal  high-light-white'>
                                    Use our unique platform to message our {windowWidth > 1200 && <br />} creators and ask them to tailor your trips {windowWidth > 1200 && <br />} to meet your specific needs, wheither it’s {windowWidth > 768 && <br />} changing your travel dates, flights or {windowWidth > 768 && <br />} accommodation.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 vrtical-swiper-img-container-mobile ">
                            <img src={slide4} alt="image" className='swiper-slide-img2'/>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='row center-content'>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 ">
                            <div className="text-container custom-padding  m-0 mt-5 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                                <p className="main-heading fw-bold high-light-black m-0"> <span className="high-light">Receive  </span>your quote & book, love. </p>
                                <p className='slide-paragraph fw-normal light-black'>
                                    Once you have received your tailor-{windowWidth > 768 && <br />} made quote, simply <span>book your trip in{windowWidth > 768 && <br />}  seconds.</span> Our creators will take care of {windowWidth > 768 && <br />} everything else - from bookng yor trip {windowWidth > 768 && <br />} to sending you al the relevant{windowWidth > 768 && <br />}  documents before departure.
                                </p>
                                <div className="custom-btn-size d-flex justify-content-start py-4">
                                    <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={()=>(navigate("/trips-and-destination"))}>{title ??  "View All Trips"} </Button>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 vrtical-swiper-img-container-mobile">
                            <img src={slide5} alt="image" className='swiper-slide-img3'/>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='row center-content d-flex h-100 align-content-center vertical-crousal-last-slide'>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0  d-flex align-items-center">
                            <div className="text-container custom-padding ">
                                <p className="main-heading fw-bold high-light-black m-0">Make <span className="high-light"> memories  </span>for a lifetime, love.  </p>
                                <p className='slide-paragraph fw-normal light-black'>
                                    Disciver the places you’ve <span>always {windowWidth > 768 && <br />} wanted to see,</span> the foods you’ve always {windowWidth > 768 && <br />} wanted to try and meet new people to {windowWidth > 768 && <br />} build the most amazing memories.
                                </p>
                                <div className="custom-btn-size d-flex justify-content-start py-1 py-sm-1 py-md-3 py-lg-4 py-xl-4 py-xxl-4">
                                    <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={()=>(navigate("/trips-and-destination"))}> {title ??  "View All Trips"} </Button>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 vrtical-swiper-three-img-container">
                            <div className="three-image d-flex align-items-center justify-content-center">
                                <div className="left">
                                    <img className="image img-fluid img-grid1" src={thumb1} alt="Image 1" />
                                </div>
                                <div className="right d-flex flex-column justify-content-center">
                                    <img className="image img-fluid mb-2 img-grid2" src={thumb2} alt="Image 2" />
                                    <img className="image img-fluid img-grid3" src={thumb3} alt="Image 3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>

    )
}

export default OneSlideCrousal