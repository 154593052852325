import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import './DummyCard.css'; // Make sure to import the CSS file

const DummyCard = () => {
  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-2">
        <Card className="dummy-card card-trip">
          <div className="dummy-image-container">
            <div className="dummy-image-placeholder"></div>
          </div>
          <Card.Body>
            <Card.Title>
              <p className="dummy-text-placeholder">Loading...</p>
            </Card.Title>
            <div className="d-flex flex-row align-items-center mb-3">
              <div className="dummy-avatar-placeholder"></div>
              <div className="d-flex flex-column gap-1 ps-3">
                <p className="dummy-text-placeholder">Loading...</p>
                <p className="dummy-text-placeholder">Please wait</p>
              </div>
            </div>
            <div className="dummy-description-placeholder">
              <p className="dummy-text-placeholder">Loading description...</p>
            </div>
            <div className="dummy-price-placeholder">
              <p className="dummy-text-placeholder">Loading price...</p>
            </div>
            <div className="dummy-btn-placeholder d-flex justify-content-center py-2">
            <p className="dummy-text-placeholder">Loading ...</p>
            </div>
          </Card.Body>
        </Card>
    </div>
  );
};

export default DummyCard;
