import { createContext, useContext, ReactNode, useState } from "react";

type SignUpContextType = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialContextValue: SignUpContextType = {
    show: false,
    setShow: () => {}
};


const SignUpContext = createContext<SignUpContextType | undefined>(initialContextValue);


export function useSignUp() {
    const context = useContext(SignUpContext);
    if (!context) {
        throw new Error('useSignUp must be used within a SignUpProvider');
    }
    return context;
}

type SignUpProviderProps = {
    children: ReactNode;
};

export function SignUpProvider({ children }: SignUpProviderProps) {
    const [show, setShow] = useState(false);

    // Create the context value with the state value
    const contextValue: SignUpContextType = {
        show: show,
        setShow: setShow
    };

    return (
        <SignUpContext.Provider value={contextValue}>
            {children}
        </SignUpContext.Provider>
    );
}
