import { Link, useNavigate } from "react-router-dom";
import { Accordion, Button, Col, Container, DropdownButton, Nav, Row, Spinner, Tab } from "react-bootstrap";
import parse from 'html-react-parser';
import currencySymbols from "services/currencySymbols";
import ieoStatus, { convertTo12HourFormat } from "services/ieoStatus";
import useWindowWidth from 'hooks/useWindowWidth';
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useAuth } from "providers/AuthContext";
import { Dropdown } from "react-bootstrap";




type TripViewUpdateProps = {
    tripData: any | null;
    viewType: number;
    currentVersion: string | '';
    tripToggle: () => void;
    tripView:boolean;
    handleUpdateUrlClick: (version: string) => void;
    sendForApproval: (trip_uuid: string, version: string) => void
    copyTrip: (trip_uuid: string, version: string) => void;
    loading: boolean;
    source: string;
}
type NavItemType = {
    label: string;
    icon: string;
    eventKey: string;
};


const NavItem = ({ item, isLast, windowWidth }: { item: NavItemType; isLast?: boolean, windowWidth: number }) => {
    return (
        <div className='custom-tab-item'>
            <Nav.Link
                eventKey={item.eventKey}
                className={classNames(
                    'text-center text-sm-start cursor-pointer outline-none d-sm-flex align-items-sm-center custom-tab-nav-item',
                    {
                        ' border-end border-end-sm-0 border-300': !isLast
                    }
                )}
            >
                {windowWidth < 576 && <span className="d-sm-inline">{item.label}</span>}
                <span className="d-none d-sm-inline">{item.label}</span>
            </Nav.Link>
        </div>
    );
};



const TripViewUpdate: React.FC<TripViewUpdateProps> = ({ tripData, viewType, tripToggle, currentVersion, handleUpdateUrlClick, sendForApproval, copyTrip, loading, source, tripView }) => {


    const windowWidth = useWindowWidth();
    const [navItems, setNavItems] = useState<NavItemType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { userType } = useAuth();
    const [updatedTripData, setUpdatedTripData] = useState(tripData)


    useEffect(() => {
        setIsLoading(true);
        if (updatedTripData?.dayItinerary) {
            const filteredItinerary: any[] = [];
            const navItems: any[] = [];
            let currentGroup: any[] = [];
            let startDayCount = 1;
            let currentLabel = `Day ${startDayCount}`;
            let eventKeyCounter = 1; // Counter for eventKey
    
            // Iterate over the dayItineraryOrder to maintain order
            for (let i = 0; i < updatedTripData.dayItineraryOrder.length; i++) {
                const dayId = updatedTripData.dayItineraryOrder[i];
                const day = updatedTripData.dayItinerary.find((d: any) => d.id === dayId);
                if (!day) continue;
    
                if (day.is_same_as_above === 0) {
                    // Push the current group with a label if it exists
                    if (currentGroup.length > 0) {
                        filteredItinerary.push(currentGroup[0]);
                        navItems.push({
                            label: currentLabel,
                            eventKey: `day-${eventKeyCounter}`, // Use counter for eventKey
                            icon: 'tag',
                        });
                        eventKeyCounter++; // Increment eventKey counter
                    }
    
                    // Reset the current group and label
                    currentGroup = [day];
                    startDayCount = i + 1; // Update start day
                    currentLabel = `Day ${startDayCount}`;
                } else {
                    // Continue the current group
                    currentGroup.push(day);
                    currentLabel = `Day ${startDayCount}-${i + 1}`; // Update label with range
                }
            }
    
            // Add the last group
            if (currentGroup.length > 0) {
                filteredItinerary.push(currentGroup[0]);
                navItems.push({
                    label: currentLabel,
                    eventKey: `day-${eventKeyCounter}`, // Use counter for eventKey
                    icon: 'tag',
                });
            }
    
            // Update the state with the filtered itinerary and nav items
            setUpdatedTripData((prev: any) => ({
                ...prev,
                dayItinerary: filteredItinerary,
                dayItineraryOrder: filteredItinerary.map((day: any) => day.id),
            }));
    
            setNavItems(navItems);
        }
    
        setIsLoading(false);
    }, [, tripView]);
    

    
    

    const getCategoryName = (code: string): string => {
        const categoryNames: { [key: string]: string } = {
            "ACTI": "Activities",
            "ACCO": "Accommodation",
            "FLIG": "Flight",
            "GROU": "Ground Transportation",
            "INFO": "Information"
        };

        return categoryNames[code] || "";
    };
    const getActivityWithId = (activity: any) => {
        if (activity) {
            if (activity.ACTI?.id !== 0) {
                return activity.ACTI;
            } else if (activity.ACCO?.id !== 0) {
                return activity.ACCO;
            } else if (activity.FLIG?.id !== 0) {
                return activity.FLIG;
            } else if (activity.GROU?.id !== 0) {
                return activity.GROU;
            } else if (activity.INFO?.id !== 0) {
                return activity.INFO;
            }

        }
        return null; // Return null if no non-zero ID activity is found
    };


    const handleChange = (selectedVersion: string) => {
        handleUpdateUrlClick(selectedVersion);
    };

    return (
        <div className="trip-details-form">
            {isLoading ? ( // Full page loading overlay
                <div className="loading-overlay">
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="listing-spinner"
                    />
                </div>
            ) :
                <>
                    <section id="hero-faq" className="d-flex flex-column justify-content-center"
                        style={{ background: `url(${updatedTripData?.banner_image_preview})` }}>
                    </section>
                    <Container fluid className="cont">
                        <Row className="pb-5 pt-4">
                            <Col xl={9} className="px-5" style={{ background: "#f4f4f4" }}>
                                <Row>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3 d-flex align-items-end">
                                        <div className="trip-title-big high-light-black fw-bold pt-3">{updatedTripData?.title}</div>

                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3">
                                        <div className="d-flex flex-column flex-wrap flex-md-row gap-3 align-items-sm-start align-items-md-center justify-content-md-end">
                                            {
                                                (updatedTripData?.status === 1 && (userType === 3 || userType === 4)) &&
                                                <>
                                                    {viewType === 2 ?
                                                        <>
                                                            {loading ? (
                                                                <span className="disabled-link" style={{ display: 'block', color: 'gray' }}>
                                                                    Update
                                                                </span>
                                                            ) : (
                                                                <Link

                                                                    state={{ trips: updatedTripData }}
                                                                    to={{
                                                                        pathname: `/creator/trips/${updatedTripData?.trip_uuid}/${updatedTripData?.version}/update`,
                                                                    }}
                                                                    className="text-decoration-none"
                                                                    style={{ display: 'block' }} // Ensure the link behaves like a block element
                                                                >
                                                                    Update

                                                                </Link>
                                                            )}
                                                        </>
                                                        :
                                                        <Button disabled={loading} variant='link' onClick={tripToggle}> Edit </Button>
                                                    }
                                                </>
                                            }

                                            {
                                                updatedTripData?.status === 0 &&
                                                <>
                                                    {viewType === 2 ?
                                                        <>
                                                            {loading ? (
                                                                <span className="disabled-link" style={{ display: 'block', color: 'gray' }}>
                                                                    Update
                                                                </span>
                                                            ) : (
                                                                <Link

                                                                    state={{ trips: updatedTripData }}
                                                                    to={{
                                                                        pathname: `/creator/trips/${updatedTripData?.trip_uuid}/${updatedTripData?.version}/update`,
                                                                    }}
                                                                    className="text-decoration-none"
                                                                    style={{ display: 'block' }} // Ensure the link behaves like a block element
                                                                >
                                                                    Update

                                                                </Link>
                                                            )}
                                                        </>
                                                        :
                                                        <Button disabled={loading} variant='link' onClick={tripToggle}> Edit </Button>
                                                    }
                                                </>
                                            }
                                            {source == 'pna' ?
                                                <div className="custom-btn-size big-font d-flex justify-content-center">
                                                    <Button disabled={loading} variant="primary" className=" btn btn-primary" onClick={() => { }}>
                                                        {updatedTripData?.version}
                                                    </Button>
                                                </div>
                                                :
                                                <div className="custom-btn-size">
                                                    <DropdownButton
                                                        id="dropdown-basic-button"
                                                        title={currentVersion || "Select Version"}
                                                        disabled={loading}
                                                    >
                                                        {updatedTripData?.versions?.map((item: any, index: number) => (
                                                            <Dropdown.Item
                                                                key={index}
                                                                onClick={() => handleChange(item.version)}
                                                                active={currentVersion === item.version}
                                                            >
                                                                {`version: ${item.version} status: ${item?.tripStatus?.status?.name} Updated: ${item?.tripStatus?.trip_status_datetime}`}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </DropdownButton>
                                                </div>
                                            }
                                            {source !== 'pna' ? <div className="custom-btn-size big-font ">
                                                <Button disabled={loading} variant="primary" className=" btn btn-primary" onClick={() => { copyTrip(updatedTripData?.trip_uuid, updatedTripData?.version) }}>
                                                    Copy
                                                </Button>
                                            </div> : ""}
                                            <div className="custom-btn-size big-font">
                                                {updatedTripData?.status === 0 && <Button disabled={loading} variant="primary" className=" btn btn-primary send-for-approval" onClick={() => { sendForApproval(updatedTripData?.trip_uuid, updatedTripData?.version) }}>
                                                    Send For Approval
                                                </Button>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                    <div className="col-lg-3">
                                        <p className="price-about">From:<span className="price"> {currencySymbols(updatedTripData?.meta_currency_code || '')}{updatedTripData?.price}/</span>(Per Person)</p>
                                    </div>
                                    <div className="col-lg-3">
                                        <p>Number of Days: <span className="price">{updatedTripData?.no_of_days} Days</span></p>
                                    </div>
                                    <div className="col-lg-3">
                                        <p>Start Location: <span className="price">{updatedTripData?.start_location_option?.label}</span></p>
                                    </div>
                                    <div className="col-lg-3 d-flex justify-content-lg-end justify-content-sm-start">
                                        <p>End Location: <span className="price">{updatedTripData?.end_location_option?.label}</span></p>
                                    </div>
                                </Row>

                                <Row className="">
                                    <div className="col-lg-12">
                                        <div className="pera2 breif-discription">{updatedTripData?.description ? parse(updatedTripData.description || '') : ''}</div>
                                    </div>
                                </Row>
                                {(updatedTripData?.details || (updatedTripData?.selectedCategories?.length ?? 0) > 0) && <hr className="hr-line" />}
                                <Row className="">
                                    <div className="col-lg-12">
                                        <div className="pera2 pt-0 breif-discription">{updatedTripData?.details ? parse(updatedTripData.details || '') : ''}</div>
                                    </div>
                                </Row>

                                {((updatedTripData?.selectedCategories?.length ?? 0) > 0) && <Row>
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        <div className="trip-cate">
                                            <p className="cateogries-trip">Trip Category</p>
                                        </div>
                                        <div className="categories-container">
                                            {
                                                updatedTripData?.selectedCategories?.map((categories: any, index: any) => (
                                                    <div
                                                        key={categories.id}
                                                        className={`categories-trip  selected`}
                                                    >
                                                        <div className='categories-image'>
                                                            {categories.iconImage && typeof categories.iconImage === 'object' ? (
                                                                <>
                                                                    <img src={categories?.iconImage?.url} alt={categories.iconImage.alt || 'Category Icon'} className="categories-icon" />
                                                                </>

                                                            ) : (
                                                                <img src="default-image-url" alt="Default Icon" className="categories-icon" />
                                                            )}
                                                        </div>
                                                        <span>{categories.name}</span>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                        <div className="trip-cate">
                                            {((updatedTripData?.trip_availabilities?.length ?? 0) > 0) && <p className="cateogries-trip">Availability:</p>}
                                        </div>
                                        <div className="months-container">
                                            {updatedTripData?.trip_availabilities?.length === 12 ?
                                                <div
                                                    key={"all-year-around"}
                                                    className={`months-trip selected`}
                                                >
                                                    <span>{"All Year Around"}</span>
                                                </div>
                                                :
                                                <>
                                                    {updatedTripData?.trip_availabilities?.map((months: any, index: any) => (
                                                        <div
                                                            key={months.month_no}
                                                            className={`months-trip selected`}
                                                        >
                                                            <span>{months.full_name}</span>
                                                        </div>
                                                    ))}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Row>
                                }
                                {(updatedTripData?.accomodation_status || updatedTripData?.accomodation_note || updatedTripData?.meals_status
                                    || updatedTripData?.meals_note || updatedTripData?.flight_status || updatedTripData?.flight_note
                                    || updatedTripData?.ground_transport_status || updatedTripData?.ground_transport_note || updatedTripData?.included
                                    || updatedTripData?.optional || updatedTripData?.excluded
                                ) &&
                                    <>
                                        <hr className="hr-line" />
                                        <Row>
                                            <div className="col-lg-12">
                                                <div className="trip-cate">
                                                    <p className="cateogries-trip">Inclusive/Exclusive</p>
                                                </div>
                                            </div>
                                        </Row>
                                    </>
                                }
                                {(updatedTripData?.accomodation_status || updatedTripData?.accomodation_note || updatedTripData?.meals_status
                                    || updatedTripData?.meals_note || updatedTripData?.flight_status || updatedTripData?.flight_note)
                                    && <Row>
                                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-row gap-2 sm-mt-2">
                                            {updatedTripData?.accomodation_status && <div className="d-flex flex-column justify-content-center">
                                                {ieoStatus(updatedTripData?.accomodation_status || "")}
                                            </div>
                                            }
                                            {(updatedTripData?.accomodation_note || updatedTripData?.accomodation_status) && <div className="d-flex flex-column">
                                                <div className="ie-heading py-1">{"Accommodation"}</div>
                                                <div className="ie-details">{updatedTripData?.accomodation_note || ''}</div>
                                            </div>
                                            }
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2 ">
                                            {updatedTripData?.meals_status && <div className="d-flex flex-column justify-content-center">
                                                {ieoStatus(updatedTripData?.meals_status || "")}
                                            </div>
                                            }
                                            {(updatedTripData?.meals_note || updatedTripData?.meals_status) && <div className="d-flex flex-column">
                                                <div className="ie-heading py-1">{"Meals"}</div>
                                                <div className="ie-details">{updatedTripData?.meals_note || ''}</div>
                                            </div>
                                            }
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">
                                            {updatedTripData?.flight_status && <div className="d-flex flex-column justify-content-center">
                                                {ieoStatus(updatedTripData?.flight_status || "")}
                                            </div>
                                            }
                                            {(updatedTripData?.flight_note || updatedTripData?.flight_status) && <div className="d-flex flex-column">
                                                <div className="ie-heading py-1">{"Flights"}</div>
                                                <div className="ie-details">{updatedTripData?.flight_note || ''}</div>
                                            </div>
                                            }
                                        </div>
                                    </Row>
                                }
                                {(updatedTripData?.ground_transport_status || updatedTripData?.ground_transport_note) && <Row className="py-3">
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-row gap-2">
                                        {updatedTripData?.ground_transport_status && <div className="d-flex flex-column justify-content-center">
                                            {ieoStatus(updatedTripData?.ground_transport_status || "")}
                                        </div>
                                        }
                                        {(updatedTripData?.ground_transport_note || updatedTripData?.ground_transport_status) && <div className="d-flex flex-column">
                                            <div className="ie-heading py-1">{"Ground Transport"}</div>
                                            <div className="ie-details">{updatedTripData?.ground_transport_note || ''}</div>
                                        </div>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">

                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">

                                    </div>
                                </Row>
                                }
                                <Row className="pt-0 pb-2">
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-row gap-2">
                                        {updatedTripData?.included && <div className="d-flex flex-column">
                                            <div className="ie-heading py-1">{"Included"}</div>
                                            <div className="ie-details">{updatedTripData?.included || ''}</div>
                                        </div>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">
                                        {updatedTripData?.optional && <div className="d-flex flex-column">
                                            <div className="ie-heading py-1">{"Optional"}</div>
                                            <div className="ie-details">{updatedTripData?.optional || ''}</div>
                                        </div>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-flex flex-row gap-2">
                                        {updatedTripData?.excluded && <div className="d-flex flex-column">
                                            <div className="ie-heading py-1">{"Optional"}</div>
                                            <div className="ie-details">{updatedTripData?.excluded || ''}</div>
                                        </div>
                                        }
                                    </div>
                                </Row>

                                <Row className="flex-center mt-3">
                                    <Col xs={12} xl={12} className="pb-4">
                                        <div className="itin-policy">
                                            <Tab.Container
                                                id="controlled-tab-example"
                                                defaultActiveKey="itinerary"
                                            >
                                                <Nav variant="underline" className="mb-3  horizontal-tab">
                                                    <Nav.Item>
                                                        <div className="custom-tab-item">
                                                            <Nav.Link eventKey="itinerary" className="custom-tab-nav-item">Itinerary</Nav.Link>
                                                        </div>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <div className="custom-tab-item">
                                                            <Nav.Link eventKey="policy" className="custom-tab-nav-item">Policy</Nav.Link>
                                                        </div>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="itinerary">
                                                        <Tab.Container id="left-tabs-example" defaultActiveKey={`day-1`}>
                                                            <Row className="g-0   border-300">
                                                                <Col xs={12} sm={2} >
                                                                    <Nav className="day-tab-container flex-sm-column border-bottom-sm-0  border-300 fs-9 vertical-tab h-100 gap-2 tab-horizontal-sm">
                                                                        <div className="dayInfo">Day Plan</div>
                                                                        {((navItems?.length ?? 0) > 0) && navItems?.map((item, index) => {

                                                                            return (
                                                                                <NavItem
                                                                                    key={item.label}
                                                                                    item={item}
                                                                                    isLast={index === navItems.length - 1}
                                                                                    windowWidth={windowWidth}
                                                                                />
                                                                            )
                                                                        })}
                                                                    </Nav>
                                                                </Col>

                                                                <Col xs={12} sm={10}>
                                                                    <Tab.Content className="py-0 ps-sm-4 h-100">

                                                                        {viewType === 2 &&
                                                                            <>
                                                                                {updatedTripData?.dayItinerary?.map((dayData: any, index: number) => {
                                                                                 
                                                                                    return (
                                                                                        <Tab.Pane eventKey={`day-${(index + 1)}`} key={index}>
                                                                                            {dayData?.title && <Row>
                                                                                                <div className="col-lg-12"> <h5>{dayData?.title} </h5></div>
                                                                                            </Row>
                                                                                            }
                                                                                            {dayData?.info && <Row>
                                                                                                <div className="col-lg-12"> <p>{parse(dayData?.info || '')} </p></div>
                                                                                            </Row>
                                                                                            }
                                                                                            <Row>
                                                                                                {(dayData?.startLocation?.name || dayData?.selectedStartLoc?.label) && <div className="col-sm-12 col-md-4 col-lg-3"> <h5 className="mb-0">Start Location: </h5>
                                                                                                    <p className="dayInfo">{viewType === 2 ? dayData?.startLocation?.name : dayData?.selectedStartLoc?.label}</p>
                                                                                                </div>
                                                                                                }
                                                                                                {(dayData?.endLocation?.name || dayData?.selectedEndLoc?.label) && <div className="col-sm-12 col-md-4 col-lg-3"> <h5 className="mb-0">End Location: </h5>
                                                                                                    <p className="dayInfo">{viewType === 2 ? dayData?.endLocation?.name : dayData?.selectedEndLoc?.label}</p>
                                                                                                </div>
                                                                                                }
                                                                                                {dayData?.hotel_name && <div className="col-sm-12 col-md-4 col-lg-3"> <h5 className="mb-0">Hotel: </h5>
                                                                                                    <span className="dayInfo">{dayData?.hotel_name}</span>
                                                                                                </div>
                                                                                                }
                                                                                                {((dayData?.meals?.length ?? 0) > 0) && <div className="col-sm-12 col-md-4 col-lg-3"><h5 className="mb-0"> Meals: </h5>
                                                                                                    <p className="dayInfo">
                                                                                                        {dayData?.meals?.map((mealItem: any, index: any) => (
                                                                                                            <span key={index}>
                                                                                                                {mealItem?.name}
                                                                                                                {index !== dayData?.meals?.length - 1 && ", "}
                                                                                                            </span>
                                                                                                        ))}
                                                                                                    </p>
                                                                                                </div>
                                                                                                }
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <div className="col-lg-12 mb-5">
                                                                                                    {((dayData?.listingImages?.length ?? 0) > 0) && <div className='listin-image-main-container border-top'>
                                                                                                        {viewType === 2 ? <>
                                                                                                            {dayData?.listingImages?.map((imageItem: any, index: number) => {
                                                                                                                return (
                                                                                                                    <div key={index} className="listing-image-item">
                                                                                                                        <div className="text-decoration-none d-flex listing-image-container">
                                                                                                                            <div className="rounded-3 flex-column me-2 position-relative text-500 border-500 banner-image-container">
                                                                                                                                <img src={imageItem?.url} alt={imageItem?.alt} className='listing-image-preview' />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })}
                                                                                                        </> :
                                                                                                            <>
                                                                                                                {dayData?.listingImagesPreview?.map((imageItem: any, index: number) => {
                                                                                                                    return (
                                                                                                                        <div key={index} className="listing-image-item">
                                                                                                                            <div className="text-decoration-none d-flex listing-image-container">
                                                                                                                                <div className="rounded-3 flex-column me-2 position-relative text-500 border-500 banner-image-container">
                                                                                                                                    <img src={imageItem?.url} alt={imageItem?.alt} className='listing-image-preview' />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </>
                                                                                                        }

                                                                                                    </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Row>
                                                                                            {dayData?.plan?.map((activity: any, activityIndex: number) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {activity?.trip_category_code && <Row className=''>
                                                                                                            <div className='col-md-12'>
                                                                                                                <div className='border-top  '></div>
                                                                                                            </div>
                                                                                                            <div className='col-md-12   mb-3 d-flex justify-content-between align-items-center'>
                                                                                                                <div>
                                                                                                                    {getCategoryName(activity?.trip_category_code)}
                                                                                                                </div>
                                                                                                                <div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                        }
                                                                                                        <Row >
                                                                                                            {activity?.plan?.title && <div className='col-md-12 mb-3'>
                                                                                                                <h5>Activity Title </h5>
                                                                                                                <p> {activity?.plan?.title}</p>

                                                                                                            </div>
                                                                                                            }
                                                                                                            {activity?.plan?.note && <div className='col-md-12 mb-3'>
                                                                                                                <h5>Note </h5>
                                                                                                                <div>
                                                                                                                    {parse(activity?.plan?.note || '')}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            }
                                                                                                        </Row>
                                                                                                        <Row className="row-title">
                                                                                                            {activity?.plan?.startLocation?.name && <div className='col-md-4 mb-3'>
                                                                                                                <h5>Start Location</h5>
                                                                                                                <div>{activity?.plan?.startLocation?.name || ""}</div>
                                                                                                            </div>
                                                                                                            }
                                                                                                            {activity?.plan?.endLocation?.name && <div className='col-md-4 mb-3'>
                                                                                                                <h5>End Location</h5>
                                                                                                                <div>{activity?.plan?.endLocation?.name || ""}</div>
                                                                                                            </div>
                                                                                                            }
                                                                                                            {(activity?.plan?.is_optional === 0 || activity?.plan?.is_optional === 1) && <div className='col-md-4 mb-3'>
                                                                                                                <h5>Is Optional</h5>
                                                                                                                <div>{Number(activity?.plan?.is_optional) === 1 ? "Included" : "Optional"}</div>
                                                                                                            </div>
                                                                                                            }
                                                                                                        </Row>
                                                                                                        {((activity?.plan?.listingImages?.length ?? 0) > 0) && <Row>
                                                                                                            <div className='col-md-12 mb-3'>
                                                                                                                <h5>Images</h5>
                                                                                                                <div className='listin-image-main-container'>
                                                                                                                    {activity?.plan?.listingImages?.map((imageItem: any, index: any) => {

                                                                                                                        return (
                                                                                                                            <div key={index} className="listing-image-item">
                                                                                                                                <div className="text-decoration-none d-flex listing-image-container">
                                                                                                                                    <div className="rounded-3 flex-column me-2 position-relative text-500 border-500 banner-image-container">
                                                                                                                                        <img src={imageItem?.url} alt="banner image" className='listing-image-preview' />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    })
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                        }
                                                                                                        {activity?.trip_category_code === "ACTI" &&
                                                                                                            <>
                                                                                                                {Array.isArray(activity?.plan?.tag_id) && <Row>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Type</h5>
                                                                                                                        <div>{activity?.plan?.tag_id[0]?.label }</div>
                                                                                                                    </div>
                                                                                                                    <div className='col-md-6 mb-3'>

                                                                                                                    </div>
                                                                                                                </Row>
                                                                                                                }
                                                                                                                {activity?.plan?.tag?.name && <Row>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Type</h5>
                                                                                                                        <div>{activity?.plan?.tag?.name}</div>
                                                                                                                    </div>
                                                                                                                    <div className='col-md-6 mb-3'>

                                                                                                                    </div>
                                                                                                                </Row>
                                                                                                                }
                                                                                                                <Row>
                                                                                                                    {activity?.plan?.start_at && <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Start at</h5>
                                                                                                                        <div>{activity?.plan?.start_at && convertTo12HourFormat(activity?.plan?.start_at)}</div>
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                    {activity?.plan?.duration && <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Duration</h5>
                                                                                                                        <div>{activity?.plan?.duration} {activity?.plan?.duration_type}</div>
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                </Row>
                                                                                                            </>
                                                                                                        }

                                                                                                        {activity?.trip_category_code === "ACCO" &&
                                                                                                            <>
                                                                                                                {Array.isArray(activity?.plan?.tag_id) && <Row>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Type</h5>
                                                                                                                        <div>{activity?.plan?.tag_id[0]?.label || activity?.plan?.tag?.name}</div>
                                                                                                                    </div>
                                                                                                                    <div className='col-md-6 mb-3'>

                                                                                                                    </div>
                                                                                                                </Row>
                                                                                                                }
                                                                                                                {activity?.plan?.tag?.name && <Row>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Type</h5>
                                                                                                                        <div>{activity?.plan?.tag?.name}</div>
                                                                                                                    </div>
                                                                                                                    <div className='col-md-6 mb-3'>

                                                                                                                    </div>
                                                                                                                </Row>
                                                                                                                }
                                                                                                                <Row>
                                                                                                                    {activity?.plan?.check_in_time && <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Check-in Time</h5>
                                                                                                                        <div>{activity?.plan?.check_in_time && convertTo12HourFormat(activity?.plan?.check_in_time)}</div>
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                    {activity?.plan?.check_out_time && <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Check-out Time</h5>
                                                                                                                        <div>{activity?.plan?.check_out_time && convertTo12HourFormat(activity?.plan?.check_out_time)}</div>
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                </Row>
                                                                                                            </>
                                                                                                        }
                                                                                                        {activity?.trip_category_code === "FLIG" &&
                                                                                                            <>
                                                                                                                <Row>
                                                                                                                    {Array.isArray(activity?.plan?.tag_id) &&
                                                                                                                        <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Type</h5>
                                                                                                                            <div>{activity?.plan?.tag_id[0]?.label || activity?.plan?.tag?.name}</div>
                                                                                                                        </div>


                                                                                                                    }
                                                                                                                    {activity?.plan?.tag?.name &&
                                                                                                                        <div className='col-md-6 mb-3'>
                                                                                                                            <h5>Type</h5>
                                                                                                                            <div>{activity?.plan?.tag?.name}</div>
                                                                                                                        </div>

                                                                                                                    }
                                                                                                                    {activity?.plan?.airline && <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Airline</h5>
                                                                                                                        <div>{activity?.plan?.airline}</div>
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                </Row>

                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                                )
                                                                                            })}


                                                                                        </Tab.Pane>
                                                                                    )
                                                                                })}

                                                                            </>
                                                                        }
                                                                        {viewType === 1 &&
                                                                            <>
                                                                                {updatedTripData?.dayItinerary?.map((dayData: any, index: number) => {
                                                                             
                                                                                    return (
                                                                                        <Tab.Pane eventKey={`day-${(index + 1)}`} key={index}>
                                                                                            {dayData?.title && <Row>
                                                                                                <div className="col-lg-12"><h5>{dayData?.title} </h5></div>
                                                                                            </Row>
                                                                                            }
                                                                                            {dayData?.info && <Row>
                                                                                                <div className="col-lg-12"> <p>{parse(dayData?.info || '')} </p></div>
                                                                                            </Row>
                                                                                            }
                                                                                            <Row>
                                                                                                {dayData?.selectedStartLoc?.label && <div className="col-sm-12 col-md-4 col-lg-3"> <p className="mb-0">Start Location: </p>
                                                                                                    <p className="dayInfo">{dayData?.selectedStartLoc?.label}</p>
                                                                                                </div>
                                                                                                }
                                                                                                {dayData?.selectedEndLoc?.label && <div className="col-sm-12 col-md-4 col-lg-3"> <p className="mb-0">End Location: </p>
                                                                                                    <p className="dayInfo">{dayData?.selectedEndLoc?.label}</p></div>}
                                                                                                {dayData?.hotel_name && <div className="col-sm-12 col-md-4 col-lg-3"> <p className="mb-0">Hotel: </p>
                                                                                                    <span className="dayInfo">{dayData?.hotel_name}</span>
                                                                                                </div>
                                                                                                }
                                                                                                {((dayData?.meals?.length ?? 0) > 0) && <div className="col-sm-12 col-md-4 col-lg-3"><p className="mb-0"> Meals: </p>
                                                                                                    <p className="dayInfo">
                                                                                                        {dayData?.meals?.map((mealItem: any, index: any) => (
                                                                                                            <span key={index}>
                                                                                                                {mealItem?.name}
                                                                                                                {index !== dayData?.meals?.length - 1 && ", "}
                                                                                                            </span>
                                                                                                        ))}
                                                                                                    </p>
                                                                                                </div>
                                                                                                }
                                                                                            </Row>
                                                                                            {((dayData?.listingImagesPreview?.length ?? 0) > 0) && <Row>
                                                                                                <div className="col-lg-12 mb-5">
                                                                                                    <div className='listin-image-main-container border-top'>
                                                                                                        {dayData?.listingImagesPreview?.map((imageItem: any, index: number) => {
                                                                                                            return (
                                                                                                                <div key={index} className="listing-image-item">
                                                                                                                    <div className="text-decoration-none d-flex listing-image-container">
                                                                                                                        <div className="rounded-3 flex-column me-2 position-relative text-500 border-500 banner-image-container">
                                                                                                                            <img src={imageItem?.url} alt={imageItem?.alt} className='listing-image-preview' />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })}
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Row>
                                                                                            }
                                                                                            {dayData?.activity?.map((activity: any, activityIndex: number) => {
                                                                                                let displayActivity = getActivityWithId(activity);

                                                                                                return (
                                                                                                    <>
                                                                                                        {displayActivity?.trip_category_code && <Row className=''>
                                                                                                            <div className='col-md-12'>
                                                                                                                <div className='border-top  '></div>
                                                                                                            </div>
                                                                                                            <div className='col-md-12   mb-3 d-flex justify-content-between align-items-center'>
                                                                                                                <div>
                                                                                                                    {getCategoryName(displayActivity?.trip_category_code)}
                                                                                                                </div>
                                                                                                                <div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                        }
                                                                                                        <Row>
                                                                                                            {displayActivity?.title && <div className='col-md-12 mb-3'>
                                                                                                                <h5>Activity Title </h5>
                                                                                                                <p> {displayActivity?.title}</p>

                                                                                                            </div>
                                                                                                            }
                                                                                                            {displayActivity?.note && <div className='col-md-12 mb-3'>
                                                                                                                <h5>Note </h5>
                                                                                                                <div>
                                                                                                                    {parse(displayActivity?.note || '')}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            }
                                                                                                        </Row>
                                                                                                        <Row>
                                                                                                            {displayActivity?.startLocation?.name && <div className='col-md-4 mb-3'>
                                                                                                                <h5>Start Location</h5>
                                                                                                                <div>{displayActivity?.startLocation?.name}</div>
                                                                                                            </div>
                                                                                                            }
                                                                                                            {displayActivity?.endLocation?.name && <div className='col-md-4 mb-3'>
                                                                                                                <h5>End Location</h5>
                                                                                                                <div>{displayActivity?.endLocation?.name}</div>
                                                                                                            </div>
                                                                                                            }
                                                                                                            {displayActivity?.is_optional && <div className='col-md-4 mb-3'>
                                                                                                                <h5>Is Optional</h5>
                                                                                                                <div>{Number(displayActivity?.is_optional) === 1 ? "Included" : "Optional"}</div>
                                                                                                            </div>
                                                                                                            }
                                                                                                        </Row>
                                                                                                        {((displayActivity?.listingImagesPreview?.length ?? 0) > 0) && <Row>
                                                                                                            <div className='col-md-12 mb-3'>
                                                                                                                <h5>Images</h5>
                                                                                                                <div className='listin-image-main-container'>
                                                                                                                    {displayActivity?.listingImagesPreview?.map((imageItem: any, index: any) => {

                                                                                                                        return (
                                                                                                                            <div key={index} className="listing-image-item">
                                                                                                                                <div className="text-decoration-none d-flex listing-image-container">
                                                                                                                                    <div className="rounded-3 flex-column me-2 position-relative text-500 border-500 banner-image-container">
                                                                                                                                        <img src={imageItem?.url} alt="banner image" className='listing-image-preview' />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    })
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                        }
                                                                                                        {displayActivity?.trip_category_code === "ACTI" &&
                                                                                                            <>
                                                                                                                <Row>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Type</h5>
                                                                                                                        <div>{(Array.isArray(displayActivity?.plan?.tag_id) && displayActivity?.tag_id[0]?.label) || displayActivity?.tag?.name}</div>
                                                                                                                    </div>
                                                                                                                    <div className='col-md-6 mb-3'>

                                                                                                                    </div>
                                                                                                                </Row>
                                                                                                                <Row>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Start at</h5>
                                                                                                                        <div>{displayActivity?.start_at && convertTo12HourFormat(displayActivity?.start_at)}</div>
                                                                                                                    </div>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Duration</h5>
                                                                                                                        <div>{displayActivity?.duration} {displayActivity?.duration_type}</div>
                                                                                                                    </div>
                                                                                                                </Row>
                                                                                                            </>
                                                                                                        }

                                                                                                        {displayActivity?.trip_category_code === "ACCO" &&
                                                                                                            <>
                                                                                                                <Row>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Type</h5>
                                                                                                                        <div>{(Array.isArray(displayActivity?.plan?.tag_id) && displayActivity?.tag_id[0]?.label) || displayActivity?.tag?.name}</div>
                                                                                                                    </div>
                                                                                                                    <div className='col-md-6 mb-3'>

                                                                                                                    </div>
                                                                                                                </Row>
                                                                                                                <Row>
                                                                                                                    {displayActivity?.check_in_time && <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Check-in Time</h5>
                                                                                                                        <div>{displayActivity?.check_in_time && convertTo12HourFormat(displayActivity?.check_in_time)}</div>
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                    {displayActivity?.check_out_time && <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Check-out Time</h5>
                                                                                                                        <div>{displayActivity?.check_out_time && convertTo12HourFormat(displayActivity?.check_out_time)}</div>
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                </Row>
                                                                                                            </>
                                                                                                        }
                                                                                                        {displayActivity?.trip_category_code === "FLIG" &&
                                                                                                            <>
                                                                                                                <Row>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Type</h5>
                                                                                                                        <div>{(Array.isArray(displayActivity?.plan?.tag_id) && displayActivity?.tag_id[0]?.label) || displayActivity?.tag?.name}</div>
                                                                                                                    </div>
                                                                                                                    <div className='col-md-6 mb-3'>
                                                                                                                        <h5>Airline</h5>
                                                                                                                        <div>{displayActivity?.airline}</div>
                                                                                                                    </div>
                                                                                                                </Row>

                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                            }


                                                                                        </Tab.Pane>
                                                                                    )
                                                                                })}

                                                                            </>
                                                                        }

                                                                    </Tab.Content>
                                                                </Col>
                                                            </Row>
                                                        </Tab.Container>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="policy">
                                                        <Accordion className="" defaultActiveKey="1">
                                                            {updatedTripData?.privacy_policy && <Accordion.Item
                                                                className={''}
                                                                eventKey={String(1)}
                                                                key={1}
                                                            >
                                                                <Accordion.Button className="fs-7">
                                                                    <div className="seo-ie-additional">
                                                                        {"Privacy Policy"}
                                                                    </div>
                                                                </Accordion.Button>
                                                                <Accordion.Body className="pt-0">
                                                                    <Row>
                                                                        <div className="col-lg-12 breif-discription">
                                                                            {parse(updatedTripData?.privacy_policy || '')}
                                                                        </div>
                                                                    </Row>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            }
                                                            {updatedTripData?.term_and_conditions && <Accordion.Item
                                                                className={''}
                                                                eventKey={String(2)}
                                                                key={2}
                                                            >
                                                                <Accordion.Button className="fs-7">
                                                                    <div className="seo-ie-additional">
                                                                        {"Terms & Condition"}
                                                                    </div>
                                                                </Accordion.Button>
                                                                <Accordion.Body className="pt-0">
                                                                    <Row>
                                                                        <div className="col-lg-12 breif-discription">
                                                                            {parse(updatedTripData?.term_and_conditions || '')}
                                                                        </div>
                                                                    </Row>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            }
                                                            {updatedTripData?.change_policy && <Accordion.Item
                                                                className={''}
                                                                eventKey={String(3)}
                                                                key={3}
                                                            >
                                                                <Accordion.Button className="fs-7">
                                                                    <div className="seo-ie-additional">
                                                                        {"Change Policy"}
                                                                    </div>
                                                                </Accordion.Button>
                                                                <Accordion.Body className="pt-0">
                                                                    <Row>
                                                                        <div className="col-lg-12 breif-discription">
                                                                            {parse(updatedTripData?.change_policy || '')}
                                                                        </div>
                                                                    </Row>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            }
                                                            {updatedTripData?.must_carry && <Accordion.Item
                                                                className={''}
                                                                eventKey={String(4)}
                                                                key={4}
                                                            >
                                                                <Accordion.Button className="fs-7">
                                                                    <div className="seo-ie-additional">
                                                                        {"Other Information"}
                                                                    </div>
                                                                </Accordion.Button>
                                                                <Accordion.Body className="pt-0">
                                                                    <Row>
                                                                        <div className="col-lg-12 breif-discription">
                                                                            {parse(updatedTripData?.must_carry || '')}
                                                                        </div>
                                                                    </Row>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            }
                                                        </Accordion>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </Col>
                                </Row>



                            </Col>

                            <Col xl={3} className="px-xl-5 px-lg-0 px-md-0  mt-xxl-0 mt-xl-0 mt-lg-3 mt-md-3 mt-sm-3 mt-3"  >
                                {(updatedTripData?.pendingVersion || updatedTripData?.liveVersion) &&
                                    <div className="ml-5 p-5 mb-3 pb-3" style={{ background: "#f4f4f4" }}>
                                        {updatedTripData?.pendingVersion && <>
                                            <div className="main-trip ">
                                                <h6>Pending Version</h6>
                                            </div>
                                            <div className="projectes">
                                                <div className="short mb-1 d-flex align-items-center justify-content-between">
                                                    <p className="short-view">Version</p>
                                                    <p className="short-para">{updatedTripData?.pendingVersion?.version}</p>
                                                </div>
                                                <div className="short mb-1 d-flex align-items-center justify-content-between">
                                                    <p className="short-view">Status</p>
                                                    <p className="short-para">{updatedTripData?.pendingVersion?.tripStatus?.status?.name}</p>
                                                </div>
                                                <div className="short mb-1 d-flex align-items-center justify-content-between">
                                                    <p className="short-view">Updated</p>
                                                    <p className="short-para">{updatedTripData?.pendingVersion?.tripStatus?.trip_status_datetime}</p>
                                                </div>
                                            </div>
                                        </>
                                        }
                                        {updatedTripData?.liveVersion && <>
                                            <div className="main-trip ">
                                                <h6>Live Version</h6>
                                            </div>
                                            <div className="projectes">
                                                <div className="short mb-1 d-flex align-items-center justify-content-between">
                                                    <p className="short-view">Version</p>
                                                    <p className="short-para">{updatedTripData?.liveVersion?.version}</p>
                                                </div>
                                                <div className="short mb-1 d-flex align-items-center justify-content-between">
                                                    <p className="short-view">Status</p>
                                                    <p className="short-para">{updatedTripData?.liveVersion?.tripStatus?.status?.name}</p>
                                                </div>
                                                <div className="short mb-1 d-flex align-items-center justify-content-between">
                                                    <p className="short-view">Updated</p>
                                                    <p className="short-para">{updatedTripData?.liveVersion?.tripStatus?.trip_status_datetime}</p>
                                                </div>
                                            </div>
                                        </>
                                        }

                                    </div>
                                }
                                <div className="ml-5 p-5" style={{ background: "#f4f4f4" }}>
                                    <div className="main-trip ">
                                        <h6>Trip Analytics</h6>
                                    </div>

                                    <div className="projectes">
                                        <div className="short mb-1 d-flex align-items-center justify-content-between">
                                            <p className="short-view">View</p>
                                            <p className="short-para">0</p>
                                        </div>
                                        <div className="short mb-1 d-flex align-items-center justify-content-between">
                                            <p className="short-view">Leads</p>
                                            <p className="short-para">0</p>
                                        </div>
                                        <div className="short mb-1 d-flex align-items-center justify-content-between">
                                            <p className="short-view">Booking</p>
                                            <p className="short-para">0</p>
                                        </div>
                                        <div className="short mb-1 d-flex align-items-center justify-content-between">
                                            <p className="short-view">Share Cont</p>
                                            <p className="short-para">0</p>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row >
                    </Container >
                </>

            }
        </div >
    );
}

export default TripViewUpdate;
