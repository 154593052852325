import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./DummyCard.css"; // Ensure you create and import this CSS for dummy styles

const DummyBlogCardSmall = () => {
  return (
    <Col lg={12}>
      <Card style={{ maxWidth: "32rem", maxHeight:"180px" }}>
        <Row className="g-0">
          <Col md={4}>
            <div className="dummy-blog-small-placeholder"></div>
          </Col>
          <Col md={8}>
            <Card.Body className="pt-3 pl-3 pr-3 pb-0">
              <Card.Title as="h4"><p className="dummy-blog-text-placeholder"></p></Card.Title>
              <Card.Text><p className="dummy-blog-text-placeholder"></p></Card.Text>
              <Card.Text>
                <div className="d-flex flex-column align-items-left gap-2">
                  <p className="simple m-0">
                    <span className="fw-bold"><p className="dummy-blog-text-placeholder"></p></span>
                  </p>
                  <div className="calendar m-0 pb-3">
                  <p className="dummy-blog-text-placeholder"></p>
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default DummyBlogCardSmall;
