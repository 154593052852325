import { Button, Card, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "providers/AuthContext";
import apiCall from 'services/api';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import listingDummyImg from "assets/img/travel/creator/dummylisting.png";
import { useMaster } from "providers/MasterContext";
import currencySymbols from "services/currencySymbols";
import { useToast } from "providers/ToastProvider";
import { useMeta } from "providers/MetaContext";
import { MultiValue, ActionMeta } from 'react-select';
import CustomSelectFilter from "components/select/CustomSelectFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import MySelectComponent from "./CustomMultiValueLabel";
import { faDashcube, faUsps } from "@fortawesome/free-brands-svg-icons";
import { FilterStatus, initialSummary, SummaryProps } from "types/interfaces";
import { handleApiError } from "services/handleApiError";


type OptionType = { value: string; label: string };
const Listing = () => {
  const navigate = useNavigate();
  const { months } = useMeta();
  const [monthsOptions, setMonthsOptions] = useState<OptionType[]>([]);
  const { showErrorToast } = useToast();
  const { userTkn, signOut, userAgentCode } = useAuth();
  const { locationsForDp } = useMaster()
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [tripsViewAll, setTripsViewAll] = useState<any[]>([]);
  const [trips, setTrips] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [viewAll, setViewAll] = useState<boolean>(false);
  const [location, setLocation] = useState<OptionType | null>(null);
  const [summary, setSummary] = useState<SummaryProps>(initialSummary);
  const [filters, setFilters] = useState<FilterStatus>({
    status: 0,
    title: '',
    month: [],
    location: 0,
    no_of_days: ""
  });


  const [filterStatus, setFilterStatus] = useState<boolean>(false)

  useEffect(() => {
    const monthsOp = (months || []).map((item: any) => ({
      value: String(item?.month_no),
      label: item?.full_name,
    }));
    setMonthsOptions(monthsOp);
  }, [months]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const url = viewAll
          ? `trips?page=${pageIndex}&pageSize=12&all-trip=1`
          : `trips?page=${pageIndex}&pageSize=12`;
        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
          },
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.TripVersion?.data;
            const summary = response?.data?.TripVersion?.summary;

            setSummary({
              total: summary.total,
              page: summary.page,
              pageSize: summary.pageSize,
              total_page: summary.total_page,
            });

            if (viewAll) {
              setTripsViewAll((prevTrips) => {
                // Create a map of existing trip IDs to their index in prevTrips
                const tripIndexMap = new Map(prevTrips.map((trip) => [trip.trip_uuid, trip]));

                // Update prevTrips with newData
                const updatedTrips = prevTrips.map((trip) => {
                  const newDataTrip = data.find((newTrip: any) => newTrip.trip_uuid === trip.trip_uuid);

                  if (newDataTrip && JSON.stringify(newDataTrip) !== JSON.stringify(trip)) {
                    return newDataTrip;
                  }
                  return trip;
                });

                // Append any new trips that are not already in prevTrips
                const newTrips = data.filter((newTrip: any) => !tripIndexMap.has(newTrip.trip_uuid));

                return [...updatedTrips, ...newTrips];
              });
            } else {
              setTrips((prevTrips) => {
                // Create a map of existing trip IDs to their index in prevTrips
                const tripIndexMap = new Map(prevTrips.map((trip) => [trip.trip_uuid, trip]));

                // Update prevTrips with newData
                const updatedTrips = prevTrips.map((trip) => {
                  const newDataTrip = data.find((newTrip: any) => newTrip.trip_uuid === trip.trip_uuid);

                  if (newDataTrip && JSON.stringify(newDataTrip) !== JSON.stringify(trip)) {
                    return newDataTrip;
                  }
                  return trip;
                });

                // Append any new trips that are not already in prevTrips
                const newTrips = data.filter((newTrip: any) => !tripIndexMap.has(newTrip.trip_uuid));

                return [...updatedTrips, ...newTrips];
              });
            }
            setLoading(false);
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
        setLoading(false);
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    };

    fetchData();
  }, [userTkn, pageIndex, viewAll]);

  useEffect(() => {

    const fetchData = async () => {
      if (!filterStatus) return; // Exit early if filterStatus is false
      if (pageIndex === 1) {
        setTrips([]);
        setTripsViewAll([])
      }

      try {
        setLoading(true);
        // Convert availability array to query string parameters
        const availabilityParams = filters?.month
          ? filters?.month.map((val, index) => `availability[${index}]=${val}`).join('&')
          : '';

        const baseUrl = 'trips';
        // const commonParams = `page=${pageIndex}&pageSize=12&title=${filters?.title}&status=${filters?.status}&location=${filters.location}&no_of_days=${filters.no_of_days}`;
        // Dynamically construct the common params
        const commonParamsArray: string[] = [`page=${pageIndex}`, `pageSize=12`];
        if (filters?.title) {
          commonParamsArray.push(`title=${filters.title}`);
        }

        if (filters?.status && filters.status !== 0) {
          commonParamsArray.push(`status=${filters.status}`);
        }

        if (filters?.location && filters.location !== 0) {
          commonParamsArray.push(`location=${filters.location}`);
        }

        if (filters?.no_of_days) {
          commonParamsArray.push(`no_of_days=${filters.no_of_days}`);
        }

        // Join the common params array into a single string
        const commonParams = commonParamsArray.join('&');
        const availabilityQuery = availabilityParams ? `&${availabilityParams}` : '';

        const url = viewAll
          ? `${baseUrl}?${commonParams}&all-trip=1${availabilityQuery}`
          : `${baseUrl}?${commonParams}${availabilityQuery}`;

        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
          },
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.TripVersion?.data;
            const summary = response?.data?.TripVersion?.summary;

            setSummary({
              total: summary.total,
              page: summary.page,
              pageSize: summary.pageSize,
              total_page: summary.total_page,
            });

            if (viewAll) {
              setTripsViewAll((prevTrips) => {
                // Create a map of existing trip IDs to their index in prevTrips
                const tripIndexMap = new Map(prevTrips.map((trip) => [trip.trip_uuid, trip]));

                // Update prevTrips with newData
                const updatedTrips = prevTrips.map((trip) => {
                  const newDataTrip = data.find((newTrip: any) => newTrip.trip_uuid === trip.trip_uuid);

                  if (newDataTrip && JSON.stringify(newDataTrip) !== JSON.stringify(trip)) {
                    return newDataTrip;
                  }
                  return trip;
                });

                // Append any new trips that are not already in prevTrips
                const newTrips = data.filter((newTrip: any) => !tripIndexMap.has(newTrip.trip_uuid));

                return [...updatedTrips, ...newTrips];
              });
            } else {
              setTrips((prevTrips) => {
                // Create a map of existing trip IDs to their index in prevTrips
                const tripIndexMap = new Map(prevTrips.map((trip) => [trip.trip_uuid, trip]));

                // Update prevTrips with newData
                const updatedTrips = prevTrips.map((trip) => {
                  const newDataTrip = data.find((newTrip: any) => newTrip.trip_uuid === trip.trip_uuid);

                  if (newDataTrip && JSON.stringify(newDataTrip) !== JSON.stringify(trip)) {
                    return newDataTrip;
                  }
                  return trip;
                });

                // Append any new trips that are not already in prevTrips
                const newTrips = data.filter((newTrip: any) => !tripIndexMap.has(newTrip.trip_uuid));

                return [...updatedTrips, ...newTrips];
              });
            }
            setLoading(false);
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
        setLoading(false);
      } finally {
        setLoading(false);
        setLoadingMore(false);
        setFilterStatus(false)
      }
    };

    fetchData();
  }, [userTkn, pageIndex, viewAll, filterStatus]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    if (pageIndex <= summary?.total_page) {
      // Set loading state for "Load More" button
      setPageIndex(prevPage => prevPage + 1);
    } else {
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);

    }
  };



  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;

    setFilters((prev) => ({
      ...prev,
      [name]: Number(value) // Assuming status is a number
    }));

  };

  const handleSelectChangeMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;

    setFilters((prev) => ({
      ...prev,
      [name]: Number(value) // Assuming status is a number
    }));

  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFilters((prev) => ({
      ...prev,
      [name]: String(value) // Assuming status is a number
    }));

  };


  // submit filters
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFilterStatus(true)

  };


  const handleLocation = (option: OptionType | null) => {
    setLocation(option);
    setFilters((prev) => ({
      ...prev,
      location: Number(option?.value || 0) // Assuming status is a number
    }));
  }

  const handleMonthChange = (selectedOptions: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {

  };

  const resetFilters = () => {
    setFilters({
      status: 0,
      title: '',
      month: [],
      location: 0,
      no_of_days: ""
    });
    setLocation(null)
    setFilterStatus(true)
  }



  return (
    <div>
      {loading && ( // Full page loading overlay
        <div className="loading-overlay">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="listing-spinner"
          />
        </div>
      )}
      <div className="pt-3 pb-3 trips-listing-heading" >
        <div className="container-fluid cardListing">
          {userAgentCode === "A" ?
            <div className="row mb-2">
              <div className="col-md-12 col-lg-7 col-xl-7 col-xxl-9">
                <p className="main-heading fw-bold high-light-black m-0">Search and <span className="high-light-red">Find</span>  the Trip Package you Took</p>


              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-1 mt-5 order-md-1 order-lg-1 order-xl-1 order-xxl-1 d-flex justify-content-start  justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                <Form.Group controlId={`all-listing`} className="mb-3">
                  <Form.Check
                    type='checkbox'
                    name="all_listing"
                    label={"View all"}
                    checked={viewAll} // Bind checked state to formData
                    onChange={(e) => { setViewAll(!viewAll) }}
                  />
                </Form.Group>
              </div>
              <div className="d-flex justify-content-end col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-2  pt-3  order-md-2 order-lg-2 order-xl-2 order-xxl-2">
                <div className="custom-btn-size ">
                  <Button variant="primary" className="flex-grow-1 flex-lg-grow-0 create-new-btn" onClick={() => { navigate("/creator/trips/create") }}> Create New</Button>
                </div>
              </div>

            </div>
            :
            <div className="row mb-2">
              <div className="col-lg-9 col-xl-10">
                
                <p className="main-heading fw-bold high-light-black m-0"><span className="high-light-red">Trip</span> Package you Took</p>
              </div>
              <div className="col-lg-3 col-xl-2 d-flex pt-3 justify-content-lg-end">
                <div className="custom-btn-size">
                  <Button variant="primary" className="flex-grow-1 flex-lg-grow-0 create-new-btn"
                    onClick={() => { navigate("/creator/trips/create", { state: { trips: '', source: "pna" } }) }}
                  >
                    Create New</Button>
                </div>
              </div>

            </div>
          }
        </div>
      </div >
      <div className="container-fluid cardListing">
        <Form className="trip-search-modal" onSubmit={handleSubmit}>
          <div className="filter-container ">
            <Row className="d-flex justify-content-center align-items-center">

              <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 ">
                <CustomSelectFilter
                  label={""}
                  controlId={"search"}
                  value={filters.location !== 0 ? location : { value: "", label: "" }}
                  onChange={handleLocation}
                  placeholder="Where ?"
                  errors={""}
                  location={location}
                  setLocation={setLocation}

                />
              </div>
              <div className=" col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 select-filters mt-3 mt-sm-3 mt-md-0  mt-lg-0  mt-xl-0 mt-xxl-0">

                {/* <Select
                  isMulti
                  options={monthsOptions}
                  onChange={handleMonthChange}
                /> */}
                <MySelectComponent setFilters={setFilters} months={filters?.month} />
                {/* <MultiSelectMonths setFilters={setFilters} /> */}
              </div>
              <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 trip-title-filter-container mt-3 mt-sm-3 mt-md-0  mt-lg-0  mt-xl-0 mt-xxl-0">
                <Form.Group controlId={"months-select"}>
                  <div className="form-icon-container">
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Trip Title"
                      value={filters?.title}
                      onChange={handleChange}
                      className="flex-grow-1 title-filter"
                      id="title-filter"
                    />
                    <div className="eye-icon2">
                      <FontAwesomeIcon
                        className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
                        style={{ left: '10px', cursor: 'pointer' }}
                        icon={faMagnifyingGlass}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 mt-3  mt-sm-3  mt-md-3 mt-lg-3 mt-xl-0 mt-xxl-0">
                <Form.Group controlId={"months-select"}>
                  <div className="form-icon-container">
                    <Form.Select
                      aria-label="Default select example"
                      value={filters?.status}
                      onChange={handleSelectChange}
                      name="status"
                      className="flex-grow-1"
                      id="select-status"
                    >
                      <option className="select-option-style" value="0">Draft</option>
                      <option className="select-option-style" value="1">Send for Approval</option>
                      <option className="select-option-style" value="2">Approved</option>
                      <option className="select-option-style" value="3">Rejected</option>
                      <option className="select-option-style" value="4">Terminated</option>
                      <option className="select-option-style" value="5">Cancel</option>
                    </Form.Select>
                    <div className="eye-icon2">
                      <FontAwesomeIcon
                        className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
                        style={{ left: '10px', cursor: 'pointer' }}
                        icon={faUsps}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className=" col-sm-12 col-md-4 col-lg-4 col-xl-2 col-xxl-2 mt-3  mt-sm-3 mt-md-3 mt-lg-3  mt-xl-0 mt-xxl-0">
                <Form.Group controlId={"months-select"}>
                  <div className="form-icon-container">
                    <Form.Select
                      aria-label="Default select example"
                      value={filters?.no_of_days}
                      onChange={handleSelectChangeMonth}
                      name="no_of_days"
                      className="flex-grow-1"
                      id="select-days"
                    >
                      <option className="select-option-style" value="">Select Days</option>
                      <option className="select-option-style" value="1">1</option>
                      <option className="select-option-style" value="2">2</option>
                      <option className="select-option-style" value="3">3</option>
                      <option className="select-option-style" value="4">4</option>
                      <option className="select-option-style" value="5">5</option>
                      <option className="select-option-style" value="6">6</option>
                      <option className="select-option-style" value="7">7</option>
                      <option className="select-option-style" value="8">8</option>
                      <option className="select-option-style" value="9">9</option>
                      <option className="select-option-style" value="10">10</option>
                      <option className="select-option-style" value="11"> {`Greater than 10`}</option>
                    </Form.Select>
                    <div className="eye-icon2">
                      <FontAwesomeIcon
                        className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
                        style={{ left: '10px', cursor: 'pointer' }}
                        icon={faDashcube}
                      />
                    </div>
                  </div>
                </Form.Group>
                {/* <Form.Group controlId="no_of_days">
                  <div className="input-group">
                    <Button
                      variant="outline-secondary minus-icon"
                      onClick={handleDecrement}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="fs-12 "
                      />
                    </Button>
                    <Form.Control
                      type="number"
                      name="no_of_days"
                      value={filters?.no_of_days}
                      className="text-center no_of_days"
                      onChange={handleChange}
                      placeholder="Number of Days"
                    />
                    <Button
                      variant="outline-secondary plus-icon"
                      onClick={handleIncrement}
                    >
                      <FontAwesomeIcon
                        className="  fs-12 input-icon-color"
                        icon={faPlus}
                      />
                    </Button>
                  </div>
                </Form.Group> */}
              </div>
              <div className=" col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 custom-btn-small d-flex gap-2 mt-xs-3  mt-3 mt-md-3  mt-lg-3  mt-xl-3 mt-xxl-0  ">
                <Button type="submit" aria-label="Find" className="flex-grow-0 ">
                  Find
                </Button>
                <Button variant="outline-primary" onClick={resetFilters} className=""> Reset </Button>
              </div>

            </Row>







          </div>
        </Form>
      </div>


      {viewAll ?
        <section className="container-fluid cardListing pt-0">
          <div className='row'>
            {tripsViewAll?.map((item, index) => {
              const location = locationsForDp?.find((location: any) => Number(location?.value) === Number(item?.start_location))
              return (
                <div key={index} className='col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-2'>
                  <Card className='southAfricaCards mb-4'>
                    {item?.listingImages?.length === 0
                      ?
                      <div className='image-container'>
                        <Card.Img variant="top" className='cardImg customCardImg' src={item.listingImages[0]?.url || listingDummyImg} />
                      </div>
                      :
                      item?.listingImages?.length === 1
                        ?
                        <div className='image-container'>
                          <Card.Img variant="top" className='cardImg customCardImg' src={item.listingImages[0]?.url || listingDummyImg} />
                        </div>
                        :
                        <Swiper
                          // direction={'vertical'}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination, Autoplay]}
                          className="mySwiper"
                          id="trip-card-swiper"
                          loop={true}
                          autoplay={{
                            delay: 2000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false
                          }}
                        >
                          {item?.listingImages?.map((image: any, index: number) => (
                            <SwiperSlide>
                              <Card.Img variant="top"  className='cardImg customCardImg' src={image?.url} alt="image" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                    }
                    <Card.Body className='cardBody'>
                      <div className='cardTitleWrapper'>
                        <p className='cardTitle'>{item.title}</p>
                      </div>
                      <div className='cardText'>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Tour Length</div>
                          <div className="col lisitingRightSide">{item.no_of_days} Days</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Price</div>
                          <div className="col lisitingRightSide">{currencySymbols(item?.meta_currency_code || '')} {item.price}</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Destination</div>
                          <div className="col lisitingRightSide">{item?.endLocation && item?.endLocation?.name}</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Status</div>
                          <div className="col lisitingRightSide">{item?.tripStatus?.status?.name}</div>
                        </div>
                      </div>
                      <div className="custom-btn-size d-flex justify-content-center align-items-center">
                        <Link
                          state={{ trips: item, source: "creator" }}
                          to={{
                            pathname: `/creator/trips/${item?.trip_uuid}/${item?.version}/view`,
                          }}
                          className="btn btn-primary  w-100  d-flex justify-content-center align-items-center"
                        >
                          View
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                  {/* </Link> */}
                </div>
              )
            })}
          </div>
          {tripsViewAll?.length > 0 ?
            <>
              {((Number(pageIndex) === 1 && !loading) || Number(pageIndex) > 1) && <div className="custom-btn-size d-flex justify-content-center mt-3">
                {Number(summary?.page) === Number(summary?.total_page) ?
                  <div className="d-flex justify-content-center"></div> :
                  <button onClick={handleLoadMore} className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2">
                    {loadingMore ? <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                      : ''
                    }
                    <span className="ml-2">{"Load More"}</span>
                  </button>
                }

              </div>
              }
            </> :
            <>
              {!loading && <div className="d-flex justify-content-center"><h3>Oops No Record Found !!</h3></div>}
            </>
          }
        </section >
        : <section className="container-fluid cardListing pt-0">
          <div className='row'>
            {trips?.map((item, index) => {
              const location = locationsForDp?.find((location: any) => Number(location?.value) === Number(item?.start_location))
              return (
                <div key={index} className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-2'>
                  {/* <Link
                    state={{ trips: item }}
                    to={{
                      pathname: `/creator/trips/${item?.trip_uuid}/${item?.version}/view`,
                    }}
                    className="text-decoration-none"
                    style={{ display: 'block' }} // Ensure the link behaves like a block element
                  > */}
                  <Card className='southAfricaCards mb-4'>
                    {item?.listingImages?.length === 0
                      ?
                      <div className='image-container'>
                        <Card.Img variant="top" className='cardImg customCardImg' src={item.listingImages[0]?.url || listingDummyImg} />
                      </div>
                      :
                      item?.listingImages?.length === 1
                        ?
                        <div className='image-container'>
                          <Card.Img variant="top" className='cardImg customCardImg' src={item.listingImages[0]?.url || listingDummyImg} />
                        </div>
                        :
                        <Swiper
                          // direction={'vertical'}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination, Autoplay]}
                          className="mySwiper"
                          id="trip-card-swiper"
                          loop={true}
                          autoplay={{
                            delay: 2000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false
                          }}
                        >
     
                          {item?.listingImages?.map((image: any, index: number) => (
                            <SwiperSlide key={index}>
                              <Card.Img variant="top"  className='cardImg customCardImg' src={image?.url} alt="image" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                    }
                    <Card.Body className='cardBody'>
                      <p className='cardTitle'>{item.title}</p>
                      <div className='cardText'>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Tour Length</div>
                          <div className="col lisitingRightSide">{item.no_of_days} Days</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Price</div>
                          <div className="col lisitingRightSide">{currencySymbols(item?.meta_currency_code || '')} {item.price}</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Destination</div>
                          <div className="col lisitingRightSide">{item?.endLocation && item?.endLocation?.name}</div>
                        </div>
                        {/* <div className="row mb-2">
                            <div className="col lisitingLeftSide">Status</div>
                            <div className="col lisitingRightSide">{item?.tripStatus?.status?.name}</div>
                          </div> */}
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Live Version</div>
                          <div className="col lisitingRightSide">{item?.liveVersion?.version}</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Pending Version</div>
                          <div className="col lisitingRightSide">{item?.pendingVersion?.version}</div>
                        </div>
                      </div>

                      <div className="custom-btn-size d-flex justify-content-center align-items-center">
                        <Link
                          state={{ trips: item, source: "creator" }}
                          to={{
                            pathname: `/creator/trips/${item?.trip_uuid}/${item?.version}/view`,
                          }}
                          className="btn btn-primary w-100 d-flex justify-content-center align-items-center"
                        >
                          View
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                  {/* </Link> */}
                </div>
              )
            })}
          </div>
          {trips?.length > 0 ?
            <>
              {(!loading && Number(summary.total_page) > 1) && <div className="custom-btn-size d-flex justify-content-center mt-3">
                {Number(summary?.page) === Number(summary?.total_page) ?
                  <div className="d-flex justify-content-center"></div> :
                  <button onClick={handleLoadMore} className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2">
                    {loadingMore ? <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                      : ''
                    }
                    <span className="ml-2">{"Load More"}</span>
                  </button>
                }

              </div>
              }
            </> :
            <>
              {!loading && <div className="d-flex justify-content-center"><h3>Oops No Record Found !!</h3></div>}
            </>
          }
        </section >
      }
    </div >
  )
}

export default Listing;
