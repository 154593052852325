import { faDollarSign, faEuroSign, faSterlingSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const currencySymbols = (code: string) => {
    switch (code) {
        case 'AUD':
            return <>AUD</>
            break;
        case 'CAD':
            return <>CAD</>
            break;
        case 'EUR':
            return <FontAwesomeIcon icon={faEuroSign} color="#000000" className="fs-7" />
            break;
        case 'GBP':
            return <FontAwesomeIcon icon={faSterlingSign} color="#000000" className="fs-7" />
            break;
        case 'NZD':
            return <>NZD</>
            break;
        case 'USD':
            return <FontAwesomeIcon icon={faDollarSign} color="#000000" className="fs-7" />
            break;
        case 'ZAR':
            return <>R</>
            break;
        default:
            return ""

    }
}

export default currencySymbols

// const currencySymbols = (code: string) => {
//     switch (code) {
//         case 'AUD':
//             return <>A<FontAwesomeIcon icon={faDollarSign} color="#000000" className="fs-7" /></>
//             break;
//         case 'CAD':
//             return <>CA<FontAwesomeIcon icon={faDollarSign} color="#000000" className="fs-7" /></>
//             break;
//         case 'EUR':
//             return <FontAwesomeIcon icon={faEuroSign} color="#000000" className="fs-7" />
//             break;
//         case 'GBP':
//             return <FontAwesomeIcon icon={faSterlingSign} color="#000000" className="fs-7" />
//             break;
//         case 'NZD':
//             return <>CA<FontAwesomeIcon icon={faDollarSign} color="#000000" className="fs-7" /></>
//             break;
//         case 'USD':
//             return <FontAwesomeIcon icon={faDollarSign} color="#000000" className="fs-7" />
//             break;
//         case 'ZAR':
//             return <>R<FontAwesomeIcon icon={faDollarSign} color="#000000" className="fs-7" /></>
//             break;
//         default:
//             return ""

//     }
// }

// export default currencySymbols