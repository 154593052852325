import { createContext, useContext, ReactNode, useState } from "react";

type ToggleFormsState = {
    signIn: boolean;
    forgotPassword: boolean;
    resetPassword: boolean;
};


type SignInContextType = {
    showIn: boolean;
    setShowIn: React.Dispatch<React.SetStateAction<boolean>>;
    toggleForms: ToggleFormsState;
    setToggleForms: React.Dispatch<React.SetStateAction<ToggleFormsState>>;
    resetPassToken: string;
    setResetPassToken: React.Dispatch<React.SetStateAction<string>>;
};

const initialContextValue: SignInContextType = {
    showIn: false,
    setShowIn: () => {},
    toggleForms: { signIn: true, forgotPassword: false, resetPassword: false },
    setToggleForms: () => {},
    resetPassToken: "", // Initial value for resetPassToken
    setResetPassToken: () => {},
};


const SignInContext = createContext<SignInContextType | undefined>(initialContextValue);


export function useSignIn() {
    const context = useContext(SignInContext);
    if (!context) {
        throw new Error('useSignIn must be used within a SignInProvider');
    }
    return context;
}

type SignInProviderProps = {
    children: ReactNode;
};

export function SignInProvider({ children }: SignInProviderProps) {
    const [show, setShow] = useState(false);
    const [toggleForms, setToggleForms] = useState<ToggleFormsState>({
        signIn: true,
        forgotPassword: false,
        resetPassword: false
    });
    const [resetPassToken, setResetPassToken] = useState("")

    // Create the context value with the state value
    const contextValue: SignInContextType = {
        showIn: show,
        setShowIn: setShow,
        toggleForms: toggleForms,      // Provide the toggleForms state
        setToggleForms: setToggleForms, // Provide the setter for toggleForms
        resetPassToken: resetPassToken,       // Provide resetPassToken state
        setResetPassToken: setResetPassToken 
    };

    return (
        <SignInContext.Provider value={contextValue}>
            {children}
        </SignInContext.Provider>
    );
}
