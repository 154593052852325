import "../../assets/css/sidharth.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import southAfrica from "../../assets/img/travel/footer-destinations/southAfrica.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToast } from "providers/ToastProvider";
import { useEffect, useState } from "react";
import { useAuth } from "providers/AuthContext";
import apiCall from "services/api";
import { handleApiError } from "services/handleApiError";
import parse from "html-react-parser";
import {
  ChildItemAllBtnText,
  ChildItemTopDestinations,
  initialChildItemAllBtnText,
  initialChildTopDestinations,
  initialLocationItem,
  LocationItem,
} from "types/interfaces";
import TripCard from "components/cards/TripsCard";
import { getHeaders } from "services/getHeaders";
import { useMeta } from "providers/MetaContext";
import { markWishList } from "services/markWishList";
import { useSignIn } from "providers/SignInProvider";
import { Button } from "react-bootstrap";
import Testimonial from "components/asseenin/Testimonial";
import NotFound from "components/404/NotFound";
import DummyAgentCard from "components/common/dummycard/DummyAgentCard";

const TopDestination = () => {
  const navigate = useNavigate();
  const { signOut, userTkn } = useAuth();
  const { currency } = useMeta();
  const { setShowIn } = useSignIn();
  const { showToast, showSuccessToast, showInfoToast, showErrorToast } =
    useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<LocationItem>(initialLocationItem);
  const [trips, setTrips] = useState<any[]>([]);
  const [btnText, setBtnText] = useState<ChildItemAllBtnText>(
    initialChildItemAllBtnText
  );
  const [creatorContentItems, setCreatorContentItems] =
    useState<ChildItemTopDestinations>(initialChildTopDestinations);
  const { slug, id } = useParams<{ slug: string; id: string }>();
  if (!slug) {
    showErrorToast("Error: No slug provided");
    navigate("/"); // Redirect to homepage or another page
  }

  const handleMarkWishlist = async (tripId: string) => {
    await markWishList(
      tripId,
      userTkn,
      currency,
      setTrips,
      setShowIn,
      apiCall,
      showErrorToast
    );
  };

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    const fetchTripData = async () => {
      try {
        setLoading(true);
        const [
          contentResponse,
          btnTextResponse,
          locationDetails,
          locationWiseTrips,
        ] = await Promise.all([
          apiCall({
            url: `cms/content-items/top-destination`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `cms/content-items/all_btn_text`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `locations/${slug}`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `tours?location=${id}&page=1&pageSize=8`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
        ]);

        if (contentResponse?.status === 200) {
          let data = contentResponse?.data?.ContentItem?.data?.content?.childs;
          setCreatorContentItems(data);
        }
        if (btnTextResponse?.status === 200) {
          let data = btnTextResponse?.data?.ContentItem?.data?.content?.childs;
          setBtnText(data);
        }
        if (locationDetails.status === 200) {
          if (locationDetails?.data?.errors) {
            const errors = locationDetails?.data.errors;
            // Handle errors if needed
          } else {
            const data = locationDetails?.data?.MasterLocation?.data;
            setLocation(data);
          }
        }

        if (locationWiseTrips.status === 200) {
          if (locationWiseTrips?.data?.errors) {
            const errors = locationWiseTrips?.data.errors;
            // Handle errors if needed
          } else {
            const data = locationWiseTrips?.data?.TripVersion?.data;
            setTrips(data);
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        if (isMounted) setLoading(false);
      }
    };
    fetchTripData();
  }, [slug, id, navigate, showErrorToast, currency]);

  return (
    <>
      {loading && (
        <>
          <section id="hero-common" className="p-0 mb-5">
            <div
              className="dummy-text-placeholder"
              style={{ height: "450px" }}
            ></div>
            <div
              className="dummy-text-placeholder"
              style={{ height: "100px" }}
            ></div>
          </section>
          <section className="pb-0">
            <div className="container-fluid">
              <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3">
                <div className="col-xl-12">
                  <div
                    className="dummy-text-placeholder"
                    style={{ height: "250px" }}
                  ></div>
                </div>
                <div className="col-xl-8 ">
                  <div
                    className="dummy-text-placeholder"
                    style={{ height: "80px" }}
                  ></div>
                </div>
                <div className="col-xl-4 ">
                  <div
                    className="dummy-text-placeholder"
                    style={{ height: "80px" }}
                  ></div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {!loading && (
        <>
          {location?.id ? (
            <>
              <section
                id="hero-faq"
                className="d-flex flex-cloumn justify-content-center southAfricaHeadingImage"
                style={{
                  background: `url(${
                    location?.coverImage?.url ?? southAfrica
                  })`,
                }}
              >
                {/* <div className='container-fluid headingSouthAfrica'> */}
                {/* <img src={southAfrica} alt="southAfricaPoint" className="southAfricaheading"/> */}
                <div className="text-block">
                  <p className="main-heading  fw-bold high-light-black">
                    <span className="high-light-red">
                      {location?.name ?? ""}
                    </span>
                  </p>
                  {/* <p className='southAfricaHeadingSubText'>
                        Discover the world thanks to <br />
                        our tailor-made trips from our <br />
                        community of travel creators.
                    </p> */}
                  <p className="southAfricaHeadingSubText">
                    {location?.sub_heading ?? ""}
                  </p>
                </div>
                {/* </div> */}
              </section>

              <section style={{ paddingTop: "0px" }}>
                <div className="container-fluid ">
                  <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-5 mx-xxl-5">
                    <p className="brand-title ">
                      <span>
                        <FontAwesomeIcon icon={faLocationDot} />
                      </span>
                      {/* Africa &gt; Southern Africa */}
                      <span className="pl-2"> {location?.name ?? ""}</span>
                    </p>

                    {location?.description && (
                      <div className="brand-title southAfricaText">
                        {parse(location?.description ?? "")}
                      </div>
                    )}
                  </div>
                </div>
              </section>
              <section className="container-fluid py-3 ">
                <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-5 mx-xxl-5">
                  <div className="col-sm-6 col-lg-9 p-0">
                    <div className="main-heading  fw-bold high-light-black">
                      Travel <span className="high-light-red">Creators</span>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 p-0 d-flex justify-content-end">
                    <nav aria-label="...">
                      <ul className="pagination pagination-lg highlight travelCreatorsCardSSectionPagination">
                        <li className="paginationNo">
                          <a className="paginationNumbering" href="/">
                            1
                          </a>
                        </li>
                        <li className="paginationNo">
                          <a className="paginationNumbering" href="/">
                            2
                          </a>
                        </li>
                        <li className="paginationNo">
                          <a className="paginationNumbering" href="/">
                            3
                          </a>
                        </li>
                        <li className="paginationNo">
                          <a className="paginationNumbering" href="/">
                            4
                          </a>
                        </li>
                        <br />
                      </ul>
                    </nav>
                  </div>
                </div>

                <div className="row brand-title-container align-items-center lineView mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-5 mx-xxl-5">
                  <div className="col-md-9 col-lg-9 brand-title flex-grow-1 order-2 order-md-1">
                    <span className="travelCreatorsCardSSectionSubHeading">
                     {creatorContentItems?.description?.item_value ?? ""}
                    </span>
                  </div>
                  <div className="col-md-3 col-lg-3 d-flex justify-content-end flex-shrink-0 order-1 order-md-2">
                    <Link to="/trips-and-destination" className="viewAllTrips">
                      {btnText?.view_all_trip.item_value ?? "View All Trips"}
                    </Link>
                  </div>
                </div>
              </section>

              {!loading && (
                <>
                  {Array.isArray(trips) && trips.length > 0 && (
                    <section className="pb-0">
                      <div className="container-fluid">
                        <div className="row mx-3">
                          {Array.isArray(trips) && trips.length > 0 ? (
                            <>
                              {trips?.map((item, index) => (
                                <TripCard
                                  key={index}
                                  item={item}
                                  markWishList={handleMarkWishlist}
                                  title={btnText?.trip_card_btn?.item_value}
                                />
                              ))}
                            </>
                          ) : (
                            <div className="col-12  d-flex justify-content-center">
                              <h3>Sorry No Trip Found!!</h3>
                            </div>
                          )}
                        </div>
                      </div>
                    </section>
                  )}
                </>
              )}
              <section>
                <div className="custom-btn-size d-flex justify-content-center justify-content-lg-center justify-content-xl-center justify-content-xxl-center">
                  <div>
                    <Button
                      variant="primary"
                      className="flex-grow-1 flex-lg-grow-0"
                      onClick={() => {
                        navigate("/intrest-form");
                      }}
                    >
                      {btnText?.become_a_creators?.item_value ??
                        "Become a Creator"}
                    </Button>
                  </div>
                </div>
              </section>
              <Testimonial />
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

export default TopDestination;
