import React, { useState } from 'react';
import { Dropdown, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './styles.css'; // Import your custom CSS file
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FilterStatusMain } from 'types/interfaces';
import { useMeta } from 'providers/MetaContext';
import currencySymbols from 'services/currencySymbols';
import currencySymbolsPricefilter from 'services/currencySymbolsPricefilter';



type PriceRangeProps = {
    setFilters: React.Dispatch<React.SetStateAction<FilterStatusMain>>;
    filters: FilterStatusMain;
    value: [number, number];
    setValue: React.Dispatch<React.SetStateAction<[number, number]>>; 
    setFilterStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const PriceRange: React.FC<PriceRangeProps> = ({ setFilters, filters, value, setValue, setFilterStatus }) => {
    const {  currency } = useMeta();
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    

    const handleApply = () => {
        setIsDropdownOpen(false);

        // Update filters with the selected price range
        setFilters(prevFilters => ({
            ...prevFilters,
            min_price: value[0],
            max_price: value[1],
        }));
        // setFilterStatus(true)
    };


    const handleReset = () => {
        setValue([0, 5000])
    };

    return (
        <Dropdown autoClose="outside" className="h-100 custom-pricerange-dropdown"
            show={isDropdownOpen}
            onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
        >
            <Dropdown.Toggle
                className="custom-price-btn d-flex align-items-center"
                variant=""
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
               {currencySymbolsPricefilter(currency || '')} 
                Price
                <FontAwesomeIcon icon={faAngleDown} className='price-btn-icon' />
            </Dropdown.Toggle>

            <Dropdown.Menu
                align="end"
                className="navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-price shadow" 
            >
                <Card className="position-relative border-0">
                    <Card.Body className="p-0 pricerange-card">
                        <Slider
                            className='pricerange-rcslider'
                            range
                            // defaultValue={[0, 10, 30]}
                            // onChange={log}
                            min={0}
                            max={50000}
                            value={value}
                            onChange={(nextValue) => {

                                setValue(nextValue as any);
                            }}
                        />
                        <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 d-flex align-items-end">
                            <div className='range-values'><span>{currencySymbols(currency || '')} {value[0]}</span>-<span>{currencySymbols(currency || '')} {value[1]}</span></div>
                        </div>
                        <div className="d-flex justify-content-between gap-3 pricerange-btn-container">
                            <Button variant="outline-primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleApply}>Apply</Button>
                            <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleReset}>Reset</Button>
                        </div>
                    </Card.Body>
                </Card>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default PriceRange;
