import { Button, Card, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "providers/AuthContext";
import apiCall from 'services/api';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';

import listingDummyImg from "assets/img/travel/creator/dummylisting.png";
import { useMaster } from "providers/MasterContext";
import currencySymbols from "services/currencySymbols";
import { useTrips } from "providers/TripsProvider";
import { useToast } from "providers/ToastProvider";
import { handleApiError } from "services/handleApiError";
import { initialSummary, SummaryProps } from "types/interfaces";

const ListingPendingForApproval = () => {
  const navigate = useNavigate();
  const { showToast, showSuccessToast, showInfoToast, showErrorToast } = useToast();
  const { userTkn, signOut } = useAuth();
  const { tripCategories, locations, locationsForDp } = useMaster()
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const { trips, setTrips } = useTrips();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [summary, setSummary] = useState<SummaryProps>(initialSummary);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `pending-trips?page=${pageIndex}&pageSize=${'12'}`
        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn
          },
        });
        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.TripVersion?.data;
            let summary = response?.data?.TripVersion?.summary

            setSummary({
              "total": summary.total,
              "page": summary.page,
              "pageSize": summary.pageSize,
              "total_page": summary.total_page,
            });
            setTrips(prevTrips => {
              // Create a map of existing trip IDs to their index in prevTrips
              const tripIndexMap = new Map(prevTrips.map((trip, index) => [trip.trip_uuid, index]));

              // Update prevTrips with newData
              const updatedTrips = prevTrips.map(trip => {
                // Find the corresponding trip in data
                const newDataTrip = data.find((newTrip: any) => newTrip.trip_uuid === trip.trip_uuid);

                // If newDataTrip exists and is different from trip, return newDataTrip
                if (newDataTrip && JSON.stringify(newDataTrip) !== JSON.stringify(trip)) {
                  return newDataTrip;
                }

                // Otherwise, return the original trip
                return trip;
              });

              // Append any new trips that are not already in prevTrips
              const newTrips = data.filter((newTrip: any) => !tripIndexMap.has(newTrip.trip_uuid));

              // Combine updatedTrips with newTrips
              const mergedTrips = [...updatedTrips, ...newTrips];

              return mergedTrips;
            });
            setLoading(false);
          }
        }

      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
        setLoading(false);
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    };

    fetchData();
  }, [userTkn, pageIndex]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    if (pageIndex <= summary?.total_page) {
      // Set loading state for "Load More" button
      setPageIndex(prevPage => prevPage + 1);
    } else {
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);

    }
  };



  return (
    <div>
      {loading && ( // Full page loading overlay
        <div className="loading-overlay">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="listing-spinner"
          />
        </div>
      )}
      <div className="pt-3 pb-3 trips-listing-heading" >
        <div className="container-fluid cardListing">
          <div className="row mb-2">
            <div className="col-lg-9 col-xl-10">
              {/* <p className="main-heading fw-bold high-light-black m-0">Search and <span className="high-light-red">Find</span>  the Trip Package you Took</p> */}
              <p className="main-heading fw-bold high-light-black m-0">Trips <span className="high-light-red">Package</span></p>

            </div>
            <div className="col-lg-3 col-xl-2 d-flex pt-3 justify-content-lg-end">
              <div className="custom-btn-size">
                <Button variant="primary" className="flex-grow-1 flex-lg-grow-0 create-new-btn"
                  onClick={() => { navigate("/creator/trips/create", { state: { trips: '', source: "pna" } }) }}
                > Create New</Button>
              </div>
            </div>

          </div>
        </div>
      </div >


      <section className="container-fluid cardListing pt-0">
        <div className='row'>
          {trips?.map((item, index) => {
            const location = locationsForDp?.find((location: any) => Number(location?.value) === Number(item?.start_location))
            return (
              <div key={index} className='col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-2'>
                <Link
                  state={{ trips: item, source: "pna" }}
                  to={{
                    pathname: `/creator/trips/${item?.trip_uuid}/${item?.version}/view`,
                  }}
                  className="text-decoration-none"
                  style={{ display: 'block' }} // Ensure the link behaves like a block element
                >
                  <Card className='southAfricaCards mb-4'>
                    {item?.listingImages?.length === 0
                      ?
                      <div className='image-container'>
                        <Card.Img variant="top" className='cardImg' src={item.listingImages[0]?.url || listingDummyImg} />
                      </div>
                      :
                      item?.listingImages?.length === 1
                        ?
                        <div className='image-container'>
                          <Card.Img variant="top" className='cardImg' src={item.listingImages[0]?.url || listingDummyImg} />
                        </div>
                        :
                        <Swiper
                          // direction={'vertical'}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination, Autoplay]}
                          className="mySwiper"
                          id="trip-card-swiper"
                          loop={true}
                          autoplay={{
                            delay: 2000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false
                          }}
                        >

                          {item?.listingImages?.map((image, index) => (
                            <SwiperSlide>
                              <Card.Img variant="top" src={image?.url} alt="image" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                    }
                    <Card.Body className='cardBody'>
                      <p className='cardTitle'>{item.title}</p>
                      <div className='cardText'>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Tour Length</div>
                          <div className="col lisitingRightSide">{item.no_of_days} Days</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Price</div>
                          <div className="col lisitingRightSide">{currencySymbols(item?.meta_currency_code || '')} {item.price}</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Destination</div>
                          <div className="col lisitingRightSide">{item?.endLocation && item?.endLocation?.name}</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col lisitingLeftSide">Status</div>
                          <div className="col lisitingRightSide">{item?.tripStatus?.status?.name}</div>
                        </div>
                      </div>
                      <button className='cardButton'>View</button>

                    </Card.Body>
                  </Card>
                </Link>
              </div>
            )
          })}
        </div>
        {trips?.length > 0 ?
          <>
            {((Number(pageIndex) === 1 && !loading) || Number(pageIndex) > 1) && <div className="custom-btn-size d-flex justify-content-center mt-3">
              {Number(summary?.page) === Number(summary?.total_page) ?
                <div className="d-flex justify-content-center"></div> :
                <button onClick={handleLoadMore} className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2">
                  {loadingMore ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                    : ''
                  }
                  <span className="ml-2">{"Load More"}</span>
                </button>
              }

            </div>
            }
          </> :
          <>
            {!loading && <div className="d-flex justify-content-center">OOps No Record Found !!</div>}
          </>
        }
      </section >
    </div >
  )
}

export default ListingPendingForApproval;
