import { createContext, useContext, ReactNode, useState } from "react";
import { EnquireNowFormProps, initialEnqNowError, initialEnquireNow } from "types/interfaces";

export type Step = {
    step: number;
    title: string;
};

type EnquireNowContextType = {
    showEnquireNow: boolean;
    setShowEnquireNow: React.Dispatch<React.SetStateAction<boolean>>;
    formSteps: Step[];
    setFormSteps: React.Dispatch<React.SetStateAction<Step[]>>;
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    enquireData: EnquireNowFormProps;
    setEnquireData: React.Dispatch<React.SetStateAction<EnquireNowFormProps>>;
    errors: typeof initialEnqNowError;
    setErrors: React.Dispatch<React.SetStateAction<typeof initialEnqNowError>>;
};

const initialContextValue: EnquireNowContextType = {
    showEnquireNow: false,
    setShowEnquireNow: () => {},
    formSteps: [],
    setFormSteps: () => {},
    currentStep: 0,
    setCurrentStep: () => {},
    enquireData: initialEnquireNow,
    setEnquireData: () => {},
    errors: initialEnqNowError,
    setErrors: () => {}
};


const EnquireNowContext = createContext<EnquireNowContextType | undefined>(initialContextValue);


export function useEnquireNow() {
    const context = useContext(EnquireNowContext);
    if (!context) {
        throw new Error('useEnquireNow must be used within a EnquireNowProvider');
    }
    return context;
}

type EnquireNowProviderProps = {
    children: ReactNode;
};

export function EnquireNowProvider({ children }: EnquireNowProviderProps) {
    const [show, setShow] = useState(false);
    const [formSteps, setFormSteps] = useState<Step[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [enquireData, setEnquireData] = useState<EnquireNowFormProps>(initialEnquireNow)
    const [errors, setErrors] = useState(initialEnqNowError)

    // Create the context value with the state value
    const contextValue: EnquireNowContextType = {
        showEnquireNow: show,
        setShowEnquireNow: setShow,
        formSteps,
        setFormSteps,
        currentStep,
        setCurrentStep,
        enquireData,
        setEnquireData,
        errors,
        setErrors
    };

    return (
        <EnquireNowContext.Provider value={contextValue}>
            {children}
        </EnquireNowContext.Provider>
    );
}
