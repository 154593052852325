import { capitalize } from 'helpers/utils';
import { useEffect, useState } from 'react';
import { Button, Dropdown, Nav } from 'react-bootstrap';
import { RouteItems, routes } from 'sitemap';
import TopNavMegaMenu from './TopNavMegaMenu';
import TopNavItem from './TopNavItem';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import useWindowWidth from 'hooks/useWindowWidth';
import { useAuth } from 'providers/AuthContext';
import { useSignIn } from 'providers/SignInProvider';
import { useSignUp } from 'providers/SignUpProvider';
import { useAppContext } from 'providers/AppProvider';

const NavbarTopNav = () => {

  const windowWidth = useWindowWidth();
  const {setShow } = useSignUp();
  const {setShowIn } = useSignIn();
  const { isAuthenticated } = useAuth();
  const {  config: { openNavbarVertical },  setConfig  } = useAppContext();
  const toggleOpenNavbarVertical = () => {
    setConfig({ openNavbarVertical: !openNavbarVertical  });
  };


  const handleShow = () => { setShow(true); }
  const handleShowIn = () => { setShowIn(true); }
  return (
    <>
      <Nav className="navbar-nav-top pb-4 pb-lg-0 scrollbar">
        {routes.map(route => (
          <NavbarTopNavItem route={route} key={route.label} />
        ))}
      </Nav>

      {windowWidth < 768 && <div className="d-flex flex-row gap-3 header-btn mb-4">
        {!isAuthenticated && <Button variant="outline-primary" className="flex-grow-1 flex-lg-grow-0" onClick={()=>{handleShowIn();toggleOpenNavbarVertical();}}> Log in </Button>}
        {!isAuthenticated && <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={()=>{handleShow(); toggleOpenNavbarVertical();}}> Sign Up </Button>}
      </div>
      }
    </>
  );
};

const NavbarTopNavItem = ({ route }: { route: RouteItems }) => {
  // const Icon = route.icon;
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { pathname } = useLocation();
  const navigation = useNavigate() as NavigateFunction;
  const path = route.path;

  const { breakpoints } = useBreakpoints();

  const handleMouseEnter = () => {
    if (breakpoints.up('lg')) {
      setShow(true);
    }
  };

  const handleMouseLeave = () => {
    if (breakpoints.up('lg')) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      setShow(false);
    }
  }, [pathname]);

  const handleClick = () => {
    navigation(`${path}`);
  };

  useEffect(() => {

    setIsActive(pathname === `${path}`); // Check if current path matches this item's path
  }, [pathname, `${path}`]);

  return (
    <Dropdown
      as="li"
      show={show}
      className={`nav-item ${isActive ? 'active' : ''}`}
      key={route.label}
      autoClose="outside"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onToggle={() => setShow(!show)}
    >
      <Dropdown.Toggle
        as="a"
        variant=""
        className="nav-link lh-1 d-flex align-items-center cursor-pointer vertical-nav-items"
        onClick={handleClick}
      >
        {/* <Icon className="me-2" size={16} /> */}
        <span>
          {capitalize(
            route.horizontalNavLabel ? route.horizontalNavLabel : route.label
          )}
        </span>
      </Dropdown.Toggle>
      {/* {route.megaMenu ? (
        <TopNavMegaMenu route={route} />
      ) : (
        
        <TopNavItem route={route} />
      )} */}
    </Dropdown>
  );
};

export default NavbarTopNav;
