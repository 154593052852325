import React, { createContext, useContext } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { ToastContextType } from 'types/interfaces';

// Define the context type


// Create the context
const ToastContext = createContext<ToastContextType | undefined>(undefined);

// Define the props type for ToastProvider
interface ToastProviderProps {
  children: React.ReactNode; // Specify children as ReactNode
}

// Define the provider component
export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const showToast = (message: string, options?: ToastOptions) => {
    toast(message, options);
  };

  const showSuccessToast = (message: string, options?: ToastOptions) => {
    toast.success(message, options);
  };

  const showInfoToast = (message: string, options?: ToastOptions) => {
    toast.info(message, options);
  };

  const showWarningToast = (message: string, options?: ToastOptions) => {
    toast.warning(message, options);
  };

  const showErrorToast = (message: string, options?: ToastOptions) => {
    toast.error(message, options);
  };

  const contextValue: ToastContextType = {
    showToast,
    showSuccessToast,
    showInfoToast,
    showWarningToast,
    showErrorToast,
  };

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  );
};

// Custom hook to use the toast context
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
