import BannerImage from "assets/img/travel/banner/search.jpg";
import Testimonial from "components/asseenin/Testimonial";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "providers/ToastProvider";
import { useEffect, useState } from "react";
import { useAuth } from "providers/AuthContext";
import apiCall from "services/api";
import {
  Agent,
  ChildItemAllBtnText,
  initialChildItemAllBtnText,
  intialTripCategoryItem,
  TripCategoryItem,
} from "types/interfaces";
import { handleApiError } from "services/handleApiError";
import parse from "html-react-parser";
import TripCard from "components/cards/TripsCard";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import AgentCard from "components/cards/AgentCard";
import { markWishList } from "services/markWishList";
import { useSignIn } from "providers/SignInProvider";
import NotFound from "components/404/NotFound";
import DummyCard from "components/common/dummycard/DummyCard";
import DummyAgentCard from "components/common/dummycard/DummyAgentCard";

const TopCategories = () => {
  const navigate = useNavigate();
  const { signOut, userTkn } = useAuth();
  const { currency } = useMeta();
  const { setShowIn } = useSignIn();
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryDetails, setCategoryDetails] = useState<TripCategoryItem>(
    intialTripCategoryItem
  );
  const [btnText, setBtnText] = useState<ChildItemAllBtnText>(
    initialChildItemAllBtnText
  );
  const [trips, setTrips] = useState<any[]>([]);
  const [agents, setAgents] = useState<Agent[]>([]);
  const { showErrorToast } = useToast();
  const { slug, code } = useParams<{ slug: string; code: string }>();

  const handleMarkWishlist = async (tripId: string) => {
    await markWishList(
      tripId,
      userTkn,
      currency,
      setTrips,
      setShowIn,
      apiCall,
      showErrorToast
    );
  };

  useEffect(() => {
    if (!slug) {
      showErrorToast("Error: No slug provided");
      navigate("/"); // Redirect to homepage or another page
    }
  }, [slug, navigate, showErrorToast]);

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    const fetchTripData = async () => {
      try {
        setLoading(true);
        const [
          btnTextResponse,
          categorieResponse,
          tripsResponse,
          agentsResponse,
        ] = await Promise.all([
          apiCall({
            url: `cms/content-items/all_btn_text`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `master/trip-category/${slug}`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `tours?page=1&pageSize=12&category[0]=${code}`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `agents?page=1&pageSize=12&sort=-is_top_agent`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
        ]);
        if (btnTextResponse?.status === 200) {
          let data = btnTextResponse?.data?.ContentItem?.data?.content?.childs;
          setBtnText(data);
        }
        if (categorieResponse?.status === 200) {
          if (categorieResponse?.data?.errors) {
            const errors = categorieResponse?.data.errors;
            // Handle errors if needed
          } else {
            const data = categorieResponse?.data?.MasterTripCategory?.data;
            setCategoryDetails(data);
          }
        }

        if (tripsResponse?.status === 200) {
          if (tripsResponse?.data?.errors) {
            const errors = tripsResponse?.data.errors;
            // Handle errors if needed
          } else {
            const data = tripsResponse?.data?.TripVersion?.data;
            setTrips(data);
          }
        }

        if (agentsResponse?.status === 200) {
          if (agentsResponse?.data?.errors) {
            const errors = agentsResponse?.data.errors;
            // Handle errors if needed
          } else {
            const data = agentsResponse?.data?.Agents?.data;
            setAgents(data);
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        if (isMounted) setLoading(false);
      }
    };
    fetchTripData();
  }, [slug, code, navigate, showErrorToast, currency]);

  return (
    <>
      {loading && (
        <>
          <section id="hero-common" className="p-0 mb-5">
            <div
              className="dummy-text-placeholder"
              style={{ height: "450px" }}
            ></div>
            <div
              className="dummy-text-placeholder"
              style={{ height: "100px" }}
            ></div>
          </section>
          <section className="pb-0">
            <div className="container-fluid">
              <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3">
                <div className="col-xl-12 ">
                  {/* <p className="dummy-text-placeholder" ></p> */}
                </div>
                <DummyAgentCard />
                <DummyAgentCard />
                <DummyAgentCard />
                <DummyAgentCard />
              </div>
            </div>
          </section>
        </>
      )}
      {!loading && (
        <>
          {categoryDetails?.id ? (
            <>
              <section
                id="hero-faq"
                className="d-flex flex-column justify-content-center"
                style={{
                  background: `url(${
                    categoryDetails?.bannerImage?.url ?? BannerImage
                  })`,
                }}
              >
                <div className="container-fluid const">
                  <div className="row justify-content-center">
                    <div className="col-xl-12">
                      <p className="hero-heading fw-bold high-light-white text-center">
                        <span className="high-light-red">
                          {categoryDetails?.name ?? ""}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              {categoryDetails?.description && (
                <div className="section-para">
                  <div className="col-xl-12 container-fluid cont ">
                    <p className="pera1">
                      {" "}
                      {parse(categoryDetails?.description ?? "")}
                    </p>
                  </div>
                </div>
              )}

              <section className="pb-0 pt-4">
                <div className="container-fluid cont">
                  <div className="row justify-content-center">
                    <div className="col-xl-12 ">
                      <p className="main-heading  fw-bold high-light-black">
                        {" "}
                        Travel<span className="high-light-red">
                          {" "}
                          Creators
                        </span>{" "}
                      </p>
                    </div>

                    {Array.isArray(agents) &&
                      agents.length > 0 &&
                      agents?.map((agent, index) => (
                        <AgentCard key={index} agent={agent} />
                      ))}
                  </div>
                </div>
              </section>

              {!loading && (
                <>
                  {Array.isArray(trips) && trips?.length > 0 && (
                    <section className="pb-0">
                      <div className="container-fluid">
                        <div className="row mx-3">
                          <div className="col-xl-12 ">
                            <p className="main-heading  fw-bold high-light-black">
                              {" "}
                              My<span className="high-light-red">
                                {" "}
                                trips
                              </span>{" "}
                            </p>
                          </div>

                          {Array.isArray(trips) && trips?.length > 0 ? (
                            <>
                              {trips?.map((item, index) => (
                                <TripCard
                                  key={index}
                                  item={item}
                                  markWishList={handleMarkWishlist}
                                  title={btnText?.trip_card_btn?.item_value}
                                />
                              ))}
                            </>
                          ) : (
                            <div className="col-12  d-flex justify-content-center">
                              <h3>Sorry No Trip Found!!</h3>
                            </div>
                          )}
                        </div>
                      </div>
                    </section>
                  )}
                </>
              )}

              <Testimonial />
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

export default TopCategories;
