import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import avatar57 from 'assets/img/travel/profile/profile.jpg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSignOut} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'providers/AuthContext';
import Button from 'components/base/Button';

const ProfileDropdownMenuCreator = ({ className, closeDropdown, navigate }: { className?: string, closeDropdown: () => void, navigate: any }) => {
  const { signOut, userProfile } = useAuth();
  const [navItems] = useState([
    {
      label: 'Profile',
      icon: faEnvelope,
      to: "/creator/profile-creator"
    }
  ]);
  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
        <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={userProfile?.user?.image?.url ?? avatar57} size="xl" />
            <h6 className="text-black"> {`${userProfile?.user?.first_name ?? ""} ${userProfile?.user?.last_name ?? ""}`}</h6>
          </div>

          <hr className='p-0 m-0'/>
          <Nav className="nav flex-column  pb-1">
            {navItems.map(item => (
              <Nav.Item key={item.label}>
                <Nav.Link as={Link} to={item.to} className="px-3">
                  <FontAwesomeIcon
                    icon={item.icon}

                    className="me-2 "
                  />
                  <span className="">{item.label}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
            <Nav.Item key={"logout"}>
              <Nav.Link
                // as={Link} to={item.to}
                as={Button}
                onClick={() => {
                  // closeDropdown();
                  signOut()
                }}
                className="px-3"   >
                <FontAwesomeIcon
                  icon={faSignOut}

                  className="me-2 "
                />
                <span className="">{"Sign Out"}</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>


          {/* </Scrollbar>
          </div> */}
        </Card.Body>
        {/* <Card.Footer className="p-0 ">
          <div className="px-3">
            <Link
              to="#!"
              className=""
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </Link>
          </div>
        </Card.Footer> */}
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenuCreator;
