import React from "react";
import { Card } from "react-bootstrap";
import { Agent } from "types/interfaces";
import bgImg1 from 'assets/img/travel/blogs/Rectangle.png';
import profile1 from 'assets/img/travel/profile/profile.jpg';
import { useNavigate } from "react-router-dom";

interface AgentCardProps {
  agent: Agent
}

const AgentCard: React.FC<AgentCardProps> = ({ agent }) => {
    const navigate = useNavigate(); 
  return ( 
    <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-3 link-hover" onClick={()=>{navigate(`/agents/${agent?.slug}`)}} >
      <Card className="card-trip">
        <div className="topside agent-card-img-container">
          <Card.Img className="agent-card-img" variant="top" src={agent?.coverImage?.url ?? bgImg1}  alt="profile_image"/>
        </div>
        <div className="center-img d-flex align-items-center justify-content-center">
          <div className="rounded-circle-base">
            <img src={agent?.profileImage?.url ?? profile1} alt={agent.name} />
          </div>
        </div>
        <div className="bottomSide mb-4 mt-4 text-center">
          <h6>{agent.name}</h6>
          <p>Based in: {agent?.location?.name}</p>
        </div>
      </Card>
    </div>
  );
};

export default AgentCard;




// {agentData?.slice(0, 4).map((agent: any, index: number) => {
//   const bgImages = [bgImg1, bgImg2, bgImg3, bgImg4];
//   let bgImage = bgImages[index % bgImages.length];
//   return (
//       <div key={index} className="col-xxl-3 col-xl-4 col-lg-6 col-md-12 mb-3">
//           <Card className="card-trip">
//               <div className="topside">
//                   <Card.Img variant="top" src={agent?.coverImage?.url ? agent?.coverImage?.url : bgImage } />
//               </div>
//               <div className="center-img d-flex align-items-center justify-content-center">
//                   <div className="rounded-circle-base"
//                   // onClick={() => { navigate("/agents/:") }}
//                   >
//                       <img src={agent?.profileImage?.url ? agent?.profileImage?.url : avtar1} alt={agent.name} />
//                   </div>
//               </div>
//               <div className="bottomSide mb-4 mt-4 text-center">
//                   <h6>{agent.name}</h6>
//                   <p>Based in: {agent?.location?.long_name}</p>
//               </div>
//           </Card>
//       </div>
//   )
// })}
