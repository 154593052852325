import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

interface ViewAllTripProps {
    title: string | "";
}

const ViewAllTrip: React.FC<ViewAllTripProps> = ({ title }) => {
    const navigate = useNavigate()
    return (
        <div className="custom-btn-size d-flex justify-content-start py-4">
            <div>
                <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={() => (navigate("/trips-and-destination"))}>{title ? title : "View All Trips"}  </Button>
            </div>
        </div>
    )
}

export default ViewAllTrip