import AgentImg from "assets/img/travel/creator/agentcard.png"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css'
import { Agent } from 'types/interfaces';
import { useNavigate } from "react-router-dom";

interface CrausalProps {
    agents: Agent[] | []
}

const MultiSlidesCrousalCreator: React.FC<CrausalProps> = ({ agents }) => {
    const navigate = useNavigate();
    return (

        <>
            <Swiper
                slidesPerView={6}
                spaceBetween={30}

                modules={[Pagination]}

                className="mySwiper"

                breakpoints={{
                    1600: {
                        slidesPerView: 6,
                    },
                    1440: {
                        slidesPerView: 5,
                    },
                    1200: {
                        slidesPerView: 5,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    320: {
                        slidesPerView: 1,
                    }
                }}

            >
                {agents?.map((item, index) => (
                    <SwiperSlide>
                        <div onClick={()=>{navigate(`/agents/${item?.slug}`)}} className="link-hover">
                            <div className="slide-img-container">
                                <img src={ item?.profileImage?.url ?? AgentImg} alt="image" width={365} height={80} />
                            </div>
                            <span className="travel-category fw-bold high-light-black m-0">{item?.name ?? ""} </span>
                            <p className="m-0">{item?.location?.name}</p>
                        </div>
                    </SwiperSlide>
                ))}
              
            </Swiper>
        </>

    )
}

export default MultiSlidesCrousalCreator