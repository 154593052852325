import React, { useState, useEffect, lazy, Suspense, useRef } from 'react';
import { tripForms } from './tripForm';
import './TripForm.css';
const AboutTrip = lazy(() => import('./steps/AboutTrip'));
const TripDetails = lazy(() => import('./steps/TripDetails'));
const Itinerary = lazy(() => import('./steps/Itinerary'));
const Media = lazy(() => import('./steps/Media'));
import { Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useMeta } from 'providers/MetaContext';
import { useMaster } from 'providers/MasterContext';
import { DayItinerary, OptionType, TripDetailsEditMode, TripDetailsFormProps, initialActivitiesData, initialFormData, initialTripDetailsEditMode } from 'types/interfaces';
import TripViewUpdate from './view/TripViewUpdate';
import apiCall from 'services/api';
import { useAuth } from 'providers/AuthContext';
import { AxiosError } from 'axios';
import { useToast } from 'providers/ToastProvider';
import { handleApiError } from 'services/handleApiError';

export type Step = {
    step: number;
    title: string;
};
type ActivityCode = 'ACTI' | 'ACCO' | 'FLIG' | 'GROU' | 'INFO'; // Example values, adjust to your actual keys


const UpdateTrip: React.FC = () => {
    const navigate = useNavigate();
    const { id, trip_uuid, version } = useParams<{ id: string, trip_uuid: string, version: string }>();
    const { showToast, showSuccessToast, showInfoToast, showErrorToast } = useToast();
    const { state } = useLocation();
    let tripData = state?.trips;
    let source = state?.source;
    const { signOut, userTkn } = useAuth()
    const { currenciesOptions } = useMeta();
    const { locationsForDp } = useMaster();
    const [formSteps, setFormSteps] = useState<Step[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState<boolean>(false); //when send for Approval
    const [message, setMessage] = useState('');
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [selectedStartLocation, setSelectedStartLocation] = useState<OptionType | null>(null);
    const [selectedEndLocation, setSelectedEndLocation] = useState<OptionType | null>(null);
    const [tripView, setTripView] = useState<boolean>(false);
    const [currentVersion, setCurrentVersion] = useState<string>(version || '');


    const [formData, setFormData] = useState<TripDetailsFormProps>(initialFormData);
    const [startFormData, setStartFormData] = useState<TripDetailsFormProps>(initialFormData);

    const [mode,] = useState(2); //for About Trip
    const [editMode, setEditMode] = useState(false); //for About Trip


    const [errors, setErrors] = useState({
        title: '',
        meta_currency_code: '',
        price: '',
        no_of_days: '',
        start_location: '',
        end_location: '',
        banner_image: '',
        listing_images: '',
        description: '',

        details: '',
        trip_categories: '',
        trip_availabilities: '',
        meta_title: '',
        is_meta_title_same_as_title: '',
        meta_keywords: '',
        is_meta_title_same_as_trip_tags: '',
        meta_description: '',
        is_meta_title_same_as_description: '',
        accomodation_status: '',
        accomodation_note: '',
        flight_status: '',
        flight_note: '',
        ground_transport_status: '',
        ground_transport_note: '',
        meals_status: '',
        meals_note: '',
        included: '',
        excluded: '',
        optional: '',

        privacy_policy: '',
        term_and_conditions: '',
        change_policy: '',
        must_carry: ''
    });

    const [isEditMode, setIsEditMode] = useState<TripDetailsEditMode>(initialTripDetailsEditMode);
     //for tags
     const [selected, setSelected] = useState<any[]>([])


   
    useEffect(() => {
        let isMounted = true;  // Flag to track component mount status
        const fetchTripData = async () => {
            try {
                setLoading(true);
                const response = await apiCall({
                    url: `trip/${trip_uuid}/${currentVersion}/view`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn
                    },
                });
                if (isMounted && response.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;
                        showErrorToast(errors)

                    } else {
                        const tripData = response?.data?.TripVersion?.data;
                        const selectedCurrency = currenciesOptions?.find(options => tripData?.meta_currency_code?.includes(options.value))
                        const start_location = locationsForDp?.find(location => Number(location.value) === Number(tripData?.start_location));
                        const end_location = locationsForDp?.find(location => Number(location.value) === Number(tripData?.end_location));

                        const selectedMonthsCode = tripData?.tripAvailability?.map((item: any) => Number(item?.meta_months_no));
                        const selectedMonths = tripData?.tripAvailability?.map((item: any) => item?.month);
                        //set code[] and alldata[] of Months
                        const selectedCategories = tripData?.tripCategory?.map((item: any) => item?.masterTripCategory);
                        const selectedCategoryCodes = tripData?.tripCategory?.map((item: any) => Number(item?.master_trip_category_code));
                        // Create dayItinerary with all days initialized
                        const dayItinerary: DayItinerary[] = tripData?.dayItinerary?.map((item: any, index: any) => {
                            const activities = tripData.dayItinerary[index]?.plan || [];

                            let dayActivities = activities?.map((activity: any, index: any) => {
                                let activityCode: ActivityCode = activity.trip_category_code as ActivityCode;

                                let activityData = { ...initialActivitiesData };

                                if (activityCode === 'ACTI') {
                                    activityData.ACTI = {
                                        ...activityData.ACTI,
                                        id: activity?.id,
                                        title: activity?.plan?.title,
                                        note: activity?.plan?.note,
                                        start_location: activity?.plan?.startLocation?.id,
                                        is_end_location_available: activity?.plan?.is_end_location_available,
                                        end_location: activity?.plan?.endLocation?.id,
                                        selectedStartLoc: { value: activity?.plan?.startLocation?.id, label: activity?.plan?.startLocation?.name },
                                        selectedEndLoc: { value: activity?.plan?.endLocation?.id, label: activity?.plan?.endLocation?.name },
                                        is_optional: activity?.plan?.is_optional,
                                        listingImages: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        listingImagesPreview: activity?.plan?.listingImages || [],
                                        listingImagesPreserve: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        sequence: activity?.plan?.sequence,
                                        startLocation: activity?.plan?.startLocation,
                                        endLocation: activity?.plan?.endLocation,
                                        start_at: activity?.plan?.start_at,
                                        duration: activity?.plan?.duration,
                                        duration_type: activity?.plan?.duration_type,
                                        tag_id: [{value: activity?.plan?.tag?.id, label: activity?.plan?.tag?.name}]

                                    };
                                } else if (activityCode === 'ACCO') {
                                    activityData.ACCO = {
                                        ...activityData.ACCO,
                                        id: activity?.id,
                                        title: activity?.plan?.title,
                                        note: activity?.plan?.note,
                                        start_location: activity?.plan?.startLocation?.id,
                                        is_end_location_available: activity?.plan?.is_end_location_available,
                                        end_location: activity?.plan?.endLocation?.id,
                                        selectedStartLoc: { value: activity?.plan?.startLocation?.id, label: activity?.plan?.startLocation?.name },
                                        selectedEndLoc: { value: activity?.plan?.endLocation?.id, label: activity?.plan?.endLocation?.name },
                                        is_optional: activity?.plan?.is_optional,
                                        listingImages: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        listingImagesPreview: activity?.plan?.listingImages || [],
                                        listingImagesPreserve: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        sequence: activity?.plan?.sequence,
                                        startLocation: activity?.plan?.startLocation,
                                        endLocation: activity?.plan?.endLocation,

                                        check_in_time: activity?.plan?.check_in_time,
                                        check_out_time: activity?.plan?.check_out_time,

                                        tag_id: [{value: activity?.plan?.tag?.id, label: activity?.plan?.tag?.name}]

                                    };
                                } else if (activityCode === 'FLIG') {
                                    activityData.FLIG = {
                                        ...activityData.FLIG,
                                        id: activity?.id,
                                        title: activity?.plan?.title,
                                        note: activity?.plan?.note,
                                        start_location: activity?.plan?.startLocation?.id,
                                        is_end_location_available: activity?.plan?.is_end_location_available,
                                        end_location: activity?.plan?.endLocation?.id,
                                        selectedStartLoc: { value: activity?.plan?.startLocation?.id, label: activity?.plan?.startLocation?.name },
                                        selectedEndLoc: { value: activity?.plan?.endLocation?.id, label: activity?.plan?.endLocation?.name },
                                        is_optional: activity?.plan?.is_optional,
                                        listingImages: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        listingImagesPreview: activity?.plan?.listingImages || [],
                                        listingImagesPreserve: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        sequence: activity?.plan?.sequence,
                                        startLocation: activity?.plan?.startLocation,
                                        endLocation: activity?.plan?.endLocation,

                                        airline: activity?.plan?.airline,
                                        airlineOp:  { value: activity?.plan?.airlineDetails?.code, label: activity?.plan?.airlineDetails?.name },
                                        tag_id: [{value: activity?.plan?.tag?.id, label: activity?.plan?.tag?.name}]

                                    };
                                } else if (activityCode === 'GROU') {
                                    activityData.GROU = {
                                        ...activityData.GROU,
                                        id: activity?.id || 0,
                                        title: activity?.plan?.title || '',
                                        note: activity?.plan?.note || '',
                                        start_location: activity?.plan?.startLocation?.id || '',
                                        is_end_location_available: activity?.plan?.is_end_location_available,
                                        end_location: activity?.plan?.endLocation?.id || '',
                                        selectedStartLoc: { value: activity?.plan?.startLocation?.id, label: activity?.plan?.startLocation?.name } || { value: '', label: '' },
                                        selectedEndLoc: { value: activity?.plan?.endLocation?.id, label: activity?.plan?.endLocation?.name } || { value: '', label: '' },
                                        is_optional: activity?.plan?.is_optional,
                                        listingImages: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        listingImagesPreview: activity?.plan?.listingImages || [],
                                        listingImagesPreserve: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        sequence: activity?.plan?.sequence,
                                        startLocation: activity?.plan?.startLocation,
                                        endLocation: activity?.plan?.endLocation,

                                        ground_transport_code: activity?.plan?.ground_transport_code,
                                        departure_location: activity?.plan?.departure_location,
                                        departure_time: activity?.plan?.departure_time,
                                        arrival_location: activity?.plan?.arrival_location,
                                        arrival_time: activity?.plan?.arrival_time,
                                        selectedGtCategory: { value: activity?.plan?.ground_transport_code, label: activity?.plan?.ground_transport_code },
                                        selectedArrivalLoc: { value: activity?.plan?.arrival_location, label: activity?.plan?.arrival_location },
                                        selectedDeptLoc: { value: activity?.plan?.departure_location, label: activity?.plan?.departure_location },


                                        tag_id: [{value: activity?.plan?.tag?.id, label: activity?.plan?.tag?.name}]

                                    };
                                } else if (activityCode === 'INFO') {
                                    activityData.INFO = {
                                        ...activityData.INFO,
                                        id: activity?.id,
                                        title: activity?.plan?.title,
                                        note: activity?.plan?.note,
                                        start_location: activity?.plan?.startLocation?.id,
                                        is_end_location_available: activity?.plan?.is_end_location_available,
                                        end_location: activity?.plan?.endLocation?.id,
                                        selectedStartLoc: { value: activity?.plan?.startLocation?.id, label: activity?.plan?.startLocation?.name },
                                        selectedEndLoc: { value: activity?.plan?.endLocation?.id, label: activity?.plan?.endLocation?.name },
                                        is_optional: activity?.plan?.is_optional,
                                        listingImages: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        listingImagesPreview: activity?.plan?.listingImages || [],
                                        listingImagesPreserve: activity?.plan?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                        sequence: activity?.plan?.sequence,
                                        startLocation: activity?.plan?.startLocation,
                                        endLocation: activity?.plan?.endLocation,
                                        tag_id: [{value: activity?.plan?.tag?.id, label: activity?.plan?.tag?.name}]
                                    };
                                }

                                return activityData
                            });


                            return ({
                                id: item?.id || 0,
                                day: item?.day || '',
                                title: item?.title || '',
                                info: item?.info || '',
                                start_location: item?.start_location || '',
                                is_end_location: item?.is_end_location || 0,
                                end_location: item?.end_location || item?.start_location || '',
                                hotel_name: item?.hotel_name || '',
                                hotel_latitude: item?.hotel_latitude || '',
                                hotel_longitude: item?.hotel_longitude || '',
                                meals: item?.meals,
                                mealsArray: item?.meals?.map((meal: any) => Number(meal?.id)) || [],
                                listingImages: item?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                listingImagesPreview: item?.listingImages || [],
                                listingImagesPreserve: item?.listingImages?.map((image: any) => Number(image?.id)) || [],
                                status: 1,
                                startLocation: item?.startLocation || null,
                                endLocation: item?.endLocation || null,
                                selectedStartLoc: {
                                    value: item?.startLocation?.id,
                                    label: item?.startLocation?.name
                                },
                                selectedEndLoc: {
                                    value: item?.endLocation?.id,
                                    label: item?.endLocation?.name
                                },
                                is_same_as_above: item?.is_same_as_above,
                                dayplanOrder: item?.dayplanOrder,
                                activity: dayActivities
                            })
                        });


                        setFormData(prev => ({
                            ...prev,
                            id: tripData?.id,
                            version: tripData?.version || '',
                            versions: tripData?.versions || '',
                            tags: tripData?.tags || '',
                            pendingVersion: tripData?.pendingVersion,
                            liveVersion: tripData?.liveVersion,
                            status: tripData?.status,
                            trip_uuid: tripData?.trip_uuid || '',
                            title: tripData?.title,
                            meta_currency_code: tripData?.meta_currency_code,
                            price: tripData?.price,
                            no_of_days: tripData?.no_of_days,
                            start_location: tripData?.startLocation?.id,
                            end_location: tripData?.endLocation.id,
                            start_location_option: { value: tripData?.startLocation?.id, label: tripData?.startLocation?.name },
                            end_location_option: { value: tripData?.endLocation?.id, label: tripData?.endLocation?.name },
                            banner_image: tripData?.bannerImage?.id,
                            banner_image_preview: tripData?.bannerImage?.url,

                            listing_images: tripData?.listingImages.map((image: any) => Number(image?.id)) || [],
                            listing_image_preview: tripData?.listingImages.map((image: any) => ({
                                url: image.url,
                                id: image.id,
                                name: image.name
                            })),
                            listing_images_preserve: tripData?.listingImages.map((image: any) => Number(image?.id)) || [],

                            description: tripData?.description,

                            details: tripData?.details,

                            slectedCategoriesCode: selectedCategoryCodes,
                            selectedCategories: selectedCategories,
                            trip_availabilities_code: selectedMonthsCode,
                            trip_availabilities: selectedMonths,

                            meta_title: tripData?.seo?.meta_title,
                            is_meta_title_same_as_title: tripData?.seo?.is_meta_title_same_as_title,
                            meta_keywords: tripData?.seo?.meta_keywords,
                            is_meta_title_same_as_trip_tags: tripData?.seo?.is_meta_title_same_as_trip_tags,
                            meta_description: tripData?.seo?.meta_description,
                            is_meta_title_same_as_description: tripData?.seo?.is_meta_title_same_as_description,

                            accomodation_status: tripData?.includedexcluded?.accomodation?.accomodation_status,
                            accomodation_note: tripData?.includedexcluded?.accomodation?.accomodation_note,
                            flight_status: tripData?.includedexcluded?.flight?.flight_status,
                            flight_note: tripData?.includedexcluded?.flight?.flight_note,
                            ground_transport_status: tripData?.includedexcluded?.ground_transport?.ground_transport_status,
                            ground_transport_note: tripData?.includedexcluded?.ground_transport?.ground_transport_note,
                            meals_status: tripData?.includedexcluded?.measls?.meals_status,
                            meals_note: tripData?.includedexcluded?.measls?.meals_note,
                            included: tripData?.includedexcluded?.included,
                            excluded: tripData?.includedexcluded?.excluded,
                            optional: tripData?.includedexcluded?.optional,

                            privacy_policy: tripData?.addionalInfo?.privacy_policy,
                            term_and_conditions: tripData?.addionalInfo?.term_and_conditions,
                            change_policy: tripData?.addionalInfo?.change_policy,
                            must_carry: tripData?.addionalInfo?.must_carry,

                            dayItineraryOrder: tripData.dayItineraryOrder,
                            dayItinerary: dayItinerary
                        }));
                        setStartFormData(prev => ({
                            ...prev,
                            id: tripData?.id,
                            version: tripData?.version || '',
                            versions: tripData?.versions || '',
                            tags: tripData?.tags || '',
                            pendingVersion: tripData?.pendingVersion,
                            liveVersion: tripData?.liveVersion,
                            status: tripData?.status,
                            trip_uuid: tripData?.trip_uuid || '',
                            title: tripData?.title,
                            meta_currency_code: tripData?.meta_currency_code,
                            price: tripData?.price,
                            no_of_days: tripData?.no_of_days,
                            start_location: tripData?.startLocation?.id,
                            end_location: tripData?.endLocation.id,
                            start_location_option: { value: tripData?.startLocation?.id, label: tripData?.startLocation?.name },
                            end_location_option: { value: tripData?.endLocation?.id, label: tripData?.endLocation?.name },
                            banner_image: tripData?.bannerImage?.id,
                            banner_image_preview: tripData?.bannerImage?.url,
                            listing_images: tripData?.listingImages.map((image: any) => Number(image?.id)) || [],
                            listing_image_preview: tripData?.listingImages.map((image: any) => ({
                                url: image.url,
                                id: image.id,
                                name: image.name
                            })),
                            listing_images_preserve: tripData?.listingImages.map((image: any) => Number(image?.id)) || [],

                            description: tripData?.description,

                            details: tripData?.details,

                            slectedCategoriesCode: selectedCategoryCodes,
                            selectedCategories: selectedCategories,
                            trip_availabilities_code: selectedMonthsCode,
                            trip_availabilities: selectedMonths,

                            meta_title: tripData?.seo?.meta_title,
                            is_meta_title_same_as_title: tripData?.seo?.is_meta_title_same_as_title,
                            meta_keywords: tripData?.seo?.meta_keywords,
                            is_meta_title_same_as_trip_tags: tripData?.seo?.is_meta_title_same_as_trip_tags,
                            meta_description: tripData?.seo?.meta_description,
                            is_meta_title_same_as_description: tripData?.seo?.is_meta_title_same_as_description,

                            accomodation_status: tripData?.includedexcluded?.accomodation?.accomodation_status,
                            accomodation_note: tripData?.includedexcluded?.accomodation?.accomodation_note,
                            flight_status: tripData?.includedexcluded?.flight?.flight_status,
                            flight_note: tripData?.includedexcluded?.flight?.flight_note,
                            ground_transport_status: tripData?.includedexcluded?.ground_transport?.ground_transport_status,
                            ground_transport_note: tripData?.includedexcluded?.ground_transport?.ground_transport_note,
                            meals_status: tripData?.includedexcluded?.measls?.meals_status,
                            meals_note: tripData?.includedexcluded?.measls?.meals_note,
                            included: tripData?.includedexcluded?.included,
                            excluded: tripData?.includedexcluded?.excluded,
                            optional: tripData?.includedexcluded?.optional,

                            privacy_policy: tripData?.addionalInfo?.privacy_policy,
                            term_and_conditions: tripData?.addionalInfo?.term_and_conditions,
                            change_policy: tripData?.addionalInfo?.change_policy,
                            must_carry: tripData?.addionalInfo?.must_carry,

                            dayItineraryOrder: tripData.dayItineraryOrder,
                            dayItinerary: dayItinerary
                        }));


                        if (selectedCurrency) {
                            setSelectedOption(selectedCurrency)
                        }
                        if (start_location) {
                            setSelectedStartLocation(start_location)
                        }
                        if (end_location) {
                            setSelectedEndLocation(end_location)
                        }
                        if(tripData?.tags){
                            let formattedData = tripData.tags.map((item: any) => ({
                                value: item.name,
                                label: item.name
                              }));

                              setSelected(formattedData)
                        }

                        setLoading(false)




                    }
                }
            } catch (error: any) {

                handleApiError(error, showErrorToast, signOut);

            } finally {
                if (isMounted) setLoading(false);
            }
        };


        if (trip_uuid && version && userTkn) {
            fetchTripData();
        }
        // Cleanup function
        return () => {
            isMounted = false;  // Mark component as unmounted
        };

    }, [trip_uuid, currentVersion, userTkn, signOut, showErrorToast]);

    const aboutTripRef = useRef<{ handleSubmit: () => void }>(null);
    useEffect(() => {
        setFormSteps(tripForms.steps);
    }, []);

    const nextStep = () => {
        if (currentStep < formSteps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const nextStepSave = () => {
        if (currentStep < formSteps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };
    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const goToStep = (index: number) => {
        if (index >= 0 && index < formSteps.length) {
            if (formData.trip_uuid) {
                setCurrentStep(index);
                setMessage(''); // Clear any previous messages
            } else {
                setMessage('Please complete About Trip first');
            }
        }
    };

    const handleSaveClick = () => {
        if (currentStep === 0 && aboutTripRef.current) {
            aboutTripRef.current.handleSubmit();
        } else {
            nextStep();
        }
    };

    const renderStepContent = (step: Step) => {
        switch (step.step) {
            case 1:
                return <AboutTrip
                    step={step}
                    nextStepSave={nextStepSave}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    selectedStartLocation={selectedStartLocation}
                    setSelectedStartLocation={setSelectedStartLocation}
                    selectedEndLocation={selectedEndLocation}
                    setSelectedEndLocation={setSelectedEndLocation}
                    formData={formData}
                    setFormData={setFormData}
                    startFormData={startFormData}
                    setStartFormData={setStartFormData}
                    errors={errors}
                    setErrors={setErrors}
                    mode={mode}  // 1  for create, 2 for update
                    editMode={editMode}
                    setEditMode={setEditMode}
                />;
            case 2:
                return <TripDetails
                    step={step}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    formData={formData}
                    setFormData={setFormData}
                    startFormData={startFormData}
                    setStartFormData={setStartFormData}
                    errors={errors}
                    setErrors={setErrors}
                    selected = {selected}
                    setSelected = {setSelected}




                // tripDetailsFormData={tripDetailsFormData}
                // setTripDetailsFormData={setTripDetailsFormData}
                // tripDetailsErrors = {tripDetailsErrors}
                // setTripDetailsErrors ={setTripDetailsErrors} 
                />;
            case 3:
                return <Itinerary
                    step={step}
                    formData={formData}
                    setFormData={setFormData}
                    startFormData={startFormData}
                    setStartFormData={setStartFormData}
                />;
            case 4:
                return <Media step={step} startFormData={startFormData} />;
            default:
                return null;
        }
    };

    const toggleView = () => {
        setTripView(!tripView);
    }

    const updateUrlVersion = (version: string) => {
        const currentUrl = window.location.href;
        const versionRegex = /\/v\d+\//; // Regex to match version path, e.g., /v1/
        const updatedUrl = currentUrl.replace(versionRegex, `/${version}/`);
        window.history.replaceState(null, '', updatedUrl);
    };
    const handleUpdateUrlClick = (version: string) => {
        setCurrentVersion(version);
        updateUrlVersion(version);
    };


    const sendForApproval = async (trip_uuid: string, version: string) => {
        try {
            setLoading(true);
            let url = `trip/${trip_uuid}/${version}/send-for-approval`
            const response = await apiCall({
                url: url,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn
                },
            });
            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                } else {
                    let data = response?.data?.TripVersion?.data;
                    setStartFormData(prev => ({
                        ...prev,
                        status: data?.status
                    }))

                    setFormData(prev => ({
                        ...prev,
                        status: data?.status
                    }))

                }
            }
        } catch (error: any) {
            handleApiError(error, showErrorToast, signOut);
            setLoading(false);

        } finally {
            setLoading(false);
        }

    }

    const copyTrip = async (trip_uuid: string, version: string)=>{
   
        try {
            setLoading(true);
            let url = `trip/${trip_uuid}/${version}/copy-trip`
            const response = await apiCall({
                url: url,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn
                },
            });
            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                } else {
                   
                    showSuccessToast(response?.data?.message)
                }
            }
        } catch (error: any) {
            handleApiError(error, showErrorToast, signOut);
            setLoading(false);

        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? <div className="loading-overlay">
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="listing-spinner"
                        />
                    </div> :
        <>
            {tripView ? (
                
                        <TripViewUpdate loading={loading} source={source} sendForApproval={sendForApproval} copyTrip={copyTrip} currentVersion={currentVersion} handleUpdateUrlClick={handleUpdateUrlClick} tripData={startFormData} viewType={1} tripToggle={toggleView} tripView={tripView}/>
               
            )
                :
                (<section id="create-trip-container">
                    <div className='container'>
                        <div className={`inner-container-${currentStep}`} key={currentStep}>
                            <Row>
                                <div className='col-md-12'>
                                    <div className="stepper">
                                        {formSteps.map((step, index) => (
                                            <React.Fragment key={index}>
                                                <div className="step-container" key={index}>
                                                    <div className={`step-title-${index}`}>{step.title}</div>
                                                    <div
                                                        className={`step ${index === currentStep ? 'active' : ''}  ${currentStep > index ? 'completed' : ''}`}
                                                        onClick={() => goToStep(index)}
                                                    >
                                                        <div className="step-number"></div>
                                                    </div>
                                                </div>
                                                {index < formSteps.length - 1 && <div className="step-connector" key={index + 1}>
                                                    <span className={`steppr-line  ${currentStep > index ? 'completed-line' : ''}`}></span>
                                                </div>}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </Row>

                            {formSteps.length > 0 && (
                                <Suspense fallback={<div className='step-content-0-main'>Loading...</div>}>
                                    <div className={currentStep !== 0 ? `step-content-${currentStep}` : ""}>
                                        {renderStepContent(formSteps[currentStep])}
                                    </div>
                                </Suspense>
                            )}
                            {currentStep !== 0 && <div className={`step-navigation navigatio-step-${currentStep}`}>
                                {currentStep !== 0 && <div className="custom-btn-size d-flex justify-content-center py-4">
                                    <button onClick={toggleView} disabled={currentStep === 0} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                        {"View"}
                                    </button>
                                </div>}
                                {currentStep !== 0 && <div className="custom-btn-size d-flex justify-content-center py-4">
                                    <button onClick={prevStep} disabled={currentStep === 0} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                        {"Previous"}
                                    </button>
                                </div>}

                                {!(currentStep === formSteps.length - 1) && <div className="custom-btn-size d-flex justify-content-center py-4">
                                    <button onClick={handleSaveClick} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                        {currentStep === 0 ? "Save" : "Next"}
                                    </button>
                                </div>
                                }

                            </div>
                            }
                            {(mode === 2 && currentStep === 0 && !editMode) &&
                                <div className={`step-navigation navigatio-step-${currentStep}`}>
                                    <div className="custom-btn-size d-flex justify-content-center py-4">
                                        <button onClick={handleSaveClick} disabled={currentStep === formSteps.length - 1} type="button" className="flex-grow-1 flex-lg-grow-0 btn btn-primary">
                                            {"Next"}
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </section>)
            }
            </>
}
        </>
    );
};

export default UpdateTrip;
