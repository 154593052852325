import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";;
import { ChangeEvent, useEffect, useState } from "react";
import { Form, Modal} from "react-bootstrap"
import { OptionType, initialEnqNowError, EnquireNowFormProps, initialEnquireNow } from "types/interfaces";
import { Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select, { SingleValue } from 'react-select';
import { useMeta } from "providers/MetaContext";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useNavigate } from "react-router-dom";
import apiCall from "services/api";
import { useToast } from "providers/ToastProvider";
import { handleApiError } from "services/handleApiError";
import { getHeaders } from "services/getHeaders";
import { useAuth } from "providers/AuthContext";
import { useMaster } from "providers/MasterContext";
import { customStylesSelect } from 'services/customStylesForms';
interface TripRequestProps {
    modalShow: boolean;
    setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
    enquireData: EnquireNowFormProps;
    setEnquireData: React.Dispatch<React.SetStateAction<EnquireNowFormProps>>;
    errors: typeof initialEnqNowError;
    setErrors: React.Dispatch<React.SetStateAction<typeof initialEnqNowError>>;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    loading: boolean;
    travelerData: any;
}

const TripRequestModal: React.FC<TripRequestProps> = ({
    modalShow,
    setModalShow,
    enquireData,
    setEnquireData,
    errors,
    setErrors,
    handleSubmit,
    loading,
    travelerData
}) => {
    const navigate = useNavigate();
    const { currenciesOptions } = useMeta();
    const { travelStyleTagOption, travelTripTypeTagOption } = useMaster();
    const [loadingTours, setLoadingTours] = useState(false);
    const { showErrorToast } = useToast();
    const { months, currency } = useMeta();
    const { userTkn, signOut, userProfile } = useAuth();
    const [trips, setTrips] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [range, setRange] = useState<[number, number]>([
        enquireData.budget_min || 0,
        enquireData.budget_max || 5000
    ]);

    useEffect(() => {

        const fetchTripData = async () => {
            try {
                setLoadingTours(true);
                const [tripsResponse] = await Promise.all([
                    apiCall({
                        url: `tours?owner_id=${userProfile?.user?.id}`,
                        method: 'GET',
                        headers: getHeaders(userTkn, currency),
                    })
                ])

                if (tripsResponse?.status === 200) {
                    const data = tripsResponse?.data?.TripVersion?.data;
                    const total = tripsResponse?.data?.TripVersion?.summary.total;
                    setTrips(data);
                }


            } catch (error: any) {

                handleApiError(error, showErrorToast, signOut);

            } finally {
                setLoadingTours(false);
            }
        };

        fetchTripData();
    }, []);

    const handleClose = () => {
        setEnquireData(initialEnquireNow);
        setErrors(initialEnqNowError);
        setModalShow(!modalShow)
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, type, value } = event.target;
        // Handle checkbox input
        let inputValue: any;
        // Handle checkbox input
        if (type === "checkbox") {
            inputValue = (event.target as HTMLInputElement).checked ? 1 : 0; // Convert to 1 or 0
        } else {
            inputValue = value;
        }

        setEnquireData((prevState) => ({ ...prevState, [name]: inputValue }));
        setErrors((prevState) => ({ ...prevState, [name]: "" }));
    };
    const handleSelectChange = (option: OptionType | null) => {

        setEnquireData(prev => ({
            ...prev,
            meta_currency_code: option?.value || ''
        }));
        setErrors({ ...errors, meta_currency_code: '' });
    };
    const handleSliderChange = (value: number | number[]) => {
        if (Array.isArray(value)) {
            setRange(value as [number, number]);
            setEnquireData(prevState => ({
                ...prevState,
                budget_min: value[0],
                budget_max: value[1]
            }));
            setErrors(prevState => ({
                ...prevState,
                budget_min: '',
                budget_max: ''
            }));
        } else {
            console.warn("Single value encountered, but expected range values.");
        }
    };

    const handleSelectChangeStyle = (option: SingleValue<{ value: string; label: string }> | null,) => {
        // setTravelStyle(option)
        setEnquireData(prevState => ({
            ...prevState,
            travel_style: option?.value ?? "",
            travel_style_option: option ?? { value: "", label: "" }
        }));
        setErrors(prevState => ({
            ...prevState,
            travel_style: ''
        }));
    };

    const handleSelectChangeTrip = (event: ChangeEvent<HTMLSelectElement>) => {
        const { name, value, selectedIndex } = event.target;
        const tripVersion = event.target[selectedIndex].getAttribute('data-trip-version');

        setEnquireData((prevState) => ({
            ...prevState,
            [name]: value,          // Set trip_uuid
            trip_version: tripVersion // Set trip_version
        }));

        setErrors((prevState) => ({ ...prevState, [name]: "" }));
    };
    return (
        <Modal show={modalShow} size="xl" onHide={handleClose} id="create-trip-container" centered={true} backdrop="static" scrollable={true}>
            <Modal.Header className="header-passport">
                <div className="d-flex header-title-passport">
                    <p className="modal-profile-title mb-0">New Request</p>
                </div>
                <div className="modal-close-profile" onClick={handleClose}>
                    <FontAwesomeIcon icon={faClose} className="fs-9" />
                </div>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleSubmit} >
                    <div className="d-flex flex-column border border-gray-400 rounded-1 p-2">
                        <p className="fs-6 fw-medium m-0">Traveler Details</p>
                        <div className="table-wrapper">
                            <Table size='sm' hover>
                                <thead>
                                    <tr>
                                        <th>User name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{travelerData?.first_name ?? ""} {travelerData?.last_name ?? ""}</td>
                                        <td>{travelerData?.email ?? ""}</td>
                                        <td>{travelerData?.phone_number ?? ""}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="d-flex flex-column border border-gray-400 rounded-1 p-2 mt-3">
                        <p className="fs-6 fw-medium m-0">Trips Details</p>
                        <div className="row my-3">
                            <div className="col-12">
                                <Form.Group>
                                    <Form.Label className='mb-1'>Listing*</Form.Label>

                                    <Form.Select
                                        aria-label="Default select example"
                                        value={enquireData?.trip_uuid}
                                        onChange={handleSelectChangeTrip}
                                        name="trip_uuid"
                                        className="flex-grow-1"
                                        id="select-days"
                                        style={{ height: "50px", minWidth: "200px" }}
                                    >
                                        <option className="select-option-style" value="">Select</option>
                                        {trips?.map((item, index) => (
                                            <option
                                                className="select-option-style"
                                                key={index}
                                                value={item?.trip_uuid}
                                                data-trip-version={item?.version}  // Store trip_version here
                                            >
                                                {item?.title}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {errors.trip_uuid && (
                                        <div className={errors.trip_uuid ? 'error-feedback' : ""}>
                                            {errors.trip_uuid}
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3 mb-md-0">
                                <div className="d-flex gap-4">
                                    <Form.Group controlId={"months-select"}>
                                        <Form.Label className='mb-1'>Trip Date</Form.Label>
                                        <div style={{ width: "100%" }} className="custom-datepicker-container">
                                            <DatePicker className="form-control"
                                                selected={enquireData?.trip_date}
                                                onChange={(date: Date | null) => {
                                                    setEnquireData({ ...enquireData, trip_date: date! })
                                                    setErrors(prevState => ({
                                                        ...prevState,
                                                        trip_date: ''
                                                    }));
                                                }}
                                            />

                                        </div>
                                        {errors.trip_date && (
                                            <div className={errors.trip_date ? 'error-feedback' : ""}>
                                                {errors.trip_date}
                                            </div>
                                        )}
                                    </Form.Group>
                                    <div className="d-flex align-items-end">
                                        <Form.Group className="" controlId="exampleForm.ControlInput1">
                                            <Form.Check
                                                type='checkbox'
                                                name="is_date_firm"
                                                label={"Flex?"}
                                                checked={enquireData?.is_date_firm === 1}
                                                onChange={handleInputChange}
                                            />
                                            <div className="checkbox-error-container">
                                                {errors.is_date_firm && (
                                                    <div className={errors.is_date_firm ? 'error-feedback' : ""}>
                                                        {errors.is_date_firm}
                                                    </div>
                                                )}
                                            </div>

                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3 mb-md-0">
                                <div className="d-flex   gap-4">
                                    <Form.Group className="flex-grow-1">
                                        <Form.Label className='mb-1'>Length</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            value={enquireData?.trip_duration}
                                            onChange={handleInputChange}
                                            name="trip_duration"
                                            className="trip-length-select"
                                            id="select-days"
                                           
                                        >
                                            <option className="select-option-style" value="">Select</option>
                                            {[...Array(30).keys()].map(i => (
                                                <option className="select-option-style" key={i + 1} value={i + 1}>{i + 1}</option>
                                            ))}

                                        </Form.Select>

                                        {errors.trip_duration && (
                                            <div className={errors.trip_duration ? 'error-feedback' : ""}>
                                                {errors.trip_duration}
                                            </div>
                                        )}
                                    </Form.Group>
                                    <div className="d-flex align-items-end flex-shrink-1">
                                        <Form.Group className="" controlId="exampleForm.ControlInput1">

                                            <Form.Check
                                                type='checkbox'
                                                name="is_trip_lenth_firm"
                                                label={"Flex?"}
                                                checked={enquireData?.is_trip_lenth_firm === 1}
                                                onChange={handleInputChange}
                                            />
                                            <div className="checkbox-error-container">
                                                {errors.is_trip_lenth_firm && (
                                                    <div className={errors.is_trip_lenth_firm ? 'error-feedback' : ""}>
                                                        {errors.is_trip_lenth_firm}
                                                    </div>
                                                )}
                                            </div>
                                        </Form.Group>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                <Form.Group >
                                    <Form.Label className='mb-1'>Passengers</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        value={enquireData?.no_of_travellers}
                                        onChange={handleInputChange}
                                        name="no_of_travellers"
                                        className="flex-grow-1"
                                        id="select-days"
                                        style={{ height: "50px", minWidth: "200px" }}
                                    >
                                        <option className="select-option-style" value="">Select</option>
                                        {[...Array(30).keys()].map(i => (
                                            <option className="select-option-style" key={i + 1} value={i + 1}>{i + 1}</option>
                                        ))}

                                    </Form.Select>

                                    {errors.no_of_travellers && (
                                        <div className={errors.no_of_travellers ? 'error-feedback' : ""}>
                                            {errors.no_of_travellers}
                                        </div>
                                    )}
                                </Form.Group>
                            </div>


                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-0">

                                <Form.Group controlId="meta_currency_code" className="mb-">
                                    <Form.Label className="mb-1">Currency</Form.Label>
                                    <Select
                                        defaultValue={selectedOption}
                                        onChange={handleSelectChange}
                                        options={currenciesOptions}
                                        className={`about-trip-select-container ${errors?.meta_currency_code ? 'is-invalid' : ''}`}
                                        classNamePrefix="about-trip-select"
                                        isSearchable={true}
                                        placeholder="Currency"

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.meta_currency_code}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">

                                <Form.Label className="mb-1">Approximate Budget Per Person</Form.Label>
                                <div style={{ width: "100%" }}>
                                    <Slider
                                        range
                                        min={0}
                                        max={50000}
                                        value={range}
                                        allowCross={false}
                                        onChange={handleSliderChange}
                                    />
                                </div>
                                {errors.no_of_travellers && (
                                    <div className={errors.no_of_travellers ? 'error-feedback' : ""}>
                                        {errors.no_of_travellers}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-4">
                                <Form.Group controlId="meta_currency_code" className="mb-0">
                                    <div >
                                        <Form.Label className="mb-1">What is your travel style preference</Form.Label>
                                        <Select
                                            defaultValue={enquireData?.travel_style_option}
                                            onChange={handleSelectChangeStyle}
                                            options={travelTripTypeTagOption}
                                            className={`form-select-container`}
                                            classNamePrefix="form-select"
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Travel Style"
                                            styles={customStylesSelect}
                                            maxMenuHeight={140}

                                        />
                                        {errors.travel_style && (
                                            <div className={errors.travel_style ? 'error-feedback' : ""}>
                                                {errors.travel_style}
                                            </div>
                                        )}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-4">
                                <Form.Group className="" controlId="title">
                                    <Form.Label className="mb-1">Departing From</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="depature_from"
                                        placeholder="Departing From"
                                        value={enquireData?.depature_from}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.depature_from}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.depature_from}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>



                    </div>
                    <div className="d-flex flex-column border border-gray-400 rounded-1 p-2 mt-3">
                        <p className="fs-6 fw-medium m-0">Request Details</p>
                        {/* <Form.Group className="mb-3" controlId="firstName">
                            <div><Form.Label className="">Notes</Form.Label></div>
                            <Suspense fallback={<div className='step-content-0-main'>Loading...</div>}>
                                <EditorProvider>
                                    <Editor
                                        value={enquireData?.notes}
                                        onChange={(e) => {
                                            setEnquireData((prev: any) => ({
                                                ...prev,
                                                notes: e.target.value
                                            }));
                                            setErrors({ ...errors, notes: '' });
                                        }}
                                    />
                                </EditorProvider>
                                {errors?.notes && (
                                    <div className={errors?.notes ? 'error-feedback' : ""}>
                                        {errors?.notes}
                                    </div>
                                )}
                            </Suspense>
                        </Form.Group> */}
                        <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label className="mb-1">Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                maxLength={1000}
                                className='text-area-custom'
                                placeholder='write here...'
                                name="notes"
                                value={enquireData.notes}
                                onChange={handleInputChange}
                                isInvalid={!!errors.notes}
                            />
                            {errors?.notes && (
                                <div className={errors?.notes ? 'error-feedback' : ""}>
                                    {errors?.notes}
                                </div>
                            )}
                        </Form.Group>
                    </div>

                    <div className="custom-btn-size d-flex  justify-content-evenly mt-4">
                        <button onClick={handleClose} type="button" className="btn btn-outline-primary">
                            {"Cancel"}
                        </button>
                        <button type='submit' className=" btn btn-primary">
                            {"Save"}
                        </button>
                    </div>
                </Form>
            </Modal.Body>

        </Modal>
    )
}

export default TripRequestModal