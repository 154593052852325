import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import Avatar from "components/base/Avatar";
import avtar1 from 'assets/img/travel/profile/profile.jpg';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import parse from 'html-react-parser';
import { Link, useNavigate } from "react-router-dom";
import listingDummyImg from "assets/img/travel/creator/dummylisting.png";
import currencySymbols from "services/currencySymbols";
import TripLikeIcon from "components/travel-common/TripLikeIcon";

interface TripCardProps {
  item: any;  // Ideally, define an interface for `item` with proper types
  markWishList: (tripId: string) => void;
  title: string | "";
}

const LocationLink: React.FC<{ item: any, startLocation: string }> = ({ item, startLocation }) => {

  return (
    <Link
      className="trip-destination"
      to={`/top-destinations/${item?.id}/${item?.slug}`}
    >
      <FontAwesomeIcon icon={faMapMarkerAlt} className="trip-destination-icon fs-12" />
      <span className="ms-1">{startLocation}</span>
    </Link>
  );
};


const TripCard: React.FC<TripCardProps> = ({ item, markWishList, title }) => {
  const navigate = useNavigate()
  const startLocation = item?.startLocation?.name || "";



  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-5 px-0 px-sx-0 px-md-3 px-lg-3 px-xl-3 px-xxl-3">
      <Card className="card-trip ">
        {item?.listingImages?.length === 0 ? (
          <div className='image-container'>
            <Card.Img onClick={() => { navigate(`/trips-and-destination/tour/${item?.slug}`) }} variant="top" className='cardImg customCardImg trip-card-container' src={listingDummyImg} />
            <div className="trip-location">
              <LocationLink item={item?.startLocation} startLocation={startLocation} />
              <div onClick={() => markWishList(item.slug)} className="trip-like-container">
                <TripLikeIcon iswishlisted={item?.iswishlisted === 1} />
              </div>
            </div>
          </div>
        ) : item?.listingImages?.length === 1 ? (
          <div className='image-container'>
            <Card.Img onClick={() => { navigate(`/trips-and-destination/tour/${item?.slug}`) }} variant="top" className='cardImg customCardImg trip-card-container' src={item.listingImages[0]?.url || listingDummyImg} />
            <div className="trip-location">
              <LocationLink item={item?.startLocation} startLocation={startLocation} />
              <div onClick={() => markWishList(item.slug)} className="trip-like-container">
                <TripLikeIcon iswishlisted={item?.iswishlisted === 1} />
              </div>
            </div>
          </div>
        ) : (
          <Swiper
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
            id="trip-card-swiper"
            loop={true}
            autoplay={{
              delay: 2000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
          >
            {item?.listingImages?.map((image: any, index: number) => (
              <SwiperSlide key={index}>
                <Card.Img onClick={() => { navigate(`/trips-and-destination/tour/${item?.slug}`) }} variant="top" className="customCardImg trip-card-container" src={image?.url ?? listingDummyImg} alt={image?.alt ?? "travelcreator"} />
              </SwiperSlide>
            ))}
            <div className="trip-location">
              <LocationLink item={item?.startLocation} startLocation={startLocation} />
              <div onClick={() => markWishList(item.slug)} className="trip-like-container">
                <TripLikeIcon iswishlisted={item?.iswishlisted === 1} />
              </div>
            </div>
          </Swiper>
        )}
        <Card.Body onClick={() => { navigate(`/trips-and-destination/tour/${item?.slug}`) }} className="trip-card-container">
          <Card.Title>
            <p className="footer-nav-category">{item?.title}</p>
          </Card.Title>
          <div className="d-flex flex-row align-items-center mb-0">
            <Avatar src={item?.agent?.profileImage?.url  ?? avtar1} size="2xl" className="card-profile-image" />
            <div className="d-flex flex-column gap-1 ps-3 created-by-agent">
              <p className="created-by m-0">Created by</p>
              <p className="creator-title m-0">{item?.agent?.name}</p>
            </div>
          </div>
          <div className="trip-discription">
            <div>{item?.description ? parse(item?.description || '') : ''}</div>
          </div>
          <div className="trip-price">
            <p className="m-0">
              From {currencySymbols(item?.priceExchange?.currency || '')}{' '}
              {new Intl.NumberFormat().format(item?.priceExchange?.price)} / person
            </p>
          </div>
          <div className="custom-btn-size d-flex justify-content-center align-items-center">
            <Link
              state={{ trips: item, source: "creator" }}
              to={{ pathname: `/trips-and-destination/tour/${item?.slug}` }}
              className="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-2"
            >
              {title ? title : "Enquire Now"}
            </Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TripCard;