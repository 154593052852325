import React, { useState, useEffect, useCallback, useRef } from 'react';
import Select, {  SingleValue } from 'react-select';
import { Form } from 'react-bootstrap';
import { customStylesSelect } from 'services/customStylesForms';
import { useAuth } from 'providers/AuthContext';
import apiCall from 'services/api';
import { handleApiError } from 'services/handleApiError';
import { useToast } from 'providers/ToastProvider';
import { useMeta } from 'providers/MetaContext';
import { getHeaders } from 'services/getHeaders';

// Define the type for the options
interface OptionType {
  value: string;
  label: string;
}

type CustomSelectProps = {
  label: string;
  controlId: string;
  value: OptionType | null;
  onChange: (option: SingleValue<OptionType>) => void;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  errors?: string;
};

 // Set your default value here
// Define the props for the Leads component
const CustomSelectForm2: React.FC<CustomSelectProps> = ({
  label,
  controlId,
  value,
  onChange,
  placeholder = 'Select...',
  isDisabled = false,
  errors = ''
}) => {
  const { showErrorToast } = useToast();
  const [defaultValue, setDefaultValue] = useState(value)
  const [formData, setFormData] = useState<OptionType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userTkn, signOut } = useAuth();
  const { currency } = useMeta();
  const [isClearable, setIsClearable] = useState(true);
  const [summary, setSummary] = useState({
    total: 0,
    page: 0,
    pageSize: 0,
    total_page: 0,
  });

  const isTypingRef = useRef<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const fetchOptions = useCallback(async (page: number, query: string = '') => {
    
    setIsLoading(true);
    try {
      const response = await apiCall({
        url: `master/locations?page=${page}&name=${query}`,
        method: 'GET',
        headers: getHeaders(userTkn, currency)
      });

      const data = response?.data?.MasterLocation?.data;
      const summary = response?.data?.MasterLocation?.summary;
      setSummary({
        "total": summary?.total,
        "page": summary?.page,
        "pageSize": summary?.pageSize,
        "total_page": summary?.total_page,
      })
      if (data && data.length > 0) {
        const transformedOptions = data.map((item: any) => ({
          value: String(item.id),
          label: item.name
        }));
        setOptions(prevOptions => page === 1 ? transformedOptions : [...prevOptions, ...transformedOptions]);
        setHasMore(data.length > 0);
      } else {
        setHasMore(false);
      }
    } catch (error: any) {
      handleApiError(error, showErrorToast, signOut);
    } finally {
      setIsLoading(false);
    }
  }, [userTkn]);

  const fetchDefaultOption = useCallback(async () => {
    setIsLoading(true);
    if (!defaultValue) {
      // If defaultValue is null, exit early
      setIsLoading(false);
      return;
    }
    try {
      const response = await apiCall({
        url: `master/locations?name=${defaultValue.label}`,
        method: 'GET',
        headers: getHeaders(userTkn, currency)
      });
      const data = response?.data?.MasterLocation?.data;
      if (data && data.length > 0) {
        const transformedOptions = data.map((item: any) => ({
          value: String(item.id),
          label: item.name,
        }));
        setOptions(transformedOptions);
        const matchedOption = transformedOptions.find(
          (option: OptionType) => option.label === defaultValue.label
        );
        setFormData(matchedOption || null);
      }
    } catch (error: any) {
      handleApiError(error, showErrorToast, signOut);
    } finally {
      setIsLoading(false);
    }
  }, [userTkn]);

  useEffect(() => {
  
      fetchOptions(1, inputValue);
    
  }, [fetchOptions, inputValue]);

  const handleInputChange = (newValue: string) => {
    const value = newValue.replace(/\W/g, '');
    setInputValue(value);
    setPage(1);
    isTypingRef.current = true;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      fetchOptions(1, value);
      isTypingRef.current = false;
    }, 3000); // Adjust the debounce delay as needed
  };



  const handleMenuScrollToBottom = () => {
    if ((summary.total_page > summary.page) && !isLoading) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchOptions(nextPage, inputValue);
    }
  };

  const handleStartLocationChange = (option: SingleValue<OptionType>) => {
    setFormData(option);
    onChange(option)
    isTypingRef.current = false; // Reset typing flag
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the timeout to prevent unnecessary fetch
    }
  };

  useEffect(() => {
    if (defaultValue) { // Check if defaultValue is not null
      if (!options.some((option) => Number(option.value) === Number(defaultValue.value))) {
        fetchDefaultOption();
      } else {
        const matchedOption = options.find((option) => option.value === defaultValue.value);
        setFormData(matchedOption || null);
      }
    }
  }, []);

  return (
      <Form.Group className="mb-3" controlId={controlId}>
        {label ? <Form.Label className="mb-1">{label}</Form.Label> : ""}
        <Select<OptionType>
         value={formData}
        //  defaultValue={defaultValue}
         onChange={handleStartLocationChange}
         options={options}
         className={`form-select-container ${errors ? 'is-invalid' : ''}`}
         classNamePrefix="form-select"
         isSearchable={true}
         placeholder="Start Location"
         styles={customStylesSelect}
         isDisabled={loading || isDisabled}
         onInputChange={handleInputChange}
        //  onMenuScrollToBottom={handleMenuScrollToBottom}
         isLoading={isLoading}
         isClearable={isClearable}
        />
        <Form.Control.Feedback type="invalid">
          {errors}
        </Form.Control.Feedback>
      </Form.Group>
  );
};

export default CustomSelectForm2;
