import { Col, Form, Row, Spinner } from "react-bootstrap";
import BannerImage from "assets/img/travel/banner/search.jpg";
import thumb1 from "assets/img/travel/blogs/blog5.png";
import thumb2 from "assets/img/travel/blogs/blog3.png";
import thumb3 from "assets/img/travel/blogs/blog6.png";
import Unicon from "components/base/Unicon";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import Testimonial from "components/asseenin/Testimonial";
import { useEffect, useState } from "react";
import {
  BlogFiltersFront,
  BlogProps,
  BlogsCategoryFront,
  ChildItemBlog,
  initialChildItemBlog,
  initialSummary,
  SummaryProps,
} from "types/interfaces";
import { handleApiError } from "services/handleApiError";
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import { useToast } from "providers/ToastProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import DummyBlogCard from "components/common/dummycard/DummyBlogCard";
import BlogCard from "components/cards/BlogCard";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import { useMaster } from "providers/MasterContext";
import useWindowWidth from "hooks/useWindowWidth";
import { Card } from "react-bootstrap";
import { formattedDate } from "./formattedDate";
import DummyBlogCardSmall from "components/common/dummycard/DummyBlogCardSmall";

const BlogsListing = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { currency } = useMeta();
  const windowWidth = useWindowWidth();
  const { signOut, userTkn } = useAuth();
  const { frontBlogCategory } = useMaster();
  const { showErrorToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [blogList, setBlogList] = useState<BlogProps[]>([]);
  const [summary, setSummary] = useState<SummaryProps>(initialSummary);
  const [blogContentItems, setBlogContentItems] =
    useState<ChildItemBlog>(initialChildItemBlog);
  const [filters, setFilters] = useState<BlogFiltersFront>({
    pageIndex: 1,
    search: "",
    pageSize: 10,
    category: {
      id: null,
      name: "",
      slug: "",
      blogCount: 0,
    },
  });

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        const [contentItemsResponse] = await Promise.all([
          apiCall({
            url: `cms/content-items/blog`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
        ]);

        if (contentItemsResponse?.status === 200) {
          const contentData =
            contentItemsResponse.data?.ContentItem?.data?.content?.childs;
          setBlogContentItems(contentData);
        }

        if (contentItemsResponse?.data?.errors) {
          const errors = contentItemsResponse.data.errors;
          // Handle errors if needed
          console.error("Errors:", errors);
        }
      } catch (error: any) {
        console.error("Error fetching data:", error);
        handleApiError(error, showErrorToast, signOut);
      } finally {
      }
    };
    fetchTripData();
  }, [showErrorToast]);

  const buildUrl = (filters: BlogFiltersFront, pageIndex: number) => {
    const params = new URLSearchParams({
      page: pageIndex.toString(),
      pageSize: String(filters?.pageSize),
    });

    // Add filter parameters only if they have a meaningful value
    if (filters.search) params.append("title", filters.search.toString());
    if (filters.category?.id)
      params.append("category_id", String(filters?.category?.id));

    // Check if no filters were applied
    if (Array.from(params).length === 2) {
      // Only 'page' and 'pageSize' are in params
      return `cms/frontend-blog?page=${pageIndex}&pageSize=10`;
    }

    return `cms/frontend-blog?${params.toString()}`;
  };

  const buildUrlFilter = (filters: BlogFiltersFront, pageIndex: number) => {
    const params = new URLSearchParams({
      page: pageIndex.toString(),
      pageSize: String(filters?.pageSize),
    });

    // Add filter parameters only if they have a meaningful value
    if (filters.search) params.append("title", filters.search.toString());
    if (filters.category?.id)
      params.append("category_id", String(filters?.category?.id));

    // Check if no filters were applied
    if (Array.from(params).length === 2) {
      // Only 'page' and 'pageSize' are in params
      return `page=${pageIndex}&pageSize=10`;
    }

    return `${params.toString()}`;
  };

  const fetchBlogData = async () => {
    try {
      setLoading(true);
      const url = buildUrl(filters, filters?.pageIndex);
      const blogsResponse = await apiCall({
        url,
        method: "GET",
        headers: getHeaders(userTkn, currency),
      });

      if (blogsResponse?.status === 200) {
        const data = blogsResponse.data?.FrontendBlog?.data;
        const summaryData = blogsResponse.data?.FrontendBlog?.summary;

        setSummary({
          total: summaryData.total,
          page: summaryData.page,
          pageSize: summaryData.pageSize,
          total_page: summaryData.total_page,
        });

        setBlogList((prev) => {
          if (filters?.pageIndex === 1) {
            // Reset data if fetching the first page
            return [...data];
          } else {
            // Append new data for subsequent pages
            return [...prev, ...data];
          }
        });
      }

      if (blogsResponse?.data?.errors) {
        const errors = blogsResponse.data.errors;
        console.error("Errors:", errors);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      handleApiError(error, showErrorToast, signOut);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // Retrieve values from searchParams
  //   const page = searchParams.get('page');
  //   const categoryId = searchParams.get('category_id');
  //   const title = searchParams.get('title');

  //   const pageIndex = page ? Number(page) : 1;
  //   const category = categoryId ? {
  //     id: Number(categoryId),
  //     name: "",
  //     slug: "",
  //     blogCount: 0
  //   } : {
  //     id: null,
  //     name: "",
  //     slug: "",
  //     blogCount: 0
  //   };

  //   setFilters({
  //     pageIndex,
  //     search: title || "",
  //     pageSize: 10,
  //     category
  //   });

  // }, [searchParams]);

  useEffect(() => {
    fetchBlogData();
  }, [filters?.category, filters?.pageIndex]);

  // Add useEffect for Enter key press
  useEffect(() => {
    const handleEnterKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        const url = buildUrlFilter(filters, filters?.pageIndex);
        navigate(`/blogs?${url}`);
        fetchBlogData(); // Fetch data when Enter key is pressed
      } else if (event.key === "Backspace") {
        // If Backspace is pressed and the search filter is cleared, fetch data
        if (filters.search.trim() === "") {
          const url = buildUrlFilter(filters, filters?.pageIndex);
          navigate(`/blogs?${url}`);
          fetchBlogData();
        }
      }
    };

    window.addEventListener("keydown", handleEnterKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [filters]);

  const handleCategory = (item: BlogsCategoryFront) => {
    setFilters((prev) => ({
      ...prev,
      category: item,
    }));
    const url = buildUrlFilter(filters, filters?.pageIndex);
    navigate(`/blogs?${url}`);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleLoadMore = () => {
    setLoadingMore(true);
    if (filters?.pageIndex <= summary?.total_page) {
      const newPage = filters?.pageIndex + 1;
      setFilters((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex + 1,
      }));
      const url = buildUrlFilter(filters, newPage);
      navigate(`/blogs?${url}`);
    } else {
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
  };

  return (
    <>
      <section
        id="hero-faq"
        className="d-flex flex-column justify-content-center"
        style={{ background: `url(${BannerImage})` }}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="hero-heading fw-bold high-light-white text-center">
                {blogContentItems?.blog_banner_text?.item_value ?? ""}{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5">
        <div className="container">
          <Row>
            {/* Left Column - Blog Content */}
            <Col
              sm={12}
              md={12}
              lg={12}
              xl={7}
              xxl={8}
              className="d-flex flex-column order-2 order-sm-2 order-md-2 order-lg-2 order-xl-1 order-xxl-1"
            >
              {/* Blog Header with Category and Search */}
              <Row className="mb-4">
                <Col xs={12} md={6}>
                  {filters?.category?.name && (
                    <h2>
                      {filters?.category?.name}{" "}
                      <span className="blog-count-bg">{summary?.total}</span>
                    </h2>
                  )}
                </Col>
                <Col xs={12} md={6} className="">
                  <Form onSubmit={handleSubmit} className="blog-search-form">
                    <Form.Group className="mb-3" controlId="blogSearch">
                      <Form.Control
                        type="text"
                        value={filters?.search}
                        onChange={(e) =>
                          setFilters({ ...filters, search: e.target.value })
                        }
                        className="blog-search"
                        placeholder="Search"
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>

              {/* Blog Posts */}
              {loading && ( // Full page loading overlay
                <Row>
                  <DummyBlogCard />
                  <DummyBlogCard />
                  <DummyBlogCard />
                  <DummyBlogCard />
                </Row>
              )}
              {!loading && (
                <Row>
                  {Array.isArray(blogList) && blogList?.length > 0 ? (
                    <>
                      {blogList?.map((item, index) => (
                        <BlogCard key={index} item={item} />
                      ))}
                    </>
                  ) : (
                    <div className="col-12  d-flex justify-content-center">
                      <h3>Sorry No Blog Found!!</h3>
                    </div>
                  )}
                  {blogList?.length > 0 ? (
                    <>
                      {((Number(filters?.pageIndex) === 1 && !loading) ||
                        Number(filters?.pageIndex) > 1) && (
                        <div className="custom-btn-size d-flex justify-content-center mt-3">
                          {Number(summary?.page) ===
                          Number(summary?.total_page) ? (
                            <div className="d-flex justify-content-center"></div>
                          ) : (
                            <button
                              onClick={handleLoadMore}
                              className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2"
                            >
                              {loadingMore ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                ""
                              )}
                              <span className="ml-2">{"Load More"}</span>
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {/* {!loading && <div className="d-flex justify-content-center"><h3>Oops No Record Found !!</h3></div>} */}
                    </>
                  )}
                </Row>
              )}
            </Col>

            {/* Right Column */}
            <Col
              sm={12}
              md={12}
              lg={12}
              xl={5}
              xxl={4}
              className="order-1 order-sm-1 order-md-1 order-lg-1 order-xl-2 order-xxl-2"
            >
              <div className="categories-blog">
                <div className="blog">
                  <h2>{blogContentItems?.blog_category?.item_value ?? ""}</h2>
                </div>
              </div>

              <div className="mb-5">
                {frontBlogCategory &&
                  frontBlogCategory.slice(0, 5).map((item, index) => (
                    <div
                      key={index}
                      className="category-blog d-flex align-items-center justify-content-between"
                      onClick={() => {
                        handleCategory(item);
                      }}
                    >
                      <p className="m-0 py-3">{item?.name}</p>
                      <p className="m-0 dum-line">{item?.blogCount}</p>
                    </div>
                  ))}
              </div>

              {windowWidth > 1200 && (
                <div className="other-post">
                  <h3 className="post">
                    {blogContentItems?.blog_other?.item_value ?? ""}
                  </h3>
                  {loading && ( // Full page loading overlay
                    <Row className="g-4 flex-column">
                      <DummyBlogCardSmall />
                      <DummyBlogCardSmall />
                      <DummyBlogCardSmall />
                    </Row>
                  )}
                  {!loading && (
                    <Row className="g-5">
                      {Array.isArray(blogList) && blogList?.length > 0 ? (
                        <>
                          {blogList?.slice(0, 3)?.map((item, index) => {
                            return (
                              <Col lg={12}>
                                <Card
                                  style={{ maxWidth: "32rem" }}
                                  key={index}
                                  onClick={() =>
                                    navigate(`/blogs/${item?.slug}`)
                                  }
                                  className="blogs-card"
                                >
                                  <Row className="g-0">
                                    <Col md={4}>
                                      <img
                                        className="img-fluid h-100 rounded-start"
                                        src={item?.listingImage?.url}
                                        alt="..."
                                      />
                                    </Col>
                                    <Col md={8}>
                                      <Card.Body className="pt-3 pl-3 pr-3 pb-0">
                                        <Card.Title as="h4">
                                          {item?.category?.name}
                                        </Card.Title>
                                        <Card.Text>
                                          {item?.title ?? ""}
                                        </Card.Text>
                                        <Card.Text>
                                          <div className="d-flex flex-column align-items-left gap-2">
                                            <p className="simple m-0">
                                              by{" "}
                                              <span className="fw-bold">
                                                {item?.author}
                                              </span>
                                            </p>
                                            <p className="calendar m-0 pb-3">
                                              <Unicon
                                                icon={UilCalendarAlt}
                                                size={20}
                                                color="#5ca0e0"
                                              />
                                              <span className="ms-1">
                                                {formattedDate(
                                                  item?.published_date_time
                                                )}
                                              </span>
                                            </p>
                                          </div>
                                        </Card.Text>
                                      </Card.Body>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            );
                          })}
                        </>
                      ) : (
                        <Col lg={12}>
                          <div className="">
                            {/* <h3>Sorry No Blog Found!!</h3> */}
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}
                </div>
              )}
            </Col>
            {windowWidth < 1200 && (
              <Col
                sm={12}
                md={12}
                lg={12}
                xl={5}
                xxl={4}
                className="order-3 order-sm-3 order-md-3 order-lg-3  "
              >
                <div className="other-post mt-5 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-0 mt-xxl-0">
                  <h3 className="post">
                    {blogContentItems?.blog_other?.item_value ?? ""}
                  </h3>
                  {loading && ( // Full page loading overlay
                    <Row className="g-4 flex-column">
                      <DummyBlogCardSmall />
                      <DummyBlogCardSmall />
                      <DummyBlogCardSmall />
                    </Row>
                  )}
                  {!loading && (
                    <Row className="g-4 flex-column">
                      {Array.isArray(blogList) && blogList?.length > 0 ? (
                        <>
                          {blogList?.slice(0, 3)?.map((item, index) => {
                            return (
                              <Col lg={12}>
                                <Card
                                  style={{ maxWidth: "32rem" }}
                                  onClick={() =>
                                    navigate(`/blogs/${item?.slug}`)
                                  }
                                  className="blogs-card"
                                >
                                  <Row className="g-0">
                                    <Col md={4}>
                                      <img
                                        className="img-fluid h-100 rounded-start"
                                        src={item?.listingImage?.url}
                                        alt="..."
                                      />
                                    </Col>
                                    <Col md={8}>
                                      <Card.Body className="pt-3 pl-3 pr-3 pb-0">
                                        <Card.Title as="h4">
                                          {item?.category?.name}
                                        </Card.Title>
                                        <Card.Text>
                                          {item?.title ?? ""}
                                        </Card.Text>
                                        <Card.Text>
                                          <div className="d-flex flex-column align-items-left gap-2">
                                            <p className="simple m-0">
                                              by{" "}
                                              <span className="fw-bold">
                                                {item?.author}
                                              </span>
                                            </p>
                                            <p className="calendar m-0 pb-3">
                                              <Unicon
                                                icon={UilCalendarAlt}
                                                size={20}
                                                color="#5ca0e0"
                                              />
                                              <span className="ms-1">
                                                {formattedDate(
                                                  item?.published_date_time
                                                )}
                                              </span>
                                            </p>
                                          </div>
                                        </Card.Text>
                                      </Card.Body>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            );
                          })}
                        </>
                      ) : (
                        <Col lg={12}>
                          <div className="">
                            {/* <h3>Sorry No Blog Found!!</h3> */}
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </div>
      </section>
      <Testimonial />
    </>
  );
};

export default BlogsListing;
