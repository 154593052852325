import { useEffect } from "react";

// Component for adding the TrustIndex script
export const TrustIndexScriptReview = () => {
    useEffect(() => {
        // Check if the widget has already been added
        if (!document.querySelector("#trustIndexScriptReview")) {
            // Create a script element
            const script = document.createElement('script');
            script.src = 'https://cdn.trustindex.io/loader.js?00f40ba2069f2378ba0619759fa';
            script.defer = true;
            script.async = true;
            script.id = "trustIndexScriptReview"; // Add an ID to avoid duplicate scripts

            // Append the script to the div where it should be rendered
            const scriptTarget = document.getElementById('trustindex-container-review');
            if (scriptTarget) {
                scriptTarget.appendChild(script);
            }
        }
    }, []);

    return null; // This component doesn't render anything
};
