
const BookTrip = () => {
    let text = "Comming soon"
    return (

      <div className="vh-75 d-flex align-items-center justify-content-center">
      {text}
    </div>
    )
}

export default BookTrip
