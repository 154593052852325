import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

interface ViewAllTripCenterProps {
    title: string | "";
}

const ViewAllTripCenter: React.FC<ViewAllTripCenterProps> = ({ title }) => {
    const navigate = useNavigate()
    return (
        <div className="custom-btn-size d-flex justify-content-center justify-content-lg-center justify-content-xl-center justify-content-xxl-center">
            <div>
                <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={() => (navigate("/trips-and-destination"))}>{title ? title : "View All Trips"}  </Button>
            </div>
        </div>
    )
}

export default ViewAllTripCenter