import React from 'react';
import { Card } from 'react-bootstrap';
import './DummyCard.css'; // Ensure you create and import this CSS for dummy styles

const DummyAgentCard: React.FC = () => {
  return (
    <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-5">
      <Card className="dummy-card card-trip">
        {/* Dummy Cover Image Placeholder */}
        <div className="dummy-agent-container">
          <div className="dummy-agent-placeholder"></div>
        </div>

        {/* Dummy Profile Image Placeholder */}
        <div className="center-img d-flex align-items-center justify-content-center">
          <div className="rounded-circle-base-dummy">
            <div className="dummy-profile-img-placeholder"></div>
          </div>
        </div>

        {/* Dummy Text for Name and Location */}
        <div className="d-flex justify-content-center p-4">
          <div className='bottomSide-dummy   mb-4'>
            <h6 className="dummy-text-placeholder"></h6>

            <h6 className="dummy-text-placeholder"></h6>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DummyAgentCard;
