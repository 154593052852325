export const tripForms = {
  "steps": [
    {
      "step": 1,
      "title": "About Trip",

    },
    {
      "step": 2,
      "title": "Trip Details",
    
    },
    {
      "step": 3,
      "title": "Itinerary",
   
    },
    {
      "step": 4,
      "title": "Media",
    
    }
  ]
}