import BannerImage from "assets/img/travel/banner/privacy.jpg";
import Testimonial from "components/asseenin/Testimonial";
import { useEffect, useState } from "react";
import apiCall from "services/api";
import { initialStaticPages, StaticPagesProps } from "types/interfaces";
import parse from 'html-react-parser';
import { getHeaders } from "services/getHeaders";
import { useMeta } from "providers/MetaContext";
import { useAuth } from "providers/AuthContext";

const PrivacyPolicy = () => {
  const { currency } = useMeta();
  const { signOut, userTkn } = useAuth()
  const [privacyPolicy, setPrivacyPolicy] = useState<StaticPagesProps>(initialStaticPages);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiCall({
          url: `cms/privacy-policy`,
          method: 'GET',
          headers: getHeaders(userTkn, currency)
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            let policy = response?.data?.StaticPage?.data
            setPrivacyPolicy(policy);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section id="hero-faq" className="d-flex flex-column justify-content-center"
        style={{ background: `url(${privacyPolicy?.bannerImage?.url ?? BannerImage})` }} >

        <div className="container-fluid">
          <div className="row justify-content-center">

          </div>
        </div>
      </section>

      <section className="pb-0">
        <div className="container-fluid cont">
          <div className="row justify-content-center">
            <div className="col-xl-12 ">
              <div className="pt-2">
                <p className="main-heading  fw-bold high-light-black"> {privacyPolicy?.heading} </p>
              </div>
              <div className="pt-5">
                <p className="small-text"> {privacyPolicy?.description ? parse(privacyPolicy?.description || '') : ''} </p>
              </div>

            </div>
          </div>
        </div>
      </section>


      <Testimonial />
    </>
  )
}

export default PrivacyPolicy