import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useState, Suspense, useEffect } from "react";
import { Accordion, Button, Form } from "react-bootstrap"
import { ColourOption, CreatorProfileProps, initialCreatorProfile, initialCreatorProfileError, OptionType } from "types/interfaces";
import Editor, { EditorProvider } from 'react-simple-wysiwyg';
import avatar57 from 'assets/img/travel/profile/profile.jpg';
import profile1 from 'assets/img/travel/profile/250x400.png';
import profile2 from 'assets/img/travel/profile/450x320.png';
import banner1 from 'assets/img/travel/profile/1920x450.png';
import Unicon from "components/base/Unicon";
import { UilCloudUpload } from "@iconscout/react-unicons";
import "./profile.css"
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import { useToast } from "providers/ToastProvider";
import Select from 'react-select';
import { useMeta } from "providers/MetaContext";
import { customStylesSelect } from 'services/customStylesForms';
import CustomSelectForm2 from "components/select/CustomSelectForm2";
import {  default as ReactSelect, components, StylesConfig } from "react-select";
import parse from 'html-react-parser';
import { Modal } from "react-bootstrap";
import ImageManager from "common/ImageManager";
import { Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { handleApiError } from "services/handleApiError";

const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = "#E94560";
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? "#E94560"
                    : isFocused
                        ? "#e945601f"
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? "#E94560"
                        ? '#ffffff'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : "#e945601f"
                    : undefined,
            },
        };
    },

};


const ProfileCreator = () => {
    const location = useLocation();
    
    const { userTkn, signOut, userAgentCode, userProfile } = useAuth();
    // const currentUrl = window.location.origin + location.pathname;
    const currentUrl = `${window.location.origin}/agents/${userProfile?.user?.userAgent?.agent?.slug}`
    const { currenciesOptions, languageOptions } = useMeta()
    const { showErrorToast, showSuccessToast } = useToast();
    const [formData, setFormData] = useState<CreatorProfileProps>(initialCreatorProfile);
    const [errors, setErrors] = useState(initialCreatorProfileError)
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState(avatar57);
    // const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [editMode, setEditMode] = useState({
        creatorDetails: false,
        creatorImages: false,
        creatorContactInfo: false,
        creatorContent: false,
        creatorSocial: false
    });
    const [editCheck, setEditCheck] = useState(false);
    const [selectedOption, setSelectedOption] = useState({ optionSelected: [] });
    const [modalShow, setModalShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState<number[]>([]);
    const [selectedImageUrl, setSelectedImageUrl] = useState<any[]>([]);
    const [customImageSize, setCustomImageSize] = useState({
        minWidth: 450,
        minHeight: 325,
        maxWidth: 450,
        maxHeight: 325
    })
    const [imageType, setImageType] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [handleDpSelect, setHandleDpSelect] = useState(0)

    useEffect(() => {

        let isMounted = true;  // Flag to track component mount status
        const fetchTripData = async () => {
            try {
                setLoading(true);
                let slug = userProfile?.user?.userAgent?.agent?.slug
                const agentResponse = await apiCall({
                    url: `agents/${slug}`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                    },
                });
                userProfile



                if (agentResponse.status === 200) {
                    if (agentResponse?.data?.errors) {
                        const errors = agentResponse?.data.errors;
                        showErrorToast(errors);
                    } else {

                        let userData = agentResponse?.data?.Agent?.data;
                        let matchedCurrency = currenciesOptions?.find(c => c.value === userData?.default_currency_code);
                        let languageSelectedOptions = userData?.dictionary?.map((item: any) => ({ value: item?.code, label: item?.name }))
                        setFormData(prevData => ({
                            ...prevData,
                            name: `${userData?.name ?? ''}`,
                            location_id: userData?.location?.id || 0,
                            location_option: {
                                value: userData?.location?.id ?? 0,
                                label: userData?.location?.name ?? ''
                            },
                            default_currency_code: userData?.default_currency_code ?? '',
                            currency_option: matchedCurrency || { value: "", label: "" },
                            email_address: userData?.email_address,
                            phone_number: userData?.phone_number ?? "",
                            website_url: (userData?.website_url === 'null' || userData?.website_url == null) ? '' : userData?.website_url,
                            sub_title: userData?.sub_title ?? "",
                            about: userData?.about ?? "",
                            facebook: userData?.facebook,
                            instagram: userData?.instagram,
                            pinterest: userData?.pinterest,
                            tiktok: userData?.tiktok,
                            youtube: userData?.youtube,
                            twitter: userData?.twitter,
                            profile_image_url: userData?.profileImage?.url ?? "",
                            profile_image_id: userData?.profileImage?.id ?? null,
                            profile_image_2_url: userData?.profileImage2?.url ?? "",
                            profile_image_2_id: userData?.profileImage2?.id ?? null,
                            listing_image_id: userData?.listingImage?.id ?? null,
                            listing_image_url: userData?.listingImage?.url ?? "",
                            cover_image_id: userData?.coverImage?.id ?? null,
                            cover_image_url: userData?.coverImage?.url ?? ""
                        }));

                        setSelectedOption({ optionSelected: languageSelectedOptions })
                    }
                }

            } catch (error: any) {

                handleApiError(error, showErrorToast, signOut);

            } finally {
                if (isMounted) setLoading(false);
            }
        };



        fetchTripData();

        // Cleanup function
        return () => {
            isMounted = false;  // Mark component as unmounted
        };

    }, [signOut, showErrorToast, userProfile]);


    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, type, checked, value } = event.target;
        // Allow input only if it meets the length requirement
        if (name === "contact_no" && value.length > 15) {
            return;
        }


        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
        }));
        setErrors(prevState => ({
            ...prevState,
            [name]: ''
        }));
    };

    const toggleEditMode = (value: string) => {
        switch (value) {
            case "details":
                setEditMode(prev => ({
                    ...prev,
                    creatorDetails: !(editMode?.creatorDetails)
                }));
                break;
            case "contactInfo":
                setEditMode(prev => ({
                    ...prev,
                    creatorContactInfo: !(editMode?.creatorContactInfo)
                }));
                break;
            case "content":
                setEditMode(prev => ({
                    ...prev,
                    creatorContent: !(editMode?.creatorContent)
                }));
                break;
            case "social":
                setEditMode(prev => ({
                    ...prev,
                    creatorSocial: !(editMode?.creatorSocial)
                }));
                break;
            default:
                break;
        }

        setEditCheck(!editCheck)
    };


    const handleLocationChange = (option: OptionType | null) => {
        setFormData(prev => ({
            ...prev,
            location_id: option ? Number(option.value) : 0,
            location_option: option || { value: '', label: '' }
        }));
        setErrors({ ...errors, location_id: '' });
    };

    const handleCurrencyChange = (option: OptionType | null) => {
        setFormData(prev => ({
            ...prev,
            default_currency_code: option?.value || '',
            currency_option: option || { value: '', label: '' }
        }));
        setErrors({ ...errors, default_currency_code: '' });
    };



    const handleSubmitCreatorDetails = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { name, location_id, default_currency_code } = formData;
        let errors = { ...initialCreatorProfileError };


        let isValid = true;

        if (!name) {
            errors.name = "Name is required";
            isValid = false;
        } else if (/^\d/.test(name)) {
            errors.name = "Name cannot start with a number";
            isValid = false;
        } else if (name.length > 50) {
            errors.name = "Name cannot exceed 50 characters";
            isValid = false;
        }
        if (location_id === 0 || !location_id) {
            errors.location_id = "Location is required";
            isValid = false;
        }

        if (!isValid) {
            setErrors(errors);
            return;
        }

        if (isValid) {
            try {
                setLoading(true)
                const formDataSubmit = new FormData();
                formDataSubmit.append('name', formData?.name);
                formDataSubmit.append('location_id', String(formData?.location_id));
                formDataSubmit.append('default_currency_code', formData?.default_currency_code);

                const headers = userTkn
                    ? { 'x-access-token': userTkn }
                    : {};

                const response = await apiCall({
                    url: `agent-profile-update?section=details`,
                    method: 'POST',
                    data: formDataSubmit, // Change this line
                    headers,
                });
                if (response?.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;

                        // Dynamically set errors based on the response
                        Object.keys(errors).forEach((key) => {
                            const errorMessages = errors[key];
                            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                            // Set the error state for the corresponding field
                            setErrors((prevData: any) => ({
                                ...prevData,
                                [key]: firstErrorMessage,
                            }));
                        });
                    } else {
                        let data = response?.data?.Agent?.data;

                        let matchedCurrency = currenciesOptions?.find(c => c.value === data?.default_currency_code);
                        setFormData(prevData => ({
                            ...prevData,
                            name: `${data?.name ?? ''}`,
                            location_id: data?.location?.id || 0,
                            location_option: {
                                value: data?.location?.id ?? 0,
                                label: data?.location?.name ?? ''
                            },
                            default_currency_code: data?.default_currency_code ?? '',
                            currency_option: matchedCurrency || { value: "", label: "" }
                        }));
                        showSuccessToast("Update sucessfully!!")
                        toggleEditMode("details")
                    }
                }
            } catch (error:any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);


            }
        }
    }

    const handleSubmitContactInfo = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { email_address, phone_number, website_url } = formData;
        let errors = { ...initialCreatorProfileError };


        let isValid = true;

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email_address) {
            errors.email_address = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(email_address)) {
            errors.email_address = "Invalid email format";
            isValid = false;
        }

        // Validate phone only if a value is provided
        const phoneRegex = /^[+]?[0-9]{1,15}$/;
        if (phone_number && !phoneRegex.test(phone_number)) {
            errors.phone_number = "Invalid phone number. Only [0-9] and + are allowed, and it should be a maximum of 15 digits.";
            isValid = false;
        }


        if (!isValid) {
            setErrors(errors);
            return;
        }

        if (isValid) {
            try {
                setLoading(true)
                const formDataSubmit = new FormData();
                formDataSubmit.append('email_address', formData?.email_address);
                formDataSubmit.append('phone_number', String(formData?.phone_number));
                formDataSubmit.append('website_url', formData?.website_url);

                const headers = userTkn
                    ? { 'x-access-token': userTkn }
                    : {};

                const response = await apiCall({
                    url: `agent-profile-update?section=contact`,
                    method: 'POST',
                    data: formDataSubmit, // Change this line
                    headers,
                });

                if (response?.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;

                        // Dynamically set errors based on the response
                        Object.keys(errors).forEach((key) => {
                            const errorMessages = errors[key];
                            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                            // Set the error state for the corresponding field
                            setErrors((prevData: any) => ({
                                ...prevData,
                                [key]: firstErrorMessage,
                            }));
                        });
                    } else {
                        let data = response?.data?.Agent?.data;


                        setFormData(prevData => ({
                            ...prevData,
                            email_address: data?.email_address,
                            phone_number: data?.phone_number,
                            website_url: data?.website_url
                        }));
                        toggleEditMode("contactInfo")
                        showSuccessToast("Update sucessfully!!")

                    }
                }
            } catch (error:any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);


            }
        }
    }

    const handleSubmitContent = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { sub_title, languages, about } = formData;
        let errors = { ...initialCreatorProfileError };


        let isValid = true;

        if (!isValid) {
            setErrors(errors);
            return;
        }

        if (isValid) {
            try {
                setLoading(true);
                const formDataSubmit = new FormData();
                formDataSubmit.append('sub_title', formData?.sub_title);
                if (selectedOption?.optionSelected) {
                    selectedOption?.optionSelected?.forEach((lang: any) => {
                        formDataSubmit.append('languages[]', lang?.value);
                    });
                }
                formDataSubmit.append('about', formData?.about);

                const headers = userTkn
                    ? { 'x-access-token': userTkn }
                    : {};

                const response = await apiCall({
                    url: `agent-profile-update?section=content`,
                    method: 'POST',
                    data: formDataSubmit, // Change this line
                    headers,
                });

                if (response?.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;

                        // Dynamically set errors based on the response
                        Object.keys(errors).forEach((key) => {
                            const errorMessages = errors[key];
                            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                            // Set the error state for the corresponding field
                            setErrors((prevData: any) => ({
                                ...prevData,
                                [key]: firstErrorMessage,
                            }));
                        });
                    } else {
                        let data = response?.data?.Agent?.data;

                        let languageOptions = data?.dictionary?.map((item: any) => ({ value: item?.code, label: item?.name }))

                        setFormData(prevData => ({
                            ...prevData,
                            sub_title: data?.sub_title,
                            languages: languageOptions,
                            about: data?.about
                        }));
                        toggleEditMode("content")
                        showSuccessToast("Update sucessfully!!")

                    }
                }
            } catch (error:any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);


            }
        }
    }
    const validateSocialMediaUrl = (url: string, platform: string): boolean => {
        let pattern;

        if (platform === 'facebook') {
            pattern = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9(\.\?)?]/;
        } else if (platform === 'instagram') {
            pattern = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._]+\/?$/;
        } else if (platform === 'pinterest') {
            pattern = /^(https?:\/\/)?(www\.)?pinterest\.com\/[a-zA-Z0-9_]+\/?$/;
        } else if (platform === 'tiktok') {
            pattern = /^(https?:\/\/)?(www\.)?tiktok\.com\/@[a-zA-Z0-9._]+\/?$/;
        } else if (platform === 'twitter') {
            pattern = /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/;
        } else if (platform === 'youtube') {
            pattern = /^(https?:\/\/)?(www\.)?youtube\.com\/(channel\/|c\/|user\/|@)[a-zA-Z0-9_-]+\/?$/;
        } else {
            return false;
        }

        return pattern.test(url);
    };

    const handleSubmitSocial = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { facebook, instagram, pinterest, tiktok, twitter, youtube } = formData;
        let errors = { ...initialCreatorProfileError };
        let isValid = true;

        // Validate each social media URL if it has a value
        if (facebook && !validateSocialMediaUrl(facebook, 'facebook')) {
            errors.facebook = 'Invalid Facebook URL';
            isValid = false;
        }

        if (instagram && !validateSocialMediaUrl(instagram, 'instagram')) {
            errors.instagram = 'Invalid Instagram URL';
            isValid = false;
        }

        if (pinterest && !validateSocialMediaUrl(pinterest, 'pinterest')) {
            errors.pinterest = 'Invalid Pinterest URL';
            isValid = false;
        }

        if (tiktok && !validateSocialMediaUrl(tiktok, 'tiktok')) {
            errors.tiktok = 'Invalid TikTok URL';
            isValid = false;
        }

        if (twitter && !validateSocialMediaUrl(twitter, 'twitter')) {
            errors.twitter = 'Invalid Twitter URL';
            isValid = false;
        }

        if (youtube && !validateSocialMediaUrl(youtube, 'youtube')) {
            errors.youtube = 'Invalid YouTube URL';
            isValid = false;
        }

        if (!isValid) {
            setErrors(errors);
            return;
        }



        if (isValid) {
            try {
                setLoading(true)
                const formDataSubmit = new FormData();
                formDataSubmit.append('facebook', formData?.facebook);
                formDataSubmit.append('instagram', formData?.instagram);
                formDataSubmit.append('pinterest', formData?.pinterest);
                formDataSubmit.append('tiktok', formData?.tiktok);
                formDataSubmit.append('twitter', formData?.twitter);
                formDataSubmit.append('youtube', formData?.youtube);

                const headers = userTkn
                    ? { 'x-access-token': userTkn }
                    : {};

                const response = await apiCall({
                    url: `agent-profile-update?section=social`,
                    method: 'POST',
                    data: formDataSubmit, // Change this line
                    headers,
                });
                if (response?.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;

                        // Dynamically set errors based on the response
                        Object.keys(errors).forEach((key) => {
                            const errorMessages = errors[key];
                            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                            // Set the error state for the corresponding field
                            setErrors((prevData: any) => ({
                                ...prevData,
                                [key]: firstErrorMessage,
                            }));
                        });
                    } else {
                        let data = response?.data?.Agent?.data;

                        let languageOptions = data?.dictionary?.map((item: any) => ({ value: item?.code, label: item?.name }))

                        setFormData(prevData => ({
                            ...prevData,
                            facebook: data?.facebook,
                            instagram: data?.instagram,
                            pinterest: data?.pinterest,
                            tiktok: data?.tiktok,
                            youtube: data?.youtube,
                            twitter: data?.twitter
                        }));
                        toggleEditMode("social")
                        showSuccessToast("Update sucessfully!!")

                    }
                }
            } catch (error:any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);


            }
        }
    }

    const handleSubmitImages = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { profile_image_id, profile_image_2_id, listing_image_id, cover_image_id } = formData;
        let errors = { ...initialCreatorProfileError };
        let isValid = true;

        // Add your validation logic here if needed

        if (!isValid) {
            setErrors(errors);
            return;
        }

        if (isValid) {
            try {
                setLoading(true);
                const formDataSubmit = new FormData();

                // Dynamically append only non-null values to formDataSubmit

                formDataSubmit.append(imageType, String(selectedImage));



                const headers = userTkn
                    ? { 'x-access-token': userTkn }
                    : {};

                const response = await apiCall({
                    url: `agent-profile-update?section=images`,
                    method: 'POST',
                    data: formDataSubmit,
                    headers,
                });
                if (response?.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;

                        // Dynamically set errors based on the response
                        Object.keys(errors).forEach((key) => {
                            const errorMessages = errors[key];
                            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                            // Set the error state for the corresponding field
                            setErrors((prevData: any) => ({
                                ...prevData,
                                [key]: firstErrorMessage,
                            }));
                        });
                    } else {
                        let data = response?.data?.Agent?.data;
                        if (imageType === "profile_image_id") {
                            setFormData(prevData => ({
                                ...prevData,
                                [imageUrl]: data?.profileImage?.url,
                                [imageType]: data?.profileImage?.id
                            }));
                        }
                        if (imageType === "profile_image_2_id") {
                            setFormData(prevData => ({
                                ...prevData,
                                [imageUrl]: data?.profileImage2?.url,
                                [imageType]: data?.profileImage2?.id
                            }));
                        }
                        if (imageType === "listing_image_id") {
                            setFormData(prevData => ({
                                ...prevData,
                                [imageUrl]: data?.listingImage?.url,
                                [imageType]: data?.listingImage?.id
                            }));
                        }
                        if (imageType === "cover_image_id") {
                            setFormData(prevData => ({
                                ...prevData,
                                [imageUrl]: data?.coverImage?.url,
                                [imageType]: data?.coverImage?.id
                            }));
                        }


                        toggleEditMode("images");
                        showSuccessToast("Update successfully!!");
                    }
                }
            } catch (error:any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }
        }
    };


    const Option = (props: any) => {
        return (
            <div className="trip-details-form">
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const handleMultiOptions = (selected: any) => {
        setSelectedOption({
            optionSelected: selected
        });
    }
    const closeGallery = () => {
        // setSelectedImage([])
        // setSelectedImageUrl([]);
        // Create a synthetic event (not ideal)
        const syntheticEvent = new Event('submit', { bubbles: true, cancelable: true });
        handleSubmitImages(syntheticEvent as unknown as React.FormEvent<HTMLFormElement>);
        setModalShow(false);
    }
    const openGallery = () => {
        setModalShow(true)
    }

    const getImageType = (value: string): string => {
        if (value === "profile_image_id" || value === "profile_image_2_id" || value === "cover_image_id") {
            return "bannerImage";
        } else if (value === "listing_image_id") {
            // return "listingImage";
            return "bannerImage";
        } else {
            return ""; // Return a default string if none of the conditions match
        }
    };
    const handleCopyLinkClick = (value: number) => {
        setHandleDpSelect(value)
        navigator.clipboard.writeText(currentUrl).then(() => {
            console.warn("Link copied to clipboard!");
        });
    };
    return (
        <div className='creator-profile-form enquire-now-modal'>
            <div className="container creator-profile-cont" >
                <div className="row justify-content-center">
                    <div className="col-xl-12 ">
                        <p className="main-heading fw-bold high-light-black"> Creator <span className="high-light-red">Profile</span></p>
                    </div>
                    <div className="col-xl-12 profile-inner-container mb-5">
                        <Accordion defaultActiveKey="0" >
                            <Accordion.Item eventKey="0" className="">
                                <Accordion.Header className="custom-accordian-btn">Details</Accordion.Header>
                                <Accordion.Body className="pt-0">

                                    {editMode?.creatorDetails === false ? <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between">
                                                <div>Name: <span>{formData?.name} </span></div>
                                                <div>{userAgentCode === "A" && <Button variant='link' disabled={editCheck === true} onClick={() => toggleEditMode("details")} className="edit-btn-profile"> Edit </Button>}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                            <div>Location: <span>{formData?.location_option?.label}</span></div>
                                        </div>
                                        <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                            <div>Preferred Currency: <span>{formData?.default_currency_code}</span></div>
                                        </div>
                                    </div>
                                        :
                                        <Form onSubmit={handleSubmitCreatorDetails} className="enquire-now-form">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Form.Group className="mb-3" controlId="firstName">

                                                        <div className="d-flex justify-content-between">
                                                            <div><Form.Label className="">Name *</Form.Label></div>
                                                            <div className='d-flex gap-1 edit-btn-profile'>
                                                                <Button variant='link' className='pl-1 pr-1' onClick={() => toggleEditMode("details")}> Cancel </Button>
                                                                <Button variant='link' className="spiner-btn" type='submit' >
                                                                    {/* {loading?.tripSeo && <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className='custom-spiner'
                                                                />
                                                                } */}
                                                                    <span> Save</span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <Form.Control
                                                            type="text"
                                                            name="name"
                                                            placeholder="Name"
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.name}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                                    <Form.Group className="mb-3" controlId="firstName">
                                                        <CustomSelectForm2
                                                            label={"Location *"}
                                                            controlId={"location"}
                                                            value={formData?.location_option || { value: '', label: '' }}
                                                            onChange={handleLocationChange}
                                                            placeholder="Location"
                                                            errors={errors.location_id}
                                                        />
                                                        {/* <CustomSelectForm
                                                            // required
                                                            label={"Location *"}
                                                            controlId={"location_id"}
                                                            value={formData?.location_option || { value: '', label: '' }}
                                                            onChange={handleLocationChange}
                                                            placeholder="Location"
                                                            errors={errors.location_id}
                                                        /> */}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                                    <Form.Group className="mb-3" controlId="firstName">
                                                        <Form.Label className="mb-1">Preferred Currency</Form.Label>
                                                        <Select
                                                            defaultValue={formData?.currency_option}
                                                            onChange={handleCurrencyChange}
                                                            options={currenciesOptions}
                                                            className={`form-select-container ${errors.default_currency_code ? 'is-invalid' : ''}`}
                                                            classNamePrefix="form-select"
                                                            isSearchable={true}
                                                            placeholder="Currency"
                                                            styles={customStylesSelect}
                                                        // isDisabled={loading?.about}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.default_currency_code}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                        </Form>

                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="custom-accordian-btn">
                                    <div>
                                        <h4>Images</h4>
                                        <span className="fs-9 fw-normal">jpeg, jpg, png</span>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className="pt-0">
                                    <div className="row">
                                        <div className="col-lg-2 ">
                                            <div>
                                                <p className="mb-0 fs-7">Profile</p>
                                                <span className="fs-9 fw-normal">(250x250px)</span>
                                            </div>
                                            <div className="image-upload">
                                                <label htmlFor="file-input">
                                                    <div className="image-container">
                                                        <img id="uploaded-image" src={formData?.profile_image_url ? formData?.profile_image_url : imageSrc} alt="Upload" />

                                                        {userAgentCode === "A" && <Unicon icon={UilCloudUpload} className="text-success me-2 upload-icon" size={24} color="#E94560"
                                                            onClick={() => {
                                                                setImageType("profile_image_id");
                                                                setImageUrl("profile_image_url")
                                                                setCustomImageSize({
                                                                    minWidth: 250,
                                                                    minHeight: 250,
                                                                    maxWidth: 250,
                                                                    maxHeight: 250
                                                                })
                                                                openGallery();

                                                            }}
                                                        />
                                                        }
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-lg-2">
                                            <div>
                                                <p className="mb-0 fs-7">Profile2</p>
                                                <span className="fs-9 fw-normal">(250x400px)</span>
                                            </div>
                                            <div className="image-upload">
                                                <label htmlFor="file-input">
                                                    <div className="image-container-2">
                                                        <img id="uploaded-image" src={formData?.profile_image_2_url ? formData?.profile_image_2_url : profile1} alt="Upload" />
                                                        {userAgentCode === "A" && <Unicon icon={UilCloudUpload} className="text-success me-2 upload-icon" size={24} color="#E94560"
                                                            onClick={() => {
                                                                setImageType("profile_image_2_id");
                                                                setImageUrl("profile_image_2_url")
                                                                setCustomImageSize({
                                                                    minWidth: 250,
                                                                    minHeight: 400,
                                                                    maxWidth: 250,
                                                                    maxHeight: 400
                                                                })
                                                                openGallery();

                                                            }}
                                                        />
                                                        }
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-lg-2">
                                            <div>
                                                <p className="mb-0 fs-7">Listing</p>
                                                <span className="fs-9 fw-normal">(450x320px)</span>
                                            </div>

                                            <div className="image-upload">
                                                <label htmlFor="file-input">
                                                    <div className="image-container-2">
                                                        <img id="uploaded-image" src={formData?.listing_image_url ? formData?.listing_image_url : profile2} alt="Upload" />

                                                        {userAgentCode === "A" && <Unicon icon={UilCloudUpload} className="text-success me-2 upload-icon" size={24} color="#E94560"

                                                            onClick={() => {
                                                                setImageType("listing_image_id");
                                                                setImageUrl("listing_image_url")
                                                                setCustomImageSize({
                                                                    minWidth: 450,
                                                                    minHeight: 320,
                                                                    maxWidth: 450,
                                                                    maxHeight: 320
                                                                })
                                                                openGallery();

                                                            }}
                                                        />
                                                        }
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <p className="mb-0 fs-7">Banner</p>
                                                <span className="fs-9 fw-normal">(1920x500px)</span>
                                            </div>
                                            <div className="image-upload">
                                                <label htmlFor="file-input">
                                                    <div className="image-container-2">
                                                        <img id="uploaded-image" src={formData?.cover_image_url ? formData?.cover_image_url : banner1} alt="Upload" />

                                                        {userAgentCode === "A" && <Unicon icon={UilCloudUpload} className="text-success me-2 upload-icon" size={24} color="#E94560"

                                                            onClick={() => {
                                                                setImageType("cover_image_id");
                                                                setImageUrl("cover_image_url")
                                                                setCustomImageSize({
                                                                    minWidth: 1920,
                                                                    minHeight: 450,
                                                                    maxWidth: 1920,
                                                                    maxHeight: 450
                                                                })
                                                                openGallery();

                                                            }}
                                                        />
                                                        }
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header className="custom-accordian-btn">Contact Information</Accordion.Header>
                                <Accordion.Body className="pt-0">
                                    {editMode?.creatorContactInfo === false ? <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between">
                                                <div>Email: <span>{formData?.email_address} </span></div>
                                                <div>{userAgentCode === "A" && <Button variant='link' disabled={editCheck === true} onClick={() => toggleEditMode("contactInfo")} className="edit-btn-profile"> Edit </Button>}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div>Phone: <span>{formData?.phone_number}</span></div>
                                        </div>
                                        <div className="col-11">
                                            <div>Public URL: <span>{currentUrl}</span></div>
                                        </div>
                                        <div className="col-1 d-flex align-items-center justify-content-center">
                                            {/* <FontAwesomeIcon icon={faShareNodes} color="#E94560" className="fs-5" /> */}
                                            <Dropdown className="custom-share-link">
                                                <Dropdown.Toggle as="button" variant="link" id="share-dropdown" className="icon-button-custom">
                                                    <FontAwesomeIcon icon={faShareNodes} id="share-dropdown" color="#E94560" className="fs-5" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item className={`item-option-dp ${handleDpSelect === 2 ? 'selected' : ''}`} onClick={() => handleCopyLinkClick(2)}>
                                                        Copy Link
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                        :
                                        <Form onSubmit={handleSubmitContactInfo} className="enquire-now-form">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Form.Group className="mb-3" controlId="emailId">
                                                        {/* <Form.Label className="mb-1">Email</Form.Label> */}
                                                        <div className="d-flex justify-content-between">
                                                            <div><Form.Label className="">Email *</Form.Label></div>
                                                            <div className='d-flex gap-1 edit-btn-profile'>
                                                                <Button variant='link' className='pl-1 pr-1' onClick={() => toggleEditMode("contactInfo")}> Cancel </Button>
                                                                <Button variant='link' className="spiner-btn" type='submit' >
                                                                    {/* {loading?.tripSeo && <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className='custom-spiner'
                                                                />
                                                                } */}
                                                                    <span> Save</span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <Form.Control
                                                            type="text"
                                                            name="email_address"
                                                            placeholder="Email"
                                                            value={formData.email_address}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.email_address}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email_address}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">
                                                    <Form.Group className="mb-3" controlId="phoneNumber">
                                                        <Form.Label className="mb-1">Phone</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="phone_number"
                                                            placeholder="Phone"
                                                            value={formData.phone_number}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.phone_number}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.phone_number}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">
                                                    <Form.Group className="mb-3" controlId="websiteUrl">
                                                        <Form.Label className="mb-1">Public URL</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="website_url"
                                                            placeholder="Public URL"
                                                            value={formData.website_url}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.website_url}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.website_url}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Form>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header className="custom-accordian-btn">Content</Accordion.Header>
                                <Accordion.Body className="pt-0">

                                    {editMode?.creatorContent === false ? <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between">
                                                <div>Subtitle: <span>{formData?.sub_title} </span></div>
                                                <div>{userAgentCode === "A" && <Button variant='link' disabled={editCheck === true} onClick={() => toggleEditMode("content")} className="edit-btn-profile"> Edit </Button>}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div>Language: <span>{formData?.phone_number}</span></div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div>About: <span>{parse(formData?.about || '')}</span></div>
                                        </div>
                                    </div>
                                        :
                                        <Form onSubmit={handleSubmitContent} className="enquire-now-form">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Form.Group className="mb-3" controlId="firstName">
                                                        <div className="d-flex justify-content-between">
                                                            <div><Form.Label className="">Subtitle</Form.Label></div>
                                                            <div className='d-flex gap-1 edit-btn-profile'>
                                                                <Button variant='link' className='pl-1 pr-1' onClick={() => toggleEditMode("content")}> Cancel </Button>
                                                                <Button variant='link' className="spiner-btn" type='submit' >
                                                                    {/* {loading?.tripSeo && <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className='custom-spiner'
                                                                />
                                                                } */}
                                                                    <span> Save</span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <Form.Control
                                                            type="text"
                                                            name="sub_title"
                                                            placeholder="Sub Title"
                                                            value={formData?.sub_title}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.sub_title}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.sub_title}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">
                                                    <Form.Group className="mb-3" controlId="firstName">
                                                        <Form.Label className="mb-1">Language</Form.Label>
                                                        <Form.Group className="mb-3" controlId="firstName">
                                                            {(Array.isArray(languageOptions) && languageOptions.length > 0) && <ReactSelect
                                                                options={languageOptions}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                className={`form-select-container `}
                                                                styles={colourStyles}
                                                                value={selectedOption.optionSelected}
                                                                components={{
                                                                    Option
                                                                }}

                                                                onChange={handleMultiOptions}


                                                                classNamePrefix="form-select"
                                                            />
                                                            }


                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.languages}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Form.Group>
                                                </div>
                                                <div className='col-md-12'>
                                                    <Form.Label className="mb-1">About </Form.Label>
                                                    <Suspense fallback={<div className='step-content-0-main'>Loading...</div>}>
                                                        <EditorProvider>
                                                            <Editor
                                                                value={formData?.about}
                                                                onChange={(e) => {
                                                                    setFormData((prev: any) => ({
                                                                        ...prev,
                                                                        about: e.target.value
                                                                    }));
                                                                    setErrors({ ...errors, about: '' });
                                                                }}
                                                            />
                                                        </EditorProvider>
                                                        {/* {errors.description && (
                                                    <div className={errors.description ? 'error-feedback' : ""}>
                                                        {errors.description}
                                                    </div>
                                                )} */}
                                                    </Suspense>


                                                </div>
                                            </div>
                                        </Form>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header className="custom-accordian-btn">Social</Accordion.Header>
                                <Accordion.Body className="pt-0">
                                    {editMode?.creatorSocial === false ? <div className="row">

                                        <div className="d-flex justify-content-between social-btn-container" >
                                            <div></div>
                                            <div>{userAgentCode === "A" && <Button variant='link' disabled={editCheck === true} onClick={() => toggleEditMode("social")} className="edit-btn-profile"> Edit </Button>}</div>
                                        </div>

                                        <div className="col-12 col-sx-12 col-md-12 col-lg-6 mb-3">
                                            <div className="d-flex justify-content-between">
                                                <div>Facebook ID: <span className="custom-fs">{formData?.facebook} </span></div>

                                            </div>
                                        </div>
                                        <div className="col-12 col-sx-12 col-md-12 col-lg-6 mb-3">
                                            <div>Tiktok Handle: <span className="custom-fs">{formData?.tiktok} </span></div>

                                        </div>
                                        <div className="col-12 col-sx-12 col-md-12 col-lg-6 mb-3">
                                            <div>Instagram Handle: <span className="custom-fs">{formData?.instagram} </span></div>
                                        </div>
                                        <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                            <div>YouTube Channel: <span className="custom-fs">{formData?.youtube} </span></div>
                                        </div>
                                        <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                            <div>Pinterest Handle: <span className="custom-fs">{formData?.pinterest} </span></div>
                                        </div>
                                        <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                            <div>Twitter Handle: <span className="custom-fs">{formData?.twitter} </span></div>
                                        </div>
                                    </div>
                                        :
                                        <Form onSubmit={handleSubmitSocial} className="enquire-now-form">
                                            <div className="row">
                                                <div className="d-flex justify-content-between social-btn-container">
                                                    <div></div>
                                                    <div className="d-flex justify-content-between">

                                                        <div className='d-flex gap-1 edit-btn-profile'>
                                                            <Button variant='link' className='pl-1 pr-1' onClick={() => toggleEditMode("social")}> Cancel </Button>
                                                            <Button variant='link' className="spiner-btn" type='submit' >
                                                                {/* {loading?.tripSeo && <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className='custom-spiner'
                                                                />
                                                                } */}
                                                                <span> Save</span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                                    <Form.Group className="mb-3" controlId="facebook">
                                                        <Form.Label className="mb-1">Facebook ID</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="facebook"
                                                            placeholder="Facebook"
                                                            value={formData.facebook}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.facebook}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.facebook}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                                    <Form.Group className="mb-3" controlId="tiktok">
                                                        <Form.Label className="mb-1">Tiktok Handle</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="tiktok"
                                                            placeholder="Tiktok Handle"
                                                            value={formData.tiktok}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.tiktok}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.tiktok}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                                    <Form.Group className="mb-3" controlId="instagram">
                                                        <Form.Label className="mb-1">Instagram Handle</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="instagram"
                                                            placeholder="nstagram Handle"
                                                            value={formData.instagram}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.instagram}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.instagram}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                                    <Form.Group className="mb-3" controlId="youtube">
                                                        <Form.Label className="mb-1">YouTube Channel</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="youtube"
                                                            placeholder="YouTube Channel"
                                                            value={formData.youtube}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.youtube}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.youtube}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                                    <Form.Group className="mb-3" controlId="pinterest">
                                                        <Form.Label className="mb-1">Pinterest Handle</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="pinterest"
                                                            placeholder="Pinterest Handle"
                                                            value={formData.pinterest}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.pinterest}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.pinterest}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-sx-12 col-md-12 col-lg-6">
                                                    <Form.Group className="mb-3" controlId="twitter">
                                                        <Form.Label className="mb-1">Twitter Handle</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="twitter"
                                                            placeholder="Twitter Handle"
                                                            value={formData.twitter}
                                                            onChange={handleInputChange}
                                                            isInvalid={!!errors.twitter}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.twitter}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Form>

                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Image Gallery</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImageManager selectedImage={selectedImage} setSelectedImage={setSelectedImage}
                        selectedImageUrl={selectedImageUrl} setSelectedImageUrl={setSelectedImageUrl}
                        customSize={customImageSize}

                        type={getImageType(imageType) || ""}
                        closeModal={() => closeGallery()}
                    />

                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ProfileCreator