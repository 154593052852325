import classNames from 'classnames';
import {
  Accept,
  DropEvent,
  FileRejection,
  DropzoneProps as ReactDropZoneProps,
  useDropzone
} from 'react-dropzone';
import Button from './Button';
import imageIcon from 'assets/img/icons/image-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useMemo, useState } from 'react';
import AttachmentPreview, { FileAttachment } from 'components/common/AttachmentPreview';
import { convertFileToAttachment } from 'helpers/utils';
import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AttachmentPreviewBig from 'components/common/AttachmentPreviewBig';

export interface ImageSize {
  minWidth: number;
  minHeight: number;
  maxWidth: number;
  maxHeight: number;
}


interface DropzoneProps {
  className?: string;
  size?: 'sm';
  reactDropZoneProps?: ReactDropZoneProps;
  accept?: Accept;
  noPreview?: boolean;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  multiple?: boolean; // Added multiple prop
  imageSize: ImageSize;
  setImageDetails: React.Dispatch<React.SetStateAction<FileAttachment[]>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<any>>;
  errorMessage: string;
  setCropImage: React.Dispatch<React.SetStateAction<boolean>>;
  croppedImageUrl: string | null;
}

const DropzoneCustom = ({
  className,
  size,
  onDrop,
  accept,
  noPreview,
  reactDropZoneProps,
  multiple = true, // Default to multiple files,
  imageSize,
  setImageDetails,
  setErrorMessage,
  errorMessage,
  setCropImage,
  croppedImageUrl,
  children
}: PropsWithChildren<DropzoneProps>) => {
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<FileAttachment[]>([]);


  const handleRemoveFile = (index: number) => {
    setFiles(files.filter((_, ind) => index !== ind));
    setPreviews(previews.filter((_, ind) => index !== ind));
    setErrorMessage('')
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, fileRejections, event) => {
      const newPreviews = acceptedFiles.map(file => convertFileToAttachment(file));
      setPreviews(prevPreviews => (multiple ? [...prevPreviews, ...newPreviews] : newPreviews));

      const validFiles = await Promise.all(
        acceptedFiles.map(async file => {
          const isValid = await validateImage(file);
          return isValid ? file : file;
        })
      );

      const newFiles = validFiles.filter(file => file !== null) as File[];
      setFiles(prevFiles => (multiple ? [...prevFiles, ...newFiles] : newFiles));

      setImageDetails(prevPreviews => (multiple ? [...prevPreviews, ...newPreviews] : newPreviews))

      if (onDrop) {
        onDrop(newFiles, fileRejections, event);
      }
    },
    accept,
    multiple,
    ...reactDropZoneProps
  });


  const imageOnly = useMemo(() => {
    return Boolean(accept && accept['image/*']);
  }, [accept]);

  const validateImage = async (file: File): Promise<boolean> => {
    // Check file type
    const acceptedTypes = ['image/jpeg', 'image/png'];
    if (!acceptedTypes.includes(file.type)) {
      setErrorMessage('Accepted image types are JPEG and PNG.')
      return false;
    }

    // Check file size
    const maxSize = 1024 * 1024; // 1 MB
    if (file.size > maxSize) {
      setErrorMessage('Maximum file size exceeded (1 MB).')
      return false;
    }


    // Check dimensions
    const { minWidth, minHeight, maxWidth, maxHeight } = imageSize;

    return new Promise<boolean>((resolve) => {
      const image = new Image();
      image.onload = () => {
        const width = image.width;
        const height = image.height;
        if (
          width >= minWidth &&
          width <= maxWidth &&
          height >= minHeight &&
          height <= maxHeight
        ) {
          setErrorMessage('');
          resolve(true);
        } else {
          setErrorMessage(`Image dimensions must be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} pixels.`)
          resolve(false);
        }
      };
      image.src = URL.createObjectURL(file);
    });
  };


  return (
    <>

      <>
        {imageOnly && !noPreview && files.length > 0 && (
          <div className="d-flex flex-wrap gap-2 mb-2">
            {files.map((file, index) => (
              <ImageAttachmentPreview
                key={file.name}
                image={URL.createObjectURL(file)}
                handleClose={() => handleRemoveFile(index)}
              />
            ))}
          </div>
        )}
        <div
          {...getRootProps()}
          className={classNames(className, 'dropzone', {
            'dropzone-sm': size === 'sm'
          })}
        >
          <input {...getInputProps()} />
          {children ? (
            <>{children}</>
          ) : (
            <div className="text-600 fw-bold fs-9">
              Drag your {imageOnly ? 'photo' : 'files'} here ({imageSize.maxWidth} x {imageSize.maxHeight}){' '}
              <span className="text-800">or </span>
              <Button variant="link" className="p-0">
                Browse from device
              </Button>
              <br />
              <img
                className="mt-0"
                src={imageIcon}
                width={classNames({ 16: size === 'sm', 25: size !== 'sm' })}
                alt=""
              />
            </div>
          )
          }
        </div>



        {errorMessage ? (
          <>
            <div className="text-danger mt-2">{errorMessage}</div>


            {!imageOnly &&
              previews.map((file, index) => (
                <div
                  key={index}
                  className={classNames(
                    'border-bottom san d-flex align-items-start justify-content-between py-3'
                  )}
                >
                  <AttachmentPreviewBig attachment={file} />
                  <Button onClick={() => setCropImage(true)}>Edit</Button>
                  <button className="btn p-0" onClick={() => handleRemoveFile(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                  </button>
                </div>
              ))}
          </>
        ) : (
          <>

            {croppedImageUrl &&
            <>
              <img src={croppedImageUrl} className='cropped-image-view' alt="Cropped preview" />
              <Button onClick={() => setCropImage(true)}>Edit</Button>
              </>
            }

            {!imageOnly &&
              previews.map((file, index) => (
                <div
                  key={index}
                  className={classNames(
                    'border-bottom san d-flex align-items-start justify-content-between py-3'
                  )}
                >
                  <AttachmentPreviewBig attachment={file} />
                  <Button onClick={() => setCropImage(true)}>Edit</Button>
                  <button className="btn p-0" onClick={() => handleRemoveFile(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                  </button>
                </div>
              ))}
          </>
        )
        }

      </>

    </>
  );
};

export default DropzoneCustom;
