import slide1 from 'assets/img/travel/crausal/slide-per-view/culture.png';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css'
import { useMaster } from 'providers/MasterContext';
import { useNavigate } from 'react-router-dom';


const MultiSlidesCrousal = () => {
    const { topCategories } = useMaster();
    const navigate = useNavigate();
    return (

        <>
            <Swiper
                slidesPerView={6}
                spaceBetween={30}

                modules={[Pagination]}

                className="mySwiper"

                breakpoints={{
                    1600: {
                        slidesPerView: 6,
                    },
                    1440: {
                        slidesPerView: 5,
                    },
                    1200: {
                        slidesPerView: 5,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    320: {
                        slidesPerView: 1,
                    }
                }}

            >

                {(Array.isArray(topCategories) && topCategories.length > 0) && topCategories?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div onClick={() => { navigate(`/top-categories/${item?.code}/${item?.slug}`) }} className="link-hover">
                            <div className="slide-img-container">
                                <img src={item?.listingImage?.url ?? slide1} alt="image" width={365} height={80} />
                            </div>
                            <span className="travel-category fw-bold high-light-black">{item?.name ?? ""}</span>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>

    )
}

export default MultiSlidesCrousal