import { Button } from "react-bootstrap";
import BannerImage from "assets/img/travel/hero/hero-creator.png";
import BannerMobile from "assets/img/travel/banner/mobile/lake_como.png";
import MultiSlidesCrousalCreator from "components/crousals/multislides/MultiSlidesCrousalCreator";
import Avatar from "components/base/Avatar";
import Unicon from "components/base/Unicon";
import { UilArrowDown } from "@iconscout/react-unicons";
import VerticalSlider from "components/crousals/verticalslider/VerticalSlider";
import { useEffect, useState } from "react";
import { useToast } from "providers/ToastProvider";
import { useAuth } from "providers/AuthContext";
import {
  Agent,
  ChildItemAllBtnText,
  ChildItemCreator,
  initialChildItemAllBtnText,
  initialChildItemCreator,
} from "types/interfaces";
import apiCall from "services/api";
import { handleApiError } from "services/handleApiError";
import { Link, useNavigate } from "react-router-dom";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import NumberToDivs from "components/travel-common/NumberToDivsProps";
import TripCard from "components/cards/TripsCard";
import { markWishList } from "services/markWishList";
import { useSignIn } from "providers/SignInProvider";
import useWindowWidth from "hooks/useWindowWidth";
import VerticalSliderCreator from "components/crousals/verticalslider/VerticalSliderCreator";

const Creators = () => {
  const { showErrorToast } = useToast();
  const windowWidth = useWindowWidth();
  const { userTkn, signOut } = useAuth();
  const { setShowIn } = useSignIn();
  const { currency } = useMeta();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [creatorContentItems, setCreatorContentItems] =
    useState<ChildItemCreator>(initialChildItemCreator);
  const [btnText, setBtnText] = useState<ChildItemAllBtnText>(
    initialChildItemAllBtnText
  );
  const [agents, setAgents] = useState<Agent[]>([]);
  const [agentsTotal, setAgentsTotal] = useState(0);
  const [trips, setTrips] = useState<any[]>([]);
  const [totalTrips, setTotalTrips] = useState(0);

  const handleMarkWishlist = async (tripId: string) => {
    await markWishList(
      tripId,
      userTkn,
      currency,
      setTrips,
      setShowIn,
      apiCall,
      showErrorToast
    );
  };

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        setLoading(true);
        const [
          contentResponse,
          btnTextResponse,
          agentsResponse,
          tripsResponse,
        ] = await Promise.all([
          apiCall({
            url: `cms/content-items/creators`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `cms/content-items/all_btn_text`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `agents?sort=-is_top_agent`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
          apiCall({
            url: `tours?page=&pageSize=12`,
            method: "GET",
            headers: getHeaders(userTkn, currency),
          }),
        ]);

        if (contentResponse?.status === 200) {
          let data = contentResponse?.data?.ContentItem?.data?.content?.childs;
          setCreatorContentItems(data);
        }
        if (agentsResponse?.status === 200) {
          let data = agentsResponse?.data?.Agents?.data;
          setAgents(data);
          setAgentsTotal(agentsResponse?.data?.Agents?.summary?.total);
        }
        if (tripsResponse?.status === 200) {
          const data = tripsResponse?.data?.TripVersion?.data;
          const total = tripsResponse?.data?.TripVersion?.summary.total;
          setTotalTrips(total);
          setTrips(data);
        }

        if (btnTextResponse?.status === 200) {
          let data = btnTextResponse?.data?.ContentItem?.data?.content?.childs;
          setBtnText(data);
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setLoading(true);
      }
    };

    fetchTripData();
  }, [currency]);

  return (
    <div className="creators">
      {/*first section home banner*/}
      <section
        id="hero-creators"
        className="d-flex flex-column justify-content-center"
        style={{ background: `url(${BannerImage})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 flex-column d-flex justify-content-center">
              <div className="d-flex flex-column justify-content-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                <p className="hero-heading fw-bold high-light-white  mb-1 mb-sm-1 mb-md-2 mb-lg-3 mb-xl-4 mb-xxl-4 text-center text-md-start text-lg-start text-xl-start text-xxl-start">
                  {creatorContentItems?.creator_heading_1?.item_value ??
                    "Curate."}
                </p>
                <p className="hero-heading fw-bold high-light-white mb-1 mb-sm-1 mb-md-2 mb-lg-3 mb-xl-4 mb-xxl-4 text-center text-md-start text-xl-start text-xxl-start">
                  {creatorContentItems?.creator_heading_2?.item_value ??
                    "Share."}
                </p>
                <p className="hero-heading fw-bold high-light-red mb-1 mb-sm-1  mb-md-1 mb-lg-1 mb-xl-1 mb-xxl-1 text-center text-md-start text-xl-start text-xxl-start">
                  <span className="high-light-red">
                    {creatorContentItems?.creator_heading_3?.item_value ??
                      "Earn."}
                  </span>
                </p>
              </div>
              <div className="creator-sub-heading">
                <p className="sub-heading high-light-white fw-semibold">
                  {creatorContentItems?.creator_sub_head_1?.item_value ??
                    "Join thousands of travel creators who"}
                  {windowWidth > 768 && <br />}
                  {creatorContentItems?.creator_sub_head_12?.item_value ??
                    "have turned their passion into an"}
                  {windowWidth > 768 && <br />}
                  {creatorContentItems?.creator_sub_head_3?.item_value ??
                    "online revenue stream."}
                </p>
              </div>
              {btnText?.become_a_creators?.item_value && (
                <div className="custom-btn-size d-flex justify-content-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                  <div>
                    <Button
                      variant="primary"
                      className="flex-grow-1 flex-lg-grow-0"
                      onClick={() => {
                        navigate("/intrest-form");
                      }}
                    >
                      {btnText?.become_a_creators?.item_value ??
                        "Become a Creator"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center ">
              <img
                src={BannerMobile}
                alt="chat"
                className="creator-hero-banner-sub mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </section>
      {/*Second section creator*/}
      <section className="pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col"></div>
            <div className="col-lg-8">
              <p className="creator-title-details text-center">
                {creatorContentItems?.creator_first_1?.item_value ??
                  "Join thousands of creators who have turned their passion for travel into an income"}
              </p>
            </div>
            <div className="col"></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <p className="creator-discription">
                {creatorContentItems?.creator_ptext_1?.item_value ??
                  "Travel Creators’"}
                <span>
                  {" "}
                  {creatorContentItems?.bold_ptext_1?.item_value ?? "mission"}
                </span>
                {creatorContentItems?.creator_ptext_2?.item_value ??
                  " is to help thousands of creators from around the Globe turn their passion into a sustainable income. How? Thanks to our unique"}
                <span>
                  {" "}
                  {creatorContentItems?.bold_ptext_2?.item_value ??
                    "technology platform,"}
                </span>{" "}
                {creatorContentItems?.creator_ptext_3?.item_value ??
                  "creators like you can sign-up and build their first trip in minutes by selecting flights, hotels and activities from our curated list of partners. Once your trip is live on our site, simply"}
                <span>
                  {" "}
                  {creatorContentItems?.bold_ptext_3?.item_value ??
                    "promote it on your preferred channels"}
                </span>{" "}
                {creatorContentItems?.creator_ptext_4?.item_value ??
                  "to start generating revenue and use our messaging platform to communicate with travelers before and after their trips. It’s that simple."}
              </p>
              <p className="creator-discription">
                {creatorContentItems?.creator_ptext_5?.item_value ??
                  "Our success? We are already working with"}{" "}
                <span>
                  {creatorContentItems?.bold_ptext_4?.item_value ??
                    "1,628  creators"}
                </span>{" "}
                {creatorContentItems?.creator_ptext_6?.item_value ??
                  "worlwide and have generated over"}{" "}
                <span>
                  {" "}
                  {creatorContentItems?.bold_ptext_5?.item_value ??
                    "£4 million in bookings"}
                </span>{" "}
                {creatorContentItems?.creator_ptext_7?.item_value ??
                  "for them thourgh our platform. Join Travel Creators now to turn your passion into your income."}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*third section*/}
      <div className="d-flex flex-column justify-content-center ">
        <div className="container">
          <div className="d-flex flex-column justify-content-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-12 text-center pb-4 mb-4 mt-2 mt-sm-3 mt-md-4 mt-lg-5 mt-xl-5 mt-xxl-5">
                  {agentsTotal > 0 && (
                    <p className="main-heading fw-bold high-light-black">
                      {" "}
                      <span className="high-light-red">{agentsTotal}</span>{" "}
                      {creatorContentItems?.heading_first?.item_value ??
                        "creators waiting for you"}
                    </p>
                  )}
                  <div className="view-all-trips  d-flex justify-content-center pt-2">
                    <Link
                      to={"/all-creators"}
                      style={{ textDecoration: "none" }}
                      className="custom-link"
                    >
                      View All Creators
                    </Link>
                  </div>
                </div>
                {Array.isArray(agents) && agents.length > 0 && (
                  <MultiSlidesCrousalCreator agents={agents} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*fourth section*/}
      <div className="d-flex flex-column justify-content-center py-5  ">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-xl-12 text-center">
              <p className="main-heading fw-bold high-light-black">
                {" "}
                {creatorContentItems?.heading_second?.item_value ??
                  "How Travel Creators works"}
              </p>
            </div>
            <div className="custom-btn-size d-flex justify-content-center py-3">
              {/* <Button variant='phoenix-primary' className="p-1 bg-transparent"><Unicon icon={UilCodeBranch} size={16} color="#525b75" /></Button> */}
              <Avatar size="3xl" variant="name" className="custom-btn-circle">
                <Unicon icon={UilArrowDown} size={70} color="#ffffff" />
              </Avatar>
            </div>
          </div>
        </div>
      </div>
      {/*fifth section*/}
      <div className=" ">
        <VerticalSliderCreator  title={btnText?.view_all_trip.item_value}/>
      </div>

      {/*fifth section*/}
      <div className="d-flex flex-column justify-content-center tips-count-padding">
        <div className="container-fluid">
          <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3 justify-content-center">
            <div className="col-xl-9 text-start">
              <p className="main-heading fw-bold high-light-black m-0">
                {creatorContentItems?.heading_fourth_1?.item_value ??
                  "Trips from our"}
                <span className="high-light-red">
                  {" "}
                  {creatorContentItems?.heading_fourth_2?.item_value ??
                    "creators"}
                </span>
              </p>
              <p className="sub-heading high-light-black fw-semibold m-0">
                {" "}
                {creatorContentItems?.sub_heading_1?.item_value ??
                  "Lorem um dolor sit amet, consectetur adipiscing elit, sed do"}
                {windowWidth > 768 && <br />}{" "}
                {creatorContentItems?.sub_heading_2?.item_value ??
                  "eiusmod tempor incididunt ut labore. Nemo enim ipsam."}
              </p>
            </div>
            <div className="col-xl-3 ">
              <div className="trips d-flex flex-column ">
                <div className="trips-count d-flex flex-row gap-2 justify-content-end">
                  {totalTrips > 0 && <NumberToDivs number={totalTrips} />}
                </div>
                <div className="view-all-trips  d-flex justify-content-end pt-2">
                  <Link
                    to={"/trips-and-destination"}
                    style={{ textDecoration: "none" }}
                    className="custom-link"
                  >
                    {btnText?.view_all_trip.item_value ?? "View All Trips"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*sixth section*/}
      <section className="pb-0">
        <div className="container-fluid">
          <div className="row mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-3 mx-xxl-3 justify-content-start">
            {Array.isArray(trips) && trips.length > 0 ? (
              <>
                {trips
                  ?.slice(0, 4)
                  .map((item, index) => (
                    <TripCard
                      key={index}
                      item={item}
                      markWishList={handleMarkWishlist}
                      title={btnText?.trip_card_btn?.item_value}
                    />
                  ))}
              </>
            ) : (
              <div className="col-12  d-flex justify-content-center">
                <h3>Sorry No Trip Found!!</h3>
              </div>
            )}
            {btnText?.view_all_trip.item_value && (
              <div className="custom-btn-size d-flex justify-content-center trip-view-custom">
                <Button
                  variant="primary"
                  className="flex-grow-1 flex-lg-grow-0"
                  onClick={() => {
                    navigate("/trips-and-destination");
                  }}
                >
                  {btnText?.view_all_trip.item_value ?? "View All Trips"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Creators;
