// Function to generate headers
export const getHeaders = (userTkn: string | null, currency: string) => {
    let headers: Record<string, string> = {};
  
    if (userTkn) {
      headers['x-access-token'] = userTkn;
    }
  
    if (currency) {
      headers['x-currency'] = currency;
    }
  
    return headers;
  };
