import React, { useState, useEffect, useCallback, useRef } from 'react';
import Select, { components, SingleValue, SingleValueProps } from 'react-select';
import axios, { AxiosError } from 'axios';
import { Form } from 'react-bootstrap';
import { customStylesSelect } from 'services/customStyles';
import { useAuth } from 'providers/AuthContext';
import apiCall from 'services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faLocation, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { useToast } from 'providers/ToastProvider';
import { handleApiError } from 'services/handleApiError';
import { useMeta } from 'providers/MetaContext';
import { getHeaders } from 'services/getHeaders';
// Define the type for the options
interface OptionType {
  value: string;
  label: string;
}


type CustomSelectProps = {
  label: string;
  controlId: string;
  value: OptionType | null;
  onChange: (option: SingleValue<OptionType>) => void;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  errors?: string;
  location?: OptionType | null;
  setLocation?: React.Dispatch<React.SetStateAction<OptionType | null>> ;
};

const CustomSingleValue: React.FC<SingleValueProps<OptionType, false>> = (props) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <div className="custom-single-value">
        <FontAwesomeIcon icon={faLocation} className="me-2" /> {/* Add your desired icon here */}
        {data.label}
      </div>
    </components.SingleValue>
  );
};

// Set your default value here
// Define the props for the Leads component
const CustomSelectFilter: React.FC<CustomSelectProps> = ({
  label,
  controlId,
  value,
  onChange,
  placeholder,
  isDisabled = false,
  errors = '',
  location,
  setLocation
}) => {
  const { showErrorToast } = useToast();
  const [defaultValue, setDefaultValue] = useState(value)
  // const [formData, setFormData] = useState<OptionType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userTkn, signOut } = useAuth();
  const { currency } = useMeta();
  const [isClearable, setIsClearable] = useState(true);
  const [summary, setSummary] = useState({
    total: 0,
    page: 0,
    pageSize: 0,
    total_page: 0,
  });

 

  const isTypingRef = useRef<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const fetchOptions = useCallback(async (page: number, query: string = '') => {

    setIsLoading(true);
    try {
      const url = query 
      ? `master/locations?page=${page}&name=${query}&defaultOptionsNeeded=1` 
      : `master/locations?page=${page}&defaultOptionsNeeded=0`;

    const response = await apiCall({
      url, // Use the dynamically built URL
      method: 'GET',
      headers: getHeaders(userTkn, currency)
    });
  
      const data = response?.data?.MasterLocation?.data;
      const summary = response?.data?.MasterLocation?.summary;
      setSummary({
        "total": summary?.total,
        "page": summary?.page,
        "pageSize": summary?.pageSize,
        "total_page": summary?.total_page,
      })
      if (data && data.length > 0) {
        const transformedOptions = data.map((item: any) => ({
          value: String(item.id),
          label: item.name
        }));
        setOptions(prevOptions => page === 1 ? transformedOptions : [...prevOptions, ...transformedOptions]);
        setHasMore(data.length > 0);
      } else {
        setHasMore(false);
      }
    } catch (error: any) {
      
      handleApiError(error, showErrorToast, signOut);

    } finally {
      setIsLoading(false);
    }
  }, [userTkn]);

  const fetchDefaultOption = useCallback(async () => {

    setIsLoading(true);
    if (!defaultValue) {
      // If defaultValue is null, exit early
      setIsLoading(false);
      return;
    }
    try {
      const response = await apiCall({
        url: `master/locations?name=${defaultValue.label}`,
        method: 'GET',
        headers: {
          'x-access-token': userTkn,
        },
      });
      const data = response?.data?.MasterLocation?.data;
      if (data && data.length > 0) {
        const transformedOptions = data.map((item: any) => ({
          value: String(item.id),
          label: item.name,
        }));
        setOptions(transformedOptions);
        const matchedOption = transformedOptions.find(
          (option: OptionType) => option.label === defaultValue.label
        );

        if(setLocation){
          setLocation(matchedOption || null);
        }
      }
    } catch (error:any) {
      handleApiError(error, showErrorToast, signOut);
    } finally {
      setIsLoading(false);
    }
  }, [userTkn]);

  useEffect(() => {

    fetchOptions(1, inputValue);

  }, [fetchOptions, inputValue]);

  const handleInputChange = (newValue: string) => {
    const value = newValue.replace(/\W/g, '');
    setInputValue(value);
    setPage(1);
    isTypingRef.current = true;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {

      fetchOptions(1, value);
      isTypingRef.current = false;
    }, 3000); // Adjust the debounce delay as needed
  };

  // useEffect(() => {
  //   return () => {
  //     if (timeoutRef.current) {
  //       clearTimeout(timeoutRef.current);
  //     }
  //   };
  // }, []);

  const handleMenuScrollToBottom = () => {
    if ((summary.total_page > summary.page) && !isLoading) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchOptions(nextPage, inputValue);
    }
  };

  const handleStartLocationChange = (option: SingleValue<OptionType>) => {
    if (setLocation) {
      setLocation(option);  // Only invoke if setLocation is defined
    }
    onChange(option)
    isTypingRef.current = false; // Reset typing flag
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the timeout to prevent unnecessary fetch
    }
  };

  // useEffect(() => {
  //   if (defaultValue) { // Check if defaultValue is not null
  //     if (!options.some((option) => Number(option.value) === Number(defaultValue.value))) {
  //       fetchDefaultOption();
  //     } else {
  //       const matchedOption = options.find((option) => option.value === defaultValue.value);
  //       setFormData(matchedOption || null);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (value) { // Check if value is not null
      if (!options.some((option) => Number(option.value) === Number(value.value))) {
        // fetchDefaultOption();
      } else {
        const matchedOption = options.find((option) => option.value === value.value);
        if(setLocation){
          setLocation(matchedOption || null);
        }
    
      }
    } else {
      if(setLocation){
        setLocation(null);
      }
        // Reset formData if value is null
    }
  }, [value]);

  return (
    <Form.Group controlId={controlId}>
      {label && <Form.Label >{label}</Form.Label>}
      <div className="form-icon-container">
        <Select<OptionType>
          value={location}
          //  defaultValue={defaultValue}
          onChange={handleStartLocationChange}
          options={options}
          className={`trips-select-filter-container ${errors ? 'is-invalid' : ''}`}
          classNamePrefix="trips-select-filter"
          isSearchable={true}
          placeholder={placeholder}
          styles={customStylesSelect}
          isDisabled={loading}
          onInputChange={handleInputChange}
          //  onMenuScrollToBottom={handleMenuScrollToBottom}
          isLoading={isLoading}
          isClearable={isClearable}
          noOptionsMessage={({ inputValue }) => !inputValue ? "" : ``}
          // components={{ SingleValue: CustomSingleValue }}
        />
        <div className="eye-icon2">
          <FontAwesomeIcon
            className="position-absolute top-50 translate-middle-y fs-12  input-icon-color"
            style={{ left: '10px', cursor: 'pointer' }}
            icon={faLocationDot}
          />

        </div>
      </div>
    </Form.Group>
  );
};

export default CustomSelectFilter;
