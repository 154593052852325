import { Accordion, Col, Spinner } from "react-bootstrap"
import { Row } from "react-bootstrap"
import BannerImage from "../assets/img/travel/banner/search.jpg";
import { useEffect, useState } from "react";
import apiCall from "services/api";
import parse from 'html-react-parser';
import Testimonial from "components/asseenin/Testimonial";
import { getHeaders } from "services/getHeaders";
import { useAuth } from "providers/AuthContext";
import { useMeta } from "providers/MetaContext";
import { ChildItemFaq, initialChildItemFaq } from "types/interfaces";


const Faq = () => {
  const { userTkn, signOut } = useAuth();
  const { currency } = useMeta();
  const [faqData, setFaqData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [faqContentItems, setFaqContentItems] = useState<ChildItemFaq >(initialChildItemFaq);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {

        const [response, contentResponse] = await Promise.all([
          apiCall({
            url: `cms/faq`,
            method: 'GET',
            headers: getHeaders(userTkn, currency),
  
          }),
          apiCall({
            url: `cms/content-items/faq_faq`,
            method: 'GET',
            headers: getHeaders(userTkn, currency)
          })
        ])
      

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            let faqData = response?.data?.Faq?.data
            setFaqData(faqData);
          }
        }
        if (contentResponse?.status === 200) {
          let data = contentResponse?.data?.ContentItem?.data?.content?.childs;
          setFaqContentItems(data)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (

    <>   {loading && ( // Full page loading overlay
      <div className="loading-overlay">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="listing-spinner"
        />
      </div>
    )}

      <section id="hero-faq" className="d-flex flex-column justify-content-center"
        style={{ background: `url(${BannerImage})` }}>

        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="hero-heading fw-bold high-light-white text-center">{faqContentItems?.banner_heading_1?.item_value} <span className="high-light-red">{faqContentItems?.banner_heading_2?.item_value} </span> </p>
              <p className="faq-sub-title">{faqContentItems?.banner_head_sub?.item_value} </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-9 pt-sm-9 trips-faq">
        <div className="container">
          <Row className="flex-center">
            <Col xs={12} xl={12}>
              {/* <h2 className="text-center mb-6 mb-sm-10">
                      Frequently asked questions
                    </h2> */}
              <Accordion className="" defaultActiveKey="#">
                {faqData?.map((faq: any, index: number) => (
                  <Accordion.Item
                    className={''}
                    eventKey={String(index)}
                    key={faq?.code}
                  >
                    <Accordion.Button className="fs-7">
                      <h2 >
                        {parse(faq?.name || '')}
                      </h2>
                    </Accordion.Button>
                    <Accordion.Body className="pt-0">{faq?.faqs?.map((item: any, index: number) => (
                      <div className="faq-items" key={index}>
                        <div className="faq-item-ques">{parse(item.question || '')}</div>
                        <div className="faq-item-ans">{parse(item.answer || '')}</div>
                        {/* <div className="faq-item-ans">{item.answer2}</div> */}
                      </div>
                    ))}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </div>
      </section>

      {/*last section*/}
      <Testimonial />
    </>
  )
}

export default Faq
