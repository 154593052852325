import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import { useSignIn } from "providers/SignInProvider";
import { useAuth } from "providers/AuthContext";
import { EyeIcon2 } from "./EyeIcon";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

type TripSignInProps = {
  showErrorToast: (message: string) => void;
  showSuccessToast: (message: string) => void;
}

const SignInForm: React.FC<TripSignInProps> = ({ showErrorToast, showSuccessToast }) => {

  const { showIn, setShowIn, toggleForms, setToggleForms, resetPassToken } = useSignIn();

  const { signIn, forgotPassword, resetPassword, signInGoogle } = useAuth()
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [commonError, setCommonError] = useState<any>("");
  const [googleAuthError, setGoogleAuthError] = useState<any>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  })
  const [error, setError] = useState({
    email: "",
    password: ""
  })

  const [formDataForgot, setFormDataForgot] = useState({
    email: ""
  })
  const [errorForgot, setErrorForgot] = useState({
    email: ""
  })


  const [formDataReset, setFormDataReset] = useState({
    password: "",
    confirm_password: ""
  })
  const [errorReset, setErrorReset] = useState({
    password: "",
    confirm_password: ""
  })


  const handleClose = () => {
    setGoogleAuthError("");
    setError({
      email: "",
      password: ""
    })
    setShowIn(false)
    setToggleForms(prev => ({
      ...prev,
      signIn: true,
      forgotPassword: false,
      resetPassword: false
    }));
    setFormData({
      email: "",
    password: ""
    })
    setError({
      email: "",
    password: ""
    })
    setFormDataForgot({
      email:""
    });
    setErrorForgot({
      email:""
    });
    setFormDataReset({
      password: "",
    confirm_password: ""
    })
    setErrorReset({
      password: "",
    confirm_password: ""
    })
  };

  const goToSignIn = () => {
    setToggleForms(prev => ({
      ...prev,
      signIn: true,
      forgotPassword: false,
      resetPassword: false
    }))
  }

  const goToForgotPasseord = () => {
    setToggleForms(prev => ({
      ...prev,
      signIn: false,
      forgotPassword: true,
      resetPassword: false
    }))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setError({
      ...error,
      [name]: '',
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let errorMessage = "";
    // Validation for email
    if (name === "email") {
      if (!value.trim()) {
        errorMessage = "Email is required.";
      }
      // else if (!regexPatterns.emailRegex.test(value)) {
      //   errorMessage = "Please enter a valid email address.";
      // }
    }

    // Validation for password
    if (name === "password") {
      if (!value.trim()) {
        errorMessage = "Password is required.";
      }
      else if (value.length < 6) {
        errorMessage = "Please enter correct password.";
      }
    }


    setError({
      ...error,
      [name]: errorMessage,
    });

  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setValidated(true);

    if (!formData.email.trim()) {
      setError((prevErrors) => ({
        ...prevErrors,
        email: "Email is required.",
      }));
    }

    if (!formData.password.trim()) {
      setError((prevErrors) => ({
        ...prevErrors,
        password: "Password is required.",
      }));
    }

    if (form.checkValidity() === false || !formData.email.trim() || !formData.password.trim()) {
      return;
    }

    setLoading(true);

    try {
      const value = await signIn(formData.email, formData.password, setCommonError, handleError, showErrorToast);
      if (value === true) {
        setShowIn(false); // Close the modal after successful sign-in
      }

    } catch (error) {
      // Handle error from signIn function or any other errors
      console.error("Sign-in error:", error);
      setCommonError("An error occurred. Please try again.");
    }
    finally {
      setLoading(false);
    }
  };
  const handleError = (error: any) => {
    const errors = error?.errors;
    // Dynamically set errors based on the response
    Object.keys(errors).forEach((key) => {
      const errorMessages = errors[key];
      const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message

      // Set the error state for the corresponding field
      setError((prevData) => ({
        ...prevData,
        [key]: firstErrorMessage,
      }));
    });
  }

  const handleErrorForgot = (error: any) => {
    const errors = error?.errors;
    // Dynamically set errors based on the response
    Object.keys(errors).forEach((key) => {
      const errorMessages = errors[key];
      const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message

      // Set the error state for the corresponding field
      setErrorForgot((prevData) => ({
        ...prevData,
        [key]: firstErrorMessage,
      }));
    });
  }
  const handleErrorReset = (error: any) => {
    const errors = error?.errors;
    // Dynamically set errors based on the response
    Object.keys(errors).forEach((key) => {
      const errorMessages = errors[key];
      const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message

      // Set the error state for the corresponding field
      setErrorReset((prevData) => ({
        ...prevData,
        [key]: firstErrorMessage,
      }));
    });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityReset = () => {
    setShowPasswordReset(!showPasswordReset);
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const token = tokenResponse.access_token;
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
      const result = userInfo.data;
      signInGoogle(
        "google",
        result.sub,
        result.email,
        setGoogleAuthError,
        handleClose
      );

    }

  });

  useEffect(() => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 1500);
  }, [commonError, googleAuthError]);

  const handleInputChangeForgot = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setErrorForgot({
      ...errorForgot,
      [name]: '',
    });

    setFormDataForgot({
      ...formDataForgot,
      [name]: value,
    });
  };
  const handleInputBlurForgot = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let errorMessage = "";
    // Validation for email
    if (name === "email") {
      if (!value.trim()) {
        errorMessage = "Email is required.";
      }
      // else if (!regexPatterns.emailRegex.test(value)) {
      //   errorMessage = "Please enter a valid email address.";
      // }
    }

    // Validation for password
    if (name === "password") {
      if (!value.trim()) {
        errorMessage = "Password is required.";
      }
      else if (value.length < 6) {
        errorMessage = "Please enter correct password.";
      }
    }


    setErrorForgot({
      ...error,
      [name]: errorMessage,
    });

  };

  const handleSubmitForgotPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setValidated(true);
    const { email } = formDataForgot;

    let errors = { ...errorForgot };
    let isValid = true;
    // if (!formDataForgot.email.trim()) {
    //   setErrorForgot((prevErrors) => ({
    //     ...prevErrors,
    //     email: "Email is required.",
    //   }));
    // }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    if (!isValid) {
      setErrorForgot(errors);
      return;
    }


    if (isValid) {
      try {
        setLoading(true);
        const value = await forgotPassword(formDataForgot.email, setCommonError, handleErrorForgot, showErrorToast, showSuccessToast);
        if (value === true) {
          setTimeout(() => {
            setToggleForms(prev => ({
              ...prev,
              signIn: true,
              forgotPassword: false,
              resetPassword: false
            }));
          }, 1000);
        }

      } catch (error) {
        // Handle error from signIn function or any other errors
        console.error("Sign-in error:", error);
        setCommonError("An error occurred. Please try again.");
      }
      finally {
        setLoading(false);
      }
    }
  };

  const handleInputChangeReset = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setErrorReset({
      ...errorReset,
      [name]: '',
    });

    setFormDataReset({
      ...formDataReset,
      [name]: value,
    });
  };
  const handleInputBlurReset = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let errorMessage = "";
    // Validation for email
    if (name === "password") {
      if (!value.trim()) {
        errorMessage = "Password is required.";
      }
      // else if (!regexPatterns.emailRegex.test(value)) {
      //   errorMessage = "Please enter a valid email address.";
      // }
    }

    // Validation for password
    if (name === "confirm_password") {
      if (!value.trim()) {
        errorMessage = "Confirm password is required.";
      }
      else if (value.length < 6) {
        errorMessage = "Please enter correct password.";
      }
    }


    setErrorForgot({
      ...error,
      [name]: errorMessage,
    });

  };

  const handleSubmitResetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setValidated(true);

    if (!formDataReset.password.trim()) {
      setErrorReset((prevErrors) => ({
        ...prevErrors,
        password: "Password is required.",
      }));
    }
    if (!formDataReset.confirm_password.trim()) {
      setErrorReset((prevErrors) => ({
        ...prevErrors,
        confirm_password: "Confirm Password is required.",
      }));
    }

    // Check if password and confirm_password match
    if (formDataReset.password !== formDataReset.confirm_password) {
      setErrorReset((prevErrors) => ({
        ...prevErrors,
        confirm_password: "Passwords do not match.",
      }));
      return; // Stop submission if passwords don't match
    }

    if (form.checkValidity() === false || !formDataReset.password.trim() || !formDataReset.confirm_password.trim()) {
      return;
    }

    setLoading(true);

    try {

      const value = await resetPassword(formDataReset.password, formDataReset.confirm_password, resetPassToken, setCommonError, handleErrorReset, showErrorToast, showSuccessToast);
      if (value === true) {
        setTimeout(() => {
          setToggleForms(prev => ({
            ...prev,
            signIn: true,
            forgotPassword: false,
            resetPassword: false
          }));
        }, 1000); // Close the modal after successful sign-in
      }

    } catch (error) {
      // Handle error from signIn function or any other errors
      console.error("Sign-in error:", error);
      setCommonError("An error occurred. Please try again.");
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={showIn} onHide={handleClose} className="sign-up-modal" centered={true} backdrop="static">
      <Modal.Header >
        {toggleForms?.signIn && <p className="modal-title-custom mb-0">{"Sign In"}</p>}
        {toggleForms?.forgotPassword && <p className="modal-title-custom mb-0">{"Forgot Password"}</p>}
        {toggleForms?.resetPassword && <p className="modal-title-custom mb-0">{"Reset Password"}</p>}
        <div className="modal-close" onClick={handleClose}>
          <FontAwesomeIcon icon={faClose} className="fs-9" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-bdy">
          {toggleForms?.signIn === true &&
            <>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="email"
                    id="email"
                    placeholder="name@example.com"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    isInvalid={error.email !== ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error?.email && error?.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>Password</Form.Label>
                  <div className="form-icon-container">
                    <Form.Control
                      required
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="password"
                      value={formData?.password}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      minLength={6}
                      isInvalid={error.password !== ""}
                    />
                    <EyeIcon2 togglePasswordVisibility={togglePasswordVisibility} showPassword={showPassword} />
                    <Form.Control.Feedback type="invalid">
                      {error?.password && error?.password}
                    </Form.Control.Feedback>
                  </div>

                </Form.Group>
                {/* <div className="d-flex flex-row gap-1">
              <Form.Check
                type='checkb const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };ox'
                id='checkedCheckbox'
                label={""}
                defaultChecked
              />
              <p className="checkbox-label">By creating an account, I accept the <a href="">Terms & Conditions</a> and  <a href="">Privacy Policy</a></p>


            </div> */}

                <div className="custom-btn-size d-flex justify-content-center pt-4">
                  <Button variant="primary" className="flex-grow-1 flex-lg-grow-0 w-100" type="submit" disabled={loading} >Sign In </Button>
                </div>
                <div className="pb-3 d-flex justify-content-end pt-1">
                  <Button variant='link' onClick={() => { goToForgotPasseord() }}> Forgot Password? </Button>
                </div>
              </Form>
              <div className="custom-btn-size d-flex justify-content-center pb-3">
                <Button onClick={() => { loginGoogle() }} variant="outline-primary" className="flex-grow-1 flex-lg-grow-0 w-100 custom-btn"><i className="fab fa-google fa-1x"></i>   Sign in with Google </Button>
              </div>
              {googleAuthError && googleAuthError?.errors?.email && (
                <div
                  style={{
                    marginBottom: "0.25rem",
                    color: "#ed2000",
                    fontSize: "75%",
                    paddingLeft: "1rem",
                  }}
                >
                  {googleAuthError?.errors?.email[0]}
                </div>
              )}
              {/* <div className="custom-btn-size d-flex justify-content-center pb-3">
            <Button variant="outline-primary" className="flex-grow-1 flex-lg-grow-0 w-100 custom-btn"><i className="fab fa-facebook fa-1x"></i>  Sign in with Facebook</Button>
          </div>
          <div className="custom-btn-size d-flex justify-content-center pb-4">
            <Button variant="outline-primary" className="flex-grow-1 flex-lg-grow-0 w-100 custom-btn"> <i className="fab fa-instagram fa-1x"></i>Sign in with Instagram </Button>
          </div> */}
            </>
          }
          {toggleForms?.forgotPassword === true && <>
            <Form noValidate validated={validated} onSubmit={handleSubmitForgotPassword}>
              <Form.Group className="mb-3" >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="email"
                  id="email"
                  placeholder="name@example.com"
                  value={formDataForgot.email}
                  onChange={handleInputChangeForgot}
                  onBlur={handleInputBlurForgot}
                  isInvalid={errorForgot.email !== ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForgot?.email && errorForgot?.email}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="custom-btn-size d-flex justify-content-center pt-4">
                <Button variant="primary" className="flex-grow-1 flex-lg-grow-0 w-100" type="submit" disabled={loading} > Send</Button>
              </div>
              <div className="pb-3 d-flex justify-content-end align-items-center pt-1">
                <span className="forgot-text">Already have an account click here</span> <Button variant='link' onClick={() => { goToSignIn() }}>Sign In? </Button>
              </div>
            </Form>

          </>
          }
          {toggleForms?.resetPassword === true && <>
            <Form noValidate validated={validated} onSubmit={handleSubmitResetPassword}>
              <Form.Group className="mb-3" >
                <Form.Label>Password *</Form.Label>
                <div className="form-icon-container">
                  <Form.Control
                    required
                    type={showPasswordReset ? "text" : "password"}
                    name="password"
                    id="email"
                    placeholder="New password"
                    value={formDataReset.password}
                    onChange={handleInputChangeReset}
                    onBlur={handleInputBlurReset}
                    isInvalid={errorReset.password !== ""}
                  />
                  <EyeIcon2 togglePasswordVisibility={togglePasswordVisibilityReset} showPassword={showPasswordReset} />
                  <Form.Control.Feedback type="invalid">
                    {errorReset?.password && errorReset?.password}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Confirm password *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="confirm_password"
                  id="confirm_password"
                  placeholder="Confirm password"
                  value={formDataReset.confirm_password}
                  onChange={handleInputChangeReset}
                  onBlur={handleInputBlurReset}
                  isInvalid={errorReset.confirm_password !== ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errorReset?.confirm_password && errorReset?.confirm_password}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="custom-btn-size d-flex justify-content-center pt-4">
                <Button variant="primary" className="flex-grow-1 flex-lg-grow-0 w-100" type="submit" disabled={loading} > Send</Button>
              </div>
              <div className="pb-3 d-flex justify-content-end align-items-center pt-1">
                <span className="forgot-text">Go to </span> <Button variant='link' onClick={() => { }}>Sign In? </Button>
              </div>
            </Form>

          </>
          }


        </div>
      </Modal.Body>

    </Modal>
  )
}

export default SignInForm