import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "providers/AuthContext";
import { useCallback, useEffect, useState } from "react";
import { TripDetailsFormProps } from "types/interfaces";
import apiCall from 'services/api';
import { AxiosError } from "axios";
import TripViewUpdate from "./view/TripViewUpdate";
import { Spinner } from "react-bootstrap";
import { useToast } from "providers/ToastProvider";
import { handleApiError } from "services/handleApiError";



const TripUpdateView = () => { 
    const { trip_uuid, version } = useParams<{ trip_uuid: string, version: string }>();
    const location = useLocation();
    const { trips, source } = location.state || {};

    const { signOut, userTkn } = useAuth()
    const { showToast, showSuccessToast, showInfoToast, showErrorToast } = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [tripData, setTripData] = useState<TripDetailsFormProps | null>(null);
    const [tripView, setTripView] = useState<boolean>(false);  // for toggle Trip Form Steps and Trip View
    const [currentVersion, setCurrentVersion] = useState<string>(version || '');




    useEffect(() => {

        let isMounted = true;  // Flag to track component mount status
        const fetchTripData = async () => {
            try {
                setLoading(true);
                const response = await apiCall({
                    url: `trip/${trip_uuid}/${currentVersion}/view`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn
                    },
                });
                if (isMounted && response.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;
                        showErrorToast(errors)

                    } else {
                        const data = response?.data?.TripVersion?.data;

                        const selectedMonthsCode = data?.tripAvailability?.map((item: any) => Number(item?.meta_months_no));
                        const selectedMonths = data?.tripAvailability?.map((item: any) => item?.month);
                        //set code[] and alldata[] of Months
                        const selectedCategories = data?.tripCategory?.map((item: any) => item?.masterTripCategory);
                        const selectedCategoryCodes = data?.tripCategory?.map((item: any) => Number(item?.master_trip_category_code));

                        setTripData((prev: any) => ({
                            ...prev,
                            id: data?.id,
                            title: data?.title,
                            trip_uuid: data?.trip_uuid,
                            version: data?.version,
                            versions: data?.versions,
                            pendingVersion: data?.pendingVersion,
                            liveVersion: data?.liveVersion,
                            status: data?.status,
                            meta_currency_code: data?.meta_currency_code,
                            price: data?.price,
                            no_of_days: data?.no_of_days,
                            start_location: data?.startLocation?.id,
                            end_location: data?.endLocation.id,
                            start_location_option: { value: data?.startLocation?.id, label: data?.startLocation?.name },
                            end_location_option: { value: data?.endLocation?.id, label: data?.endLocation?.name },
                            banner_image: data?.bannerImage?.id,
                            banner_image_preview: data?.bannerImage?.url,
                            listing_images: [],
                            listing_image_preview: data?.listingImages.map((image: any) => ({
                                url: image.url,
                                id: image.id,
                                name: image.name
                            })),
                            listing_images_preserve: data?.listingImages.map((image: any) => Number(image?.id)) || [],
                            description: data?.description,

                            details: data?.details,

                            slectedCategoriesCode: selectedCategoryCodes,
                            selectedCategories: selectedCategories,
                            trip_availabilities_code: selectedMonthsCode,
                            trip_availabilities: selectedMonths,

                            meta_title: data?.seo?.meta_title,
                            is_meta_title_same_as_title: data?.seo?.is_meta_title_same_as_title,
                            meta_keywords: data?.seo?.meta_keywords,
                            is_meta_title_same_as_trip_tags: data?.seo?.is_meta_title_same_as_trip_tags,
                            meta_description: data?.seo?.meta_description,
                            is_meta_title_same_as_description: data?.seo?.is_meta_title_same_as_description,

                            accomodation_status: data?.includedexcluded?.accomodation?.accomodation_status,
                            accomodation_note: data?.includedexcluded?.accomodation?.accomodation_note,
                            flight_status: data?.includedexcluded?.flight?.flight_status,
                            flight_note: data?.includedexcluded?.flight?.flight_note,
                            ground_transport_status: data?.includedexcluded?.ground_transport?.ground_transport_status,
                            ground_transport_note: data?.includedexcluded?.ground_transport?.ground_transport_note,
                            meals_status: data?.includedexcluded?.measls?.meals_status,
                            meals_note: data?.includedexcluded?.measls?.meals_note,
                            included: data?.includedexcluded?.included,
                            excluded: data?.includedexcluded?.excluded,
                            optional: data?.includedexcluded?.optional,

                            privacy_policy: data?.addionalInfo?.privacy_policy,
                            term_and_conditions: data?.addionalInfo?.term_and_conditions,
                            change_policy: data?.addionalInfo?.change_policy,
                            must_carry: data?.addionalInfo?.must_carry,
                            dayItinerary: data?.dayItinerary,
                            dayItineraryOrder: data?.dayItineraryOrder

                        }));


                    }
                }
            } catch (error: any) {

                handleApiError(error, showErrorToast, signOut);

            } finally {
                if (isMounted) setLoading(false);
            }
        };


        if (trip_uuid && version && userTkn) {
            fetchTripData();
        }
        // Cleanup function
        return () => {
            isMounted = false;  // Mark component as unmounted
        };

    }, [trip_uuid, currentVersion, userTkn, signOut, showErrorToast]);

    const toggleView = useCallback(() => {
        setTripView((prevTripView) => !prevTripView);
    }, []);

    const updateUrlVersion = (version: string) => {
        const currentUrl = window.location.href;
        const versionRegex = /\/v\d+\//; // Regex to match version path, e.g., /v1/
        const updatedUrl = currentUrl.replace(versionRegex, `/${version}/`);
        window.history.replaceState(null, '', updatedUrl);
    };

    const handleUpdateUrlClick = (version: string) => {
        setCurrentVersion(version);
        updateUrlVersion(version);
    };


    const sendForApproval = async (trip_uuid: string, version: string) => {
        try {
            setLoading(true);
            let url = `trip/${trip_uuid}/${version}/send-for-approval`
            const response = await apiCall({
                url: url,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn
                },
            });
            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                } else {
                    let data = response?.data?.TripVersion?.data;


                    setTripData((prev: any) => ({
                        ...prev,
                        status: data?.status
                    }))
                }
            }
        } catch (error: any) {
            handleApiError(error, showErrorToast, signOut);
            setLoading(false);

        } finally {
            setLoading(false);
        }

    }


    const copyTrip = async (trip_uuid: string, version: string) => {

        try {
            setLoading(true);
            let url = `trip/${trip_uuid}/${version}/copy-trip`
            const response = await apiCall({
                url: url,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn
                },
            });
            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                } else {

                    showSuccessToast(response?.data?.message)
                }
            }
        } catch (error: any) {
            handleApiError(error, showErrorToast, signOut);
            setLoading(false);

        } finally {
            setLoading(false);
        }
    }


    return <>
        {loading ? <div className="loading-overlay">
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="listing-spinner"
            />
        </div> :
            <TripViewUpdate loading={loading} source={source} sendForApproval={sendForApproval} copyTrip={copyTrip} handleUpdateUrlClick={handleUpdateUrlClick} currentVersion={currentVersion} tripData={tripData} viewType={2} tripToggle={toggleView} tripView={tripView}/>
        }
    </>

}

export default TripUpdateView