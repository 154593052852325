import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import App from 'App';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Home from 'pages/Home';
import TripsDestination from 'pages/TripsDestination';
import Creators from 'pages/Creators';
import Faq from 'pages/Faq';
import { PrivateRouteAuth } from 'PrivateRouteAuth'; // Assuming this is for authenticated routPrivateRouteAuthes
import Messages from 'pages/authpages/Messages';
import TravelProfile from 'pages/authpages/TravelProfile';
import BookTrip from 'pages/authpages/BookTrip';
import MyAccount from 'pages/authpages/MyAccount';
import { PrivateRouteAuthCreator } from 'PrivateRouteAuthCreator';
import Dashboard from 'pages/creators/Dashboard';
import Listing from 'pages/creators/Listing';
import MyTrips from 'pages/authpages/MyTrips';
import CreateTrip from 'pages/creators/trips/CreateTrip';
import TopDestination from 'pages/topdestination/TopDestination';
import TopCategories from 'pages/topcategory/TopCategories';
import GetInTouchComp from 'pages/GetInTouchComp';
import TripUpdateView from 'pages/creators/trips/TripUpdateView';
import TripCreateView from 'pages/creators/trips/TripCreateView';
import UpdateTrip from 'pages/creators/trips/UpdateTrip';
import AboutUs from 'pages/AboutUs';
import ListingPendingForApproval from 'pages/creators/ListingPendingForApproval';
import CreatorLeads from 'pages/creators/CreatorLeads';
import TripsViewTraveller from 'pages/traveller/view/TripsViewTraveller';
import TermsConditions from 'pages/staticPages/TermsConditions';
import TopCreators from 'pages/TopCreators';
import AgentView from 'pages/agent/AgentView';
import TagsView from 'pages/tags/TagsView';
import BecomeCreators from 'pages/becomeCreator/BecomeCreators';
import CreatorIntrestForm from 'pages/becomeCreator/CreatorIntrestForm';
import ProfileCreator from 'pages/creators/profile/CreatorProfile';
import NotFound from 'components/404/NotFound';
import CopyrightPrivacyPolicy from 'pages/staticPages/CopyrightPrivacyPolicy';
import CommunityGuidline from 'pages/staticPages/CommunityGuidline';
import UserTerms from 'pages/staticPages/UserTerms';
import WebsiteUseTerms from 'pages/staticPages/WebsiteUseTerms';
import PrivacyPolicy from 'pages/staticPages/PrivacyPolicy';
import CookiePolicy from 'pages/staticPages/CookiePolicy';
import BlogsListing from 'pages/blogs/BlogsListing';
import BlogsView from 'pages/blogs/BlogsView';
import CreatorBlogsListing from 'pages/creators/blogs/CreatorBlogsListing';
import CreatorBlogsView from 'pages/creators/blogs/CreatorBlogsView';
import Traveller from 'pages/creators/Traveller/Traveller';
import CreateTraveller from 'pages/creators/Traveller/CreateTraveller';
import CreatorsViewAll from 'pages/CreatorsViewAll';



const publicRoutes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          { path: '/', element: <Home /> },
          { path: '/trips-and-destination', element: <TripsDestination /> },
          { path: '/trips-and-destination/tour/:slug', element: <TripsViewTraveller /> },
          { path: '/agents/:agentslug', element: <AgentView /> },
          { path: '/tags/:tagslug', element: <TagsView /> },
          { path: '/become-a-creator', element: <BecomeCreators /> },
          { path: '/intrest-form', element: <CreatorIntrestForm /> },
          { path: '/faq', element: <Faq /> },
          { path: '/blogs', element: <BlogsListing /> },
          { path: '/blogs/:slug', element: <BlogsView /> },
          { path: '/top-destinations/:id/:slug', element: <TopDestination /> },
          { path: '/top-categories/:code/:slug', element: <TopCategories /> },
          { path: '/getintouch', element: <GetInTouchComp /> },
          { path: '/aboutus', element: <AboutUs /> },
          { path: '/our-creators', element: <Creators /> },
          { path: '/all-creators', element: <CreatorsViewAll /> },
          { path: '/topcreators', element: <TopCreators /> },
          { path: '/terms-conditions', element: <TermsConditions /> },
          { path: '/copyright-policy', element: <CopyrightPrivacyPolicy /> },
          { path: '/community-guidline', element: <CommunityGuidline /> },
          { path: '/user-terms', element: <UserTerms /> },
          { path: '/website-use-terms', element: <WebsiteUseTerms /> },
          { path: '/privacy-policy', element: <PrivacyPolicy /> },
          { path: '/cookie-policy', element: <CookiePolicy /> },

          
       

          { path: '*', element: <NotFound /> },
  

        ]
      }

    ]
  },
];

const privateRoutes: RouteObject[] = [

  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          { path: '/message', element: <PrivateRouteAuth element={<Messages />} /> },
          { path: '/travel-profile', element: <PrivateRouteAuth element={<TravelProfile />} /> },
          { path: '/book-a-trip', element: <PrivateRouteAuth element={<BookTrip />} /> },
          { path: '/my-account', element: <PrivateRouteAuth element={<MyAccount />} /> },
          { path: '/my-trips', element: <PrivateRouteAuth element={<MyTrips />} /> },

          { path: '*', element: <NotFound /> },

        ]
      }

    ]
  },
];

const privateRoutesCreator: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/creator',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider> 
        ),
        children: [
          { path: 'dashboard', element: <PrivateRouteAuthCreator element={<Dashboard />} /> },
          { path: 'trips', element: <PrivateRouteAuthCreator element={<Listing />} /> },
          { path: 'trips/:trip_uuid/:version/view', element: <PrivateRouteAuthCreator element={<TripUpdateView />} /> },
          { path: 'trips/create/:id/view', element: <PrivateRouteAuthCreator element={<TripCreateView />} /> },
          { path: 'trips/create', element: <PrivateRouteAuthCreator element={<CreateTrip />} /> },
          { path: 'trips/:trip_uuid/:version/update', element: <PrivateRouteAuthCreator element={<UpdateTrip />} /> },
          { path: 'travelers', element: <PrivateRouteAuthCreator element={<Traveller />} /> },
          { path: 'travelers/create', element: <PrivateRouteAuthCreator element={<CreateTraveller />} /> },
          { path: 'leads', element: <PrivateRouteAuthCreator element={<CreatorLeads />} /> },
          { path: 'profile-creator', element: <PrivateRouteAuthCreator element={< ProfileCreator/>} /> },
          { path: 'blogs', element: <PrivateRouteAuthCreator element={<CreatorBlogsListing />} /> },
          { path: 'blogs/:slug', element: <PrivateRouteAuthCreator element={<CreatorBlogsView />} />},

          { path: 'trips/pending-for-approval', element: <PrivateRouteAuthCreator element={<ListingPendingForApproval />} /> },

          { path: '*', element: <NotFound /> },

        ]
      }

    ]
  },
];

const routes = [
  // Public routes first, then private routes
  ...publicRoutes,
  ...privateRoutes,
  ...privateRoutesCreator
];

export const router = createBrowserRouter(routes);

export default routes;