import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlogCardCreator from "components/cards/BlogCardCreator";
import DummyBlogCard from "components/common/dummycard/DummyBlogCard";
import { useAuth } from "providers/AuthContext";
import { useMeta } from "providers/MetaContext";
import { useToast } from "providers/ToastProvider";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Row, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom";
import apiCall from "services/api";
import { getHeaders } from "services/getHeaders";
import { handleApiError } from "services/handleApiError";
import { BlogFiltersFront, BlogProps, initialSummary, SummaryProps } from "types/interfaces";

const Dashboard = () => {
  const { userProfile, userTkn, signOut } = useAuth();
  const { currency } = useMeta();
  const { showErrorToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [trips, setTrips] = useState<any[]>([]);
  const [leadsData, setLeadsData] = useState({
    liveTripCount: 0,
    tripViewCount: 0,
    lead_count: 0
  });
  const [blogList, setBlogList] = useState<BlogProps[]>([]);
  const [summary, setSummary] = useState<SummaryProps>(initialSummary);
  const [filters, setFilters] = useState<BlogFiltersFront>({
    pageIndex: 1,
    search: "",
    pageSize: 10,
    category: {
      id: null,
      name: "",
      slug: "",
      blogCount: 0
    },
  });

  const buildUrl = (filters: BlogFiltersFront, pageIndex: number) => {
    const params = new URLSearchParams({
      page: pageIndex.toString(),
      pageSize: String(filters?.pageSize),
    });

    // Add filter parameters only if they have a meaningful value
    if (filters.search) params.append("title", filters.search.toString());
    if (filters.category?.id) params.append("category_id", String(filters?.category?.id));

    // Check if no filters were applied
    if (Array.from(params).length === 2) { // Only 'page' and 'pageSize' are in params
      return `cms/creators-blog?page=${pageIndex}&pageSize=10`;
    }

    return `cms/creators-blog?${params.toString()}`;
  };
  const fetchBlogData = async () => {
    try {
      setLoading(true);
      const url = buildUrl(filters, filters?.pageIndex);

      const blogsResponse = await apiCall({
        url,
        method: "GET",
        headers: getHeaders(userTkn, currency),
      });

      if (blogsResponse?.status === 200) {
        const data = blogsResponse.data?.CreatorsBlogs?.data;
        const summaryData = blogsResponse.data?.CreatorsBlogs?.summary;

        setSummary({
          total: summaryData.total,
          page: summaryData.page,
          pageSize: summaryData.pageSize,
          total_page: summaryData.total_page,
        });

        setBlogList(prev => {
          if (filters?.pageIndex === 1) {
            // Reset data if fetching the first page
            return [...data];
          } else {
            // Append new data for subsequent pages
            return [...prev, ...data];
          }
        });
      }

      if (blogsResponse?.data?.errors) {
        const errors = blogsResponse.data.errors;
        console.error("Errors:", errors);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      handleApiError(error, showErrorToast, signOut);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const url = `trips?page=${pageIndex}&pageSize=12&status=0`;
        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
          },
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data?.TripVersion?.data;
            // const summary = response?.data?.TripVersion?.summary;

            // setSummary({
            //   total: summary.total,
            //   page: summary.page,
            //   pageSize: summary.pageSize,
            //   total_page: summary.total_page,
            // });

            setTrips(prev => {
              if (filters?.pageIndex === 1) {
                // Reset data if fetching the first page
                return [...data];
              } else {
                // Append new data for subsequent pages
                return [...prev, ...data];
              }
            });
            setLoading(false);
          }
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
        setLoading(false);
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    };

    fetchData();
  }, [userTkn, pageIndex]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `creators-dashboard`
        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
          },
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            const data = response?.data;
            setLeadsData(data)

          }
        }
        fetchBlogData();
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userTkn])

  const handleLoadMore = () => {
    setLoadingMore(true);
    if (filters?.pageIndex <= summary?.total_page) {
      const newPage = filters?.pageIndex + 1;
      setFilters((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex + 1
      }))
      // const url = buildUrlFilter(filters, newPage);
      // navigate(`/creator/blogs?${url}`);
    } else {
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);

    }
  };


  return (

    <>
      {loading && ( // Full page loading overlay
        <div className="loading-overlay">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="listing-spinner"
          />
        </div>
      )}
      <div className="pt-3 pb-3 trips-listing-heading" >
        <div className="container-fluid cardListing">
          <div className="row mb-2">
            <div className="col-12 mb-3">
              <p className="main-heading fw-bold high-light-black text-center m-0">Welcome back <span className="high-light-red">{userProfile?.user?.first_name}</span></p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-md-5">
              <div className="dashboard-count-container">
                <p className="m-0 fw-bold high-light-black">Live <span className="high-light-red">Trips</span></p>
                <div><span className="trips-type-count">{leadsData?.liveTripCount}</span></div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-md-5">
              <div className="dashboard-count-container">
                <p className="m-0 fw-bold high-light-black">Trips <span className="high-light-red">Views</span></p>
                <div><span className="trips-type-count">{leadsData?.tripViewCount}</span></div>
              </div>

            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
              <div className="dashboard-count-container">
                <p className="m-0 fw-bold high-light-black">Leads <span className="high-light-red"></span></p>
                <div><span className="trips-type-count">{leadsData?.lead_count}</span></div>
              </div>
            </div>
          </div>
        {(Array.isArray(trips) && trips.length > 0) &&  <div className="row mb-2">
            <div className="col-12 draft-listing">
              <p className="fw-bold high-light-black fs-6 pb-2 pt-5 m-0">Draft Listing</p>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Location</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(Array.isArray(trips) && trips.length > 0) ?
                    <>
                      {trips?.map((item, index) => (
                        <tr>
                          <td>{item?.title}</td>
                          <td>{item?.startLocation?.name} </td>
                          <td>
                            <Link
                              to={{
                                pathname: `/creator/trips/${item?.trip_uuid}/${item?.version}/view`,
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </> :
                    <>

                    </>
                  }
                </tbody>
              </Table>

            </div>
          </div>
          }
        
        {(Array.isArray(blogList) && blogList?.length > 0) &&  <div className="row mb-2">
            <div className="col-12">
              <p className="fw-bold high-light-black fs-6 py-5 m-0">Blogs </p>
            </div>
          </div>
        }
          {loading && ( // Full page loading overlay

            <Row className="mt-2">
              <DummyBlogCard />
              <DummyBlogCard />
              <DummyBlogCard />
              <DummyBlogCard />
            </Row>

          )}
          {!loading && <Row className="mt-2">
            {(Array.isArray(blogList) && blogList?.length > 0) ?

              <>
                {blogList?.map((item, index) => (
                  <BlogCardCreator key={index} item={item} />

                ))}
              </>

              :
              <div className="col-12  d-flex justify-content-center">
                {/* <h3>Sorry No Blog Found!!</h3> */}
              </div>
            }
            {blogList?.length > 0 ?
              <>
                {((Number(filters?.pageIndex) === 1 && !loading) || Number(filters?.pageIndex) > 1) && <div className="custom-btn-size d-flex justify-content-center mt-3">
                  {Number(summary?.page) === Number(summary?.total_page) ?
                    <div className="d-flex justify-content-center"></div> :
                    <button onClick={handleLoadMore} className="flex-grow-1 flex-lg-grow-0 btn btn-primary gap-2">
                      {loadingMore ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                        : ''
                      }
                      <span className="ml-2">{"Load More"}</span>
                    </button>
                  }

                </div>
                }
              </> :
              <>
                {/* {!loading && <div className="d-flex justify-content-center"><h3>Oops No Record Found !!</h3></div>} */}
              </>
            }

          </Row>}


        </div>
      </div>
    </>
  )
}

export default Dashboard
