import React from 'react';
import { Card } from 'react-bootstrap';
import './DummyCard.css'; // Assuming you have a separate CSS file for styling the placeholders

const DummyTeamCard = () => {
  return (
    <div className="col-lg-4 mb-5">
      <Card className="card-trip">
        <div className="d-flex flex-row align-items-center mb-3 mt-3">
          {/* Placeholder for the avatar image */}
          <div className="teamImg">
            <div className="base-team">
              <div className="dummy-avatar-placeholder-team"></div>
            </div>
          </div>

          {/* Placeholder for the text content */}
          <div className="d-flex flex-column gap-1 ps-3">
            <div className="dummy-name-placeholder"></div>
            <div className="dummy-position-placeholder"></div>
            <div className="dummy-linkedin-placeholder"></div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DummyTeamCard;
