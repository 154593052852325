import Intercom from "@intercom/messenger-js-sdk";
import { useAuth } from "providers/AuthContext";
import CryptoJS from "crypto-js";

export default function IntercomChat() {
  const { userProfile } = useAuth();

  // const secretKey = "OmkwRU0_NvbHbR8fKekFOlMXWhUqCUoDNSK0wjjJ"; // Keep this key safe!
    const secretKey = process.env.REACT_APP_SECRET_KEY;
  const userIdentifier = userProfile?.user?.email || "support@travelcreators.com";

  // Generate the hash using crypto-browserify
  const hash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString();



  // Intercom({
  //   app_id: "j91ayenn",
  //   user_id: userProfile?.user?.id ? userProfile?.user?.id : 558555, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
  //   name: userProfile?.user?.first_name
  //     ? `${userProfile?.user?.first_name} ${userProfile?.user?.last_name}`
  //     : "Travel Creator", // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
  //   email: userProfile?.user?.email
  //     ? userProfile?.user?.email
  //     : "travel@travelcreators.com", // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
  //   created_at: Math.floor(Date.now() / 1000), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  // });

  Intercom({
    app_id: "j91ayenn",
    email: userProfile?.user?.email || "support@travelcreators.com", // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    user_hash: hash // IMPORTANT: Replace this with the HMAC code you generated on your server
  });

  return <></>;
}
