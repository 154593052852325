import { useLocation, useParams } from "react-router-dom";
import { useMaster } from "providers/MasterContext";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import {  TripDetailsFormProps } from "types/interfaces";
import apiCall from 'services/api';
import { AxiosError } from "axios";
import { useToast } from "providers/ToastProvider";

interface TripLocation {
    startLocation: string;
    endLocation: string;
}

const TripCreateView = ()=>{
    const { id } = useParams<{ id: string }>();
    const { state } = useLocation();
    const { locationsForDp } = useMaster()
    const { signOut } = useAuth()
    const { showToast, showSuccessToast, showInfoToast, showErrorToast } = useToast();
    const [tripData, setTripData] = useState<TripDetailsFormProps | null>(null);
    const [tripLocation, setTripLocation] = useState<TripLocation>({ startLocation: '', endLocation: '' });
    const trip = (state as { trip: TripDetailsFormProps })?.trip;
    // useEffect(() => {
    //     setTripData(state?.trips);
    // }, [state])

    useEffect(() => {
        let startLocation = locationsForDp?.find(location => Number(location.value) === Number(tripData?.start_location));
        let endLocation = locationsForDp?.find(location => Number(location.value) === Number(tripData?.end_location))
        setTripLocation({
            startLocation: startLocation?.label || '', // Use a fallback value
            endLocation: endLocation?.label || '' // Use a fallback value
        });
    }, [locationsForDp, tripData])

    useEffect(() => {
        const fetchTripData = async () => {
            try {
                const response = await apiCall({
                    url: `/trip/${id}/view`,
                    method: 'GET',
                });
                if (response.status === 200) {
                    const fetchedData = response.data;
                    if (JSON.stringify(fetchedData) !== JSON.stringify(tripData)) {
                        setTripData(fetchedData);
                    }
                }
            } catch  (error: any) {
                if ((error as AxiosError).response?.status === 401) {
                    showErrorToast(error?.message)
                    setTimeout(() => {
                        signOut();
                    }, 3000);
                }
            }
        };

        if (state?.trips) {
            setTripData(state.trips);
        } else {
            fetchTripData();
        }
    }, [id, state]);
    return(
        <>
        {/* <TripView tripData={tripData} tripLocation={tripLocation} viewType={1}/> */}
    </>
    )
}

export default TripCreateView