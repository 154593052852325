import { AxiosError } from 'axios'; // Import AxiosError for type checking
import { getHeaders } from './getHeaders';

export const markWishList = async (
    tripId: string,
    userTkn: string | null,
    currency: string,
    setTrips: React.Dispatch<React.SetStateAction<any[]>>,
    setShowIn: React.Dispatch<React.SetStateAction<boolean>>,
    apiCall: Function,
    showErrorToast: Function
) => {
    if (userTkn) {
        try {
            const response = await apiCall({
                url: `tour/${tripId}/wishlist`,
                method: 'POST',
                data: null,
                headers: getHeaders(userTkn, currency),
            });

            if (response?.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                    showErrorToast(errors);
                } else {
                    let responseData = response?.data;
                    setTrips((prevTrips) =>
                        prevTrips.map((trip) => {
                            if (trip.trip_uuid === responseData?.trip_uuid) {
                                return {
                                    ...trip,
                                    iswishlisted: responseData?.iswishlisted,
                                };
                            }
                            return trip;
                        })
                    );
                }
            }
        } catch (error: any) {
            if (error.isAxiosError) {
                const axiosError = error as AxiosError;

                if (axiosError.response?.status === 413) {
                    showErrorToast("Payload Too Large: Please reduce the size of your request.");
                } else {
                    const errorMessage = (axiosError.response?.data as { message?: string })?.message || 'An error occurred during upload.';
                    showErrorToast(errorMessage);
                }
            } else {
                showErrorToast(error?.message || 'An unknown error occurred.');
            }
        }
    } else {
        showErrorToast("Login First, for marking wishlist");
        setShowIn(true)
    }
};
