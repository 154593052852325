import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  path: string,
  // icon: Icon;
  labelDisabled?: boolean;
  // pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'Trip and Destinations',
    horizontalNavLabel: 'Trip and Destinations',
    active: true,
    path: "/trips-and-destination",
    // icon: UilChartPie,
    labelDisabled: true,
  },
  {
    label: 'Creators',
    horizontalNavLabel: 'Creators',
    active: true,
    path: "/our-creators",
    // icon: UilChartPie,
    labelDisabled: true,

  },
  {
    label: 'Become a Creator',
    horizontalNavLabel: 'Become a Creator',
    path: "/become-a-creator",
    active: true,
    // icon: UilChartPie,
    labelDisabled: true,

  },
  {
    label: 'FAQ',
    horizontalNavLabel: 'FAQ',
    active: true,
    path: "/faq",
    // icon: UilChartPie,
    labelDisabled: true,
  },
  {
    label: 'Blogs',
    horizontalNavLabel: 'Blogs',
    active: true,
    path: "/blogs",
    // icon: UilChartPie,
    labelDisabled: true,
  }
 
];
