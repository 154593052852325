import { StylesConfig } from "react-select";

export const customStylesSelect: StylesConfig<{ value: string; label: string }, false> = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#bfbfbf' : state.isFocused ? '#bfbfbf' : 'white',
        color: state.isSelected ? '#31374a' : '#31374a',
        padding: 10,
        fontSize: 14,
        fontWeight: 500
    }),
    control: (provided) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 0
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#31374a' : '#31374a',
        fontSize: 14,
        fontWeight: 500
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: 4,
        marginTop: 0,
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
    }),
    placeholder: (provided) => ({
        ...provided,
        color:'#1A1A2E',
        fontSize: '14px',
        fontWeight: 500
    }),
    input: (provided: any) => ({
        ...provided,
        color:'#1A1A2E',
        fontSize: '14px',
        fontWeight: 500
      }),

};