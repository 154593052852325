import DummyBanner from "assets/img/travel/banner/dummybanner.png";
import perfile1 from "assets/img/team/img2.png";
import Unicon from "components/base/Unicon";
import {
  UilFacebook,
  UilInstagram,
  UilTwitter,
} from "@iconscout/react-unicons";
import { Dropdown } from "react-bootstrap";
import Avatar from "components/base/Avatar";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import { useToast } from "providers/ToastProvider";
import { AxiosError } from "axios";
import {
  Agent,
  ChildItemAllBtnText,
  ColourOption,
  initialChildItemAllBtnText,
  Tags,
} from "types/interfaces";
import DummyTripAgent from "components/common/dummyview/DummyTripAgent";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPinterestP,
  faSquareYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { default as ReactSelect, components, StylesConfig } from "react-select";
import Testimonial from "components/asseenin/Testimonial";
import TripCard from "components/cards/TripsCard";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import { markWishList } from "services/markWishList";
import { useSignIn } from "providers/SignInProvider";
import NotFound from "components/404/NotFound";
import avtar1 from "assets/img/travel/profile/profile.jpg";

const colourStyles: StylesConfig<ColourOption> = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = "#E94560";
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#E94560"
        : isFocused
        ? "#e945601f"
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "#E94560"
          ? "#ffffff"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "#e945601f"
          : undefined,
      },
    };
  },
};

const AgentView = () => {
  const { signOut, userTkn } = useAuth();
  const { currency } = useMeta();
  const { setShowIn } = useSignIn();
  const { showErrorToast } = useToast();
  const { agentslug } = useParams<{ agentslug: string }>();
  const location = useLocation();
  const { tripsAll } = location.state || {};
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTrip, setLoadingTrip] = useState<boolean>(false);
  const [agentData, setAgentData] = useState<Agent | undefined>(undefined);
  const [tagsData, setTagsData] = useState<Tags | []>([]);
  const [trips, setTrips] = useState<any[]>([]);
  const [days, setDays] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState({ optionSelected: [] });
  const [selectedDays, setSelectedDays] = useState({ daysSelected: [] });
  const [sortBy, setSortBy] = useState("price");
  const [btnText, setBtnText] = useState<ChildItemAllBtnText>(
    initialChildItemAllBtnText
  );
  const handleMarkWishlist = async (tripId: string) => {
    await markWishList(
      tripId,
      userTkn,
      currency,
      setTrips,
      setShowIn,
      apiCall,
      showErrorToast
    );
  };

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    const fetchTripData = async () => {
      try {
        setLoading(true);
        const btnTextRequest = apiCall({
          url: `cms/content-items/all_btn_text`,
          method: "GET",
          headers: getHeaders(userTkn, currency),
        });

        // Define both API calls
        const agentRequest = apiCall({
          url: `agents/${agentslug}`,
          method: "GET",
          headers: getHeaders(userTkn, currency),
        });

        const tagsRequest = apiCall({
          url: `agents/${agentslug}/tags`,
          method: "GET",
          headers: getHeaders(userTkn, currency),
        });

        // const tripsRequest = apiCall({
        //     url: `tours?agent_id=${tripsAll?.agent?.id}&all-trip=1`,
        //     method: 'GET'
        // });
        const daysRequest = apiCall({
          url: `agents/${agentslug}/trip-lengths`,
          method: "GET",
          headers: getHeaders(userTkn, currency),
        });

        // Execute both requests in parallel
        const [btnTextResponse, agentResponse, tagsResponse, daysResponse] =
          await Promise.all([
            btnTextRequest,
            agentRequest,
            tagsRequest,
            daysRequest,
          ]);

        if (isMounted) {
          if (btnTextResponse?.status === 200) {
            let data =
              btnTextResponse?.data?.ContentItem?.data?.content?.childs;
            setBtnText(data);
          }
          if (agentResponse.status === 200) {
            if (agentResponse?.data?.errors) {
              const errors = agentResponse?.data.errors;
              showErrorToast(errors);
            } else {
              const data = agentResponse?.data?.Agent?.data;
              setAgentData(data);
            }
          }
          if (tagsResponse.status === 200) {
            if (tagsResponse?.data?.errors) {
              const errors = tagsResponse?.data.errors;
              showErrorToast(errors);
            } else {
              const tags = tagsResponse?.data?.TripTags;
              const options = tags?.map((item: any) => ({
                value: item.id,
                label: item?.name,
              }));
              setTagsData(options);
            }
          }

          if (daysResponse.status === 200) {
            if (daysResponse?.data?.errors) {
              const errors = daysResponse?.data.errors;
              showErrorToast(errors);
            } else {
              const days = daysResponse?.data?.TripLengths;
              const options = days?.map((item: any) => ({
                value: item.no_of_days,
                label: item?.name,
              }));
              setDays(options);
            }
          }
        }
      } catch (error: any) {
        if ((error as AxiosError).response?.status === 401) {
          showErrorToast(error?.message);
        } else if (error as AxiosError) {
          showErrorToast(error?.message);
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    if (agentslug) {
      fetchTripData();
    }

    // Cleanup function
    return () => {
      isMounted = false; // Mark component as unmounted
    };
  }, [agentslug, tripsAll, signOut, showErrorToast]);

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    const fetchTripData = async () => {
      try {
        setLoadingTrip(true);
        let tagsIds = selectedOption?.optionSelected?.map(
          (item: any, index: number) => item.value
        );
        let daysIds = selectedDays?.daysSelected?.map(
          (item: any, index: number) => item.value
        );
        const tripsRequest = apiCall({
          url: `tours?agent_id=${agentData?.id}&tags[0]=${tagsIds}&no_of_days[0]=${daysIds}&sort=${sortBy}&pagination=0`,
          method: "GET",
          headers: getHeaders(userTkn, currency),
        });

        // Execute both requests in parallel
        const [tripsResponse] = await Promise.all([tripsRequest]);

        if (isMounted) {
          if (tripsResponse.status === 200) {
            if (tripsResponse?.data?.errors) {
              const errors = tripsResponse?.data.errors;
              showErrorToast(errors);
            } else {
              const trips = tripsResponse?.data?.TripVersion?.data;
              setTrips(trips);
            }
          }
        }
      } catch (error: any) {
        if ((error as AxiosError).response?.status === 401) {
          showErrorToast(error?.message);
        } else if (error as AxiosError) {
          showErrorToast(error?.message);
        }
      } finally {
        if (isMounted) setLoadingTrip(false);
      }
    };

    if (agentslug) {
      fetchTripData();
    }

    // Cleanup function
    return () => {
      isMounted = false; // Mark component as unmounted
    };
  }, [
    agentslug,
    agentData,
    signOut,
    showErrorToast,
    selectedOption,
    selectedDays,
    sortBy,
    currency,
  ]);

  const Option = (props: any) => {
    return (
      <div className="trip-details-form">
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleMultiOptions = (selected: any) => {
    setSelectedOption({
      optionSelected: selected,
    });
  };
  const handleMultiOptionsDays = (selected: any) => {
    setSelectedDays({
      daysSelected: selected,
    });
  };
  const handleShortBy = (value: number) => {
    if (value === 1) {
      setSortBy("price");
    } else {
      setSortBy("-price");
    }
  };

  const socialMediaBaseUrls = {
    facebook: "https://www.facebook.com/",
    instagram: "https://www.instagram.com/",
    twitter: "https://twitter.com/",
    tiktok: "https://www.tiktok.com/",
    pinterest: "https://www.pinterest.com/",
    youtube: "https://www.youtube.com/",
  };

  const formatUrl = (url: string, baseUrl: string) => {
    return url.includes(baseUrl) ? url : `${baseUrl}${url}`;
  };

  return (
    <>
      {loading ? (
        <DummyTripAgent />
      ) : (
        <>
          {agentData ? (
            <>
              <section
                id="hero-faq"
                className="d-flex flex-column justify-content-center pb-0"
                style={{
                  background: `url(${
                    agentData?.coverImage?.url
                      ? agentData?.coverImage?.url
                      : DummyBanner
                  })`,
                }}
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-12 d-flex  flex-column">
                      <div className="center-img d-flex align-items-center justify-content-center">
                        <div className="rounded-circle-border">
                          {/* <img src={perfile1} alt="" /> */}
                          {agentData?.profileImage?.url ? (
                            <Avatar
                              src={
                                agentData?.profileImage?.url
                                  ? agentData?.profileImage?.url
                                  : avtar1
                              }
                              size="4xl"
                            />
                          ) : (
                            <Avatar src={avtar1} size="4xl" />
                          )}
                        </div>
                      </div>
                      <p className="hero-heading fw-bold high-light-white text-center">
                        {agentData?.name}{" "}
                      </p>
                      <p className="faq-sub-title mb-5">
                        {agentData?.sub_title
                          ? parse(agentData.sub_title || "")
                          : ""}
                      </p>

                      <div className="icons mt-5 d-flex gap-2">
                        {agentData?.facebook && (
                          <a
                            href={formatUrl(
                              agentData.facebook,
                              socialMediaBaseUrls.facebook
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook"
                          >
                            <Unicon
                              icon={UilFacebook}
                              size={30}
                              color="#E94560"
                            />
                          </a>
                        )}
                        {agentData?.instagram && (
                          <a
                            href={formatUrl(
                              agentData.instagram,
                              socialMediaBaseUrls.instagram
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook"
                          >
                            <Unicon
                              icon={UilInstagram}
                              size={30}
                              color="#E94560"
                            />
                          </a>
                        )}
                        {agentData?.twitter && (
                          <a
                            href={formatUrl(
                              agentData.twitter,
                              socialMediaBaseUrls.twitter
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook"
                          >
                            <Unicon
                              icon={UilTwitter}
                              size={30}
                              color="#E94560"
                            />
                          </a>
                        )}

                        {agentData?.tiktok && (
                          <a
                            href={formatUrl(
                              agentData.tiktok,
                              socialMediaBaseUrls.tiktok
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="TikTok"
                          >
                            <FontAwesomeIcon
                              icon={faTiktok}
                              color="#E94560"
                              className="fs-5"
                            />
                          </a>
                        )}

                        {agentData?.pinterest && (
                          <a
                            href={formatUrl(
                              agentData.pinterest,
                              socialMediaBaseUrls.pinterest
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Pinterest"
                          >
                            <FontAwesomeIcon
                              icon={faPinterestP}
                              color="#E94560"
                              className="fs-5"
                            />
                          </a>
                        )}
                        {agentData?.youtube && (
                          <a
                            href={formatUrl(
                              agentData.youtube,
                              socialMediaBaseUrls.youtube
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="YouTube"
                          >
                            <FontAwesomeIcon
                              icon={faSquareYoutube}
                              color="#E94560"
                              className="fs-5"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="pb-0 pt-0">
                <div className="container ">
                  <div
                    className="row justify-content-center mb-3"
                    style={{ background: "#f4f4f4" }}
                  >
                    <div className="col-xl-12 ">
                      <div className="Aboutme mt-3">
                        <p className="based">
                          Based in <br />
                          <span className="south">
                            {agentData?.location?.long_name ?? ""}
                          </span>{" "}
                        </p>
                        {agentData?.about && (
                          <>
                            <p className="main-heading fw-bold high-light-black pb-0">
                              {" "}
                              About<span className="high-light-red">
                                {" "}
                                me
                              </span>{" "}
                            </p>

                            <p className="small-text">
                              {agentData?.about
                                ? parse(agentData.about || "")
                                : ""}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="pb-0 pt-0">
                <div className="container p-0">
                  <div className="row ">
                    <p className="main-heading fw-bold high-light-black pb-0">
                      {" "}
                      My<span className="high-light-red"> trips</span>{" "}
                    </p>
                    <div className="ml-3 mr-3">
                      <hr className="hr-line " />
                      <div className="filtes-container ">
                        <div className="row">
                          <div className="col-lg-3 pb-4">
                            {Array.isArray(tagsData) && tagsData.length > 0 && (
                              <ReactSelect
                                options={tagsData}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                className={`tags-filter trips-select-filter-container`}
                                placeholder="Select Tags"
                                styles={colourStyles}
                                value={selectedOption.optionSelected}
                                components={{
                                  Option,
                                }}
                                onChange={handleMultiOptions}
                                classNamePrefix="trips-tags-filter"
                              />
                            )}
                          </div>
                          <div className="col-lg-3">
                            {Array.isArray(days) && days.length > 0 && (
                              <ReactSelect
                                options={days}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                className={`tags-filter trips-select-filter-container`}
                                placeholder="Select Days"
                                styles={colourStyles}
                                value={selectedDays.daysSelected}
                                components={{
                                  Option,
                                }}
                                onChange={handleMultiOptionsDays}
                                classNamePrefix="trips-tags-filter"
                              />
                            )}
                          </div>
                          <div className="col-lg-3"></div>
                          <div className="col-lg-3 d-flex justify-content-end">
                            <Dropdown className="custom-sort-dp">
                              <Dropdown.Toggle
                                variant="primary"
                                id="share-dropdown"
                              >
                                Sort by:{" "}
                                {sortBy === "price"
                                  ? "Low to High"
                                  : "High to Low"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  className={`item-option-dp ${
                                    sortBy === "price" ? "selected" : ""
                                  }`}
                                  onClick={() => {
                                    handleShortBy(1);
                                  }}
                                >
                                  Low to High
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={`item-option-dp ${
                                    sortBy === "-price" ? "selected" : ""
                                  }`}
                                  onClick={() => {
                                    handleShortBy(2);
                                  }}
                                >
                                  High to Low
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    {Array.isArray(trips) && trips.length > 0 ? (
                      <>
                        {trips?.map((item, index) => (
                          <TripCard
                            key={index}
                            item={item}
                            markWishList={handleMarkWishlist}
                            title={btnText?.trip_card_btn?.item_value}
                          />
                        ))}
                      </>
                    ) : (
                      <div className="col-12  d-flex justify-content-center">
                        <h3>Sorry No Trip Found!!</h3>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              <Testimonial />
            </>
          ) : (
            <>{!loading && <NotFound />}</>
          )}
        </>
      )}
    </>
  );
};

export default AgentView;
