import { StylesConfig } from "react-select";

export const customStylesSelect: StylesConfig<{ value: string; label: string }, false> = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#bfbfbf' : state.isFocused ? '#bfbfbf' : 'white',
        color: state.isSelected ? '#31374a' : '#31374a',
        padding: 10,
        fontSize: 18,
        fontWeight: 600
    }),
    control: (provided, state) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 5,
        borderWidth: 1,
        borderColor: state.isFocused ? '#E94560' : '#979797', // Border color changes on focus
        boxShadow: state.isFocused
        ? 'inset 0 1px 1px rgba(233, 69, 96, 0.075), 0 0 8px rgba(233, 69, 96, 0.6)'
        : provided.boxShadow,
        '&:hover': {
            borderColor: state.isFocused ? '#E94560' : provided.borderColor, // Border color remains on hover when focused
        }
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#31374a' : '#31374a',
        fontSize: 18,
        fontWeight: 600
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: 4,
        marginTop: 0,
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#9fa6bc',
        fontSize: '18px',
        fontWeight: 600
    }),
    input: (provided: any) => ({
        ...provided,
        color: '#31374a',
        fontSize: '18px',
        fontWeight: 600
    }),

};