// TripLikeIcon.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

interface TripLikeIconProps {
  iswishlisted: boolean; // Assuming `isWishlisted` is a boolean. Adjust if it is a number.
}

const TripLikeIcon: React.FC<TripLikeIconProps> = ({ iswishlisted }) => {
  return (
    <div className="trip-like">
      <FontAwesomeIcon
        icon={faHeart}
        className={`fs-12`}
        style={{color: iswishlisted ? '#E94560' : '#D9D9D94D'}}
      />
    </div>
  );
};

export default TripLikeIcon;
