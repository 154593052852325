import { Button, Card } from "react-bootstrap"
import CardImage from "assets/img/travel/banner/profileTrip.png";
import { useNavigate } from "react-router-dom";

const DummyNoTripBooked = () => {
    const navigate = useNavigate();
    return (
        <Card style={{ background: "#FFF9F8" }}>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                    <Card.Body>
                        <div>
                            <p className="main-heading fw-bold high-light-black">No trip booked...<span className="high-light-red">Yet!</span></p>
                        </div>
                        <div className="sub-heading high-light-black-light">
                            <span className="trip-text">Time to take your next break?</span><br />
                            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Nemo enim ipsam
                            voluptatem quia voluptas sit. consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore. Nemo enim ipsam voluptatem quia voluptas sit. consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt ut labore. Nemo enim ipsam voluptatem quia voluptas sit.
                        </div>
                        <div className="custom-btn-size mt-lg-5 pt-lg-5">
                            <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={() => { navigate("/trips-and-destination") }}>Browse Trips</Button>
                        </div>
                    </Card.Body>
                </div>
                <div className="col-12 col-sm-12 col-md-6 d-flex justify-content-center align-items-center p-5">
                    <Card.Img
                        variant="right"
                        src={CardImage}
                        className="img-fluid"
                        style={{ maxHeight: '100%', objectFit: 'contain' }}
                    />
                </div>
            </div>
        </Card>
    )
}

export default DummyNoTripBooked