
const MyAccount = () => {
    let text = "Comming Soon"
    return (

      <div className="vh-75 d-flex align-items-center justify-content-center">
      {text}
    </div>
    )
}

export default MyAccount
