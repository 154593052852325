import BannerImage from "assets/img/travel/banner/search.jpg";
import { Form, Spinner } from "react-bootstrap";
import { BecomeACreatorProps, initialBecomeACreator, initialBecomeACreatorError, OptionType } from "types/interfaces";
import CustomSelectForm from "components/select/CustomSelectForm";
import ReCAPTCHA from "react-google-recaptcha";
import { ChangeEvent, useRef, useState } from "react";
import { useToast } from "providers/ToastProvider";
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import useWindowWidth from 'hooks/useWindowWidth';
import { useNavigate } from "react-router-dom";
import Testimonial from "components/asseenin/Testimonial";
import { handleApiError } from "services/handleApiError";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";


const CreatorIntrestForm = () => {
    const recaptchaRef = useRef(null)
    const windowWidth = useWindowWidth();
    const navigate = useNavigate()
    const {  showSuccessToast, showErrorToast } = useToast();
    const { currency } = useMeta();
    const { userTkn, signOut } = useAuth();
    const [loading, setLoading] = useState<boolean>(false)
    const [location, setLocation] = useState<OptionType | null>(null);
    const [formData, setFormData] = useState<BecomeACreatorProps>(initialBecomeACreator);
    const [errors, setErrors] = useState(initialBecomeACreatorError)

    const handleLocation = (option: OptionType | null) => {
        setLocation(option)
        setFormData((prev) => ({
            ...prev,
            location_id: Number(option?.value || 0) // Assuming status is a number
        }));
        setErrors(prevState => ({
            ...prevState,
            location_id: ''
        }));
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, type, checked, value } = event.target;
        // Allow input only if it meets the length requirement
        if (name === "contact_no" && value.length > 15) {
            return;
        }


        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
        }));
        setErrors(prevState => ({
            ...prevState,
            [name]: ''
        }));
    };

    const handleInputChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        // Allow input only if it meets the length requirement



        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(prevState => ({
            ...prevState,
            [name]: ''
        }));
    };
    const handleRecaptchaChange = (token: string | null) => {
        setFormData(prevState => ({
            ...prevState,
            recaptcha: token
        }));
        setErrors(prevState => ({
            ...prevState,
            recaptcha: ''
        }));
    };


    const validateSocialMediaUrl = (url: string, platform: string): boolean => {
        let pattern;

        if (platform === 'facebook') {
            pattern = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9(\.\?)?]/;
        } else if (platform === 'instagram') {
            pattern = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._]+\/?$/;
        } else if (platform === 'pinterest') {
            pattern = /^(https?:\/\/)?(www\.)?pinterest\.com\/[a-zA-Z0-9_]+\/?$/;
        } else if (platform === 'tiktok') {
            pattern = /^(https?:\/\/)?(www\.)?tiktok\.com\/@[a-zA-Z0-9._]+\/?$/;
        } else if (platform === 'twitter') {
            pattern = /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/;
        } else if (platform === 'youtube') {
            pattern = /^(https?:\/\/)?(www\.)?youtube\.com\/(channel\/|c\/|user\/|@)[a-zA-Z0-9_-]+\/?$/;
        } else if (platform === 'other') {
            pattern = /^https:\/\/.+/; // Ensures the URL starts with https
        } else {
            return false;
        }

        return pattern.test(url);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { first_name, last_name, contact_no, email, location_id, recently_stayed_property, have_you_group_tour, why_you_wanna_join_us, add_me_to_creator_list, recaptcha, instagram_url, facebook_url, tik_tok_url, other_url, travel_type_focus_on, how_currently_monetize, plan_to_grow_your_audience } = formData;
        let errors = { ...initialBecomeACreatorError };


        let isValid = true;

        if (!first_name.trim()) {
            errors.first_name = "First Name is required";
            isValid = false;
        } else if (/^\d/.test(first_name)) {
            errors.first_name = "First Name cannot start with a number";
            isValid = false;
        } else if (first_name.length > 50) {
            errors.first_name = "First Name cannot exceed 50 characters";
            isValid = false;
        }


        if (!last_name.trim()) {
            errors.last_name = "Last Name is required";
            isValid = false;
        } else if (/^\d/.test(last_name)) {
            errors.last_name = "Last Name cannot start with a number";
            isValid = false;
        } else if (last_name.length > 50) {
            errors.last_name = "Last Name cannot exceed 50 characters";
            isValid = false;
        }

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim()) {
            errors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(email)) {
            errors.email = "Invalid email format";
            isValid = false;
        }

        // Validate phone
        const phoneRegex = /^[+]?[0-9]{1,15}$/;
        if (!contact_no.trim()) {
            errors.contact_no = "Phone number is required";
            isValid = false;
        } else if (!phoneRegex.test(contact_no)) {
            errors.contact_no = "Invalid phone number. Only [0-9] and + are allowed, and it should be a maximum of 15 digits.";
            isValid = false;
        }
        
        if (!location_id) {
            errors.location_id = "Location is required";
            isValid = false;
        }


        if (!recently_stayed_property.trim()) {
            errors.recently_stayed_property = "Recently stayed property is required";
            isValid = false;
        } else if (/^\d/.test(recently_stayed_property.trim())) {
            errors.recently_stayed_property = "Cannot start with a number";
            isValid = false;
        }

        if (!have_you_group_tour.trim()) {
            errors.have_you_group_tour = "This field is required";
            isValid = false;
        }

        if (!why_you_wanna_join_us.trim()) {
            errors.why_you_wanna_join_us = "Please explain why you want to join us";
            isValid = false;
        } else if (/^\d/.test(why_you_wanna_join_us.trim())) {
            errors.why_you_wanna_join_us = "Cannot start with a number";
            isValid = false;
        }


        if (add_me_to_creator_list !== 1) {
            errors.add_me_to_creator_list = "Please check your concent  - Add me to the list!";
            isValid = false;
        }
        // Validate each social media URL if it has a value
        if (facebook_url && !validateSocialMediaUrl(facebook_url, 'facebook')) {
            errors.facebook_url = 'Invalid Facebook URL';
            isValid = false;
        }

        if (instagram_url && !validateSocialMediaUrl(instagram_url, 'instagram')) {
            errors.instagram_url = 'Invalid Instagram URL';
            isValid = false;
        }


        if (tik_tok_url && !validateSocialMediaUrl(tik_tok_url, 'tiktok')) {
            errors.tik_tok_url = 'Invalid TikTok URL';
            isValid = false;
        }
        if (other_url && !validateSocialMediaUrl(other_url, 'other')) {
            errors.other_url = 'Invalid URL';
            isValid = false;
        }

        if (/^\d/.test(travel_type_focus_on.trim())) {
            errors.travel_type_focus_on = "Cannot start with a number";
            isValid = false;
        }

        if (/^\d/.test(how_currently_monetize.trim())) {
            errors.how_currently_monetize = "Cannot start with a number";
            isValid = false;
        }

        if (/^\d/.test(plan_to_grow_your_audience.trim())) {
            errors.plan_to_grow_your_audience = "Cannot start with a number";
            isValid = false;
        }

        // Validate reCAPTCHA
        if (!recaptcha) {
            errors.recaptcha = "Please complete the CAPTCHA";
            isValid = false;
        }

        if (!isValid) {
            setErrors(errors);
            return;
        }

        if (isValid) {
            try {
                setLoading(true)
                const formDataSubmit = new FormData();

                formDataSubmit.append('first_name', formData?.first_name);
                formDataSubmit.append('last_name', formData?.last_name);
                formDataSubmit.append('contact_no', formData?.contact_no);
                formDataSubmit.append('email', formData.email);
                formDataSubmit.append('location_id', String(formData?.location_id));
                formDataSubmit.append('instagram_url', formData?.instagram_url);
                formDataSubmit.append('tik_tok_url', formData?.tik_tok_url);
                formDataSubmit.append('facebook_url', formData?.facebook_url);
                formDataSubmit.append('other_url', formData?.other_url);
                formDataSubmit.append('travel_type_focus_on', formData?.travel_type_focus_on);
                formDataSubmit.append('how_currently_monetize', formData?.how_currently_monetize);
                formDataSubmit.append('plan_to_grow_your_audience', formData?.plan_to_grow_your_audience);
                formDataSubmit.append('recently_stayed_property', formData?.recently_stayed_property);
                formDataSubmit.append('have_you_group_tour', formData?.have_you_group_tour);
                formDataSubmit.append('why_you_wanna_join_us', formData?.why_you_wanna_join_us);
                formDataSubmit.append('add_me_to_creator_list', String(formData?.add_me_to_creator_list));


          

                const response = await apiCall({
                    url: `become-a-creator`,
                    method: 'POST',
                    data: formDataSubmit, // Change this line
                    headers: getHeaders(userTkn, currency)
                });

                if (response?.status === 200) {
                    if (response?.data?.errors) {
                        const errors = response?.data.errors;
                        // Dynamically set errors based on the response
                        Object.keys(errors).forEach((key) => {
                            const errorMessages = errors[key];
                            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                            // Set the error state for the corresponding field
                            setErrors((prevData: any) => ({
                                ...prevData,
                                [key]: firstErrorMessage,
                            }));
                        });
                    } else {
                        showSuccessToast("Your intrest submitted sucessfully!!");
                        navigate("/")

                    }

                }

            } catch (error:any) {
                handleApiError(error, showErrorToast, signOut);
            } finally {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);


            }
        } else {

            showErrorToast("Please fill in all required fields.");
        }

    }

    return (
        <> 
            {loading && <div className="loading-overlay">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="listing-spinner"
                />
            </div>
            }
            <section id="hero-faq" className="d-flex flex-column justify-content-center"
                style={{ background: `url(${BannerImage})` }}>

                <div className={`container-fluid const`}>
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <p className="hero-heading fw-bold high-light-white text-center">Travel Creators <span className="high-light-red"> Interest Form </span> </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="para-section">
                <div className="col-xl-12 container-fluid cont ">
                    <p className="alert-text">
                        !!ALERT!!: Phishing scams on LinkedIn, Facebook, and Telegram offering fake travel jobs <br /> for crypto payments. Trust only official creator sites. We don't deal in cryptocurrency. Stay <br /> alert!
                    </p>
                </div>
            </div>

            <section className="pb-0">
                <div className={`${windowWidth > 1024 ? 'container' : 'container-fluid'}   enquire-now-modal`}  >
                    <Form onSubmit={handleSubmit} className="enquire-now-form">
                        <div className="row justify-content-center mb-3">
                            <div className="col-xl-12 ">
                                <p className="main-heading fw-bold high-light-black"> Basic<span className="high-light-red">  Info</span> </p>
                            </div>

                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <Form.Label className="mb-1">First Name *</Form.Label>
                                        <Form.Control
                                            // required
                                            type="text"
                                            name="first_name"
                                            placeholder="First Name"
                                            value={formData.first_name}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.first_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.first_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group className="mb-3" controlId="lastName">
                                        <Form.Label className="mb-1">Last Name</Form.Label>
                                        <Form.Control
                                            // required
                                            type="text"
                                            name="last_name"
                                            placeholder="Last Name"
                                            value={formData.last_name}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.last_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.last_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group controlId="contactNo" className="mb-3">
                                        <Form.Label className="mb-1">Phone Number *</Form.Label>
                                        <Form.Control
                                            // required
                                            type="tel"
                                            name="contact_no"
                                            placeholder="Phone Number"
                                            value={formData.contact_no}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.contact_no}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contact_no}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group controlId="email" className="mb-3">
                                        <Form.Label className="mb-1">Email Address *</Form.Label>
                                        <Form.Control
                                            // required
                                            type="text"
                                            name="email"
                                            placeholder="Email Address"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <Form.Group className="text-start" controlId="locationId">
                                        <CustomSelectForm
                                            // required
                                            label={"Location *"}
                                            controlId={"location_id"}
                                            value={formData.location_id !== 0 ? location : { value: "", label: "" }}
                                            onChange={handleLocation}
                                            placeholder="Location"
                                            errors={errors.location_id}
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                        </div>

                        <div className="row justify-content-center mt-4 mb-4">
                            <div className="col-xl-12 ">
                                <p className="main-heading fw-bold high-light-black"> Social<span className="high-light-red">  Handles*</span> </p>
                            </div>

                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group controlId="instagramUrl" className="mb-3">
                                        <Form.Label className="mb-1">Insta</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="instagram_url"
                                            placeholder="Insta"
                                            value={formData.instagram_url}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.instagram_url}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.instagram_url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group controlId="tikTokUrl" className="mb-3">
                                        <Form.Label className="mb-1">Tik Tok</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="tik_tok_url"
                                            placeholder="Tik Tok"
                                            value={formData.tik_tok_url}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.tik_tok_url}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.tik_tok_url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group controlId="facebookUrl" className="mb-3">
                                        <Form.Label className="mb-1">FB</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="facebook_url"
                                            placeholder="FB"
                                            value={formData.facebook_url}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.facebook_url}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.facebook_url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group controlId="otherUrl" className="mb-3">
                                        <Form.Label className="mb-1">Other</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="other_url"
                                            placeholder="FB"
                                            value={formData.other_url}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.other_url}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.other_url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <p className="form-text">Which properties or hotels have you recently stayed at? </p>
                            </div>

                        </div>

                        <div className="row justify-content-center mt-4 mb-4">
                            <div className="col-xl-12 ">
                                <p className="main-heading fw-bold high-light-black"> More about<span className="high-light-red">  your creator focus</span> </p>
                            </div>

                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group controlId="travelTypeFocusOn" className="mb-3">
                                        <Form.Label className="mb-1">What travel niche do you focus on ?</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="travel_type_focus_on"
                                            placeholder="Enter"
                                            value={formData.travel_type_focus_on}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.travel_type_focus_on}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.travel_type_focus_on}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group controlId="howCurrentlyMonetize" className="mb-3">
                                        <Form.Label className="mb-1">How do you currently monetize ? </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="how_currently_monetize"
                                            placeholder="Enter"
                                            value={formData.how_currently_monetize}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.how_currently_monetize}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.how_currently_monetize}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </div>
                                <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
                                    <Form.Group controlId="planToGrowYourAudience" className="mb-3">
                                        <Form.Label className="mb-1">How do you plan to grow your audience ?  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="plan_to_grow_your_audience"
                                            placeholder="Enter"
                                            value={formData.plan_to_grow_your_audience}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.plan_to_grow_your_audience}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.plan_to_grow_your_audience}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <p className="form-text mb-5">Tell us a bit more about your travel passion, niche content and how you currently monetize.</p>
                            </div>

                            <div className="row mt-5">
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    <Form.Group controlId="recentlyStayedProperty" className="mb-3">
                                        <Form.Label className="mb-1">Which properties or hotels have you recently stayed at? *  </Form.Label>
                                        <Form.Control
                                            // required
                                            type="text"
                                            name="recently_stayed_property"
                                            placeholder="Enter"
                                            value={formData.recently_stayed_property}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.recently_stayed_property}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.recently_stayed_property}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                                    {/* <Form.Group controlId="haveYouGroupTour" className="mb-3">
                                        <Form.Label className="mb-1">Have you been on a group tour ? * </Form.Label>
                                        <Form.Control
                                            // required
                                            type="text"
                                            name="have_you_group_tour"
                                            placeholder="Enter"
                                            value={formData.have_you_group_tour}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.have_you_group_tour}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.have_you_group_tour}
                                        </Form.Control.Feedback>
                                    </Form.Group> */}
                                    <Form.Group controlId="haveYouGroupTour" className="mb-3">
                                        <Form.Label className="mb-1">Have you been on a group tour ?</Form.Label>
                                        <Form.Select
                                            name="have_you_group_tour"
                                            value={formData.have_you_group_tour}
                                            onChange={handleInputChangeSelect}
                                            isInvalid={!!errors.have_you_group_tour}
                                            aria-label="Have you been on a group tour ?"
                                        >
                                            <option value="">Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.have_you_group_tour}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
                                    <Form.Group controlId="whYouWannaJoinUs" className="mb-3">
                                        <Form.Label className="mb-1">Lastly, tell us why YOU want to join the Travel Creators 2.0 BETA ? * </Form.Label>
                                        <Form.Control
                                            // required
                                            type="text"
                                            name="why_you_wanna_join_us"
                                            placeholder="Enter"
                                            value={formData.why_you_wanna_join_us}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.why_you_wanna_join_us}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.why_you_wanna_join_us}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-sm-12 col-12 ">
                                <div className="px-3 mb-3">
                                    <Form.Group controlId="add_me_to_creator_list" className="mb-3">
                                        <Form.Check
                                            type='checkbox'
                                            name="add_me_to_creator_list"
                                            label={"I am happy to hear from the Travel Creators team - Add me to the list!"}
                                            checked={formData.add_me_to_creator_list === 1}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.add_me_to_creator_list}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.add_me_to_creator_list}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                            </div>

                            <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
                                <div className="px-3 mb-3">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LcBwTEqAAAAAKuwQiSRUYl95xpyZIet55neOE8g" // Replace with your actual site key
                                        onChange={handleRecaptchaChange}
                                    />
                                    {errors.recaptcha && (
                                        <div className="error-feedback">{errors.recaptcha}</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center cancelSubmitButtons mt-4">
                            <button className="buttonCancel mx-7" onClick={() => { navigate("/become-a-creator") }}>Cancel</button>
                            <button className="buttonSubmit mx-7" type="submit" disabled={loading}>Submit</button>
                        </div>
                    </Form>
                </div>
            </section>


            <Testimonial />

        </>
    )
}

export default CreatorIntrestForm