import { AxiosError } from "axios";

// Utility function to handle API errors
export const handleApiError = (error: any, showErrorToast: (message: string) => void, signOut: () => void) => {
    if ((error as AxiosError).response?.status === 401) {
        showErrorToast(error?.message);
        setTimeout(() => {
            signOut();
        }, 3000);
    } else if ((error as AxiosError).response?.status === 413) {
        showErrorToast("Payload Too Large: Please reduce the size of payload.");
    } else if ((error as AxiosError).response?.status === 403) {
        showErrorToast(error?.message + ' You are not allowed to perform this action.');
    } else if ((error as AxiosError).response) {
        showErrorToast(error?.message);
    } else if (error.request) {
        console.error('error.request:', error.request);
        showErrorToast('Network error: Please check your connection.');
    } else {
        console.error('An unexpected error occurred:', error.message);
        showErrorToast('An unexpected error occurred. Please try again later.');
    }
};
