export function formattedDate(dateStr) {
    // Create a new Date object
    const dateObj = new Date(dateStr);
  
    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(dateObj);
  
    return formattedDate;
  }
  

  