import { useEffect, useRef } from 'react';
import Avatar from 'components/base/Avatar';
import { Dropdown, Nav } from 'react-bootstrap';
import avatar57 from 'assets/img/travel/profile/profile.jpg';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { useAppContext } from 'providers/AppProvider';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Button from 'components/base/Button';
import { useSignUp } from 'providers/SignUpProvider';
import { useSignIn } from 'providers/SignInProvider';
import { useAuth } from 'providers/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBarsProgress, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ProfileDropdownMenuCreator from './ProfileDropdownMenuCreator';
import useWindowWidth from 'hooks/useWindowWidth';


const NavItems = () => {
  const { config: { navbarPosition } } = useAppContext();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const windowWidth = useWindowWidth()
  const navigate = useNavigate()
  const { show, setShow } = useSignUp();
  const { showIn, setShowIn } = useSignIn();
  const { isAuthenticated, userType, creatorPanel, creatorDashboard, setCreatorDashboard, userProfile } = useAuth()
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);


  const handleShow = () => { setShow(true); }
  const handleShowIn = () => { setShowIn(true); }

  const handleCreator = (value: string) => {
    if (value = 'manage') {
      navigate('/creator/dashboard');
    } else if (value = 'back') {
      navigate('/');
    }
    creatorPanel()

  }


  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (openDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown]);

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">

      {windowWidth > 767 && <div className="d-flex gap-3 header-btn">
        {!isAuthenticated && <Button variant="outline-primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleShowIn}> Log in </Button>}
        {!isAuthenticated && <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={handleShow}> Sign Up </Button>}
        {/* <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" > My Account</Button> */}
      </div>
      }
      {(isAuthenticated && (userType === 2 || userType === 3 || userType === 4) && Boolean(creatorDashboard) === false) &&
        <div className="d-flex header-btn">
          {windowWidth < 768 ?
            <>
              {windowWidth >= 425 &&
                <Button variant="primary" onClick={() => { handleCreator('manage') }}><FontAwesomeIcon icon={faBarsProgress} className='profile-btn-icon' /></Button>
              }
            </>
            :
            <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={() => { handleCreator('manage') }}> Manage</Button>}

        </div>
      }
      {(isAuthenticated && (userType === 2 || userType === 3 || userType === 4) && Boolean(creatorDashboard) === true) &&
        <div className="d-flex header-btn">
          {windowWidth < 768 ?
            <Button variant="primary" onClick={() => { handleCreator('back') }}><FontAwesomeIcon icon={faChevronLeft} className='profile-btn-icon' /></Button>


            : <Button variant="primary" className="flex-grow-1 flex-lg-grow-0" onClick={() => { handleCreator('back') }}> back</Button>}
        </div>
      }
      {isAuthenticated &&
        <Nav.Item ref={dropdownRef}>
          <Dropdown autoClose={"inside"} className="h-100 custom-profile" show={openDropdown}
            onToggle={(isOpen) => setOpenDropdown(isOpen)}>
            <Dropdown.Toggle
              as={Button}
              // to="#!"
              className="custom-profile-btn  d-flex align-items-center"
              variant=""
            >
              <Avatar src={userProfile?.user?.image?.url ?? avatar57} size="m" className='' /> {windowWidth < 1400 ? '' : 'My Account'}
              <FontAwesomeIcon icon={faAngleDown} className='profile-btn-icon' />
            </Dropdown.Toggle>
            {!Boolean(creatorDashboard) ?
              <ProfileDropdownMenu closeDropdown={closeDropdown} navigate={navigate} /> :
              <ProfileDropdownMenuCreator closeDropdown={closeDropdown} navigate={navigate} />
            }
          </Dropdown>
        </Nav.Item>

      }
    </div>
  );
};

export default NavItems;
