import React from 'react';

interface NumberToDivsProps {
  number: number;
}

const NumberToDivs: React.FC<NumberToDivsProps> = ({ number }) => {
  // Convert number to string, split into individual digits, and map over them
  const digits = number.toString().split('');

  return (
    <>
      {digits?.map((digit, index) => (
        <div key={index}>{digit}</div>
      ))}
    </>
  );
};

export default NumberToDivs;
