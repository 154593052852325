import { Button } from "react-bootstrap";
import rectangle26 from "../../assets/img/travel/creator/rectangle26.png";
import rectangle26_small from "../../assets/img/travel/creator/rectangle26_small.png";
import rectangle27small from "../../assets/img/travel/creator/rectangle27small.png";
import rectangle28small from "../../assets/img/travel/creator/rectangle28small.png";
import rectangle29small from "../../assets/img/travel/creator/rectangle29small.png";
import rectangle30small from "../../assets/img/travel/creator/rectangle30small.png";
import "../../assets/css/sidharth.css";
import { useNavigate } from "react-router-dom";
import apiCall from "services/api";
import { useEffect, useState } from "react";
import { handleApiError } from "services/handleApiError";
import { useToast } from "providers/ToastProvider";
import { useAuth } from "providers/AuthContext";
import {
  ChildItemAllBtnText,
  ChildItemsBecome,
  initialChildItemAllBtnText,
  initialChildItemsBecome,
} from "types/interfaces";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import parse from "html-react-parser";

const BecomeCreators = () => {
  let text = `Become A <span className="high-light-red">Creator</span>`;
  const { showErrorToast } = useToast();
  const { userTkn, signOut } = useAuth();
  const { currency } = useMeta();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [becomeContentItems, setBecomeContentItems] =
    useState<ChildItemsBecome>(initialChildItemsBecome);
  const [btnText, setBtnText] = useState<ChildItemAllBtnText>(
    initialChildItemAllBtnText
  );

  const handleNavigation = () => {
    navigate("/intrest-form");
  };

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        setLoading(true);

        const [pageDataResponse, contentResponse, btnTextResponse] =
          await Promise.all([
            apiCall({
              url: `cms/become-a-creator`,
              method: "GET",
              headers: getHeaders(userTkn, currency),
            }),
            apiCall({
              url: `cms/content-items/become_a_creator`,
              method: "GET",
              headers: getHeaders(userTkn, currency),
            }),
            apiCall({
              url: `cms/content-items/all_btn_text`,
              method: "GET",
              headers: getHeaders(userTkn, currency),
            }),
          ]);

        if (pageDataResponse?.status === 200) {
          let data = pageDataResponse?.data?.StaticPage?.data?.section;
          setPageData(data);
        }

        if (contentResponse?.status === 200) {
          let data = contentResponse?.data?.ContentItem?.data?.content?.childs;
          setBecomeContentItems(data);
        }

        if (btnTextResponse?.status === 200) {
          let data = btnTextResponse?.data?.ContentItem?.data?.content?.childs;
          setBtnText(data);
        }
      } catch (error: any) {
        handleApiError(error, showErrorToast, signOut);
      } finally {
        setLoading(false);
      }
    };

    fetchTripData();
  }, []);

  return (
    <div className="">
      <section
        id="hero-faq"
        className="d-flex flex-column justify-content-center heading"
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="hero-heading fw-bold high-light-white text-center">
                {becomeContentItems?.banner_text_3?.item_value ?? "Become a "}{" "}
                <span className="high-light-red">
                  {becomeContentItems?.banner_text_2?.item_value ?? "Creator"}
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>

      {Array.isArray(pageData) && pageData.length > 0 ? (
        <>
          {pageData.map((item, index) => (
            <section
              className={`py-3 ${index % 2 === 0 ? "" : "creator-bg"}`}
              key={index}
            >
              <div className="container-fluid">
                <div className="row">
                  {/* Text Section: Left for even index, Right for odd index */}
                  <div
                    className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 d-flex align-items-center ${
                      index % 2 === 0 ? "order-md-1" : "order-md-2"
                    }`}
                  >
                    <div className="bcm-creator p-lg-5 p-sm-0">
                      <div className="number-big">{item?.pre_heading}</div>
                      <div className="main-heading fw-bold high-light-black">
                        {item?.heading ? parse(item?.heading || "") : ""}
                      </div>
                      <div className="brand-title">
                        {item?.content ? parse(item?.content || "") : ""}
                      </div>
                      {btnText?.join_wait_list?.item_value && (
                        <div className="custom-btn-size mt-lg-5 pt-lg-5">
                          <Button
                            variant="primary"
                            className="flex-grow-1 flex-lg-grow-0"
                            onClick={handleNavigation}
                          >
                            {btnText?.join_wait_list?.item_value ??
                              "Join Waitlist"}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Image Section: Right for even index, Left for odd index */}
                  <div
                    className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 vh-80 d-flex justify-content-center justify-sm-content-center justify-md-content-center justify-lg-content-center justify-xl-content-end justify-xxl-content-end p-5 p-sm-0 ${
                      index % 2 === 0 ? "order-md-2" : "order-md-1"
                    }`}
                  >
                    <img
                      src={item?.sideImage?.url ?? rectangle26}
                      alt={item?.sideImage?.alt ?? "banner"}
                      className="img-sec img-sec-bcm-4"
                    />
                  </div>
                </div>
              </div>
            </section>
          ))}
        </>
      ) : (
        <></>
      )}

    </div>
  );
};

export default BecomeCreators;
