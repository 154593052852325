import { useEffect, useState } from "react";
import apiCall from "services/api";
import Testimonial from "components/asseenin/Testimonial";
import parse from 'html-react-parser';
import { initialStaticPages, StaticPagesProps } from "types/interfaces";
import { useMeta } from "providers/MetaContext";
import { getHeaders } from "services/getHeaders";
import { useAuth } from "providers/AuthContext";


const CommunityGuidline = () => {
  const { currency } = useMeta();
  const { signOut, userTkn } = useAuth()
  const [communityData, setCommunityData] = useState<StaticPagesProps>(initialStaticPages);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiCall({
          url: `cms/community-guidelines`,
          method: 'GET',
          headers: getHeaders(userTkn, currency)
          
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Handle errors if needed
          } else {
            let communityData = response?.data?.StaticPage?.data
            setCommunityData(communityData);

          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <section id="hero-faq" className="d-flex flex-column justify-content-center heading">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <p className="hero-heading fw-bold high-light-white text-center"> Community<span className="high-light-red"> Guidlines</span></p>
                </div>
              </div>
            </div>
          </section>


          <section className="pb-0">
            <div className="container-fluid cont">
              <div className="row justify-content-center">
                <div className="col-xl-12 ">
                  <p className="main-heading  fw-bold high-light-black pb-0"> {communityData?.heading} </p>
                  <p className="small-text"> {communityData?.description ? parse(communityData?.description || '') : ''} </p>
                </div>
              </div>
            </div>
          </section>

          <Testimonial />

        </>
      )}
    </>
  )
}

export default CommunityGuidline