import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Trip, TripDetailsFormProps } from "types/interfaces";

// Define a type for the props that the TripsProvider will receive
interface TripsProviderProps {
  children: ReactNode;
}

interface TripsContextType {
  trips: TripDetailsFormProps[];
  setTrips: React.Dispatch<React.SetStateAction<TripDetailsFormProps[]>>;
}

const TripsContext = createContext<TripsContextType | undefined>(undefined);

export const TripsProvider: React.FC<TripsProviderProps> = ({ children }) => {
  const [trips, setTrips] = useState<TripDetailsFormProps[]>([]);

  return (
    <TripsContext.Provider value={{ trips, setTrips }}>
      {children}
    </TripsContext.Provider>
  );
};

export const useTrips = () => {
  const context = useContext(TripsContext);
  if (!context) {
    throw new Error('useTrips must be used within a TripsProvider');
  }
  return context;
};
