import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  Icon,
  UilBlogger,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilHome,
  UilLocationPoint,
  UilPuzzlePiece,
  UilUserCircle
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  path: string,
  icon: Icon;
  labelDisabled?: boolean;
  // pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'Home',
    horizontalNavLabel: 'Dashboard',
    active: true,
    path: "/creator/dashboard",
    icon: UilHome,
    labelDisabled: true,
  },
  {
    label: 'Listing',
    horizontalNavLabel: 'Listing',
    active: true,
    path: "/creator/trips",
    icon: UilLocationPoint,
    labelDisabled: true,

  },
  {
    label: 'Leads',
    horizontalNavLabel: 'Leads',
    path: "/creator/leads",
    active: true,
    icon: UilUserCircle,
    labelDisabled: true,

  },
  {
    label: 'Travelers',
    horizontalNavLabel: 'Travelers',
    path: "/creator/travelers",
    active: true,
    icon: UilUserCircle,
    labelDisabled: true,

  },
  // {
  //   label: 'Blogs',
  //   horizontalNavLabel: 'Blogs',
  //   path: "/creator/blogs",
  //   active: true,
  //   icon: UilBlogger,
  //   labelDisabled: true,

  // },
  {
    label: 'Pending for Approval',
    horizontalNavLabel: 'Pending for Approval',
    path: "/creator/trips/pending-for-approval",
    active: true,
    icon: UilUserCircle,
    labelDisabled: true,

  }
 
];
