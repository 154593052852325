import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { formattedDate } from 'pages/blogs/formattedDate';

const BlogCard: React.FC<{ item: any }> = ({ item }) => {
    const navigate = useNavigate();


    return (
        <Col xs={12} md={12} lg={6} xl={6} className="mb-5">
            <Card className="card-trip blogs-card" onClick={() => navigate(`/blogs/${item?.slug}`)}>
                <Card.Img className='customCardImg' variant="top" src={item?.listingImage?.url ?? 'thumb4'} alt={item?.listingImage?.name ?? 'image'} />
                <Card.Body>
                    <Card.Title>
                        <p className="card-heading">{item?.title ?? ''}</p>
                    </Card.Title>
                    <div className="d-flex blog-detail">
                        <div>
                            by <span className="stick">{item?.author ?? ""}</span>
                        </div>
                        <div className="line mx-2">|</div>
                        <div>{formattedDate(item?.published_date_time)}</div>
                    </div>
                    <div className="mt-3 trip-discription">
                        <div>
                            {item?.description ? parse(item?.description || '') : ''}
                        </div>
                    </div>
                </Card.Body>
                {/* <Card.Footer className="footer-card">
                            <p className="f-name">Category name</p>
                          </Card.Footer> */}
            </Card>
        </Col>
    );
};

export default BlogCard;




